export const UNAUTHORIZED = "HUB1001"; // Incorrect password entered
export const ACOUNT_LOCKED = "HUB1002"; // Account Locked
export const NOT_FOUND = "HUB1003"; // Incorrect username entered
export const BACKEND_API_ERROR = 422;
export const NETWORK_TIMEOUT = 598;
export const NO_USAGE_DATA = "HUB2001";
export const CIN_NOT_FOUND = "HUB6404";
export const LWB_ERROR = 599;
export const LWB_TOKEN_VERIFICATION_ERROR = 597;
export const EMAIL_ALREADY_EXIST = "HUB1005";
export const ROI_NOT_FOUND = 600;
