import React from "react";
import styles from "./table.module.scss";

interface ITableDescription {
  heading: string;
  columnHeading: string;
  rows?: ITableRow[];
}
interface ITableRow {}

interface IProps {
  metadata: ITableDescription;
}

const TableComponent: React.FC<IProps> = ({
  metadata: { heading, columnHeading, rows },
}) => {
  const renderRows = (rows) => {
    return (rows || []).map((row, index) => {
      return (
        <tr className={styles.wrapper_heading} style={row.style} key={index}>
          {(row.content || []).map((column, i) => {
            return (
              <td colSpan={column.colspan} key={i}>
                <span>{column.content}</span>
              </td>
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className={styles.table_wrapper} data-test="table-component">
      <div className={styles.table_heading}>{heading}</div>
      <table cellSpacing="0" className={styles.tableContainer}>
        <tbody>{renderRows(rows)}</tbody>
      </table>
    </div>
  );
};

export default TableComponent;
