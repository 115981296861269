import { Component, Fragment } from "react";
import classNames from "classnames";
import styles from "./style.module.scss";
import FocusLock from 'react-focus-lock';
export interface Props {
  title?: any;
  body?: any;
  footer?: any;
  isVisible?: any;
  className?: string;
  contentClassName?: string;
  modalRef?: any;
}

export default class Modal extends Component<Props> {
  render() {
    const { title, body, footer, isVisible, className, contentClassName } = this.props;
   
   
    return (
      <Fragment>
       
        {isVisible ? (
        <FocusLock disabled={!isVisible} returnFocus={isVisible}>
          <div className={styles.modalContainer} data-test="modal-component" aria-modal="true" role="dialog" aria-labelledby="dialog_title">
            <div className={styles.modalContainer_mask} />
            <div className={styles.modalContainer_wrap}>
              <div className={className}>
              <div
                className={classNames(styles.modalContainer_modal)}
              >
                <div className={classNames(styles.modal_content, contentClassName)}>
                  {title ? (
                    <div
                       id="dialog_title"
                      className={styles.modal_header}
                      data-test="modal-title"
                    >
                      {title}
                    </div>
                  ) : (
                    ""
                  )}
                  {body ? (
                    <div className={styles.modal_body} data-test="modal-body">
                      {body}
                    </div>
                  ) : (
                    ""
                  )}
                  {footer ? (
                    <div
                      className={styles.modal_footer}
                      data-test="modal-footer"
                    >
                      {footer}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              </div>
            </div>
          </div>
          </FocusLock>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}
