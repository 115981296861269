import SignUpSecondPage from "./SignUpSecondPage";
import {
  SIGNUP_QUERY_AFTER_VALIDATION,
  GET_HEADER_DETAILS,
} from "services/GraphQL/gqlQueries";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { addMatomoEvent, getRefreshTokenExpiryTime } from "utlis/generalUtils";
import { withRouter } from "react-router-dom";
import { ShowErrorSignUpStateModel } from "redux/models/ShowErrorPopupSignUp";
import { LoginStateModel } from "redux/models/LoginStateModel";
import { IdleSessionModel } from "redux/models/IdleSessionModel";
import { setSessionEvents } from "components/IdleSession/IdleSession";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import { setThemeBrandWise, settingURL } from "themeApplicator";
import setting from "services/RestAPI/axiosConfig";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import * as _ from "lodash";
import { PRODUCT_DETAILS } from "config/constants";
import { TokenTimeout } from "redux/models/TokenTimeout";
import { LoginEmailModel } from "redux/models/LoginEmailModel";

const SignUpSecondPageContainer = (props) => {
  const querySignupData = async (values) => {
    let { confirmEmail, confirmPassword } = values;
    let {
      memberId,
      membershipId,
      packageId,
      firstName,
      lastName,
    } = props.history.location.state;

    const { tenantId } = BrandSpecificDataModel.getInsatnce("1")
      ? BrandSpecificDataModel.getInsatnce("1").props
      : "";

    return await graphQlCall(
      "mutation",
      SIGNUP_QUERY_AFTER_VALIDATION,
      {
        memberId: memberId,
        email: confirmEmail.toLowerCase(),
        password: confirmPassword,
        membershipId: membershipId,
        packageId: packageId,
        firstName: firstName,
        lastName: lastName,
      },
      {
        tenantId: tenantId,
      },
      "false"
    )
      .then((data) => {
        window.scrollTo(0, 0);
        const response = data.data["signupAndLogin"];
        return graphQlCall(
          "query",
          GET_HEADER_DETAILS,
          {
            id: response["memberId"],
          },
          {
            tenantId: tenantId,
          }
        )
          .then((resp) => {
            if (response && response["accessToken"]) {
              new LoginStateModel({
                id: "LoginDetails",
                LoginData: response,
              }).$save();

              let tokenExpiryTime = getRefreshTokenExpiryTime();
              if (tokenExpiryTime) {
                new TokenTimeout({
                  id: "1",
                  timeOut: tokenExpiryTime,
                }).$save();
                setSessionEvents();
              }
            }
            new IdleSessionModel({
              id: "1",
              sessionStatus: "active",
            }).$save();

            new UserDetailModel({
              id: "LoggedUser",
              LoggedInUserDetails: resp.data,
            }).$save();

            let account = resp.data.member.account;
            let defaultAccountType = resp.data.member.account.find(
              (account) => {
                return account.membershipId === response.membershipId;
              }
            );
            let {
              createdOn,
              membershipId,
              memberNumber,
              accountId,
            } = defaultAccountType;
            let {
              packageName: defaultUser,
              packageId: selectedPackageId,
              description: packageDescription,
              productDefinition: selectedProductDefinition,
              benefits: accountBenefits,
              siteTheme: selectedTheme,
            } = defaultAccountType.package;

            let slicedAccountId = accountId.slice(accountId.length - 4);
            let link_title =
              account.length === 1
                ? packageDescription
                : `${slicedAccountId} - ${packageDescription}`;

            let sortedBenefits = PRODUCT_DETAILS[selectedProductDefinition.id]
              ? _.sortBy(accountBenefits, "benefitDisplayOrder")
              : "";

            new SelectedAccountModel({
              id: "1",
              type: defaultUser,
              creationDate: createdOn,
              packageId: selectedPackageId,
              packageDescription: packageDescription,
              productDefinition: selectedProductDefinition,
              benefits: sortedBenefits,
              membershipId: membershipId,
              linkTitle: link_title,
              memberNumber: memberNumber,
              cin: resp.data.member.cin,
              memberId: defaultAccountType.memberId,
            }).$save();

            new LoginEmailModel({
              id: "1",
              loginEmail: resp.data.member.email,
            }).$save();

            new ThemeNameModel({
              id: "1",
              name: selectedTheme
                ? selectedTheme
                : process.env.REACT_APP_THEME_NAME,
            }).$update("1");

            setting.get(settingURL).then((resp) => {
              setThemeBrandWise(selectedTheme, resp.data);
            });
            addMatomoEvent('nm/generic/registrationdonewithMS','nm/generic/registrationdonewithMS');
            props.history.push("/home");
          })
          .catch((err) => {
            showError(err);
          });
      })
      .catch((error) => {
        showError(error);
      });
  };
  const handleSubmit = (values) => {
    querySignupData(values);
  };

  const showError = (err) => {
    let isShowErrorMessage = onGqlError(err);
    new ShowErrorSignUpStateModel({
      id: "ShowError",
      isShowError: true,
      isShowErrorMsg: isShowErrorMessage,
    }).$save();
    props.history.push("/user-signup");
  };

  return (
    <div>
      <SignUpSecondPage onHandleSubmit={handleSubmit} />
    </div>
  );
};

export default withRouter(SignUpSecondPageContainer);
