export const benefitMapping = {
  ADIACE: {
    REGISTRATION: `You have registered for Accidental Death Insurance.`,
    TRANSACTION: null,
    CLAIM: `You have a claim amount #ClaimAmount# and received settlement amount of #DefaultCurrency##SettleMentAmount#.`,
    DEFAULT: null,
  },
  AIRLOUNGEAFI: {
    REGISTRATION: null,
    TRANSACTION: `You visited the airport lounge at #Location#.`,
    CLAIM: null,
    DEFAULT: null,
  },
  AIRLOUNGECPP: {
    REGISTRATION: `You have registered for Airport Angel.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: `You have registered for Airport Angel.`,
  },
  AIRLOUNGEPPS: {
    REGISTRATION: null,
    TRANSACTION: `You visited #AirportDescription# Lounge at #TravelDestination#`,
    CLAIM: null,
    DEFAULT: null,
    TRANSACTION_N: `You visited #AirportDescription# Lounge at #TravelDestination# with #PassengerCount# guest(s) and incurred a guest charge of £#OrderAmount#`,
  },
  AIRLOUNGETCK: {
    REGISTRATION: null,
    TRANSACTION: `You visited the airport lounge at #Location#.`,
    CLAIM: null,
    DEFAULT: null,
  },
  CARBREAKDOWNUKI: {
    REGISTRATION: null,
    TRANSACTION: null,
    CLAIM: `You have claimed on the Car Breakdown service.`,
    DEFAULT: `You have claimed on the Car Breakdown service.`,
  },
  CONCIERGETEN: {
    REGISTRATION: null,
    TRANSACTION: `You used the Concierge Service.`,
    CLAIM: null,
    DEFAULT: null,
  },
  CONCIERGEWHT: {
    REGISTRATION: null,
    TRANSACTION: `You have used the Concierge service.`,
    CLAIM: null,
    DEFAULT: `You have used the Concierge service.`,
  },
  DATAPATROLGLK: {
    REGISTRATION: `You have registered for Data Patrol.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  DININGAFI: {
    REGISTRATION: null,
    TRANSACTION: `You have requested a Dining service.`,
    CLAIM: null,
    DEFAULT: null,
  },
  DISCOUNTCARDABS: {
    REGISTRATION: `You have registered for Retail Discount Card.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  EVENTBOOKINGAFI: {
    REGISTRATION: null,
    TRANSACTION: `You booked #TicketCount# #EventCategory# Tickets and received #DefaultCurrency##DiscountAmount#.`,
    CLAIM: null,
    DEFAULT: null,
  },
  EXWARRANTYAFI: {
    REGISTRATION: `You have registered for Extended Warranty.`,
    TRANSACTION: null,
    CLAIM: `You have made a claim on #ClaimType#. Your claim was settled to the value of #DefaultCurrency##SettlementAmount#.`,
    DEFAULT: null,
  },
  EXWARRANTYDGN: {
    REGISTRATION: `You have registered for Extended Warranty.`,
    TRANSACTION: null,
    CLAIM: `You have registered #x# appliances.  You have made a claim on #ClaimType#. Your claim was settled to the value of #DefaultCurrency##SettlementAmount#.`,
    DEFAULT: null,
  },
  FILMRENTALLFM: {
    REGISTRATION: `You have registered for the LOVEFiLM service.`,
    TRANSACTION: `You have used the LOVEFiLM service.`,
    CLAIM: null,
    DEFAULT: null,
  },
  GADGETINSCPP: {
    REGISTRATION: `You have registered for Gadget Insurance.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  HANDBAGCPP: {
    REGISTRATION: `You have registered for Handbag Insurance.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  HOMEMERGUKI: {
    REGISTRATION: null,
    TRANSACTION: null,
    CLAIM: `You have claimed on the Home Emergency service.`,
    DEFAULT: `You have claimed on the Home Emergency service.`,
  },
  HOTELSAFI: {
    REGISTRATION: null,
    TRANSACTION: `You have booked a #BookingType# and received discount of #DefaultCurrency##DiscountAmount#.`,
    CLAIM: null,
    DEFAULT: `You have booked a #BookingType# and received discount of #DefaultCurrency##DiscountAmount#.`,
  },
  IDTHEFTCPP: {
    REGISTRATION: `You have registered for ID Theft Assistance Service`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: `You have registered for ID Theft Assistance Service`,
  },
  MPICPP: {
    REGISTRATION: `You have registered for Mobile Phone Insurance.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  MPICPW: {
    REGISTRATION: `You have registered a device on your Mobile Phone Insurance policy.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  MUSICDOWNLOAD7DG: {
    REGISTRATION: `You have registered for the hmvdigital music download service.`,
    TRANSACTION: `You have used the hmvdigital music download service.`,
    CLAIM: null,
    DEFAULT: null,
  },
  NATIONALTRUSTAFI: {
    REGISTRATION: null,
    TRANSACTION: `You have downloaded a National Trust pass.`,
    CLAIM: null,
    DEFAULT: `You have downloaded a National Trust pass.`,
  },
  NATIONALTRUSTREPLACEAFI: {
    REGISTRATION: null,
    TRANSACTION: `You have requested a replacement National Trust pass.`,
    CLAIM: null,
    DEFAULT: `You have requested a replacement National Trust pass`,
  },
  NUMBERSVAULTCPP: {
    REGISTRATION: `You have registered for Numbers Vault.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  PCPAFI: {
    REGISTRATION: `You have registered for Card Protection.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  PCPCPP: {
    REGISTRATION: `You have registered for Card Protection.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  PRIORITYPASSAFI: {
    REGISTRATION: `You have registered for Priority Pass.`,
    TRANSACTION: null,
    CLAIM: null,
    DEFAULT: null,
  },
  PRIVILCARDMET: {
    REGISTRATION: null,
    TRANSACTION: `You have used the Days Out card service.`,
    CLAIM: null,
    DEFAULT: null,
  },
  PURCHASEPROTRSA: {
    REGISTRATION: null,
    TRANSACTION: null,
    CLAIM: `You have made a claim on #ClaimType#. Your claim was settled to the value of #DefaultCurrency##SettlementAmount#.`,
    DEFAULT: null,
  },
  RAILCARD2TC: {
    REGISTRATION: `You have registered for Railcard.`,
    TRANSACTION: `Your Rail card was sent and provides #DiscountAmount#% discount.`,
    CLAIM: null,
    DEFAULT: null,
  },
  TRAVELAFI: {
    REGISTRATION: null,
    TRANSACTION: `You have booked a #BookingType# and received discount of #DefaultCurrency##DiscountAmount#.`,
    CLAIM: null,
    DEFAULT: null,
  },
  TRAVELINSUKI: {
    REGISTRATION: null,
    TRANSACTION: `You have booked a #BookingType# and received discount of #DiscountValue#.`,
    CLAIM: null,
    DEFAULT: null,
  },
  TRAVELMONEYTCS: {
    REGISTRATION: null,
    TRANSACTION: `You have used the Travel Money service.`,
    CLAIM: null,
    DEFAULT: `You have used the Travel Money service.`,
  },
  TRAVELTCK: {
    REGISTRATION: null,
    TRANSACTION: `You have booked a #BookingType# and received discount of #DefaultCurrency##DiscountAmount#.`,
    CLAIM: null,
    DEFAULT: null,
  },
  WWTRAVELINSUKI: {
    REGISTRATION: null,
    TRANSACTION: null,
    CLAIM: `You have claimed on Travel Insurance.`,
    DEFAULT: `You have claimed on Travel Insurance.`,
  },
};
