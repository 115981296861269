import React, { Fragment, useState, useEffect } from "react";
import styles from "./home.module.scss";
import { Card, CardHeader, CardFooter, CardBody } from "components/Card";
import { History } from "history";
import { getBenefits, getBenefitIconObj, getBrandName } from "services/RestAPI/exploreCalls";
import { HeroBanner } from "components/HeroBanner";
import { Button } from "components/Button";
import { HeroBannerWithVideo } from "components/HeroBannerWithVideo";
import { MarkedComponent } from "components/MarkedComponent";
import { Link, withRouter } from "react-router-dom";
import { Section } from "components/Section";
import { NoticeSection } from "components/NoticeSection";
import { connect } from "react-redux";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { addGAEvent, addMatomoEvent, getBenefitPath, getTrackingUrl } from "utlis/generalUtils";
import { cinemaRegex, ticketRegex } from "config/constants";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { Modal } from "components/Modal";
import { PrimaryButton } from "components/PrimaryButton";
import {
  BRAND_ULSTER_NI,
} from "../../../config/constants";
interface IProps {
  history: History;
  themeName: any;
  selectedAccount: SelectedAccountModel;
  userInstance: UserDetailModel;
}

interface benefit {
  id: number;
  title: string;
  icon: string;
  content: string;
  buttonText: string;
  text: string;
  buttonUrl?: string;
  url?: string;
  path: string;
}

const Home: React.FC<IProps> = ({
  history,
  themeName,
  selectedAccount,
  userInstance,
}) => {
  let currentTheme = themeName.name;
  let cards: benefit[] = [];
  const { benefits, type } =
    selectedAccount && selectedAccount[0].props && selectedAccount[0].props;

  const loggedInUserData =
    userInstance &&
    userInstance.props &&
    userInstance.props.LoggedInUserDetails;

  const APIBenefits =
    loggedInUserData &&
    loggedInUserData.member &&
    loggedInUserData.member.account[0] &&
    loggedInUserData.member.account[0].package &&
    loggedInUserData.member.account[0].package.benefits;

  const initialBannerData = {
    type: "HeroBanner",
    content: {
      headingText: "",
      textInfo: "",
      bannerImageUrl: "",
      bannerImageHref: "",
      appStoreButtons: [{ imageUrl: "", href: "" }],
      buttonText: "",
      imageUrl: "",
      bannerNote: "",
      secondaryBannerNote: ""
    },
  };
  const initialNoticeData = {
    metaData: {
      noticeHeader: {
        heading: "",
        headerIcon: "",
        headerBackgroundColor: "",
      },
      content: [
        {
          type: "notice",
          content: {
            className: "",
            heading: "",
            content: ""
          }
        }
      ]
    }
  }
  const [benefitCards, setBenefitCards]: any[] = useState([]);
  const [blocks, setBlocks]: any = useState([]);
  const [bannerData, setBannerData] = useState(initialBannerData);
  const [sectionData, setSectionData] = useState([]);
  const [noticeData, setNoticeData] = useState(initialNoticeData);
  const [maxHeaderHeight, setHeaderHeight] = useState(0);
  const [maxBodyHeight, setBodyHeight] = useState(0);
  const [maxFooterHeight, setFooterHeight] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [benefit1001, setBenefit1001]: any = useState([]);
  const [benefit1028, setBenefit1028]: any = useState([]);
  const [cinemaRedirection, setCinemaRedirection]: any = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const getPageContent = async () => {
    let resp = await getBenefits();
    setBannerData(resp.banner);
    setBlocks(resp.benefitBlocks);
    setSectionData(resp.section);
    setNoticeData(resp.noticeSection);
    getBenefitCards();
  };
  const getButtonDetails = (benefitObj) => {
    let buttonDetails = {
      buttonText: "",
      buttonUrl: "",
      text: "Explore",
    };
    const { benefitType, redirectionUrl, benefitId, benefitName } = benefitObj;
    let benefitTitle = benefitName.toLowerCase();
    if (benefitType === "PARTNER_JUMP" || benefitType === "PARTNER_JUMP_DISABLE_EXPLORE") {//PARTNER_JUMP benefit type will be routed to the generated page.
      buttonDetails.buttonText = "Use benefit";
      if (cinemaRegex.test(benefitTitle)) {
        buttonDetails.buttonUrl = "/partner-jump/" + benefitId + "/Cinema/Use-Benefit";
      } else {
        buttonDetails.buttonUrl = "/partner-jump/" + benefitId + "/Use-Benefit";
      }
    } else if (benefitType === "REDIRECTION" || benefitType === "REDIRECTION_DISABLE_EXPLORE") {//REDIRECTION benefit type will be redirected to the url mentioned in DB.
      buttonDetails.buttonText = "Use benefit";
      buttonDetails.buttonUrl = redirectionUrl;
    } else if (benefitType === "STATIC") {//STATIC benefit type will remove the "Use benefit" button from the tile.
      buttonDetails.buttonText = "";
      buttonDetails.buttonUrl = "";
    } else if (benefitType === "DISABLED") {//DISABLED benefit type will remove the "Use benefit" button and "Explore" link from the tile.
      buttonDetails.buttonText = "";
      buttonDetails.buttonUrl = "";
      buttonDetails.text = "";
    }
    //PARTNER_JUMP_DISABLE_EXPLORE benefit type will be routed to the generated page adn Explore button will be disabled.
    //REDIRECTION_DISABLE_EXPLORE benefit type will be redirected to the url mentioned in DB and Explore button will be disabled.
    if (benefitType === "PARTNER_JUMP_DISABLE_EXPLORE" || benefitType === "REDIRECTION_DISABLE_EXPLORE") {
      buttonDetails.text = "";
    }
    // TODO: 3202 - Needs to remove the condition on the travel money issue resolve 
    if (benefitId === 1006) {
      buttonDetails.text = "";
    }
    if(benefitId == 1007){
      buttonDetails.text = "More Information";
    }
    return buttonDetails;
  };

  const getBenefitCards = async () => {
    let benefitIconObj = await getBenefitIconObj(currentTheme);
    // eslint-disable-next-line array-callback-return
    (benefits || []).map((benefitObj, i) => {
      let contentNotice = '';
      if(benefitObj.benefitId === 1007) {
        contentNotice =  benefitIconObj['travelServiceText'];
      }
      let cardObj: benefit = {
        id: 0,
        title: "",
        icon: "",
        content: "",
        buttonText: "",
        buttonUrl: "",
        url: "",
        path: "",
        text: ""
      };
      let explorePath = getBenefitPath(benefitObj.benefitName);
      let explorePathWithId = `${explorePath}?bId=${benefitObj.benefitId}`;
      let buttonDetails = getButtonDetails(benefitObj);
      cardObj["id"] = benefitObj.benefitId;
      cardObj["title"] = benefitObj.benefitName;
      cardObj["icon"] = benefitIconObj[benefitObj.benefitName];
      cardObj["content"] = benefitObj.shortDescription.concat(contentNotice);
      cardObj["buttonText"] = buttonDetails.buttonText;
      cardObj["text"] = buttonDetails.text;
      cardObj["buttonUrl"] = buttonDetails.buttonUrl;
      cardObj["benefitType"] = benefitObj.benefitType;
      cardObj["path"] = explorePathWithId;
      if (benefitObj.benefitId === 1028 || benefitObj.benefitId === 1029) {
        setBenefit1028(cardObj);
        return;
      }
      if(benefitObj.benefitId === 1004 ){
        setCinemaRedirection(cardObj);
      }
      cards.push(cardObj);
    });
    setBenefitCards(cards);
  };

  useEffect(() => {
    if (benefits) {
      getPageContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTheme, benefits]);
  
  const BrandLoaded = getBrandName();

  useEffect(() => {
     const modalShown = localStorage.getItem('modalShown');
   if (!modalShown && BrandLoaded !== BRAND_ULSTER_NI) {
    setPopupVisible(true);
    localStorage.setItem('modalShown', 'true');
     }
  }, []);

  const renderBlocks = () => {
    return (
      <div className={styles.block_container}>
        {(blocks || []).map(
          ({ heading, blockText, buttonUrl, buttonText }, componentKey) => {
            return (
              <div className={styles.block_content} key={componentKey}>
                <h2>{heading}</h2>
                <MarkedComponent rawString={blockText} />
                <Link to={buttonUrl} tabIndex="-1">
                  <Button
                    classNames={styles.block_button}
                    text={buttonText}
                    disabled={false}
                  />
                </Link>
              </div>
            );
          }
        )}
      </div>
    );
  };

  const pushHeaderRef = (ref) => {
    setTimeout(() => {
      if (ref && ref.clientHeight > maxHeaderHeight) {
        setHeaderHeight(ref.clientHeight);
      }
    }, 1);
  };

  const pushBodyRef = (ref) => {
    setTimeout(() => {
      if (ref && ref.clientHeight > maxBodyHeight) {
        setBodyHeight(ref.clientHeight);
      }
    }, 1);
  };
  const pushFooterRef = (ref) => {
    setTimeout(() => {
      if (ref && ref.clientHeight > maxFooterHeight) {
        setFooterHeight(ref.clientHeight);
      }
    }, 1);
  };

  const renderExternalLink = (value) => {
    if (value.id === 1001 || value.id === 1014) {
      setBenefit1001(value);
      setIsVisible(true);
      return;
    }
    const { buttonUrl, url, title, benefitType } = value;
    let newTitle = title.toLowerCase();
    return url
      ? window.open(url)
      : cinemaRegex.test(newTitle) || ticketRegex.test(newTitle)
        ? history.push(buttonUrl)
        : openRedirectionLink(buttonUrl, benefitType, title);
  };

  const handleModalClick = (value) => {
    const { buttonUrl, title, benefitType } = value;
    openRedirectionLink(buttonUrl, benefitType, title);
  }

  const openRedirectionLink = (buttonUrl, benefitType, title) => {
    if (benefitType === "REDIRECTION") {
      let selectedAccount = SelectedAccountModel.getInsatnce("1");
      let trackingUrl = getTrackingUrl(selectedAccount, history.location);
      let splitUrl = trackingUrl.split("/");
      let newUrl = splitUrl.slice(0, 2);
      trackingUrl = newUrl.join("/");
      trackingUrl = trackingUrl + "/" + title + "/Use-Benefit/" + splitUrl[splitUrl.length - 1];
      trackingUrl = trackingUrl.toLowerCase().split(' ').join('-');
      addMatomoEvent(trackingUrl, trackingUrl);
      addGAEvent(trackingUrl);
    }
    window.open(buttonUrl);
  }

  const renderCards = () => {
    return (benefitCards || []).map((value: benefit) => {
      return (
        <div className={styles.card_container} key={value.id}>
          <Card className={styles.card}>
            <div ref={(ref) => pushHeaderRef(ref)}>
              <CardHeader
                classNames={styles.card_header}
                cardHeading={value.title}
                iconName={value.icon}
                cardHeaderHeight={maxHeaderHeight + "px"}
              />
            </div>
            <div ref={(ref) => pushBodyRef(ref)}>
              <CardBody
                classNames={styles.card_body}
                cardBodyContent={
                  value.content ? (
                    <MarkedComponent rawString={value.content} />
                  ) : null
                }
                cardBodyHeight={maxBodyHeight + "px"}
              />
            </div>
            <div
              ref={(ref) => pushFooterRef(ref)}
              className={styles.card_footer_main_div}
            >
              <CardFooter
                classNames={styles.card_footer}
                buttonText={value.buttonText}
                text={value.text}
                iconName="icon-chevron-circle-right"
                onButtonClick={() => renderExternalLink(value)}
                cardFooterHeight={maxFooterHeight + "px"}
                exploreLink={value.path}
                cardHeading={value.title}
              />
            </div>
          </Card>
        </div>
      );
    });
  };

  const renderHeroBanner = () => {
    return (
      <Fragment>
        <HeroBanner metaData={bannerData.content} />
      </Fragment>
    );
  };

  const renderHeroBannerWithVideo = () => {
    return (
      <Fragment>
        <HeroBannerWithVideo metaData={bannerData.content} />
      </Fragment>
    );
  };

  const typeMapping = {
    HeroBanner: renderHeroBanner,
    HeroBannerWithVideo: renderHeroBannerWithVideo,
  };

  const renderBanner = () => {
    return typeMapping[bannerData.type]();
  };

  const renderSection = () => {
    return sectionData ? (
      <Section metaData={{ content: sectionData }}></Section>
    ) : null;
  };

  const renderNoticeSection = () => {
    return noticeData ? (
      <NoticeSection metaData={noticeData.metaData}></NoticeSection>
    ) : null;
  };

  const toggleModal = (option) => {
    if (option === "open") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const togglePopupModal = (option) => {
    if (option === "open") {
      setPopupVisible(true);
    } else {
      setPopupVisible(false);
    }
  };

  return benefits && benefits.length !== 0 ? (
    <Fragment>
      <div className={styles.page}>
        {renderBanner()}
        {renderNoticeSection()}
        {renderSection()}
        <div className={styles.cards}>{renderCards()}</div>
        {renderBlocks()}
      </div>
      <Modal
        title={
          <Fragment>
            <div className={styles.importantText}>Important Information</div>
            <div className={styles.imageHeading}><span className={styles.text}>Travel Insurance</span></div>
            <div className={styles.modalHeader} style={{
              backgroundImage: `url("/asset/header-bg.png")`,
            }}>

            </div>
            <PrimaryButton
              text={" Close"}
              disabled={false}
              iconImage={"/asset/icons-close.png"}
              classNames={styles.primary_button_close}
              handleClick={() => toggleModal("close")} />
          </Fragment>
        }
        body={
          <>
            <p className={styles.heading}>
              On 1 February 2024 we changed the provider of our travel insurance from U K Insurance Limited to AWP P&C S.A. administered by AWP Assistance UK Ltd (trading as Allianz Assistance)
            </p>
            <p className={styles.info}> <img className={styles.img} src="/asset/info-icon.png" /> Please carefully read the options listed below to direct you to the correct provider for your circumstances and make your enquiry as smooth as possible.
            </p>
            <div className={styles.modalCards}>
              <div className={styles.modalCardContainer}>
                <div className={styles.modalcardHeader} style={{
                  backgroundImage: 'url("/asset/rectangle.png")'
                }}>
                </div>
                <div className={styles.modalCardBody}>
                  <div className={styles.title}>
                    New upgrades or claims for incidents that happened on or after 1 February 2024
                  </div>
                  <div className={styles.text}>
                    Allianz Assistance will support you in these scenarios.
                  </div>

                  <button type="button" className={styles.cardButton} onClick={() => handleModalClick(benefit1028)}>
                    Allianz Assistance
                  </button>

                </div>
              </div>
              <div className={styles.modalCardContainer}>
                <div className={styles.modalcardHeader} style={{
                  backgroundImage: 'url("/asset/modal2.png")'
                }}>
                </div>
                <div className={styles.modalCardBody}>
                  <div className={styles.title}>
                    New or existing claims for any incidents that happened prior to 1 February 2024
                  </div>
                  <div className={styles.text}>
                    U K Insurance Limited (UKI) will support you with these claims. They will also support with existing UKI upgrades and related documents.
                  </div>

                  <button type="button" className={styles.cardButton} onClick={() => handleModalClick(benefit1001)}>
                    U K Insurance Limited
                  </button>

                </div>
              </div>
            </div>
          </>

        }
        isVisible={isVisible}
        className={styles.modalInsurance}
      />
      {isPopupVisible && (
        <Modal
          title={
            <Fragment>
            
            <button className={styles.popupCloseBtn}
            onClick={()=>togglePopupModal("close")}
             >
              <img src={"/asset/icons8-close.png"} alt="closeIcon"></img>
            </button>
            <div className={styles.popupInfo}>Discounted Cinema Tickets</div>
            </Fragment>
          }
          body={
            <>
              <div className={styles.popupCards}>
                <div className={styles.modalCardContainer}>
                  <div
                    className={styles.modalcardHeader}
                    style={{
                      backgroundImage: 'url("/asset/PaddingtoninPeru.png")',
                    }}
                  ></div>
                 
                </div>
                <div className={styles.modalCardContainer}>
                  <div className={styles.modalcardHeader}>
                    <span className={styles.popupText}>Paddington in Peru</span>
                    <br />

                    <div className={styles.popupBody}>
                      <span className={styles.popupReleaseDate}>
                        Out in Cinemas on 8<sup>th</sup> November
                      </span><br/>
                      <a className={styles.popupReleaseDate} href="https://www.youtube.com/watch?v=lKgitu25ZAg" target='_blank' >Trailer</a>
                      <br/>
                      <div className={styles.buttonWrapper}>
                      <PrimaryButton text={"Book your Cinema Tickets"} disabled={false} 
                      handleClick={() => renderExternalLink(cinemaRedirection)}/>
                      </div>
                        
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          isVisible={isPopupVisible}
          className={styles.popupContent}
          contentClassName={styles.contentClassName}
        />
      )}
    </Fragment>
  ) : APIBenefits ? null : (
    <div className={styles.page_empty}>
      The {type ? type : "account"} is discontinued.
    </div>
  );
};

const mapStateToProps = () => {
  let selectedAccount = SelectedAccountModel.list();
  const userInstance = UserDetailModel.getInsatnce("LoggedUser");
  return {
    themeName: ThemeNameModel.getInsatnce("1").props,
    selectedAccount,
    userInstance,
  };
};

export default withRouter(connect(mapStateToProps)(Home));
