import { useState, Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ForgotPasswordSecondPage from "./ForgotPasswordSecondPage";
import { FORGOT_PASSWORD_RESET } from "services/GraphQL/gqlQueries";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { getURLParams } from "utlis/generalUtils";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { connect } from "react-redux";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import { getBrandName } from "services/RestAPI/exploreCalls";

const ForgotPasswordSecondPageContainer = (props) => {
  const [backendErrors, setError] = useState([]);
  const [tenantId, setTenantId] = useState("");
  let params = getURLParams(props.location.search);

  useEffect(() => {
    getSettingConfig().then((resp) => {
      let brand = getBrandName().toUpperCase();
      setTenantId(resp.BRAND_SPECIFIC_TENANT_ID[brand]);
    });
  }, []);

  const queryForgotPasswordData = async (values) => {
    let { password } = values;
    return await graphQlCall(
      "mutation",
      FORGOT_PASSWORD_RESET,
      { password: password, forgotPasswordToken: params[0].resetToken },
      {
        tenantId: tenantId,
      },
      "false"
    )
      .then((data) => {
        const response = data.data["resetPassword"];
        if (response && response.returnDetails.code === "200") {
          props.history.push("/forgot-password-success");
        } else {
          props.history.push("/forgot-password");
        }
      })
      .catch((error) => {
        return setError(onGqlError(error));
      });
  };
  const onHandleSubmit = (values) => {
    queryForgotPasswordData(values);
  };

  return (
    <Fragment>
      <ForgotPasswordSecondPage
        errorSet={backendErrors}
        onHandleSubmit={onHandleSubmit}
      />
    </Fragment>
  );
};

const mapStateToProps = () => {
  return {
    tenantDetails: BrandSpecificDataModel.getInsatnce("1"),
  };
};

export default connect(mapStateToProps)(
  withRouter(ForgotPasswordSecondPageContainer)
);
