import { useEffect, useState } from "react";
import { ChangeEmailForm } from "./ChangeEmailForm";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { queryLoginData } from "utlis/loginUtils";
import { onGqlError } from "services/GraphQL/gqlHelper";
import { removeSessionEvents } from "components/IdleSession/IdleSession";
const ChangeEmailContainer = (props) => {
  const [backendErrors, setError] = useState([]);
  const { history } = props;
  useEffect(() => {
    populateBreadCrumbs();
    if(history.location.state !== undefined)
    setError(onGqlError(history.location.state));
    //setError([]);
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Change Email",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  const handleNextClick = (values) => {
    let newEmailValue = values.newEmail;  
    queryLoginData(values.oldEmail, values.password).then((data) => {
      let { type, value } = data;
      switch (type) {
        case "LoginSuccess": 
          history.push("/account/confirm-email", { newEmailValue });
          break;
        case "Invalid":
          setError(onGqlError(value));
          break;
        case "Error":
          if(value.graphQLErrors[0].extensions.error_code === 'HUB1002'){
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.clear();
            removeSessionEvents();
            history.push("/login",value);
          }else {
            setError(onGqlError(value));
          } 
          break;
      }
    });
  };

  return (
    <ChangeEmailForm handleNextClick={handleNextClick} error={backendErrors} />
  );
};

export default ChangeEmailContainer;
