import React from "react";
import settings from "../../services/RestAPI/axiosConfig";
import { withRouter } from "react-router-dom";
import { getTrackingUrl } from "utlis/generalUtils";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
interface IProps {
  history: History;
  location: Location;
}
class GoogleAnalytics extends React.Component<IProps> {
  history = this.props.history;
  location = this.props.location;

    renderGAScript = async function (trackingUrl) {
    settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const trackingKeys = response.data.GA_TRACKING_KEY;
        let brand = process.env.REACT_APP_THEME_NAME;
        let gaTrackKey = trackingKeys[`${brand}`];
        let gASrcUrl = `https://www.googletagmanager.com/gtag/js?id=${gaTrackKey}`;
        trackingUrl = trackingUrl.toLowerCase().split(' ').join('-');
        var scriptText: string = ` window.dataLayer = window.dataLayer || [];
                                   function gtag(){dataLayer.push(arguments);}
                                   gtag('js', new Date());
                                   gtag('config', '${gaTrackKey}', {
                                    'page_title' : '${trackingUrl}',
                                    'page_path': '${trackingUrl}'
                                  });`;
        const scriptGAUrl = document.createElement("script");
        scriptGAUrl.src = `${gASrcUrl}`;
        scriptGAUrl.async = true;
        if(document.getElementById("ot-group-id-C0002")){
            let checkboxCondition = document.getElementById("ot-group-id-C0002");
            let ariaChecked = checkboxCondition? checkboxCondition.getAttribute("aria-checked") : null;
            if(ariaChecked === "true"){
                scriptGAUrl.type = "text/javascript";
            }
            else{
                scriptGAUrl.type = "text/plain";
            }
          }
          else{
            scriptGAUrl.type = "text/plain";
          }
        scriptGAUrl.className = "optanon-category-C0002";
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        if(document.getElementById("ot-group-id-C0002")){
            let checkboxCondition = document.getElementById("ot-group-id-C0002");
            let ariaChecked = checkboxCondition? checkboxCondition.getAttribute("aria-checked") : null;
            if(ariaChecked === "true"){
              script.type = "text/javascript";
            }
            else{
              script.type = "text/plain";
            }
          }
          else{
            script.type = "text/plain";
          }
        script.className = "optanon-category-C0002";
        script.text = scriptText;
        head.appendChild(script);
        head.appendChild(scriptGAUrl);
      })
      .catch((error) => console.log(error));
  };

  removePreviousScript= async function (){
    settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const trackingKeys = response.data.GA_TRACKING_KEY;
        let brand = process.env.REACT_APP_THEME_NAME;
        let gaTrackKey = trackingKeys[`${brand}`];
        const allScripts = document.getElementsByTagName("script");
    for(let i=0;i<allScripts.length;i=i+1){
      if(allScripts[i].outerHTML.includes(gaTrackKey)){
        allScripts[i].remove();
      }
    }
      }).catch((error) => console.log(error));
  }

  componentDidMount(){
    let trackingURL = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), this.props.location);
    this.removePreviousScript();
    this.renderGAScript(trackingURL);
  }t

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let trackingURL = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), this.props.location);
      this.removePreviousScript();
      this.renderGAScript(trackingURL);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(props => <GoogleAnalytics {...props} />);
