import { BaseModel } from "./BaseModel";

interface ICaptchaModelProps {
  id: string;
  captchaSiteKey: string;
}
/**
 * To store the captchaSiteKey from setting.json.
 * @export
 * @class captchaSiteKey
 * @extends {BaseModel<IDummyModelProps>}
 */
export class CaptchaModel extends BaseModel<ICaptchaModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ICaptchaModelProps) {
    super(props);
  }

  static resource = "captchaSiteKey";
}
