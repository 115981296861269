import React from "react";
import settings from "../../services/RestAPI/axiosConfig";
import { withRouter } from "react-router-dom";
import { getTrackingUrl, getAdobeTrackingUrl } from "utlis/generalUtils";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
interface IProps {
  history: History;
  location: Location;
}
class AdobeAnalytics extends React.Component<IProps> {
  history = this.props.history;
  location = this.props.location;

    renderAdobeScript = async function (adobeTrackingUrl) {
    settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const adobeSettings = response.data.ADOBE_SETTINGS;

        const trackingScriptUrl = adobeSettings.TRACKING_URL_ADOBE;
        var scriptText: string = ` var str = 'RBS/PERSONAL/MembershipBenefits/${adobeTrackingUrl}';
        var adobeUrl = '${trackingScriptUrl}';
        str = str.replace(/\\s/g, '',str)
        str = str.replace(/\\//g, '>',str)
        str = str.toUpperCase();
       if (str.charAt(str.length - 1) == '>')
        { str = str.substr(0, str.length - 1); };
        window.tmParam =
        { sc_platform : 'NWmembershipbenefits', sc_pagename : 'INFO: '+str };`;
        const scriptAdobeUrl = document.createElement("script");
        scriptAdobeUrl.src = `${trackingScriptUrl}`;
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.text = scriptText;
        head.appendChild(script);
        head.appendChild(scriptAdobeUrl);
      })
      .catch((error) => console.log(error));
  };

  removePreviousScript= async function (){
    settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const adobeSettings = response.data.ADOBE_SETTINGS;
        const trackingScriptUrl = adobeSettings.TRACKING_URL_ADOBE;
        const allScripts = document.getElementsByTagName("script");
    for(let i=0;i<allScripts.length;i=i+1){
      if(allScripts[i].outerHTML.includes(trackingScriptUrl)){
        allScripts[i].remove();
      }
    }
      }).catch((error) => console.log(error));
  }

  componentDidMount(){
    let trackingURL = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), this.props.location);
    let adobeTrackingUrl = getAdobeTrackingUrl(SelectedAccountModel.getInsatnce("1"), trackingURL);
    this.removePreviousScript();
    this.renderAdobeScript(adobeTrackingUrl);
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let trackingURL = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), this.props.location);
      let adobeTrackingUrl = getAdobeTrackingUrl(SelectedAccountModel.getInsatnce("1"), trackingURL);
      this.removePreviousScript();
      this.renderAdobeScript(adobeTrackingUrl);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(props => <AdobeAnalytics {...props} />);
