import { useEffect } from "react";
import { logout } from "services/RestAPI/exploreCalls";
import { withRouter } from "react-router-dom";
import { History } from "history";
interface IProps {
  history: History;
}

const SignOut = (props: IProps) => {
  let { history } = props;

  useEffect(() => {
    logout();
    history.push(`/login`);
  }, []);

  return null;
};

export default withRouter(SignOut);
