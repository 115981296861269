import { Fragment, useState } from "react";
import styles from "./forgotEmail.module.scss";
import { withRouter } from "react-router-dom";
import * as yup from "yup";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { useForm } from "react-hook-form";
import { Captcha } from "../../components/Captcha";
import { ErrorBox } from "../Login/ErrorBox";
import { History } from "history";
import labels from "../../config/Labels/label.json";
import { FormFieldError } from "components/FormFieldError/index";
import captchaErrors from "components/Captcha/captchaErrors.json";
import { BreadCrumbs } from "components/BreadCrumbs";
import {
  FORGOT_EMAIL_ERROR_MAPPING,
  ERROR_BOX_HEADER,
  DEFAULT_ERROR_MESSAGE,
  FORGOT_EMAIL_NAME_VALIDATION_FIRSTNAME,
  FORGOT_EMAIL_NAME_VALIDATION_LASTNAME,
  FORGOT_EMAIL_FIRSTNAME_ERROR,
  FORGOT_EMAIL_LASTNAME_ERROR,
  BIRTH_DAY_MIN,
  BIRTH_DAY_MAX,
  BIRTH_DAY_MATCHES,
  BIRTH_DATE_REQUIRED,
  BIRTHMONTH_DIGIT_ERROR,
  BIRTHMONTH_ERROR,
  BIRTHYEAR_DIGIT_ERROR,
  BIRTHYEAR_ERROR,
  SORT_CODE_MATCHES,
  SORT_CODE_MIN,
  SORT_CODE_REQUIRED,
  ACCOUNT_EXACT_NUMBER_ERROR,
  ACCOUNT_NUMBER_MINMAX_ERROR,
  ACCOUNT_NUMBER_ERROR,
} from "config/errorMessages";
import { allowNumbersOnly, getConstantNameForTheme, useYupValidationResolver } from "utlis/generalUtils";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import { PrimaryButton } from "components/PrimaryButton";

interface IForgotEmailProps {
  isSubmitting: boolean;
  handleLoginClick: any;
  errorSet: any;
  showCaptcha: boolean;
  history: History;
  captchaSiteKey: string;
}

const ForgotEmailSchema = yup.object({
  firstName: yup.string()
    .matches(/^[a-zA-Z '.-]+$/, FORGOT_EMAIL_NAME_VALIDATION_FIRSTNAME)
    .required(FORGOT_EMAIL_FIRSTNAME_ERROR),
  lastName: yup.string()
    .matches(/^[a-zA-Z '.-]+$/, FORGOT_EMAIL_NAME_VALIDATION_LASTNAME)
    .required(FORGOT_EMAIL_LASTNAME_ERROR),
  birthDay: yup.string()
    .min(1, `${BIRTH_DAY_MIN}`)
    .max(2, `${BIRTH_DAY_MAX}`)
    .matches(/^(([0]?[1-9])|([1-2][0-9])|(3[01]))$/, `${BIRTH_DAY_MATCHES}`)
    .required(`${BIRTH_DATE_REQUIRED}`),
  birthMonth: yup.string()
    .matches(/^(0?[1-9]|1[012])$/, BIRTHMONTH_DIGIT_ERROR)
    .required(BIRTHMONTH_ERROR),
  birthYear: yup.string()
    .matches(/(19|20)\d{2}$/, BIRTHYEAR_DIGIT_ERROR)
    .required(BIRTHYEAR_ERROR),
  sortCodeFirstPart: yup.string()
    .matches(/^([0-9])/, `${SORT_CODE_MATCHES}`)
    .min(2, `${SORT_CODE_MIN}`)
    .max(2, `${SORT_CODE_MIN}`)
    .required(`${SORT_CODE_REQUIRED}`),
  sortCodeSecondPart: yup.string()
    .matches(/^([0-9])/, `${SORT_CODE_MATCHES}`)
    .min(2, `${SORT_CODE_MIN}`)
    .max(2, `${SORT_CODE_MIN}`)
    .required(`${SORT_CODE_REQUIRED}`),
  sortCodeThirdPart: yup.string()
    .matches(/^([0-9])/, `${SORT_CODE_MATCHES}`)
    .min(2, `${SORT_CODE_MIN}`)
    .max(2, `${SORT_CODE_MIN}`)
    .required(`${SORT_CODE_REQUIRED}`),
  accountNumber: yup.string()
    .matches(/^[0-9]*$/, ACCOUNT_EXACT_NUMBER_ERROR)
    .max(8, ACCOUNT_NUMBER_MINMAX_ERROR)
    .min(8, ACCOUNT_NUMBER_MINMAX_ERROR)
    .required(ACCOUNT_NUMBER_ERROR),
});

const {
  forgotEmail:{
  forgotEmailHeading,
  forgotEmailSubHeading,
  forgotEmailSubHeadingText,
  firstNameText,
  lastNameText,
  dateOfBirthText,
  accountDetailsText,
  sortCodeText,
  accountNumberText,
  forgotEmailBottomTextPart1,
  forgotEmailBottomTextPart2,
  }
} = labels;

export const ForgotEmailPage: React.FC<IForgotEmailProps> = (
  props: IForgotEmailProps
) => {
  const { errorSet, handleLoginClick, captchaSiteKey } = props;
  const [isCaptchaValid, setCaptchaStatus] = useState(false);
  getSettingConfig().then((data) => {
    setCaptchaStatus(data.SET_CAPTCHA_VALID);
  });
  const resolver = useYupValidationResolver(ForgotEmailSchema);
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver
  });

  const onSubmit = (value) => {
    if (window["grecaptcha"].getResponse() !== "" || isCaptchaValid) {
      handleLoginClick(value);
    } else {
      setError('captchaInvalid', {  type: 'custom', message: captchaErrors.captchaInvalidMessage } );
     // setError(captchaErrors.captchaInvalid, captchaErrors.captchaInvalidError, captchaErrors.captchaInvalidMessage);
    }
  };

  const submitCaptcha = (value) => {
    if (value) {
      delete errors.captchaInvalid;
      renderErrorBox(errors);
      setCaptchaStatus(true);
    }
  };
  const changeFocus = (e, id, maxLength) => {
    let element = e.target.value;
    let elementId = document.getElementById(id);

    if (element.length === maxLength || element.length === maxLength + 1) {
      elementId && elementId.focus();
    }
  };

  const renderErrorBox = (errors?: any) => {
    let errorsArray: any;
    let uniqueErrors: any;
    errorsArray = Object.values(errors).map((item: any) => {
      return item.message;
    });
    uniqueErrors = Array.from(new Set(errorsArray));
    if (errorSet && errorSet.length) {
      let errorMsg = errorSet.map((errorItem) =>{
       return FORGOT_EMAIL_ERROR_MAPPING[errorItem] || DEFAULT_ERROR_MESSAGE;
      }
      );
      return (
        <ErrorBox
          className={styles.error_box_background}
          errors={errorMsg}
          errorHeader={ERROR_BOX_HEADER}
        />
      );
    } else {
      if (errorsArray && errorsArray.length) {
        // if (window["grecaptcha"].getResponse() === "") {
        //   setError(captchaErrors.captchaInvalid, captchaErrors.captchaInvalidError, captchaErrors.captchaInvalidMessage);
        // }
        return (
          <ErrorBox
            className={styles.error_box_background}
            errors={uniqueErrors}
            errorHeader={ERROR_BOX_HEADER}
          />
        );
      } else {
        return;
      }
    }
  };

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container}>
        <BreadCrumbs />
        <div className={styles.forgot_email__heading}>{forgotEmailHeading}</div>
        {errors ? renderErrorBox(errors) : null}
        <form
          className={styles.main_form}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className={styles.forgot_email__form_fields}>
            <div className={styles.forgot_email__left_column_form_fields}>
              <h4 className={styles.forgot_email__about_you}>
                {forgotEmailSubHeading}
              </h4>
              <div className={styles.forgot_email__sub_heading}>
                {forgotEmailSubHeadingText}
              </div>
              <div className={styles.forgot_email__form_field_name}>
                {firstNameText}
              </div>
              {errors.firstName && (
                <FormFieldError errorText={errors.firstName.message} />
              )}
              <input
                className={styles.forgot_email__form_field}
                {...register('firstName')}
                type="text" />
              <div className={styles.forgot_email__form_field_name}>
                {lastNameText}
              </div>
              {errors.lastName && (
                <FormFieldError errorText={errors.lastName.message} />
              )}
              <input
                className={styles.forgot_email__form_field}
                {...register('lastName')}
                type="text" />
              <div className={styles.forgot_email__form_field_name}>
                {dateOfBirthText}
              </div>
              {errors.birthDay && (
                <div className={styles.day_error}>
                  <FormFieldError errorText={errors.birthDay.message} />
                </div>
              )}
              {!errors.birthDay && errors.birthMonth && (
                <div className={styles.month_error}>
                  {" "}
                  <FormFieldError errorText={errors.birthMonth.message} />
                </div>
              )}
              {!errors.birthDay && !errors.birthMonth && errors.birthYear && (
                <div className={styles.year_error}>
                  {" "}
                  <FormFieldError errorText={errors.birthYear.message} />
                </div>
              )}
              <div className={styles.forgot_email__form_field_dob}>
                <input
                  className={styles.forgot_email__form_field_dob__dob}
                  {...register('birthDay')}
                  placeholder="DD"
                  data-test="date-field"
                  type="text"
                  id="DD"
                  maxLength={2}
                  onKeyUp={(e) => {
                    changeFocus(e, "MM", 2);
                  }}
                  onKeyPress={(e) => allowNumbersOnly(e)}
                  onBlur={(e) =>
                    e.currentTarget.value.length === 1 &&
                    e.currentTarget.value !== "0"
                      ? (e.currentTarget.value = "0" + e.currentTarget.value)
                      : e.target.value
                  } />
                <div className={styles.forgot_email__form_field_dob_separator}>
                  -
                </div>
                <input
                  className={styles.forgot_email__form_field_dob__dob}
                  {...register('birthMonth')}
                  placeholder="MM"
                  id="MM"
                  data-test="date-field"
                  type="text"
                  maxLength={2}
                  onKeyUp={(e) => changeFocus(e, "YYYY", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)}
                  onBlur={(e) =>
                    e.currentTarget.value.length === 1 &&
                    e.currentTarget.value !== "0"
                      ? (e.currentTarget.value = "0" + e.currentTarget.value)
                      : e.target.value
                  } />
                <div className={styles.forgot_email__form_field_dob_separator}>
                  -
                </div>
                <input
                  className={styles.forgot_email__form_field_dob__year}
                  {...register('birthYear')}
                  id="YYYY"
                  placeholder="YYYY"
                  data-test="date-field"
                  type="text"
                  maxLength={4}
                  onKeyUp={(e) => changeFocus(e, "sortCodeFirstPart", 4)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
              </div>
            </div>
            <div className={styles.forgot_email__right_column_form_fields}>
              <div className={styles.forgot_email__account_details}>
                {accountDetailsText}
              </div>
              <div className={styles.forgot_email__form_field_name}>
                {sortCodeText}
              </div>

              {errors.sortCodeFirstPart && (
                <FormFieldError errorText={errors.sortCodeFirstPart.message} />
              )}
              {!errors.sortCodeFirstPart && errors.sortCodeSecondPart && (
                <div className={styles.sortCode_two}>
                  <FormFieldError
                    errorText={errors.sortCodeSecondPart.message}
                  />
                </div>
              )}
              {!errors.sortCodeFirstPart &&
                !errors.sortCodeSecondPart &&
                errors.sortCodeThirdPart && (
                  <div className={styles.sortCode_three}>
                    <FormFieldError
                      errorText={errors.sortCodeThirdPart.message}
                    />
                  </div>
                )}
              <div className={styles.forgot_email__form_field_sortcode}>
                <input
                  required
                  className={styles.forgot_email__form_field_sortcode__sortcode}
                  {...register('sortCodeFirstPart')}
                  data-test="sortCode-field"
                  type="text"
                  maxLength={2}
                  id="sortCodeFirstPart"
                  onKeyUp={(e) => changeFocus(e, "sortCodeSecondPart", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
                <input
                  required
                  className={styles.forgot_email__form_field_sortcode__sortcode}
                  {...register('sortCodeSecondPart')}
                  data-test="sortCode-field"
                  type="text"
                  maxLength={2}
                  id="sortCodeSecondPart"
                  onKeyUp={(e) => changeFocus(e, "sortCodeThirdPart", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
                <input
                  required
                  className={styles.forgot_email__form_field_sortcode__sortcode}
                  {...register('sortCodeThirdPart')}
                  data-test="sortCode-field"
                  type="text"
                  maxLength={2}
                  id="sortCodeThirdPart"
                  onKeyUp={(e) => changeFocus(e, "", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
              </div>

              <div className={styles.forgot_email__form_field_name}>
                {accountNumberText}
              </div>
              {errors.accountNumber && (
                <FormFieldError errorText={errors.accountNumber.message} />
              )}
              <input
                className={styles.forgot_email__form_field}
                {...register('accountNumber')}
                type="text"
                data-test="accountNumber-field"
                size={8}
                maxLength={8}
                onKeyPress={(e) => allowNumbersOnly(e)} />
              <div>
                <Captcha siteKey={captchaSiteKey} onSubmit={submitCaptcha} />
              </div>
            </div>
          </div>
          <div className={styles.forgot_email__btn}>
            <PrimaryButton
              text="Next"
              disabled={false}
              classNames={styles.forgot_email__btn__next_btn}
              data-test="next-button"
            />
          </div>
        </form>
        <p className={styles.bottom_text}>
          {forgotEmailBottomTextPart1}
          {getConstantNameForTheme()}
          {forgotEmailBottomTextPart2}
        </p>
      </div>
      <FooterComponent />
    </Fragment>
  );
};

export default withRouter(ForgotEmailPage);
