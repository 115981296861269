/*========================== Theme Variables for ULSTER NI GOLD =====================*/
const ulster_gold_ni_button_color_scheme = {
  "--button-background": "#ffffff",
  "--primaryButtonBackground": "#00A6B5",
  "--primaryButtonBackgroundHover": "#009eaa",
  "--secondaryButtonBackground": "#fff",
  "--buttonBorder": "#5e10b1",
  "--buttonShadow": "#666",
  "--buttonTextColor": "#5e10b1",
  "--secondaryButtonBorder": "#000",
  "--secondaryButtonColor": "#000",
  "--genericButtonBackground": "#5e10b1",
  "--genericButtonTextColor": "#ffffff",
  "--genericButtonBackgroundHover": "#3c1053",
  "--buttonBorderTop": "#1E7A8A",
   "--button-hover-background": "#ffffff",
  "--button-hover-text-color": "#3c1053",
  "--button-font-family": "RNHouseSans",
  "--block-button-font-family": "RNHouseSans",
};

const ulster_gold_ni_page_element_scheme = {
  //logo variables
  "--header-logo": 'url("/asset/ulster_ni/logo/ub-horizontal-header.png")',
  "--header-lg-height": "50px",
  "--header-md-height": "34px",
  "--header-sm-height": "28px",
  "--header-lg-width": "223px",
  "--header-md-width": "152px",
  "--header-sm-width": "128px",
  "--logo-url": "url('../assets/images/ulster-logo-black.png')",
  "--loader-url": "url('/assets/images/')",
  "--primary": "#5a287d",
  "--heading": "#333333",
  "--secondary": "#fff",
  "--tertiary": "#067CC4",
  "--footerBackground": "#5a287d",
  "--footer-text-color": "#5e10b1",
  "--boxShadow": "#e0d9d1",
  "--base-padding": "32px",
  "--inner-padding": "0px",
  "--section-base-background": "#ffffff",
  "--section-inner-background": "#ffffff",
  "--page-background": "#FFFFFF",
  "--root-background": "#f2f2f8",
  "--sticky-header-background": "#ffffff",
  "--knileBlack-font-family": "KnileBlack",
  "--font-family": "RNHouseSans",
  "--font-family-ulster": "RNHouseSans",
  "--font-family-bold": "FlamaBold",
  "--fontSize12": "12px",
  "--quick-access-font-family": "RNHouseSans",
  "--membership-title-font-family": "FlamaBold",
  "--account-dropdown-font-family": "RNHouseSans",
  "--RNHouseSansLight": "RNHouseSansLight",
  "--quick-access-font-weight": "400",
  "--footer-link-font-size": "18px",
  "--footer-link-icon-font-size": "16px",
  "--notice-header-base-padding": "25px 52px 25px 32px",
  "--lilac-color":"#f2eaf9",
  "--base-margin": "32px",
  "--body-grey" : "#646068",
  "--text-box-border-color": "#646068",
  "--no-padding" : "0px",
  "--opacity": "0.85",
  "--dropdown-hover": "#5e10b1"
};

const ulster_gold_ni_card_color_scheme = {
  "--card-background": "#F2F2F8",
 "--card-text": "#646068",
 "--card-heading": "#333333",
 "--card-icon": "#5a287d",
  "--card-header--separator": "#cccfd0",
  "--home-margin-fix": "0px 16px",
  "--card-footer-text-font-size": "18px",
  "--card-footer-color": "#5e10b1",
};

const ulster_gold_ni_image_banner_color_scheme = {
  "--title-first-child":  "#5a287d",
  "--title-second-child": "#5a287d",
  "--banner-content-left-position": "0px",
  "--banner-title-radius": "0px",
  "--banner-title-width": "100%",
  "--banner-content-width": "fit-content",
  "--banner-title-min-width": "0px",
  "--title-first-child-padding": "12px 16px 0px 12px",
  "--title-second-child-padding": "0px 16px 8px 12px",
  "--banner-button-top-margin": "12px",
  "--banner-button-left-margin": "32px",
  "--banner-title-text": "16px 48px",
  "--banner-title-single-child-padding": "16px 60px 16px 48px",
  "--image-banner-button-border": "#1b768b",
  "--ulster-covid-msg": "32px",
  "--image-banner-button-background": "#FFFFFF",
  "--image-banner-button-hover-background": "#30909e",
  "--image-banner-button-color": "#ffffff",
};
const responsive_ulster_private_ni_image_banner_color_scheme = {
  "--title-first-child-padding_responsive": "12px 16px 0px 12px",
  "--title-second-child-padding_responsive": "0px 16px 8px 12px",
};

const ulster_gold_ni_misc_elements_color_scheme = {
  "--link-color": "#5e10b1",
  "--link-hover-color": "#5e10b1",
  "--link-active-color": "#cccfd0",
  "--link-active-color-dark": "#3c1053",
  "--info-text-color": "#646068",
  "--counterBackground": "#067CC4",
  "--calendarActive": "#067CC4",
  "--calendarRange": "#C3DEF1",
  "--loadMoreLink": "#2da3c3",
  "--discountTextBackground": "#ebedfc",
  "--subTitle": "gray",
  "--optionalText": "gray",
  "--importantNoteBg": "#dfd9d1",
  "--tabActiveBgColor": "#0a2f64",
  "--tabInactiveBgColor": "#9fb4ce",
  "--noteBackground": "#dfd9d1",
  "--linkUnderline": "underline",
  "--cookies-header-background": "#ecedfc",
  "--error-color": "#CF223F",
  "--logout-text": "#333333",
  "--logout-body-text": "#646068",
  "--fontSize12": "12px",
  "--table-background-odd-row": "#e7e5df",
  "--table-text-color": "#23356b",
};
const ulster_gold_ni_accordion_color_scheme = {
  "--accordion-primary": "#5e10b1",
  "--accordion-secondary": "#FFF",
};
const ulster_gold_ni_stripes_color_scheme = {
  "--stripe-color-gold": "#B7BE16",
  "--stripe-color-private": "#23356B",
};
const ulster_ni_gold_forms_color_scheme = {
  "--formInputBgColor": "#f0eeea",
  "--formContainerBgColor": "#fff",
  "--formFieldWrapper": "#fff",
};

const ulster_ni_gold_change_password_color_scheme = {
  "--primary": "#5a287d",
  "--container-background-color": "#ffffff",
  "--change-password-content-background": "#ffffff",
  "--change-password-input-textbox-background": "#F0EEEA",
  "--change-password-error-color": "#DB1A31",
  "--change-password-button-background": "#00A6B5",
  "--error-message-color": "#fff",
  "--save-changes-button-border-bottom": "#007881",
  "--save-changes-button-border-top": "#43c9d2",
  "--textbox-outline-color": "#b7be16",
  "--change-page-container-padding": "0px 32px 32px 32px",
  "--change-page-container-inner-padding": "32px",
};

const ulster_ni_gold_event_booking_form_color_scheme = {
  "--form-heading-font-family": "RNHouseSans",
  "--field-font-family": "RNHouseSans",
  "--form-text-color": "#000",
  "--select-box-border-color": "#646068",
  "--select-box-border-color-onfocus": "#646068",
  "--select-box-shadow-primary-onfocus": "none",
  "--select-box-shadow-secondary-onfocus": "none",
  "--input-field-box-shadow-primary": "#fff",
  "--input-field-box-shadow-secondary": "#ffffff",
  "--input-field-box-shadow-primary-onfocus": "none",
  "--input-field-box-shadow-secondary-onfocus": "none",
  "--form-input-textbox-background": "#fff",
  "--error-message-color-event-booking": "#ffffff",
  "--group-error-message-color-event-booking": "#CF223F",
  "--group-error-box-max-width": "488px",
  "--textbox-outline-color": "#B7BE16",
  "--error-message-box-width": "432px",
  "--form-container-padding": "0px 32px 32px 32px",
  "--field-container-padding": "32px 32px 0px 32px",
  "--field-container-background": "#FFFFFF",
  "--event-booking-success-page-background": "#ffffff",
  "--form-heading-margin": "0px 0px 24px 0px",
};

const ulster_ni_gold_cash_back_summary_color_scheme = {
  "--cash-back-section-font-family": "Flama",
  "--cash-back-section-text-color": "#23356b",
  "--field-wrapper-border-color": "#c0c0c0",
  "--field-wrapper-background-color": "#ffffff",
  "--input-field-backgroound-color": "#f0eeea",
  "--input-field-primary-box-shadow": "#a09489",
  "--input-field-secondary-box-shadow": "#ffffff",
  "--iframe-backfround-color": "#ffffff",
  "--cash-back-success-page-background": "#f7f7f7",
};
export const ulster_ni_gold_vars = {
  ...ulster_gold_ni_button_color_scheme,
  ...ulster_gold_ni_page_element_scheme,
  ...ulster_gold_ni_card_color_scheme,
  ...responsive_ulster_private_ni_image_banner_color_scheme,
  ...ulster_gold_ni_image_banner_color_scheme,
  ...ulster_gold_ni_misc_elements_color_scheme,
  ...ulster_gold_ni_accordion_color_scheme,
  ...ulster_gold_ni_stripes_color_scheme,
  ...ulster_ni_gold_forms_color_scheme,
  ...ulster_ni_gold_change_password_color_scheme,
  ...ulster_ni_gold_event_booking_form_color_scheme,
  ...ulster_ni_gold_cash_back_summary_color_scheme,
};
