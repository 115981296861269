import styles from "./loader.module.scss";
import { LoaderStateModel } from "redux/models/LoaderModel";
import FocusLock from 'react-focus-lock';
const Loader = () => {
  const loadingState = LoaderStateModel.getInsatnce("1");
  let { isLoading } = loadingState.props;
  return isLoading ? (
    <FocusLock disabled={!isLoading} returnFocus={isLoading}>
    <div className={styles.loader_wrapper}>
      <div className={styles.loader}></div>
      <div className={styles.loader_heading} tabIndex={0} aria-live="polite">Loading</div>
    </div>
    </FocusLock>
  ) : null;
};

export default Loader;
