import React from "react";
import { Icon } from "components/Icon";
import classNames from "classnames";
import styles from "./BenefitTile.module.scss";

interface BenefitTileProps {
  tileIcon: string;
  tileContent: string;
  className?: string;
  accounts: string[];
  accountTypes: number;
}

const BenefitTile: React.FC<BenefitTileProps> = (props) => {
  const { tileContent, tileIcon, accounts, className, accountTypes } = props;
  let strokeWidth =
    accountTypes === 3 ? "33.33%" : accountTypes === 2 ? "50%" : "33%";
  return (
    <div
      className={classNames(styles.loginPage__benefit_tileWrapper, className)}
    >
      <div className={styles.loginPage__benefit_tile}>
        <div className={styles.loginPage__benefit_tile_content}>
          <div className={styles.loginPage__benefit_tile_icon}>
            <Icon iconName={`${tileIcon}`} />
          </div>
          <p tabIndex={0} aria-label={tileContent+"benefit"}>{tileContent}</p>
        </div>
      </div>

      <div className={styles.loginPage__account_type_stripe}>
        {(accounts || []).map((accountType, index) => {
          let accountTypeInformation = accountType == "ufirst_Private" ? "ufirst Private" : accountType;
          return (
            <div
              title={accountType}
              key={index}
              style={{ width: `${strokeWidth}` }}
              className={styles[accountType]}
              tabIndex={0}
              aria-label={accountTypeInformation+"account"}
            ></div>
          );
        })}
      </div>
    </div>
  );
};

export default BenefitTile;
