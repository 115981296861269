import React, { Fragment } from "react";
import styles from "./intuitive-messages.module.scss";
import { renderMarkedText } from "components/AbstractedMethods";
import { Icon } from "components/Icon";

interface componentType {
  type: string;
  className?: string;
  content: string;
}

interface data {
  topLeftIcon: string;
  bottomRightIcon: string;
  width?: string;
  className?: string;
  content?: componentType[];
  padding?: string;
}

interface IProps {
  metaData: data;
  id?: string;
}

const IntuitiveMessages: React.FC<IProps> = ({
  metaData: {
    content,
    className,
    topLeftIcon,
    bottomRightIcon,
    width,
    padding,
  },
  id,
}) => {
  const renderContent = () => {
    return (content || []).map((component, componentKey) => {
      return typeMappings[component.type](component.content, componentKey);
    });
  };

  const typeMappings = {
    marked: renderMarkedText,
  };
  return (
    <Fragment>
      <div
        id={id}
        style={{ width: width, padding: padding }}
        className={styles.intuitive_message_container}
        key={`intuitive-messages${id}`}
        data-test="intuitive-component"
      >
        <div className={styles[className || ""]}>
          {topLeftIcon ? (
            <div className={styles.top_icon} data-test="top-icon">
              <Icon iconName={topLeftIcon} />
            </div>
          ) : null}
          <div className={styles.content} data-test="message-content">
            {renderContent()}
          </div>
          {bottomRightIcon ? (
            <div className={styles.bottom_icon} data-test="bottom-icon">
              <Icon iconName={bottomRightIcon} />
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default IntuitiveMessages;
