import { useState, Fragment, useEffect } from "react";
import SignUpForm from "./SignUpForm";
import { SIGNUP_QUERY_VALIDATE_USER } from "services/GraphQL/gqlQueries";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { withRouter } from "react-router-dom";
import { SignUpStateModel } from "redux/models/SignUpModel";
import { ShowErrorSignUpStateModel } from "redux/models/ShowErrorPopupSignUp";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";

const SignUpFormContainer = (props) => {
  let showErrorModel = ShowErrorSignUpStateModel.getInsatnce("ShowError");
  let { isShowError, isShowErrorMsg } = showErrorModel
    ? showErrorModel.props
    : "";
  const [backendErrors, setBackendError] = useState([]);

  useEffect(() => {
    populateBreadCrumbs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Sign Up",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  const querySignupData = async (values) => {
    let {
      firstName,
      lastName,
      birthDay,
      birthMonth,
      birthYear,
      sortCodeFirstPart,
      sortCodeSecondPart,
      sortCodeThirdPart,
      accountNumber,
    } = values;

    const { tenantId, brandName } = BrandSpecificDataModel.getInsatnce("1")
      ? BrandSpecificDataModel.getInsatnce("1").props
      : "";

    let birthDate = birthYear + "-" + birthMonth + "-" + birthDay;
    let sortCodes = sortCodeFirstPart + sortCodeSecondPart + sortCodeThirdPart;
    return await graphQlCall(
      "query",
      SIGNUP_QUERY_VALIDATE_USER,
      {
        firstName: firstName,
        lastName: lastName,
        birthDate: birthDate,
        accountId: accountNumber,
        accountSortCode: sortCodes,
        brandName: brandName,
      },
      {
        tenantId: tenantId,
      },
      "false"
    )
      .then((data) => {
        new SignUpStateModel({
          id: "NewUser",
          SignUpData: values,
        }).$save();
        const response = data.data["validateSignup"];
        if (response) {
          let {
            memberId,
            statusCode,
            membershipId,
            packageId,
            firstName,
            lastName,
          } = response;
          return statusCode === "200"
            ? props.history.push("/user-signup/registration", {
                memberId,
                membershipId,
                packageId,
                firstName,
                lastName,
              })
            : setBackendError(onGqlError(null, statusCode));
        } else {
          props.history.push("/user-signup");
        }
      })
      .catch((error) => {
        return setBackendError(onGqlError(error));
      });
  };
  const handleSubmit = (values) => {
    querySignupData(values);
    return new ShowErrorSignUpStateModel({
      id: "ShowError",
      isShowError: false,
      isShowErrorMsg: "",
    }).$update();
  };

  return (
    <Fragment>
      <SignUpForm
        isShowError={isShowError}
        isShowErrorMsg={isShowErrorMsg}
        backendErrors={backendErrors}
        setBackendError={setBackendError}
        onHandleSubmit={handleSubmit}
      />
    </Fragment>
  );
};

export default withRouter(SignUpFormContainer);
