import * as React from "react";
import styles from "./card.module.scss";

// tslint:disable-next-line:no-empty-interface
const Card: React.FC<any> = (props) => {
  let {classNames} = props;
  return <div className={`${styles.card} ${classNames}`}>{props.children}</div>;
};

export default Card;
