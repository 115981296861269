import { Fragment, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { FormFieldError } from "components/FormFieldError";
import { ErrorBox } from "../../Login/ErrorBox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as errorMessage from "../../../config/errorMessages";
import styles from "./forgotPassword.module.scss";
import { Captcha } from "components/Captcha";
import captchaErrors from "components/Captcha/captchaErrors.json";
import { BreadCrumbs } from "components/BreadCrumbs";
import { allowNumbersOnly, getConstantNameForTheme, useYupValidationResolver } from "utlis/generalUtils";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import labels from "../../../config/Labels/label.json";
import { emailFieldRegex } from "config/constants";
import { PrimaryButton } from "components/PrimaryButton";
interface IProps {
  errorSet: any;
  onHandleSubmit: any;
  captchaSiteKey: string;
}

const {
  forgotPassword: {
    forgotPasswordHeading,
    forgotPasswordHeadingText,
    forgotPasswordSubHeading,
    forgotPasswordSubHeadingText,
    firstNameText,
    lastNameText,
    dateOfBirthText,
    emailText,
    accountDetailsText,
    sortCodeText,
    accountNumberText,
    forgotPasswordBottomTextPart1,
    forgotPasswordBottomTextPart2,
  }
} = labels;

const ForgotPasswordForm = (props: IProps) => {
  const ForgotPasswordSchema = yup.object({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z '.-]+$/, errorMessage.FIRSTNAME_MATCH_ERROR)
      .required(`${errorMessage.FIRSTNAME_REQUIRED}`),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z '.-]+$/, errorMessage.LASTNAME_MATCH_ERROR)
      .required(`${errorMessage.LASTNAME_REQUIRED}`),
    birthDay: yup
      .string()
      .min(1, `${errorMessage.BIRTH_DAY_MIN}`)
      .max(2, `${errorMessage.BIRTH_DAY_MAX}`)
      .matches(
        /^(([0]?[1-9])|([1-2][0-9])|(3[01]))$/,
        `${errorMessage.BIRTH_DAY_MATCHES}`
      )
      .required(`${errorMessage.BIRTH_DATE_REQUIRED}`),
    birthMonth: yup
      .string()
      .min(1, `${errorMessage.BIRTH_MONTH_MIN}`)
      .max(2, `${errorMessage.BIRTH_MONTH_MAX}`)
      .matches(/^(0[1-9]|1[0-2])$/, `${errorMessage.BIRTH_MONTH_MATCHES}`)
      .required(`${errorMessage.BIRTH_MONTH_REQUIRED}`),
    birthYear: yup
      .string()
      .min(4, `${errorMessage.BIRTH_YEAR_MIN}`)
      .max(4, `${errorMessage.BIRTH_YEAR_MAX}`)
      .matches(/(19|20)\d{2}$/, `${errorMessage.BIRTH_YEAR_MATCHES}`)
      .required(`${errorMessage.BIRTH_YEAR_REQUIRED}`),
    email: yup
      .string()
      .required("Email Address is a mandatory field")
      .matches(emailFieldRegex, errorMessage.EMAIL_PATTERN_ERROR),
    sortCodeFirstPart: yup
      .string()
      .matches(/^([0-9])/, `${errorMessage.SORT_CODE_MATCHES}`)
      .min(2, `${errorMessage.SORT_CODE_MIN}`)
      .max(2, `${errorMessage.SORT_CODE_MIN}`)
      .required(`${errorMessage.SORT_CODE_REQUIRED}`),
    sortCodeSecondPart: yup
      .string()
      .matches(/^([0-9])/, `${errorMessage.SORT_CODE_MATCHES}`)
      .min(2, `${errorMessage.SORT_CODE_MIN}`)
      .max(2, `${errorMessage.SORT_CODE_MIN}`)
      .required(`${errorMessage.SORT_CODE_REQUIRED}`),
    sortCodeThirdPart: yup
      .string()
      .matches(/^([0-9])/, `${errorMessage.SORT_CODE_MATCHES}`)
      .min(2, `${errorMessage.SORT_CODE_MIN}`)
      .max(2, `${errorMessage.SORT_CODE_MIN}`)
      .required(`${errorMessage.SORT_CODE_REQUIRED}`),
    accountNumber: yup
      .string()
      .matches(/^[0-9]*$/, `${errorMessage.ACCOUNT_EXACT_NUMBER_ERROR}`)
      .min(8, `${errorMessage.ACCOUNT_ERROR}`)
      .max(8, `${errorMessage.ACCOUNT_ERROR}`)
      .required(`${errorMessage.ACCOUNT_NUMBER_REQUIRED}`),
  });
  const resolver = useYupValidationResolver(ForgotPasswordSchema);
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver
  });

  let { onHandleSubmit, errorSet, captchaSiteKey } = props;
  const [isCaptchaValid, setCaptchaStatus] = useState(false);

  getSettingConfig().then((data) => {
    setCaptchaStatus(data.SET_CAPTCHA_VALID);
  });
  const onHandleClick = (value) => {
    if (window["grecaptcha"].getResponse() !== "" || isCaptchaValid) {
      onHandleSubmit(value);
    } else {
      setError('captchaInvalid', {  type: 'custom', message: captchaErrors.captchaInvalidMessage } );
    }
  };

  const submitCaptcha = (value) => {
    if (value) {
      //clearError(captchaInvalid);
      delete errors.captchaInvalid;
      setCaptchaStatus(true);
    }
  };
  const renderErrorBox = () => {
    if (errorSet && errorSet.length) {
      let errorMsg = errorSet.map((errorItem) => {
        return errorMessage.FORGOT_PASSWORD_ERROR_MAPPING[errorItem] || errorMessage.DEFAULT_ERROR_MESSAGE;
      });
      return errorSet.length ? (
        <ErrorBox
          errors={errorMsg}
          errorHeader={errorMessage.ERROR_BOX_HEADER}
        />
      ) : null;
    } else return;
  };

  useEffect(() => {
    if (window["grecaptcha"] === undefined || window["grecaptcha"] === null ) {
      window["grecaptcha"].reset();
    }
  }, []);
  const changeFocus = (e, id, maxLength) => {
    let element = e.target.value;
    let elementId = document.getElementById(id);

    if (element.length === maxLength || element.length === maxLength + 1) {
      elementId && elementId.focus();
    }
  };

  const formErrorMsg = () => {
    let formErrors;
    let uniqueErrors: any;
    let errorsArray = Object.values(errors).map((item: any) => {
      return item.message;
    });
    uniqueErrors = Array.from(new Set(errorsArray));

    if (Object.keys(errors).length) {
      // if (window["grecaptcha"].getResponse() === "") {
      //   setError('captchaInvalid', {  type: 'custom', message: "Captcha information missing. Please try again." } );
      // } else {
      //   delete errors.captchaInvalid;
      // }
      errorSet.length = 0;
      formErrors = (
        <ErrorBox
          className={styles.error_box_background}
          errors={uniqueErrors}
          errorHeader={errorMessage.ERROR_BOX_HEADER}
        />
      );
    }
    return formErrors;
  };
  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container}>
        <BreadCrumbs />
        <div className={styles.forgot_password__heading}>
          {forgotPasswordHeading}
        </div>
        <div className={styles.forgot_password__sub_heading}>
          {forgotPasswordHeadingText}
        </div>
        {formErrorMsg()}
        {renderErrorBox()}
        <form
          onSubmit={handleSubmit(onHandleClick)}
          noValidate
          className={styles.forgot_pasword__form_wrapper}
        >
          <div className={styles.forgot_password__form_fields}>
            <div className={styles.forgot_password__left_column_form_fields}>
              <div className={styles.forgot_password__about_you}>
                {forgotPasswordSubHeading}
              </div>
              <div className={styles.forgot_password__form_field__sub_heading}>
                {forgotPasswordSubHeadingText}
              </div>
              <div className={styles.forgot_password__form_field_name} >
                {firstNameText}
              </div>
              {errors.firstName && (
                <FormFieldError errorText={errors.firstName.message} errorId={"firstName"}/>
              )}
              <input
                className={styles.forgot_password__form_field}
                {...register('firstName')}
                type="text"
                aria-labelledby="firstName" />
              <div className={styles.forgot_password__form_field_name} >
                {lastNameText}
              </div>
              {errors.lastName && (
                <FormFieldError errorText={errors.lastName.message} errorId={"lastName"} />
              )}
              <input
                className={styles.forgot_password__form_field}
                {...register('lastName')}
                type="text"
                aria-labelledby="lastName" />
              <div className={styles.forgot_password__form_field_name}>
                {dateOfBirthText}
              </div>
              {errors.birthDay && (
                <FormFieldError errorText={errors.birthDay.message} errorId={"birthDay"}/>
              )}
              {!errors.birthDay && errors.birthMonth && (
                <div className={styles.month_error}>
                  <FormFieldError errorText={errors.birthMonth.message} errorId={"birthMonth"}/>
                </div>
              )}
              {!errors.birthDay && !errors.birthMonth && errors.birthYear && (
                <div className={styles.year_error}>
                  <FormFieldError errorText={errors.birthYear.message} errorId={"birthYear"}/>
                </div>
              )}
              <div className={styles.forgot_password__form_field_dob}>
                <input
                  className={styles.forgot_password__form_field_dob__dob}
                  {...register('birthDay')}
                  aria-label="date of birth"
                  aria-labelledby="birthDay"
                  placeholder="DD"
                  data-test="date-field"
                  type="text"
                  id="DD"
                  maxLength={2}
                  onKeyUp={(e) => {
                    changeFocus(e, "MM", 2);
                  }}
                  onBlur={(e) =>
                    e.currentTarget.value.length === 1 &&
                    e.currentTarget.value !== "0"
                      ? (e.currentTarget.value = "0" + e.currentTarget.value)
                      : e.target.value
                  }
                  onKeyPress={(e) => allowNumbersOnly(e)} />
                <div
                  className={styles.forgot_password__form_field_dob_separator}
                >
                  -
                </div>
                <input
                  className={styles.forgot_password__form_field_dob__dob}
                  {...register('birthMonth')}
                  aria-label="date of birth"
                  aria-labelledby="birthMonth"
                  placeholder="MM"
                  data-test="date-field"
                  type="text"
                  id="MM"
                  maxLength={2}
                  onBlur={(e) =>
                    e.currentTarget.value.length === 1 &&
                    e.currentTarget.value !== "0"
                      ? (e.currentTarget.value = "0" + e.currentTarget.value)
                      : e.target.value
                  }
                  onKeyUp={(e) => changeFocus(e, "YYYY", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
                <div
                  className={styles.forgot_password__form_field_dob_separator}
                >
                  -
                </div>
                <input
                  className={styles.forgot_password__form_field_dob__year}
                  {...register('birthYear')}
                  aria-label="date of birth"
                  aria-labelledby="birthYear"
                  placeholder="YYYY"
                  data-test="date-field"
                  type="text"
                  id="YYYY"
                  maxLength={4}
                  onKeyUp={(e) => changeFocus(e, "email", 4)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
              </div>
              <div className={styles.forgot_password__form_field_name}>
                {emailText}
              </div>
              {errors.email && (
                <FormFieldError errorText={errors.email.message} errorId={"emailText"}/>
              )}
              <input
                className={styles.forgot_password__form_field}
                {...register('email')}
                type="email"
                id="email"
                data-test="email-field"
                aria-labelledby="emailText" />
            </div>
            <div
              className={styles.forgot_password__middle_column_form_fields}
            ></div>
            <div className={styles.forgot_password__right_column_form_fields}>
              <div className={styles.forgot_password__account_details}>
                {accountDetailsText}
              </div>
              <div className={styles.forgot_password__form_field_name}>
                {sortCodeText}
              </div>
              {errors.sortCodeFirstPart && (
                <FormFieldError errorText={errors.sortCodeFirstPart.message} errorId={"sortCode"}/>
              )}
              {!errors.sortCodeFirstPart && errors.sortCodeSecondPart && (
                <FormFieldError errorText={errors.sortCodeSecondPart.message} />
              )}
              {!errors.sortCodeFirstPart &&
                !errors.sortCodeSecondPart &&
                errors.sortCodeThirdPart && (
                  <FormFieldError
                    errorText={errors.sortCodeThirdPart.message}
                  />
                )}
              <div className={styles.forgot_password__form_field_sortcode}>
                <input
                  aria-labelledby="sortCode"
                  className={
                    styles.forgot_password__form_field_sortcode__sortcode
                  }
                  {...register('sortCodeFirstPart')}
                  data-test="sortCode-field"
                  type="text"
                  maxLength={2}
                  id="sortCodeFirstPart"
                  onKeyUp={(e) => changeFocus(e, "sortCodeSecondPart", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />

                <input
                  className={
                    styles.forgot_password__form_field_sortcode__sortcode
                  }
                  aria-labelledby="sortCode"
                  {...register('sortCodeSecondPart')}
                  data-test="sortCode-field"
                  maxLength={2}
                  id="sortCodeSecondPart"
                  onKeyUp={(e) => changeFocus(e, "sortCodeThirdPart", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />

                <input
                  className={
                    styles.forgot_password__form_field_sortcode__sortcode
                  }
                  aria-labelledby="sortCode"
                  {...register('sortCodeThirdPart')}
                  data-test="sortCode-field"
                  type="text"
                  maxLength={2}
                  id="sortCodeThirdPart"
                  onKeyUp={(e) => changeFocus(e, "", 2)}
                  onKeyPress={(e) => allowNumbersOnly(e)} />
              </div>

              <div className={styles.forgot_password__form_field_name}>
                {accountNumberText}
              </div>
              {errors.accountNumber && (
                <FormFieldError errorText={errors.accountNumber.message} errorId={"accountNumber"} />
              )}
              <input
                aria-labelledby="accountNumber"
                className={styles.forgot_password__form_field}
                {...register('accountNumber')}
                type="text"
                data-test="accountNumber-field"
                maxLength={8}
                onKeyPress={(e) => allowNumbersOnly(e)} />
              <div className={styles.forgot_password__captcha}>
                <Captcha
                  siteKey={captchaSiteKey}
                  onSubmit={(e) => {
                    submitCaptcha(e);
                  }}
                />
              </div>
            </div>
          </div>
          <div className={styles.forgot_password__btn}>
            <PrimaryButton
              text="Next"
              disabled={false}
              classNames={styles.forgot_password__btn__next_btn}
              data-test="next-button"
            />
          </div>
        </form>
        <div className={styles.forgot_password__warning}>
          {forgotPasswordBottomTextPart1}
          {getConstantNameForTheme()}
          {forgotPasswordBottomTextPart2}
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};
export default withRouter(ForgotPasswordForm);
