import React, { Fragment, useState } from "react";
import styles from "./contact-us.module.scss";
import { BreadCrumbs } from "components/BreadCrumbs";
import { renderMarkedText } from "components/AbstractedMethods";
import { useForm } from "react-hook-form";
import { Captcha } from "components/Captcha";
import { PrimaryButton} from "components/PrimaryButton";
import { FormFieldError } from "components/FormFieldError";
import { FormErrorMessage } from "components/FormErrorMessage";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import * as errorMessages from "config/errorMessages";
import { ErrorBox } from "routes/Login/ErrorBox";

export interface ContactUsPageContent {
  HeadingSection: any;
  InfoSection: any;
  formSection: any;
}

export interface ContactUsComponentProps {
  isUserLoggedIn: boolean;
  pageContent: ContactUsPageContent;
  submitForm: (values) => void;
  formErrorMessage?: string;
  getDefaultValues: (values) => void;
  getInjectableValues: (values) => any;
  captchaSiteKey: string;
  backendErrors: any;
}

const ContactUsComponent: React.FC<ContactUsComponentProps> = ({
  isUserLoggedIn,
  pageContent,
  submitForm,
  formErrorMessage,
  getDefaultValues,
  getInjectableValues,
  captchaSiteKey,
  backendErrors,
}) => {
  const { register, handleSubmit,  formState: { errors }, setError } = useForm();
  const [isCaptchaValid, setCaptchaStatus] = useState(false);

  getSettingConfig().then((data) => {
    setCaptchaStatus(data.SET_CAPTCHA_VALID);
  });
  const renderHeadingSection = () => {
    return (
      <div className={styles.heading}>
        {renderCustomComponent(pageContent.HeadingSection)}
      </div>
    );
  };

  const renderInfoSection = () => {
    return (
      <div className={styles.col_md_5}>
        {renderCustomComponent(pageContent.InfoSection)}
      </div>
    );
  };

  const renderFormSection = () => {
    return (
      <div className={styles.col_md_5}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {renderCustomComponent(pageContent.formSection)}
        </form>
      </div>
    );
  };

  const renderError = () => {
    if (backendErrors !== undefined) {
      let errorMsg = backendErrors ? backendErrors.flat() : [];
      errorMsg = errorMsg.map((item) => {
        switch (item) {
          default:
            return errorMessages.DEFAULT_ERROR_MESSAGE;
        }
      });

      return <ErrorBox errorHeader={""} errors={errorMsg} />;
    } else {
      return null;
    }
  };

  const renderCustomComponent = (section) => {
    return section.metaData.content.map((element, index) => {
      return typeMapping[element.type](
        element.content,
        index,
        styles[element.className],
        element.content && element.content.hasDefaultValue && isUserLoggedIn
          ? getDefaultValues(element)
          : ""
      );
    });
  };

  const renderTextBox = (data, componentKey, className, defaultValue) => {
    let { name, labelText } = data;
    let error = errors[name];
    let isReadOnly = defaultValue ? true : false;

    let fieldRegex =
      data.validation && data.validation.pattern
        ? new RegExp(data.validation.pattern)
        : new RegExp(/[^\r\n]+((\r|\n|\r\n)[^\r\n]+)*/);

    let fieldMessage =
      data.validation && data.validation.errorMessage
        ? data.validation.errorMessage
        : "";

    return (
      <Fragment key={componentKey}>
        <div className={styles.form_element}>
          <label className={styles.label}>{labelText}</label>
          {error ? <FormFieldError errorText={error.message} /> : null}
          <input
            type="text"
            className={styles.textbox}
            {...register(name, {
              required: `${labelText} is mandatory`,
              pattern: { value: fieldRegex, message: fieldMessage },
            })}
            defaultValue={defaultValue || ""}
            readOnly={isReadOnly} />
        </div>
      </Fragment>
    );
  };

  const renderTextArea = (data, componentKey, className, defaultValue) => {
    let { name, labelText, maxLength, numbeOfCols, numberOfRow } = data;
    let error = errors[name];

    let fieldRegex =
      data.validation && data.validation.pattern
        ? new RegExp(data.validation.pattern)
        : new RegExp(/[^\r\n]+((\r|\n|\r\n)[^\r\n]+)*/);

    let fieldMessage =
      data.validation && data.validation.errorMessage
        ? data.validation.errorMessage
        : "";

    return (
      <div className={styles.form_element} key={componentKey}>
        <label className={styles.label}>{labelText}</label>
        {error ? <FormFieldError errorText={error.message} /> : null}
        <textarea
          maxLength={maxLength || 1000}
          rows={numberOfRow || 5}
          cols={numbeOfCols || 35}
          {...register(name, {
            required: `${labelText} is mandatory`,
            pattern: { value: fieldRegex, message: fieldMessage },
          })}
          defaultValue={defaultValue || ""}
          className={styles.textarea}></textarea>
      </div>
    );
  };

  const submitCaptcha = (value) => {
    if (value) {
      delete errors.captchaInvalid;
      setCaptchaStatus(true);
    }
  };

  const renderCaptcha = (data, componentKey) => {
    if (isUserLoggedIn) return null;
    return (
      <Captcha
        key={componentKey}
        siteKey={captchaSiteKey}
        onSubmit={submitCaptcha}
      />
    );
  };

  const renderButton = (data, componentKey) => {
    let { buttonText, disabled, className } = data;
    return (
      <PrimaryButton
        key={componentKey}
        text={buttonText}
        classNames={styles[className]}
        disabled={disabled}
      />
    );
  };

  const onSubmit = (data, e) => {
    if (!isUserLoggedIn) {
      if (window["grecaptcha"].getResponse() !== "" || isCaptchaValid) {
        submitForm(data);
      } else {
        setError(
          "captchaInvalid",
          {  type: 'custom', message: "Captcha information missing. Please try again." }
        );
      }
    } else {
      submitForm(data);
    }
  };

  const renderValueInjectableMarked = (data, componentKey, className) => {
    let injectedValue = getInjectableValues(data);
    return renderMarkedText(injectedValue, componentKey, className);
  };

  const typeMapping = {
    marked: renderMarkedText,
    textBox: renderTextBox,
    textArea: renderTextArea,
    Captcha: renderCaptcha,
    Button: renderButton,
    valueInjectableMarked: renderValueInjectableMarked,
  };

  return (
    <div className={styles.page}>
      <div className={styles.page_wrapper}>
        {isUserLoggedIn ? null : <BreadCrumbs />}
        {renderHeadingSection()}
        <div className={styles.content_wrapper}>
          {Object.keys(errors).length ? (
            <FormErrorMessage
              errors={{ ...errors }}
              errorMsg={formErrorMessage}
            />
          ) : (
            renderError()
          )}
          {}
          <div className={styles.form_wrapper}>
            {renderFormSection()}
            {renderInfoSection()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsComponent;
