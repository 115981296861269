import { BaseModel } from "./BaseModel";

interface IThemeNameModelProps {
  id: string;
  name: string;
}
/**
 * To store the user data coming from the GitHub API.
 * @export
 * @class ThemeName
 * @extends {BaseModel<IDummyModelProps>}
 */
export class ThemeNameModel extends BaseModel<IThemeNameModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IThemeNameModelProps) {
    super(props);
  }

  static resource = "ThemeName";
}
