import { Theme } from "../interfaces";
import { Typography } from "./typography";
import { ulster_ufirst_theme_vars } from "./themeVariables/ulster_ri";
export const ulsterRIuFirstTheme: Theme = {
  name: "ulster_ri-ufirst",
  properties: {
    ...ulster_ufirst_theme_vars,
    ...Typography.properties
  }
};
