import { Theme } from "../interfaces";
import { Typography } from "./typography";
import { natwest_platinum_theme_vars } from "./themeVariables/natwest";
import { rbs_platinum_theme_vars } from "./themeVariables/rbs";

export const natwestPlatinumTheme: Theme = {
  name: "natwest-platinum",
  properties: {
    ...natwest_platinum_theme_vars,
    ...Typography.properties
  }
};

export const rbsPlatinumTheme: Theme = {
  name: "rbs-platinum",
  properties: {
    ...rbs_platinum_theme_vars,
    ...Typography.properties
  }
};
