import { Fragment, Component } from "react";
import styles from "./error.module.scss";

interface IState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<any, IState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    let { hasError } = this.state;

    return (
      <Fragment>
        {hasError ? (
          <div className={styles.error}>
            <span>
              <strong>Error has occurred in this page.</strong> Try refreshing
              the page or contact the administrator.
            </span>
          </div>
        ) : (
          this.props.children
        )}
      </Fragment>
    );
  }
}
