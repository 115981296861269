import { BaseModel } from "./BaseModel";

interface ISelectedAccountModelProps {
  id: string;
  type: any;
  creationDate: string;
  packageId: any;
  PRefId?: String;
  packageDescription: String;
  productDefinition: any;
  benefits: any;
  membershipId: string;
  linkTitle: string;
  memberNumber: String;
  cin?: any;
  memberId?: String;
}
/**
 * To store the user data coming from the GitHub API.
 * @export
 * @class LoginStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class SelectedAccountModel extends BaseModel<
  ISelectedAccountModelProps
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ISelectedAccountModelProps) {
    super(props);
  }

  static resource = "SelectedAccount";
}
