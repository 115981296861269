import { Theme } from "../interfaces";
import { Typography } from "./typography";
import { ulster_ni_private_theme_vars } from "./themeVariables/ulster_ni";
import { ulster_ri_private_theme_vars } from "./themeVariables/ulster_ri";

export const ulsterNIPrivateTheme: Theme = {
  name: "ulster_ni-private",
  properties: {
    ...ulster_ni_private_theme_vars,
    ...Typography.properties
  }
};

export const ulsterRIPrivateTheme: Theme = {
  name: "ulster_ri-private",
  properties: {
    ...ulster_ri_private_theme_vars,
    ...Typography.properties
  }
};
