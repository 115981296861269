import { BaseModel } from "./BaseModel";

interface IForgotPasswordModelProps {
  id: string;
  ForgotPasswordData: any;
}
/**
 * To store the user data coming from the GraphQL.
 * @export
 * @class ForgotPasswordStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class ForgotPasswordModel extends BaseModel<IForgotPasswordModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IForgotPasswordModelProps) {
    super(props);
  }

  static resource = "IForgotPasswordState";
}
