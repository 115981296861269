import React, { Fragment, useState, useEffect } from "react";
import { Link, match } from "react-router-dom";
import { Icon } from "components/Icon";
import { connect } from "react-redux";
import styles from "./manage-account.module.scss";
import { LoginStateModel } from "redux/models/LoginStateModel";
import {
  getDateByProvidedFormat,
  getEnquiryLinkForManageAccount,
} from "utlis/generalUtils";
import { graphQlCall } from "services/GraphQL/gqlHelper";
import { GET_MEMBER_DETAILS } from "services/GraphQL/gqlQueries";
import { AuthenticationCheck } from "utlis/generalUtils";
import { getBrandName, getThemeName } from "services/RestAPI/exploreCalls";
import { BRAND_ULSTER_RI } from "config/constants";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import labels from "../../../../config/Labels/label.json";
import _ from "lodash";
interface manageAccountProps {
  LoginData: any;
  selectedAccount: any;
  match: match;
  tenantDetails: BrandSpecificDataModel;
}

const ManageAccount: React.FC<manageAccountProps> = (props) => {
  const {
    selectedAccount: {
      props: { packageDescription, creationDate },
    },
    tenantDetails: {
      props: { tenantId },
    },
    LoginData: {
      props: {
        LoginData: { memberId },
      },
    },
  } = props;

  const { ulsterMonthlyFee } = labels;

  const initialMemberData = {
    member: {
      id: "",
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      city: "",
      postalCode: "",
      email: "",
      account: [],
    },
  };
  const [accountDetails, getAccountDetails] = useState(initialMemberData);
  const [enquiryLink, setEnquiryLink] = useState("");

  useEffect(() => {
    populateBreadCrumbs();
    getEnquiryLink();
  }, []);

  const getEnquiryLink = () => {
    let currentTheme = getBrandName();
    let enquiryLink = currentTheme
      ? getEnquiryLinkForManageAccount(currentTheme)
      : "";
    setEnquiryLink(enquiryLink);
  };
  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Manage your account",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  /**
   * @summary Function to make api call to get the user details.
   */
  const queryUserData = async () => {
    return await graphQlCall(
      "query",
      GET_MEMBER_DETAILS,
      { id: memberId },
      {
        tenantId: tenantId,
      }
    ).then((response) => {
      getAccountDetails(response.data);
    });
  };

  useEffect(() => {
    queryUserData();
    AuthenticationCheck();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const renderUserDetails = () => {
    const {
      firstName,
      lastName,
      addressLine1,
      addressLine2,
      addressLine3,
      city,
      postalCode,
      email,
    } = accountDetails.member;
    return (
      <div className={styles.manageAccount__content_wrapper}>
        <div className={styles.base_padding}>
          <div className={styles.manageAccount__row}>
            <h2 className={styles.manageAccount__section_title}>
              Change your account setting
            </h2>
          </div>
          <div className={styles.inner_padding}>
            <div className={styles.manageAccount__row}>
              {/* Coloumn 1 */}
              <div className={styles.manageAccount__column}>
                <div className={styles.manageAccount__content_box}>
                  <p className={styles.manageAccount__title_text}>
                    Your personal details
                  </p>
                  First Name: {firstName} <br />
                  Last Name: {lastName}
                  <br />
                  <div className={styles.manageAccount__address_wrapper}>
                    <span>Address: </span>
                    <div className={styles.manageAccount__content_address}>
                      {addressLine1 ? <p>{addressLine1}</p> : null}
                      {addressLine2 ? <p>{addressLine2}</p> : null}
                      {addressLine3 ? <p>{addressLine3}</p> : null}
                      {city ? <p>{city}</p> : null}
                      {postalCode ? <p>{postalCode}</p> : null}
                    </div>
                  </div>
                  <div className={styles.manageAccount__contact_link}>
                    <span className={styles.manageAccount__fade_text}>
                      Need to change your details?{" "}
                    </span>
                    <a
                      href={enquiryLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.manageAccount__underline_link}
                    >
                      Please contact your branch
                    </a>
                  </div>
                </div>
              </div>

              {/* Coloumn 2 */}
              <div className={styles.manageAccount__column}>
                <div className={styles.manageAccount__content_box}>
                  <p className={styles.manageAccount__title_text}>
                    Your account details
                  </p>
                  Account Type: {packageDescription}
                  <br />
                  Created on:{" "}
                  {getDateByProvidedFormat(creationDate, "dd-mm-yyyy", "/")}
                  <br />
                  {getBrandName() === BRAND_ULSTER_RI ? (
                    <>
                      {` Monthly account fee: ${
                        ulsterMonthlyFee[getThemeName()[1]]
                      }`}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className={styles.manageAccount__content_box}>
                  <h4 className={styles.manageAccount__title_text}>
                    Sign-in details
                  </h4>
                  Email: {`${email}`}
                  <div className={styles.manageAccount__content_links}>
                    <Link
                      to="change-email"
                      className={styles.manageAccount__link}
                    >
                      <span> Change email</span>
                    </Link>
                    <div className={styles.manageAccount__icon_spacing}>
                        <Icon iconName="icon-chevron-circle-right" />
                      </div>
                  </div>
                  <div className={styles.manageAccount__content_links}>
                    <Link
                      to="change-password"
                      className={styles.manageAccount__link}
                    >
                      <span> Change password</span>
                    </Link>
                    <div className={styles.manageAccount__icon_spacing}>
                        <Icon iconName="icon-chevron-circle-right" />
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <Fragment>
      {_.isEqual(accountDetails, initialMemberData)
        ? null
        : renderUserDetails()}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    LoginData: LoginStateModel.getInsatnce("LoginDetails"),
    selectedAccount: SelectedAccountModel.getInsatnce("1", state),
    tenantDetails: BrandSpecificDataModel.getInsatnce("1"),
  };
};

export default connect(mapStateToProps)(ManageAccount);
