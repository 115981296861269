import { Fragment } from "react";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import styles from "./forgotPasswordSuccess.module.scss";
import { History } from "history";
import label from "../../../src/config/Labels/label.json";
import { PrimaryButton } from "components/PrimaryButton";

interface IProps {
  history: History;
}
const ForgotPasswordSuccess = (props: IProps) => {
  let { history } = props;

  const handleConfirmClick = () => {
    history.push("/login");
  };
  let { passwordUpdate, passwordChange } = label.forgotPassword;
  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container}>
        <div className={styles.forgot_password__heading}>{passwordUpdate}</div>
        <div className={styles.subhaeding}>{passwordChange}</div>

        <PrimaryButton
          text="Sign In"
          disabled={false}
          classNames={styles.sign_up__btn__next_btn}
          data-test="next-button"
          handleClick={handleConfirmClick}
        />
      </div>
      <FooterComponent />
    </Fragment>
  );
};
export default ForgotPasswordSuccess;
