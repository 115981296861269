/*========================== Theme Variables for RBS Black =====================*/
const rbs_black_button_color_scheme = {
  "--button-background": "#ffffff",
  "--primaryButtonBackground": "#0A2F64",
  "--primaryButtonBackgroundHover": "#110C51",
  "--secondaryButtonBackground": "#fff",
  "--buttonBorder": "#5e10b1",
  "--buttonTextColor": "#5e10b1",
  "--buttonShadow": "#666",
  "--button-border-background": "#110C51",
   "--button-hover-background": "#ffffff",
  "--button-hover-text-color": "#3c1053",
  "--button-font-family": "RNHouseSans",
  "--block-button-font-family": "RNHouseSans",
  "--secondaryButtonColor": "#000",
  "--genericButtonBackground": "#5e10b1",
  "--genericButtonTextColor": "#ffffff",
  "--genericButtonBackgroundHover": "#3c1053",
  "--privacy-button-background": "#ffffff",
  "--privacy-button-border": "#2e909e",
  "--privacy-button-border-top": "1px solid #dfdedb",
  "--privacy-button-border-bottom": "3px solid #dddddd",
  "--privacy-button-text-color": "#0a2f64",
  "--buttonBorderTop": "transparent",
};

const rbs_black_page_element_scheme = {
  //logo variables
  "--header-logo": 'url("/asset/rbs/logo/rb-horizontal-stacked-rgb-neg.png")',
  "--header-lg-height": "50px",
  "--header-md-height": "34px",
  "--header-sm-height": "28px",
  "--header-lg-width": "162px",
  "--header-md-width": "126px",
  "--header-sm-width": "103px",
  "--logo-url": "url('../assets/images/rbs-logo-black.png')",
  "--loader-url": "url('/assets/images/RBS-Premier.gif')",
  "--multisection-top-margin": "32px",
  "--primary": "#5a287d",
  "--heading": "#333333",
  "--secondary": "#fff",
  "--tertiary": "#0a2f64",
  "--footerBackground": "#5a287d",
  "--footer-text-color": "#5e10b1",
  "--boxShadow": "#e0d9d1",
  "--font-family": "RNHouseSans",
  "--base-padding": "32px",
  "--black-base-padding": "32px",
  "--black-base-padding-additional-info": "0px 0px 32px 0px",
  "--inner-padding": "0px",
  "--base-margin": "32px",
  "--section-base-background": "#FFFFFF",
  "--page-background": "#FFFFFF",
  "--root-background": "#f2f2f8",
  "--section-inner-background": "#ffffff",
  "--RNHouseSansLight": "RNHouseSansLight",
  "--knileBlack-font-family": "KnileBlack",
  "--quick-access-font-family": "RNHouseSans",
  "--quick-access-font-weight": "700",
  "--membership-title-font-family": "RNHouseSansThin",
  "--explore-page-background-color-black": "#fff",
  "--multisection-innerpadding-black": "0px",
  "--additional-info-bottom-padding ": "0px 0px 16px 0px",
  "--section-basepadding-black": "0px",
  "--section-margin-black ": "32px 0px",
  "--additional-info-basepadding": "0px",
  "--account-dropdown-font-family": "RNHouseSans",
  "--sticky-header-background": "#ffffff",
  "--footer-link-font-size": "18px",
  "--footer-link-icon-font-size": "14px",
  "--notice-header-base-padding": "25px 52px 25px 32px",
  "--lilac-color":"#f2eaf9",
  "--body-grey" : "#646068",
  "--text-box-border-color": "#646068",
  "--no-padding" : "0px",
  "--padding-top-zero" : "0px",
  "--opacity": "0.85",
  "--dropdown-hover": "#5e10b1"
};

const rbs_black_card_color_scheme = {
  "--cards-margin-top": "32px",
  "--card-background": "#F2F2F8",
 "--card-text": "#646068",
 "--card-heading": "#333333",
 "--card-icon": "#5a287d",
  "--card-header--separator": "#cccfd0",
  "--card-container-padding": "0px 16px 32px 16px",
  "--home-margin-fix": "0px 16px",
  "--card-footer-text-font-size": "16px",
  "--card-footer-color": "#5e10b1",
};

const rbs_black_image_banner_color_scheme = {
  "--title-first-child":  "#5a287d",
  "--title-second-child": "#5a287d",
  "--image-banner-button-background": "#FFFFFF",
  "--image-banner-button-hover-background": "#F9F9F9",
  "--image-banner-button-border": "#DFDEDB",
  "--image-banner-button-color": "#0A2F64",
};
const rbs_black_accordion_color_scheme = {
  "--accordion-primary": "#5e10b1",
  "--accordion-secondary": "#FFF",
};
const rbs_black_misc_elements_color_scheme = {
  "--link-color": "#5e10b1",
  "--link-hover-color": "#5e10b1",
  "--link-active-color": "#cccfd0",
  "--link-active-color-dark": "#3c1053",
  "--info-text-color": "#646068",
  "--counterBackground": "#0a2f64",
  "--calendarActive": "#0a2f64",
  "--calendarRange": "#9fb4ce",
  "--loadMoreLink": "#0A2F64",
  "--discountTextBackground": "#f7f7f5",
  "--subTitle": "#0a2f64",
  "--optionalText": "#0a2f64",
  "--importantNoteBg": "#dfd9d1",
  "--tabActiveBgColor": "#0a2f64",
  "--tabInactiveBgColor": "#9fb4ce",
  "--noteBackground": "#dfd9d1",
  "--linkUnderline": "underline",
  "--cookies-header-background": "#ecedfc",
  "--error-color": "#CF223F",
  "--logout-text": "#333333",
};
const rbs_black_forms_color_scheme = {
  "--formInputBgColor": "#EEEDE9",
  "--formContainerBgColor": "#fff",
  "--formFieldWrapper": "#fff",
};

const rbs_black_change_password_color_scheme = {
  "--primary": "#5a287d",
  "--container-background-color": "#fff",
  "--change-password-content-background": "#ffffff",
  "--change-password-input-textbox-background": "#F9F9F9",
  "--change-password-error-color": "#DB1A31",
  "--change-password-button-background": "#0A2F64",
  "--error-message-color": "#fff",
  "--save-changes-button-border-bottom": "#110C51",
  "--save-changes-button-border-top": "#052047",
  "--textbox-outline-color": "#ffbf47",
  "--change-page-container-padding": "0px 32px 32px 32px",
  "--change-page-container-inner-padding": "32px",
  "--padding-left-black-account-type": "16px",
};

const rbs_black_event_booking_form_color_scheme = {
  "--form-heading-font-family": "RNHouseSans",
  "--field-font-family": "RNHouseSans",
  "--form-text-color": "#000",
  "--select-box-border-color": "#646068",
  "--select-box-border-color-onfocus": "#646068",
  "--select-box-shadow-primary-onfocus": "none",
  "--select-box-shadow-secondary-onfocus": "none",
  "--input-field-box-shadow-primary": "#fff",
  "--input-field-box-shadow-secondary": "#ffffff",
  "--input-field-box-shadow-primary-onfocus": "none",
  "--input-field-box-shadow-secondary-onfocus": "none",
  "--form-input-textbox-background": "#fff",
  "--error-message-color-event-booking": "#fff",
  "--group-error-message-color-event-booking": "#CF223F",
  "--group-error-box-max-width": "556px",
  "--textbox-outline-color": "#ffbf47",
  "--error-message-box-width": "484px",
  "--form-container-padding": "0px",
  "--field-container-padding": "0px",
  "--field-container-background": "#FFFFFF",
  "--event-booking-success-page-background": "#FFFFFF",
  "--form-heading-margin": "28px 0px 16px 0px",
};
export const rbs_black_theme_vars = {
  ...rbs_black_button_color_scheme,
  ...rbs_black_page_element_scheme,
  ...rbs_black_card_color_scheme,
  ...rbs_black_image_banner_color_scheme,
  ...rbs_black_accordion_color_scheme,
  ...rbs_black_misc_elements_color_scheme,
  ...rbs_black_forms_color_scheme,
  ...rbs_black_change_password_color_scheme,
  ...rbs_black_event_booking_form_color_scheme,
};
