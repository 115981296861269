import React, { useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import styles from "./privacy.module.scss";
import { MarkedComponent } from "components/MarkedComponent";
import { connect } from "react-redux";
import { CookieSettingModel } from "redux/models/CookieSettings";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { getBrandName } from "services/RestAPI/exploreCalls";
import { BRAND_ULSTER_NI } from "config/constants";
import {Helmet} from "react-helmet";
interface IProps {
  cookieSetting: CookieSettingModel[];
}

const PrivacyUlsterRI: React.FC<IProps> = (props) => {
  const loadedBrand =
    getBrandName() === BRAND_ULSTER_NI
      ? "privacyPolicyUlsterNi"
      : "privacyPolicyUlsterRi";
  const { t } = useTranslation(loadedBrand);
  const getTranslations = (key) => {
    return t(`${key}`, { returnObjects: true });
  };

  useEffect(() => {
    populateBreadCrumbs();
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Privacy Policy",
      },
    ];
    new BreadCrumbModel({
      id: "1",
      breadCrumbData: breadCrumbData,
    }).$save();
  };

  return (
    <>
      <Helmet>
        <script>
          {`
            //SHOULD BE USED ONLY ON COOKIE POLICY. TRIGGER FUNCTION BELOW TO REMOVE DUPLICATE CATEGORIES
            function clearDup() {
              var sec = document.getElementById("ot-sdk-cookie-policy");
              var tally = [];
              for (var i = sec.length - 1; i >= 0; i--) {
                if (tally[sec[i].firstChild.innerText] === undefined){
                  tally[sec[i].firstChild.innerText] = 1;
                } else {
                  //console.log(i,sec[i].firstChild.innerText);
                  sec[i].remove();
                  //return true; 
                }
              } 
              //return false;
            }
          `}
        </script>
      </Helmet>
      <div className={styles.privacyPage}>
      <div className={styles.privacyPage__wrapper}>
        <div className={styles.content_wrapper}>
          <div className={styles.privacyPage__row}>
            <div className={styles.privacyPage__title}>
              {getTranslations(`pageHeading.sectionTitle`)}
            </div>
            <div className={styles.privacy__para}>
              {getTranslations(`pageHeading.content`).map((item, idx) => {
                return (
                  <p key={`section_${idx}`}>
                    <MarkedComponent rawString={item} />
                  </p>
                );
              })}
            </div>
          </div>
          {getTranslations("TextContent").map((item, idx) => {
            return (
              <div className={styles.privacyPage__row} key={`section_${idx}`}>
                <div className={styles.privacy__sub_heading}>
                  {item.sectionTitle}
                </div>
                <div className={styles.privacy__para} key={`para_${idx}`}>
                  <MarkedComponent
                    rawString={getTranslations(`${item.content}`)}
                  />
                </div>
              </div>
            );
          })}

          {/* ============== Accept Changes BLock =========== */}
          <div className={styles.privacyPage__row}>
            <div className={styles.privacyPage__manage_setting}>
                <button id="ot-sdk-btn" className="ot-sdk-show-settings"></button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    cookieSetting: CookieSettingModel.list(),
  };
};

export default withTranslation("privacyPolicyUlsterRi")(
  connect(mapStateToProps)(PrivacyUlsterRI)
);
