import { IdleSessionModel } from "../../redux/models/IdleSessionModel";
import { store } from "../../redux/store";
import { IdleSessionTimeout } from "../../redux/models/IdleSessionTimeout";
import { getRefreshTokenExpiryTime } from "utlis/generalUtils";
import { TokenTimeout } from "redux/models/TokenTimeout";

let idleTime = 0;
let timeAfterLogin = 0;

const resetTimer = () => {
  idleTime = 0;
  timeAfterLogin = 0;
};
const events = [
  "mousemove",
  "mousedown",
  "click",
  "touchmove",
  "touchstart",
  "touchend",
  "keydown",
  "keypress",
  "scroll",
];
let time, tokenCheckTime;

const timerIncrement = () => {
  //Increment the idle time counter every 1 minute. 20 minutes is the idle session time
  idleTime = idleTime + 1;
  let timeout = IdleSessionTimeout.list(store.getState())[0].props.time;

  //Increment the login time counter every 1 minute, tokenTimeOut is the expiry time calculated at login time
  timeAfterLogin = timeAfterLogin + 1;
  let tokenTimeout = TokenTimeout.list(store.getState())[0].props.timeOut;

  if (idleTime === timeout - 1) {
    new IdleSessionModel({
      id: "1",
      sessionStatus: "inactive",
    }).$update("1");
  } else if (idleTime === timeout) {
    new IdleSessionModel({
      id: "1",
      sessionStatus: "loggedOut",
    }).$update("1");
    resetTimer();
  } else if (timeAfterLogin === tokenTimeout) {
    new IdleSessionModel({
      id: "1",
      sessionStatus: "tokenExpired",
    }).$update("1");
    resetTimer();
  }
};

//Check token expiry after every 4 minutes
const checkTokenExpiry = () => {
  let expiryTime = getRefreshTokenExpiryTime();
  if (!expiryTime) {
    new IdleSessionModel({
      id: "1",
      sessionStatus: "tokenExpired",
    }).$update("1");
    resetTimer();
  }
};

export const setSessionEvents = () => {
  let interval = IdleSessionTimeout.list(store.getState())[0].props.interval;
  let tokenInterval = 240000;
  clearInterval(time);
  clearInterval(tokenCheckTime);
  time = setInterval(timerIncrement, interval); // 1 minute
  tokenCheckTime = setInterval(checkTokenExpiry, tokenInterval); // 4 minute

  // eslint-disable-next-line array-callback-return
  events.map((name) => {
    document.addEventListener(
      name,
      () => {
        idleTime = 0;
      },
      true
    );
  });
};

export const removeSessionEvents = () => {
  // eslint-disable-next-line array-callback-return
  events.map((name) => {
    document.removeEventListener(
      name,
      () => {
        idleTime = 0;
        clearInterval(time);
        clearInterval(tokenCheckTime);
      },
      true
    );
  });
  clearInterval(time);
  clearInterval(tokenCheckTime);
};
