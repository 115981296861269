import React, { Fragment, useState, useEffect } from "react";
import styles from "./SigninImpMessage.module.scss";
import { MultiSection } from "components/MutliSection";
import classNames from "classnames";
import { AccordionComponent } from "components/AccordionComponent";
import { get } from "services/RestAPI/apiUtil";
import { getBrandName } from "services/RestAPI/exploreCalls";
import { PrimaryButton } from "components/PrimaryButton";

interface SigninImpMessageProps {
  toggleModal: any;
}
const SigninImpMessage: React.FC<SigninImpMessageProps> = (props) => {
  const [pageContent, setPageContent] = useState<any>([]);
  const { toggleModal } = props;

  const getPageContent = async (mainPage) => {
    return get(mainPage).then((resp) => {
      setPageContent(resp.data.data);
    });
  };

  const renderCustomWidthWrapper = (componentData) => {
    return (
      <div className={classNames(styles.recentBenefit__row)}>
        {componentData.content.map((ele, index) => {
          return allTypesMapping[ele.type](ele.metaData, index);
        })}
      </div>
    );
  };
  const renderMultiSections = (multiSectionContent, index) => {
    let { width, padding } = multiSectionContent;
    return (
      <div
        style={{ width: width, padding: padding }}
        key={"Covid-Multisection" + index}
      >
        <MultiSection
          metaData={multiSectionContent}
          id={index}
          basePaddingOverride="0px"
          innerPaddingOverride="0px"
        />
      </div>
    );
  };

  const renderAccordionComponent = (accordionContent, index) => {
    let { width, padding } = accordionContent;
    return (
      <div
        style={{ width: width, padding: padding }}
        key={"Covid-Accordion" + index}
      >
        <AccordionComponent
          metaData={accordionContent}
          id={index}
          overrides={true}
        />
      </div>
    );
  };

  useEffect(() => {
    getPageContent(`/updates-section/${getBrandName()}.json`);
  }, []);

  const allTypesMapping = {
    MultiSection: renderMultiSections,
    CustomWidthWrapper: renderCustomWidthWrapper,
    AccordionComponent: renderAccordionComponent,
  };
  return pageContent && pageContent.length > 0 ? (
    <Fragment>
      <div className={styles.updatesSection_wrapper}>
        {pageContent.map((pageElement, id) => {
          return allTypesMapping[pageElement.type](pageElement.metaData, 1);
        })}

        <div className={styles.updatesSection_close_btn}>
          <PrimaryButton text={"Close"} handleClick={() => toggleModal("close")} disabled={false}/>
          {/* <Button
            text={"Close"}
            handleClick={() => toggleModal("close")}
            disabled={false}
          /> */}
        </div>
      </div>
    </Fragment>
  ) : null;
};
export default SigninImpMessage;
