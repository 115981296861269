import { Fragment } from "react";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import styles from "./activatemember.module.scss";
import { isEmpty } from "lodash";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { FormFieldError } from "components/FormFieldError";
import { withRouter } from "react-router-dom";
import * as errorMessage from "config/errorMessages";
import { ErrorBox } from "../Login/ErrorBox";
import { emailFieldRegex, SpaceCharRegex } from "config/constants";
import { BreadCrumbs } from "components/BreadCrumbs";
import { UNAUTHORIZED } from "config/errorCodeConstants";
import * as errorMessages from "../../config/errorMessages";
import { PrimaryButton } from "components/PrimaryButton";
import { useYupValidationResolver } from "utlis/generalUtils";
interface IValues {
  formErrorMessage?: string;
  onHandleSubmit: any;
  backendErrors: any;
  setBackendError: any;
  isShowError: boolean;
  isShowErrorMsg: any;
}
const staticData = {
  pageTitle: `Activate Membership Services`,
  textBlock1: {
    one: `Please provide the email address at which you'd like to receive your Membership Services communications.`,
  },
  textBlock2: {
    noteFirst: `Your password must include:`,
    noteSecond: `Your password cannot include more than 3 consecutive letters or numbers, or the word 'password'.`,
    checkList: [
      `At least 8 characters`,
      `At least one capital letter`,
      `At least one small letter`,
      `At least one number`,
    ],
  },
  textBlock3: {
    note: "This will also be your Membership Services login ID.",
  },
  textBlock4: {
    message:
      "Please also provide a password. This will enable you to access your Membership Services benefits without using your mobile or online banking credentials, should you wish to do so.",
  },
};

export const ActivateMember = (props: IValues) => {
  let { onHandleSubmit,
    backendErrors} = props;

  const {
    EMAIL_PATTERN_ERROR,
    EMAIL_REQUIRED_ERROR,
    CONFIRM_EMAIL_MATCH,
    CONFIRM_EMAIL,
    PASSWORD_VALIDATION_MIN_CHARS,
    PASSWORD_VALIDATION_MAX_CHARS,
    PASSWORD_VALIDATION_LOWERCASE_CHAR,
    PASSWORD_VALIDATION_UPPERCASE_CHAR,
    CONFIRM_PASSWORD,
    PASSWORD_VALIDATION_NUMBER,
    PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM,
    PASSWORD_MATCH_CONFIRM,
    PASSWORD_VALIDATION_SPACE_CHARACTER,
    ERROR_BOX_HEADER,
    PASSWORD_REQUIRED_ERROR,
  } = errorMessage;

  const SignupSchema = yup.object({
    newEmail: yup
      .string()
      .email(EMAIL_PATTERN_ERROR)
      .matches(emailFieldRegex, errorMessage.EMAIL_PATTERN_ERROR)
      .required(EMAIL_REQUIRED_ERROR),
    confirmEmail: yup
      .string()
      .test("emailCheck", `${CONFIRM_EMAIL_MATCH}`, function (value) {
        let newEmail:any = this.resolve(yup.ref("newEmail"));
        let confirmEmail:any = this.resolve(yup.ref("confirmEmail"));
        return newEmail.toLowerCase() === confirmEmail.toLowerCase();
      })
      .matches(emailFieldRegex, errorMessage.EMAIL_PATTERN_ERROR)
      .required(`${CONFIRM_EMAIL}`),
    newPassword: yup
      .string()
      .min(8, PASSWORD_VALIDATION_MIN_CHARS)
      .max(80, PASSWORD_VALIDATION_MAX_CHARS)
      .matches(/[a-z]/, PASSWORD_VALIDATION_LOWERCASE_CHAR)
      .matches(/[A-Z]/, PASSWORD_VALIDATION_UPPERCASE_CHAR)
      .matches(/[0-9]/, PASSWORD_VALIDATION_NUMBER)
      .matches(/^((?!password).)*$/i, PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM)
      .test("newPassword", PASSWORD_VALIDATION_SPACE_CHARACTER, (value) => {
        if (SpaceCharRegex.test(value!)) {
          return false;
        } else return true;
      })
      .test(
        "newPassword",
        PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM,
        (value) => {
          let newValue = value!.toLowerCase();
          for (let i = 0; i < value!.length; i++) {
            if (
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) - 1 &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) - 2 &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 3) - 3
            ) {
              return false;
            } else if (
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 3)
            ) {
              return false;
            }
          }
          return true;
        }
      )
      .required(PASSWORD_REQUIRED_ERROR),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], PASSWORD_MATCH_CONFIRM)
      .required(CONFIRM_PASSWORD),
  });
  const resolver = useYupValidationResolver(SignupSchema);
  const { register, handleSubmit, formState:{ errors }, getValues, clearErrors } = useForm({
      resolver
  });

  const resetErrors = (fieldOne, fieldtwo, fieldToReset) => {
    let formValues = getValues();
    return formValues[fieldOne] === formValues[fieldtwo]
      ? clearErrors(fieldToReset)
      : null;
  };

  const formErrorMsg = () => {
    let formErrors;
    if (Object.keys(errors).length) {
      let uniqueErrors: any;
      let errorsArray = Object.values(errors).map((item: any) => {
        return item.message;
      });
      uniqueErrors = Array.from(new Set(errorsArray));
      formErrors = (
        <ErrorBox
          className={styles.error_box_background}
          errors={uniqueErrors}
          errorHeader={ERROR_BOX_HEADER}
        />
      );
    }
    return formErrors;
  };

  const renderErrorBox = (errorToPrint?: any) => {
    if (errorToPrint) {
      const { errorMsg } = errorToPrint;
      return (
        <ErrorBox
          errors={errorMsg}
          errorHeader={errorMessages.ERROR_BOX_HEADER}
        />
      );
    }
    if (backendErrors && backendErrors.length) {
      let errorMsg = backendErrors.map((errorItem) => {
        switch (errorItem) {
          case UNAUTHORIZED:
            return errorMessages.GENERAL_ERROR_MESSAGE; // Incorrect password entered
          default:
            return errorMessages.LWB_MEMBER_ACTIVATION_ERROR;
        }
      });

      //If same error is occurred multiple time the to get unique value
      let unique = (item, index, array) => array.indexOf(item) === index;
      errorMsg = errorMsg.filter(unique);
      let errorHeader = "We could not activate your account for Membership Services";
      return backendErrors.length ? (
        <ErrorBox
          errors={errorMsg}
          errorHeader={errorHeader}
        />
      ) : null;
    } else {
      return;
    }
  };

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
       <div className={styles.activateMember__container_wrapper}> 
        <div className={styles.page_wrapper}>
          <BreadCrumbs/>
          <p className={styles.page_title}>{staticData.pageTitle}</p>
          <div className={styles.activateMember__row}>
               <div className={styles.activateMember__container}> 
              <div className={styles.activateMember__errorBox}>
                {renderErrorBox()}
                {formErrorMsg()}
              </div>
              <div className={styles.activateMember__row}>
                <div className={styles.sub_heading}>
                  {staticData.textBlock1.one}
                </div>
              </div>

              <div className={styles.activateMember__row}>
                <div className={styles.activateMember_form}>
                  <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
                    <div className={styles.changePassword__row_content}>
                      <div className={styles.changePassword__column_content}>
                        <div className={styles.changePassword__content_box}>
                          <p className={styles.content_text}>Email address *</p>
                          {errors.newEmail && (
                            <FormFieldError
                              errorText={errors.newEmail.message}
                              errorId={"email"}
                            />
                          )}
                          <input
                            aria-labelledby="email"
                            {...register('newEmail')}
                            type="email"
                            data-test="newEmail-field"
                            className={styles.form_field}
                            onChange={(e) =>
                              resetErrors(
                                "newEmail",
                                "confirmEmail",
                                "confirmEmail"
                              )
                            }
                            autoComplete="on" />
                          <div className={styles.text_block3}>
                            {staticData.textBlock3.note}
                          </div>
                          <p className={styles.content_text}>
                            Confirm email address*
                          </p>
                          {!errors.newEmail || errors.confirmEmail
                            ? errors.confirmEmail && (
                                <FormFieldError
                                  errorText={errors.confirmEmail.message}
                                  errorId={"confirmEmail"}
                                />
                              )
                            : null}
                          <input
                            {...register('confirmEmail')}
                            aria-labelledby="confirmEmail"
                            type="email"
                            className={styles.form_field}
                            required
                            autoComplete="off" />
                          <div className={styles.text_block4}>
                            {staticData.textBlock4.message}
                          </div>
                          <p className={styles.content_text}>Password *</p>
                          {errors.newPassword && (
                            <FormFieldError
                              errorText={errors.newPassword.message}
                              errorId={"newPassword"}
                            />
                          )}
                          <input
                            aria-labelledby="newPassword"
                            {...register('newPassword')}
                            type="password"
                            data-test="newPassword-field"
                            className={styles.form_field}
                            autoComplete="off"
                            onChange={(e) =>
                              resetErrors(
                                "newPassword",
                                "confirmPassword",
                                "newPassword"
                              )
                            } />
                          <p className={styles.content_text}>
                            Confirm password *
                          </p>
                          {errors.newPassword || errors.confirmPassword
                            ? errors.confirmPassword && (
                                <FormFieldError
                                  errorText={errors.confirmPassword.message}
                                  errorId={"confirmPassword"}
                                />
                              )
                            : null}
                          <input
                            aria-labelledby="confirmPassword"
                            {...register('confirmPassword')}
                            type="password"
                            className={styles.form_field}
                            autoComplete="off" />
                        </div>
                      </div>
                    </div>
                    <div className={styles.activateMember__row}>
                      <label>{staticData.textBlock2.noteFirst}</label>
                      <div className={styles.activateMember__row}>
                        <ul className={styles.check_list}>
                          {staticData.textBlock2.checkList.map((item, key) => (
                            <li key={key}>{item}</li>
                          ))}
                        </ul>
                      </div>
                      <div className={styles.sub_heading}>
                      <label>{staticData.textBlock2.noteSecond}</label>
                      </div>
                    </div>
                    <div
                      className={
                        (styles.changePassword__row_content,
                        styles.button_container,
                        styles.btn)
                      }
                    >
                      <div className={styles.activate__next_btn_wrapper}>
                        <PrimaryButton
                          text="Activate"
                          disabled={!isEmpty(errors) ? true : false}
                          classNames={styles.signup__next_btn}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};

export default withRouter(ActivateMember);
