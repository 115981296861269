import { BaseModel } from "./BaseModel";

interface IShowErrorSignUpProps {
  id: string;
  isShowError: boolean | false;
  isShowErrorMsg: string;
}
/**
 * To store the user data coming from the GraphQL.
 * @export
 * @class ShowErrorSignUpStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class ShowErrorSignUpStateModel extends BaseModel<
  IShowErrorSignUpProps
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IShowErrorSignUpProps) {
    super(props);
  }

  static resource = "IShowErrorSignUpState";
}
