import { BaseModel } from "./BaseModel";

interface IIdleSessionModelProps {
  id: string;
  sessionStatus: string;
}
/**
 * @export
 * @class IdleSessionModel
 * @extends {BaseModel<IIdleSessionModelProps>}
 */
export class IdleSessionModel extends BaseModel<IIdleSessionModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IIdleSessionModelProps) {
    super(props);
  }

  static resource = "idleSession";
}
