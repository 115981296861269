import { BaseModel } from "./BaseModel";

export interface ILoginEmailModelProps {
  id: string;
  loginEmail: any;
}
/**
 * To store the login email (username) of user
 * @export
 * @class LoginEmail
 * @extends {BaseModel<IDummyModelProps>}
 */
export class LoginEmailModel extends BaseModel<ILoginEmailModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ILoginEmailModelProps) {
    super(props);
  }

  static resource = "LoginEmail";
}
