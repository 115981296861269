import styles from "./footer.module.scss";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import content from "../../config/Labels/label.json";
import { Icon } from "components/Icon";
import { BrandSpecificUrlsModel } from "redux/models/brandSpecificUrlModel";
import { getBrandName } from "services/RestAPI/exploreCalls";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import {
  BRAND_RBS,
  BRAND_NATWEST,
  BRAND_ULSTER_NI,
} from "../../config/constants";
interface IProps {
  brandSpecificUrlsModel: BrandSpecificUrlsModel[];
  themeNameModel: ThemeNameModel;
}
const FooterComponent: React.FC<IProps> = (props) => {
  const { brandSpecificUrlsModel, themeNameModel } = props;
  let themeName;
  themeName =
    themeNameModel &&
    themeNameModel[0] &&
    themeNameModel[0].props.name.split("_")[0];
  let brandUrls;
  brandUrls =
    brandSpecificUrlsModel &&
    brandSpecificUrlsModel.length &&
    brandSpecificUrlsModel[0] &&
    brandSpecificUrlsModel[0].props.brandSpecificUrls;
  const {
    accessibility,
    legalInformation,
    privacyAndCookiesNotice,
  } = brandUrls;
  const {
    footer: {
    needHelp,
    contactUs,
    ulsterFooterContent,
    ulsterFooterNote,
    ulsterFooterNote1,
    ulsterNiFooterNote1,
    ulsterNiFooterContent,
    }
  } = content;

  const BrandLoaded = getBrandName();
  
  return (
    <div className={styles.footer} >
      <div className={styles.footer_body}>
        <div>
          <div className={styles.footer_need__help} >{needHelp}</div>
          <div className={styles.footer_contact_us}>
          <span className={styles.footer_contact__us} >
                <Icon iconName="icon-telephone-pad" />
          </span>
            <Link to="/contact-us">
              <span className={styles.footer_contact__us_text}>
                 {contactUs}
              </span>
            </Link>
          </div>
        </div>
        <div className={styles.footer_hyperlink__parent} >
          <div className={styles.footer_hyperlink__padding}>
            <a
              href={accessibility ? accessibility.url : ""}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footer_hyperlink}
            >
              <div className={styles.footer_hyperlink__text} >
                {accessibility ? accessibility.label : ""}
              </div>
              <div className={styles.footer_hyperlink__icon}>
                <Icon iconName="icon-chevron-circle-right" />
              </div>
            </a>
          </div>
          <div className={styles.footer_hyperlink__padding}>
            <Link
              className={styles.footer_hyperlink}
              target="_blank"
              to={BrandLoaded === BRAND_RBS || BrandLoaded === BRAND_NATWEST ? `${process.env.PUBLIC_URL}/privacy` : privacyAndCookiesNotice ? `${privacyAndCookiesNotice.url}`: ''}
            >
              <div className={styles.footer_hyperlink__text}>
                {privacyAndCookiesNotice ? privacyAndCookiesNotice.label : ""}
              </div>
              <div className={styles.footer_hyperlink__icon}>
                <Icon iconName="icon-chevron-circle-right" />
              </div>
            </Link>
          </div>
          <div className={styles.footer_hyperlink__padding}>
            <a
              href={legalInformation ? legalInformation.url : ""}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.footer_hyperlink}
            >
              <div className={styles.footer_hyperlink__text}>
                {legalInformation ? legalInformation.label : ""}
              </div>
              <div className={styles.footer_hyperlink__icon}>
                <Icon iconName="icon-chevron-circle-right" />
              </div>
            </a>
          </div>
        </div>
      </div>
      {themeName === "ulster" ? (
        <div className={styles.footer_ulster_content_container}>
          <div className={styles.footer_content_separator}></div>
          <div className={styles.footer_ulster_text}>
            {BrandLoaded === BRAND_ULSTER_NI ? (
              <>
                <div>{ulsterNiFooterNote1}</div><br/>
                <div>{ulsterNiFooterContent}</div>
              </>
            ) : (
              <>
                <div>{ulsterFooterContent}</div>
                <div>{ulsterFooterNote1}</div>
                <div>{ulsterFooterNote}</div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    brandSpecificUrlsModel: BrandSpecificUrlsModel.list(state),
    themeNameModel: ThemeNameModel.list(state),
  };
};
export default connect(mapStateToProps)(withRouter(FooterComponent));
