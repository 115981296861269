import React, { Fragment } from "react";
import Accordion from "components/Accordion";
import styles from "./accordion.module.scss";
import { Icon } from "components/Icon";
import { renderMarkedText } from "components/AbstractedMethods";
import classnames from "classnames/bind";
let bindCss = classnames.bind(styles);
interface accordion {
  heading: string;
  subTitle?: string;
  content?: string | any;
  className?: string;
  iconName?: string;
}

interface content {
  heading?: string;
  subTitle?: string;
  content?: accordion[];
}
interface IProps {
  metaData: content;
  id?: string;
  overrides?: any;
}

const AccordionComponent: React.FC<IProps> = ({
  metaData: { heading, content },
  id,
  overrides,
}) => {
  const renderAccordion = () => {
    return (content || []).map((value, index) => {
      return (
        <div className={styles.accordion_container} key={index}>
          <Accordion
            title={value.heading}
            subTitle={value.subTitle}
            iconName={value.iconName}
            isOpen={false}
            content={renderContent(value.content)}
            className={value.className}
          />
        </div>
      );
    });
  };

  const renderLinkWithIcon = (content, componentKey) => {
    return (
      <div className={styles.link_icon__container} key={componentKey}>
        <a href={content.link} className={styles.link_icon}>
          {content.text}
          <div className={styles.icon}>
            <Icon iconName={content.linkIcon} />
          </div>
        </a>
      </div>
    );
  };

  const renderContent = (value) => {
    return (value || []).map((component, componentKey) => {
      return typeMappings[component.type](
        component.content,
        componentKey,
        styles[component.className]
      );
    });
  };

  const typeMappings = {
    marked: renderMarkedText,
    linkWithIcon: renderLinkWithIcon,
  };

  return (
    <Fragment>
      <div
        id={id}
        className={bindCss({
          component_wrapper_override: overrides,
          component_wrapper: true,
        })}
        data-test="accordion-component"
      >
        <h2>{heading}</h2>
        {renderAccordion()}
      </div>
    </Fragment>
  );
};

export default AccordionComponent;
