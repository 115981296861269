import { Theme } from "../interfaces";
import { Typography } from "./typography";

const natwestTheme: Theme = {
  name: "natwest",
  properties: {
    "--login-screen-image":
      'url("/asset/Personal_Current_Account_Girl_with_tablet_LS.jpg")',
    "--stripe-silver-color": "#646068", //-silver
    "--stripe-platinum-color": "#8138e0", //-platinum
    "--stripe-black-color": "#000", //-black
    "--create_online_account-color": "#8C1D77",
    "--create_online_account-link-color": "#160720",
    "--forgot-email-background-color": "#FFF",
    "--forgot-email-form-background-color": "#ffffff",
    "--textbox-outline-color": "#ffbf47",
    "--textbox-background": "#fff",
    "--forgot-email-home-link-color": "#5e10b1",
    "--active-link-color": "#3c1053",
    "--box-shadow-color-first": "#fff",
    "--box-shadow-color-second": "#fff",
    "--login-button-width": "max-content",
    ...Typography.properties,
  },
};

const rbsTheme: Theme = {
  name: "rbs",
  properties: {
    "--login-screen-image":
    'url("/asset/Personal_Current_Account_Girl_with_tablet_LS.jpg")',
    "--stripe-silver-color": "#646068", //-silver
    "--stripe-platinum-color": "#8138e0", //-platinum
    "--stripe-black-color": "#000",
    "--create_online_account-color": "#8C1D77",
    "--create_online_account-link-color": "#0A2F64",
    "--forgot-email-background-color": "#FFF",
    "--forgot-email-form-background-color": "#ffffff",
    "--textbox-outline-color": "#ffbf47",
    "--textbox-background": "#fff",
    "--forgot-email-home-link-color": "#5e10b1",
    "--active-link-color": "#3c1053",
    "--box-shadow-color-first": "#fff",
    "--box-shadow-color-second": "#fff",
    "--login-button-width": "max-content",
    ...Typography.properties,
  },
};

const ulsterNITheme: Theme = {
  name: "ulster_ni",
  properties: {
    "--login-screen-image": 'url("/asset/ulster_ni_login.jpg")',
    "--stripe-ufirstgold-color": "#FCB900", //ufirstgold
    "--stripe-ufirst_Private-color": "#8138E0", //ufirst Private
    "--create_online_account-color": "#00A6B5",
    "--create_online_account-link-color": "#101831",
    "--forgot-email-background-color": "#FFF",
    "--forgot-email-form-background-color": "#ffffff",
    "--textbox-outline-color": "#B7BE16",
    "--textbox-background": "#fff",
    "--forgot-email-home-link-color": "#5e10b1",
    "--active-link-color": "#3c1053",
    "--box-shadow-color-first": "#fff",
    "--box-shadow-color-second": "#fff",
    "--login-button-width": "100%",
    ...Typography.properties,
  },
};

const ulsterRITheme: Theme = {
  name: "ulster_ri",
  properties: {
    "--login-screen-image": 'url("/asset/ulster_ri_login.jpg")',
    "--stripe-ufirst-color": "#009185", //ufirst
    "--stripe-ufirstgold-color": "#D7572B", //ufirstgold
    "--stripe-ufirst_Private-color": "#23356B", //ufirst Private
    "--create_online_account-color": "#00A6B5",
    "--create_online_account-link-color": "#101831",
    "--forgot-email-background-color": "#F7F7F7",
    "--forgot-email-form-background-color": "#ffffff",
    "--textbox-outline-color": "#B7BE16",
    "--textbox-background": "#f0eeea",
    "--forgot-email-home-link-color": "#AB3388",
    "--box-shadow-color-first": "#a09489",
    "--box-shadow-color-second": "#fff",
    "--login-button-width": "100%",
    ...Typography.properties,
  },
};

export const BrandWiseThemeColor = {
  natwestTheme,
  rbsTheme,
  ulsterNITheme,
  ulsterRITheme,
};

export default BrandWiseThemeColor;
