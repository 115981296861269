import React from "react";
import { Route, Switch, withRouter, match, Redirect } from "react-router-dom";
import { RecentBenefitContainer } from "./RecentBenefitUsage/index";
import ChangeEmailContainer from "routes/HeaderFooterWrapper/Account/ChangeEmail/ChangeEmailContainer";
import ConfirmEmailPage from "./ChangeEmail/ChangeEmailConfirmationPage/ChangeEmailConfirmPage";
import { ChaneEmailSuccessPage } from "./ChangeEmail/ChangeEmailSuccessPage/ChangeEmailSuccessPage";
import { ChangePasswordSuccessPage } from "./ChangePasswordSuccessPage/index";
import { AuthenticationCheck } from "utlis/generalUtils";
import { ManageAccount } from "../Account/ManageAccount/index";
import ChangePasswordContainer from "./ChangePassword/ChangePasswordContainer";

interface IProps {
  match: match;
}

const AccountRoute: React.FC<IProps> = (props) => {
  const routes = [
    /*Added new route for manage account */
    {
      path: `${props.match.path}/manage-account`,
      exact: false,
      component: ManageAccount,
    },
    {
      path: `${props.match.path}/recent-benefit-usage`,
      exact: false,
      component: RecentBenefitContainer,
    },
    /*Added new route for change-email */
    {
      path: `${props.match.path}/change-email`,
      exact: true,
      component: ChangeEmailContainer,
    },
    {
      path: `${props.match.path}/confirm-email`,
      exact: true,
      component: ConfirmEmailPage,
    },
    {
      path: `${props.match.path}/email-success-page`,
      exact: true,
      component: ChaneEmailSuccessPage,
    },
    {
      path: `${props.match.path}/password-success-page`,
      exact: true,
      component: ChangePasswordSuccessPage,
    },
    {
      path: `${props.match.path}/change-password`,
      exact: true,
      component: ChangePasswordContainer,
    },
  ];

  const renderRoutes = () => {
    return routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      );
    });
  };

  return (
    <Switch>
      {AuthenticationCheck() ? renderRoutes() : <Redirect to="/login" />}
      <Redirect to={`${props.match.path}/manage-account`} />
    </Switch>
  );
};

export default withRouter(AccountRoute);
