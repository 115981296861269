import { useState, useEffect } from "react";
import { withRouter, match } from "react-router-dom";
import { History, Location } from "history";
import ForgotEmailPage from "./ForgotEmailForm";
import { FORGOT_EMAIL } from "services/GraphQL/gqlQueries";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { connect } from "react-redux";
import { CaptchaModel } from "redux/models/CaptchaModel";
interface IProps {
  location: Location;
  history: History;
  match: match;
  loggedInUser: any;
  tenantDetails: BrandSpecificDataModel;
  captchaDetails: CaptchaModel;
}

const ForgotEmailContainer = (props: IProps) => {
  const [backendErrors, setError] = useState([]);
  let { history } = props;
  useEffect(() => {
    populateBreadCrumbs();
  }, []);

  const {
    tenantDetails: {
      props: { tenantId, brandName },
    },
    captchaDetails: {
      props: { captchaSiteKey },
    },
  } = props;

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Forgot Email",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  const forgotEmailData = async (values) => {
    let prefixBirthMonth = values.birthMonth.split("");
    let newBirthMonth =
      prefixBirthMonth.length === 1
        ? (prefixBirthMonth[0] = "0" + prefixBirthMonth)
        : values.birthMonth;
    let birthDate =
      values.birthYear + "-" + newBirthMonth + "-" + values.birthDay;
    return await graphQlCall(
      "mutation",
      FORGOT_EMAIL,
      {
        lastName: values.lastName,
        firstName: values.firstName,
        birthDate: birthDate, //"1994-02-21T00:00:00.000Z",
        accountId: values.accountNumber,
        brandName: brandName,
        accountSortCode: `${
          values.sortCodeFirstPart +
          values.sortCodeSecondPart +
          values.sortCodeThirdPart
        }`,
      },
      {
        tenantId: tenantId,
      },
      "false"
    )
      .then((data) => {
        const response = data.data.forgotUsername;
        const { userName } = response;
        if (response.returnDetails.code === "200") {
          history.push("/forgotemail-successpage", userName);
        }
      })
      .catch((error) => {
        setError(onGqlError(error));
      });
  };

  const handleLoginClick = (values) => {
    forgotEmailData(values);
  };
  return (
    <ForgotEmailPage
      handleLoginClick={handleLoginClick}
      errorSet={backendErrors}
      captchaSiteKey={captchaSiteKey}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    tenantDetails: BrandSpecificDataModel.getInsatnce("1"),
    captchaDetails: CaptchaModel.getInsatnce("1"),
  };
};
export default connect(mapStateToProps)(withRouter(ForgotEmailContainer));
