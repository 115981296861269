import React, { Fragment } from "react";
import styles from "./multi-section.module.scss";
import classnames from "classnames/bind";
import { renderMarkedText } from "components/AbstractedMethods";

let bindCss = classnames.bind(styles);

interface IProps {
  metaData: sections;
  id?: string;
  basePaddingOverride?: string;
  innerPaddingOverride?: string;
}

interface sections {
  sections: section[];
}

interface section {
  heading?: string;
  title?: string;
  className?: string;
  content?: any[];
}

const MultiSection: React.FC<IProps> = ({
  metaData: { sections },
  id,
  basePaddingOverride,
  innerPaddingOverride,
}) => {
  const renderRow = (content) => {
    return (content || []).map(({ heading, content }, innerIndex) => {
      return (
        <div
          className={bindCss({
            row: true,
            inner_section__container: true,
          })}
          key={innerIndex}
        >
          {heading ? <h4>{heading}</h4> : ""}
          {(content || []).map(
            ({ type, content, className }, innerSubIndex) => {
              return (
                <div data-test="render_row" key={innerSubIndex}>
                  {typeMapping[type](content, innerSubIndex, styles[className])}
                </div>
              );
            }
          )}
        </div>
      );
    });
  };
  const renderColumn = (content) => {
    return (content || []).map((innerSection, innerIndex) => {
      return (
        <div className={styles.inner_section__container} key={innerIndex}>
          {(innerSection.content || []).map(
            ({ heading, content }, innerSubIndex) => {
              return (
                <div
                  key={innerSubIndex}
                  className={bindCss({
                    column: true,
                  })}
                >
                  {heading ? <h4>{heading}</h4> : ""}
                  {(content || []).map(
                    ({ type, content, className }, index) => {
                      return (
                        <div key={index} data-test="render_column">
                          {typeMapping[type](content, index, styles[className])}
                        </div>
                      );
                    }
                  )}
                </div>
              );
            }
          )}
        </div>
      );
    });
  };

  const renderContent = () => {
    return (sections || []).map((section, index) => {
      let multisection_class = section.className;
      return (
        <div
          key={index}
          className={
            multisection_class
              ? classnames(styles.section, styles[multisection_class])
              : styles.section
          }
          id={"multiSection" + (index + 1)}
          data-test="render_content"
        >
          {section.heading ? <h2>{section.heading}</h2> : ""}
          {section.title ? <p className={styles.title}>{section.title}</p> : ""}
          <div className={styles.content}>
            {(section.content || []).map((subSection, subIndex) => {
              return typeMapping[subSection.orientation](
                subSection.content,
                subIndex
              );
            })}
          </div>
        </div>
      );
    });
  };

  const typeMapping = {
    marked: renderMarkedText,
    row: renderRow,
    column: renderColumn,
  };

  return (
    <Fragment>
      <div
        className={styles.component_basepadding}
        id={id}
        data-test="multisection_component"
        style={{ padding: basePaddingOverride }}
      >
        <div
          className={bindCss({
            component_innerpadding: true,
            inner_Padding_Override: innerPaddingOverride,
          })}
        >
          {renderContent()}
        </div>
      </div>
    </Fragment>
  );
};

export default MultiSection;
