import React, { Fragment, useState, useEffect } from "react";
import { withRouter, match, Link } from "react-router-dom";
import { History, Location } from "history";
import styles from "./breadcrumbs.module.scss";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { connect } from "react-redux";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { PRODUCT_DETAILS } from "config/constants";
import { getBenefitPath } from "utlis/generalUtils";

interface breadcrumbs {
  name: string;
  path: string;
  exact?: boolean;
  component?: React.ComponentClass | React.FC;
}

interface IProps {
  history: History;
  location: Location;
  match: match;
  breadCrumbsFromRedux: BreadCrumbModel[];
  selectedAccount: SelectedAccountModel;
}

const BreadCrumbs: React.FC<IProps> = (props) => {
  const [breadCrumb, setBreadCrumbs] = useState([{ name: "", path: "" }]);
  let { selectedAccount } = props;
  let selectedAccountBenefits =
    (selectedAccount &&
      selectedAccount[0] &&
      selectedAccount[0].props &&
      selectedAccount[0].props.benefits) ||
    "";

  useEffect(() => {
    getBreadCrumbs(props.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, props.breadCrumbsFromRedux]);

  const getBreadCrumbs = (pathname, breadCrumbs: breadcrumbs[] = []) => {
    let homeObj = {
      path: "/home",
      name: "Home",
    };

    let homeUrlRegExp = new RegExp("(/home)$");
    if (homeUrlRegExp.test(pathname)) {
      let selectedAccountId =
        (selectedAccount &&
          selectedAccount[0] &&
          selectedAccount[0].props &&
          selectedAccount[0].props.productDefinition &&
          selectedAccount[0].props.productDefinition.id) ||
        "";
      let selectedAccountSanitized =
        (PRODUCT_DETAILS[selectedAccountId] &&
          PRODUCT_DETAILS[selectedAccountId].breadCrumbName) ||
        "";
      let breadCrumbArray = [
        {
          path: "#",
          name: `${selectedAccountSanitized}`,
        },
      ];
      selectedAccountBenefits && selectedAccountBenefits.length !== 0
        ? setBreadCrumbs(breadCrumbArray)
        : setBreadCrumbs([{ name: "", path: "" }]);
    } else {
      let { breadCrumbsFromRedux } = props;
      let breadCrumbsDataValidated =
        (breadCrumbsFromRedux &&
          breadCrumbsFromRedux[0] &&
          breadCrumbsFromRedux[0].props &&
          breadCrumbsFromRedux[0].props.breadCrumbData) ||
        [];

      (breadCrumbsDataValidated || []).map((breadCrumbObj) => {
        return breadCrumbs.push(breadCrumbObj);
      });
      setBreadCrumbs([homeObj, ...breadCrumbs]);
    }
  };

  const getbId = (path) => {
    let benefitObj = (selectedAccountBenefits || []).find((obj) => {
      return path === getBenefitPath(obj.benefitName);
    });
    return benefitObj && benefitObj.benefitId;
  };

  const renderBreadCrumbs = () => {
    return breadCrumb.map((breadCrumbObject, index) => {
      let bId = getbId(breadCrumbObject.path);

      let linkPath = bId
        ? `${breadCrumbObject.path}?bId=${bId}`
        : breadCrumbObject.path;

      if (index === breadCrumb.length - 1) {
        return breadCrumb.length !== 1 ? (
          <span className={styles.breadcrumb_} key={index}>
            {breadCrumbObject.name}
          </span>
        ) : (
          <Link to={linkPath} key={index}>
            <span className={styles.breadcrumb_link}>
              {breadCrumbObject.name}
            </span>
          </Link>
        );
      }
      return breadCrumbObject.name !== "" ? (
        <Fragment key={index}>
          <Link to={linkPath} key={index}>
            <span className={styles.breadcrumb_link_clickable}>
              {breadCrumbObject.name}
            </span>
          </Link>
          <span className={styles.breadcrumb_seperator}>/</span>
        </Fragment>
      ) : null;
    });
  };

  return (
    <Fragment>
      <div className={styles.breadcrumbs_container}  aria-label="Breadcrumb" role="region" >{renderBreadCrumbs()}</div>
    </Fragment>
  );
};

const mapStateToProps = () => {
  let breadCrumbsFromRedux = BreadCrumbModel.list();
  let selectedAccount = SelectedAccountModel.list();
  return {
    breadCrumbsFromRedux,
    selectedAccount,
  };
};

export default React.memo(withRouter(connect(mapStateToProps)(BreadCrumbs)));
