import React, { useState, useEffect } from "react";
import { withRouter, match } from "react-router-dom";
import { History, Location } from "history";
import LoginApp from "./Login";
import { queryJWKS, queryLoginData, queryLoginwithCINData } from "utlis/loginUtils";
import { onGqlError } from "services/GraphQL/gqlHelper";
import { LoaderStateModel } from "redux/models/LoaderModel";
import { LoginStateModel } from "redux/models/LoginStateModel";
import { logoutIfTokenExpired, getURLParams, addMatomoEvent } from "utlis/generalUtils";
import { KJUR, KEYUTIL } from "jsrsasign";
import { LWB_ERROR, LWB_TOKEN_VERIFICATION_ERROR } from "config/errorCodeConstants";
import { RIO_NOT_FOUND } from "config/errorMessages";
interface IProps {
  location: Location;
  history: History;
  match: match;
}

const LoginContainer: React.FC<IProps> = ({ history, location }) => {
  const [backendErrors, setError] = useState([]);
  const [showCaptcha, setCaptchaVisibility] = useState(false);
  const [captchaReset, setCaptachReset] = useState(false);
  let vendor;
  let vendorUrl;
  if (location && location.search) {
    let urlParams = getURLParams(location.search);
    (urlParams || []).map((vendorDetails, index) => {
      vendor = vendorDetails.partner ? vendorDetails.partner : vendor;
      vendorUrl = vendorDetails.deeplinkurl ? vendorDetails.deeplinkurl : vendorUrl;
    })
  }

  const handleLoginClick = (values) => {
    addMatomoEvent('nm/generic/logindonewithMS','nm/generic/logindonewithMS');
    queryLoginData(values.email, values.password).then((data) => {
      let { type, value, faliureCount } = data;
      if (faliureCount[0] >= 3) {
        renderResponseData("CaptchaVisibility", faliureCount);
        setCaptachReset(true);
      } else {
        renderResponseData(type, value);
      }
    });
  };

  const loginWithCIN = (cin) => {
    queryLoginwithCINData(cin).then((data) => {
      let { type, value } = data;
      renderResponseLWB(type, value);
    });
  };

  const renderResponseData = (type, value) => {
    switch (type) {
      case "ulster_ri": {
        setError(onGqlError(null, RIO_NOT_FOUND));
        history.push("/login");
        break;
      }
      case "LoginSuccess": {
        if (vendor) {
          history.push(`/partner-jump?deepLinkVendor=${vendor}&deepLinkUrl=${vendorUrl}`);
        } else {
          history.push("/home");
        }
        break;
      }
      case "CaptchaVisibility":
        setCaptchaVisibility(true);
        new LoaderStateModel({
          id: "1",
          isLoading: false,
        }).$update();
        break;
      case "Invalid":
        setError(onGqlError(value));
        history.push("/login");
        break;
      case "Error":
        setError(onGqlError(value));
        history.push("/login");
        break;
    }
  };

  const renderResponseLWB = (type, value) => {
    switch (type) {
      case "LoginSuccess": {
        if (vendor) {
          history.push(`/partner-jump?deepLinkVendor=${vendor}&deepLinkUrl=${vendorUrl}`);
        } else {
          history.push("/home");
        }
        break;
      }
      case "LWBNotWebEnabled":
        new LoaderStateModel({
          id: "1",
          isLoading: true,
        }).$save();
        history.push('/user-signup/activate-member', value);
        break;
      case "Invalid":
        setError(onGqlError(value));
        history.push("/login");
        break;
      case "Error":
        setError(onGqlError(value));
        // history.push("/login");
        break;
    }
  };

  useEffect(() => {
    if(history.location.state !== undefined)
      setError(onGqlError(history.location.state));
    const origin = window.location.origin;
    if(origin === 'https://natwest-staging.identitysecure.co.uk') {
      window.open("https://natwest.stage-affinionservices.com/login", "_self");
    } else if(origin === 'https://natwest.identitysecure.co.uk'){
      window.open("https://membershipbenefits.natwest.com/login", "_self");
    }
    lwbRedirect();
    let isUserLoggedIn =
      LoginStateModel.getInsatnce("LoginDetails") &&
      LoginStateModel.getInsatnce("LoginDetails").props;
    if (typeof isUserLoggedIn !== "undefined" && isUserLoggedIn !== {}) {
      logoutIfTokenExpired(history);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lwbRedirect = () => {
    let token = location.hash?.split('#id_token=')[1]?.split('&state=')[0];
    let error = location.hash?.split('&error=')[1];
    if (error === 'access_denied') {
      setError(onGqlError(null, LWB_ERROR));
      return;
    }
    else if (token !== undefined) {
      verifyToken(token);
    }
  }

  const verifyToken = async (token) => {
      queryJWKS().then((data) => {
        const parsedtoken = KJUR.jws.JWS.parse(token);
        let rsaPublicKey = KEYUTIL.getKey(data.data.lwbGetJWKS.keys.find(key => key.kid === parsedtoken.headerObj.kid));
      if (KJUR.jws.JWS.verifyJWT(token, rsaPublicKey, {alg: [parsedtoken.headerObj.alg]})){
          const decodedtoken = KJUR.jws.JWS.parse(token);
          window.history.pushState({}, document.title, "/" + "login");
          new LoaderStateModel({
            id: "1",
            isLoading: true,
          }).$save();
          loginWithCIN(decodedtoken.payloadObj.cin);
        } else {
          setError(onGqlError(null, LWB_TOKEN_VERIFICATION_ERROR));
          return;
        }
      }).catch(error => {
        console.log(error);
        return;
      });
  }

  return (
    <LoginApp
      handleLoginClick={handleLoginClick}
      errorSet={backendErrors}
      showCaptcha={showCaptcha}
      viewMessageSection={true}
      resetCaptcha={captchaReset}
    />
  );
};
export default withRouter(LoginContainer);
