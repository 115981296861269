import React from "react";
import settings from "../../services/RestAPI/axiosConfig";
import { withRouter } from "react-router-dom";
import { getTrackingUrl } from "utlis/generalUtils";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";

interface IProps {
  history: History;
  location: Location;
}

class MatomoAnalytics extends React.Component<IProps> {
  history = this.props.history;
  location = this.props.location;

  renderMatomoScript = async function (trackingUrl) {
    let brand = process.env.REACT_APP_THEME_NAME;
    settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const brandName = brand ? brand.split("-")[0].toUpperCase() : response.data.REACT_APP_SITE;
        const matomoSettings = response.data.MATAMO_SETTINGS[brandName];
        const trackingUrlPhp = matomoSettings.TRACKING_URL_PHP;
        const trackingUrlJs = matomoSettings.TRACKING_URL_JS;
        const siteId = matomoSettings.SITE_ID;
        const selectedAccount = SelectedAccountModel.getInsatnce("1")
        ? SelectedAccountModel.getInsatnce("1").props
        : null;
        const memberNumber = selectedAccount ? selectedAccount.memberNumber : '';
        const cin = selectedAccount ? selectedAccount.cin : '';
        trackingUrl = trackingUrl.toLowerCase().split(' ').join('-');
        var scriptText: string = `var _paq = window._paq || [];
                    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                    _paq.push(['setDocumentTitle', '${trackingUrl}']);
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    
                    (function() {
                        var u="${trackingUrlPhp}";//trackingURLPhp
                        var v="${trackingUrlJs}";//trackingURLJs
                        _paq.push(['setTrackerUrl', u]);
                        _paq.push(['setSiteId', '${siteId}']);//siteid
                        _paq.push(['setCustomDimension', 1, '${memberNumber}']);
                        _paq.push(['setUserId', '${cin}']);
                        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                        g.type='text/javascript'; g.async=true; g.defer=true; g.src=v; s.parentNode.insertBefore(g,s);
                    })();`;

        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        if(document.getElementById("ot-group-id-C0002")){
          let checkboxCondition = document.getElementById("ot-group-id-C0002");
          let ariaChecked = checkboxCondition? checkboxCondition.getAttribute("aria-checked") : null;
          if(ariaChecked === "true"){
            script.type = "text/javascript";
          }
          else{
            script.type = "text/plain";
          }
        }
        else{
          script.type = "text/plain";
        }
        script.className = "optanon-category-C0002";
        script.text = scriptText;
        head.appendChild(script);
      })
      .catch((error) => console.log(error));
  };

  removePreviousScript= async function (){
    settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        let brand = process.env.REACT_APP_THEME_NAME;
        const brandName = brand ? brand.split("-")[0].toUpperCase() : response.data.REACT_APP_SITE;
        const matomoSettings = response.data.MATAMO_SETTINGS[brandName];
        const trackingUrlPhp = matomoSettings.TRACKING_URL_PHP;
        const allScripts = document.getElementsByTagName("script");
    for(let i=0;i<allScripts.length;i=i+1){
      if(allScripts[i].outerHTML.includes(trackingUrlPhp)){
        allScripts[i].remove();
      }
    }
      }).catch((error) => console.log(error));
  }

  componentDidMount() {
    let trackingURL = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), this.props.location);
    this.removePreviousScript();
    this.renderMatomoScript(trackingURL);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let trackingURL = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), this.props.location);
      this.removePreviousScript();
      this.renderMatomoScript(trackingURL);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(props => <MatomoAnalytics {...props} />);