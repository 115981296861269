import { BaseModel } from "./BaseModel";

interface IIdleSessionTimeoutProps {
  id: string;
  time: number;
  interval: number;
}
/**
 * @export
 * @class IdleSessionModel
 * @extends {BaseModel<IIdleSessionModelProps>}
 */
export class IdleSessionTimeout extends BaseModel<IIdleSessionTimeoutProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IIdleSessionTimeoutProps) {
    super(props);
  }

  static resource = "idleSessionTimeout";
}
