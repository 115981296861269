import { Fragment, useEffect } from "react";
import { withRouter } from "react-router-dom";
import styles from "./SignUpForm.module.scss";
import { useForm } from "react-hook-form";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import * as yup from "yup";
import { FormFieldError } from "components/FormFieldError";
import * as errorMessage from "../../../config/errorMessages";
import { ErrorBox } from "../../Login/ErrorBox";
import { SignUpStateModel } from "redux/models/SignUpModel";
import { ShowErrorSignUpStateModel } from "redux/models/ShowErrorPopupSignUp";
import { BreadCrumbs } from "components/BreadCrumbs";
import { allowNumbersOnly, getConstantNameForTheme, useYupValidationResolver } from "utlis/generalUtils";
import { PrimaryButton } from "components/PrimaryButton";

interface IProps {
  onHandleSubmit: any;
  backendErrors: any;
  setBackendError: any;
  isShowError: boolean;
  isShowErrorMsg: any;
}
const SignUpForm = (props: IProps) => {
  const presonalDetails = SignUpStateModel.getInsatnce("NewUser");
  let {
    firstName,
    lastName,
    birthDay,
    birthMonth,
    birthYear,
    sortCodeFirstPart,
    sortCodeSecondPart,
    sortCodeThirdPart,
    accountNumber,
  } = presonalDetails ? presonalDetails.props.SignUpData : "";

  const {
    SIGN_UP_ERROR_MAPPING,
    FIRSTNAME_MATCH_ERROR,
    FIRSTNAME_REQUIRED,
    LASTNAME_MATCH_ERROR,
    LASTNAME_REQUIRED,
    BIRTH_DAY_MIN,
    BIRTH_DAY_MAX,
    BIRTH_DAY_MATCHES,
    BIRTH_DATE_REQUIRED,
    BIRTH_MONTH_MIN,
    BIRTH_MONTH_MAX,
    BIRTH_MONTH_MATCHES,
    BIRTH_YEAR_MIN,
    BIRTH_YEAR_MAX,
    BIRTH_YEAR_MATCHES,
    BIRTH_MONTH_REQUIRED,
    BIRTH_YEAR_REQUIRED,
    SORT_CODE_MATCHES,
    SORT_CODE_MIN,
    SORT_CODE_REQUIRED,
    ACCOUNT_EXACT_NUMBER_ERROR,
    ACCOUNT_ERROR,
    ACCOUNT_NUMBER_REQUIRED,
    ERROR_BOX_HEADER,
    MESSAGE_SIGNUP,
  } = errorMessage;

  let {
    onHandleSubmit,
    backendErrors,
    setBackendError,
    isShowError,
    isShowErrorMsg,
  } = props;

  useEffect(() => {
    if (isShowError) {
      new ShowErrorSignUpStateModel({
        id: "ShowError",
        isShowError: false,
        isShowErrorMsg: "",
      }).$update("ShowError");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SignupSchema = yup.object({
    firstName: yup
      .string()
      .matches(/^[a-zA-Z '.-]+$/, FIRSTNAME_MATCH_ERROR)
      .required(`${FIRSTNAME_REQUIRED}`),
    lastName: yup
      .string()
      .matches(/^[a-zA-Z '.-]+$/, LASTNAME_MATCH_ERROR)
      .required(`${LASTNAME_REQUIRED}`),
    birthDay: yup
      .string()
      .min(1, `${BIRTH_DAY_MIN}`)
      .max(2, `${BIRTH_DAY_MAX}`)
      .matches(/^(([0]?[1-9])|([1-2][0-9])|(3[01]))$/, `${BIRTH_DAY_MATCHES}`)
      .required(`${BIRTH_DATE_REQUIRED}`),
    birthMonth: yup
      .string()
      .min(1, `${BIRTH_MONTH_MIN}`)
      .max(2, `${BIRTH_MONTH_MAX}`)
      .matches(/^(0[1-9]|1[0-2])$/, `${BIRTH_MONTH_MATCHES}`)
      .required(`${BIRTH_MONTH_REQUIRED}`),
    birthYear: yup
      .string()
      .min(4, `${BIRTH_YEAR_MIN}`)
      .max(4, `${BIRTH_YEAR_MAX}`)
      .matches(/(19|20)\d{2}$/, `${BIRTH_YEAR_MATCHES}`)
      .required(`${BIRTH_YEAR_REQUIRED}`),
    sortCodeFirstPart: yup
      .string()
      .matches(/^([0-9])/, `${SORT_CODE_MATCHES}`)
      .min(2, `${SORT_CODE_MIN}`)
      .max(2, `${SORT_CODE_MIN}`)
      .required(`${SORT_CODE_REQUIRED}`),
    sortCodeSecondPart: yup
      .string()
      .matches(/^([0-9])/, `${SORT_CODE_MATCHES}`)
      .min(2, `${SORT_CODE_MIN}`)
      .max(2, `${SORT_CODE_MIN}`)
      .required(`${SORT_CODE_REQUIRED}`),
    sortCodeThirdPart: yup
      .string()
      .matches(/^([0-9])/, `${SORT_CODE_MATCHES}`)
      .min(2, `${SORT_CODE_MIN}`)
      .max(2, `${SORT_CODE_MIN}`)
      .required(`${SORT_CODE_REQUIRED}`),
    accountNumber: yup
      .string()
      .matches(/^[0-9]*$/, `${ACCOUNT_EXACT_NUMBER_ERROR}`)
      .min(8, `${ACCOUNT_ERROR}`)
      .max(8, `${ACCOUNT_ERROR}`)
      .required(`${ACCOUNT_NUMBER_REQUIRED}`),
  });
  const resolver = useYupValidationResolver(SignupSchema);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver,
    mode: isShowError ? "onChange" : "onSubmit",
  });

  const renderErrorBox = (errorToPrint?: any) => {
    if (errorToPrint) {
      const { errorMsg } = errorToPrint;
      return <ErrorBox errors={errorMsg} errorHeader={ERROR_BOX_HEADER} />;
    }
    if (isShowError) {
      setBackendError(isShowErrorMsg);
    }

    if (backendErrors && backendErrors.length) {
      let errorMsg = backendErrors.map((errorItem) => {
        errorItem =
          typeof errorItem !== "string" ? errorItem.toString() : errorItem;
          return SIGN_UP_ERROR_MAPPING[errorItem] || MESSAGE_SIGNUP;     
      });
      return backendErrors.length ? (
        <ErrorBox errors={errorMsg} errorHeader={ERROR_BOX_HEADER} />
      ) : null;
    } else {
      return;
    }
  };
  const formErrorMsg = () => {
    let formErrors;
    if (Object.keys(errors).length) {
      if (isShowError) {
        new ShowErrorSignUpStateModel({
          id: "ShowError",
          isShowError: false,
          isShowErrorMsg: "",
        }).$update("ShowError");
      }
      backendErrors.length = 0;
      let uniqueErrors: any;
      let errorsArray = Object.values(errors).map((item: any) => {
        return item.message;
      });
      uniqueErrors = Array.from(new Set(errorsArray));
      formErrors = (
        <ErrorBox
          className={styles.error_box_background}
          errors={uniqueErrors}
          errorHeader={ERROR_BOX_HEADER}
        />
      );
    }
    return formErrors;
  };
  // const changeFocus = (e, id, maxLength) => {
  //   let element = e.target.value;
  //   let elementId = document.getElementById(id);

  //   if (element.length === maxLength || element.length === maxLength + 1) {
  //     elementId && elementId.focus();
  //   }
  // };

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container_wrapper}>
        <div className={styles.main_container}>
          <BreadCrumbs />
          <div className={styles.signup_form_heading_subheading}>
            <div className={styles.sign_up__heading}>
              Sign up to Membership Services
            </div>
            <div className={styles.signup_form__subheading}>
              {` You can only sign up if you have a ${getConstantNameForTheme()} current
              account which comes with additional benefits. If you’ve just
              opened your account, we recommend waiting at least 24 hours before
              signing up.`}
            </div>
          </div>

          {formErrorMsg()}
          {renderErrorBox()}
          <div className={styles.signup_form}>
            <form
              onSubmit={handleSubmit(onHandleSubmit)}
              noValidate
              autoComplete={"off"}
            >
              <div className={styles.sign_up__form_fields}>
                <div className={styles.sign_up__left_column_form_fields}>
                  <div>*All fields are mandatory</div>
                  <div className={styles.sign_up__about_you}>
                    A bit about you
                  </div>
                  <div className={styles.sign_up__sub_heading}>
                    Tell us who you are. Please make sure the names match those
                    on your bank account.
                  </div>
                  <label className={styles.sign_up__form_field_name} for="fname">
                    First name
                  </label> *
                  {errors.firstName && (
                    <FormFieldError errorText={errors.firstName.message} errorId={"firstName"} />
                  )}
                  <input
                    defaultValue={isShowError ? firstName : null}
                    className={styles.sign_up__form_field}
                    {...register('firstName')}
                    type="text"
                    aria-describedby="firstName"
                    id="fname"
                    aria-invalid={errors?.firstName?.message ? true : false}></input>
                  <label className={styles.sign_up__form_field_name} for="lname">
                    Last name
                  </label> *
                  {errors.lastName && (
                    <FormFieldError errorText={errors.lastName.message} errorId={"lastName"}/>
                  )}
                  <input
                    aria-describedby="lastName"
                    aria-invalid={errors?.lastName?.message ? true : false}
                    id="lname"
                    className={styles.sign_up__form_field}
                    defaultValue={isShowError ? lastName : null}
                    {...register('lastName')}
                    type="text"></input>
                  <label className={styles.sign_up__form_field_name} id="birthday">
                    Date of Birth
                  </label> (DD-MM-YYYY) *
                  {errors.birthDay && (
                    <div className={styles.day_error}>
                      <FormFieldError errorText={errors.birthDay.message} errorId={"birthDay"}/>
                      
                    </div>
                  )}
                  {!errors.birthDay && errors.birthMonth && (
                    <div className={styles.month_error}>
                      {" "}
                      <FormFieldError errorText={errors.birthMonth.message} 
                      errorId={"birthMonth"}
                      />
                    </div>
                  )}
                  {!errors.birthDay && !errors.birthMonth && errors.birthYear && (
                    <div className={styles.year_error}>
                      {" "}
                      <FormFieldError errorText={errors.birthYear.message} 
                      errorId={"birthYear"}
                      />
                    </div>
                  )}
                  <div className={styles.sign_up__form_field_dob}>
                    <input
                      defaultValue={isShowError ? birthDay : null}
                      className={styles.sign_up__form_field_dob__dob}
                      aria-label="date of birth"
                      aria-describedby="birthDay"
                      aria-invalid={errors?.birthDay?.message ? true : false}
                      {...register('birthDay')}
                      placeholder="DD"
                      data-test="date-field"
                      type="text"
                      id="DD"
                      maxLength={2}
                      onKeyPress={(e) => allowNumbersOnly(e)}
                      onBlur={(e) =>
                        e.currentTarget.value.length === 1
                          ? (e.currentTarget.value =
                              "0" + e.currentTarget.value)
                          : e.target.value
                      } />
                    <div className={styles.sign_up__form_field_dob_separator}>
                      -
                    </div>
                    <input
                      defaultValue={isShowError ? birthMonth : null}
                      className={styles.sign_up__form_field_dob__dob}
                      aria-label="date of birth"
                      {...register('birthMonth')}
                      aria-describedby="birthMonth"
                      aria-invalid={errors?.birthMonth?.message ? true : false}
                      placeholder="MM"
                      id="MM"
                      data-test="date-field"
                      type="text"
                      maxLength={2}
                      onKeyPress={(e) => allowNumbersOnly(e)}
                      onBlur={(e) =>
                        e.currentTarget.value.length === 1 &&
                        e.currentTarget.value !== "0"
                          ? (e.currentTarget.value =
                              "0" + e.currentTarget.value)
                          : e.target.value
                      } />
                    <div className={styles.sign_up__form_field_dob_separator}>
                      -
                    </div>
                    <input
                      defaultValue={isShowError ? birthYear : null}
                      className={styles.sign_up__form_field_dob__year}
                      aria-label="date of birth"
                      {...register('birthYear')}
                      aria-describedby="birthYear"
                      aria-invalid={errors?.birthYear?.message ? true : false}
                      id="YYYY"
                      placeholder="YYYY"
                      data-test="date-field"
                      type="text"
                      maxLength={4}
                      onKeyPress={(e) => allowNumbersOnly(e)} />
                  </div>
                </div>
                <div className={styles.sign_up__right_column_form_fields}>
                  <div className={styles.sign_up__account_details}>
                    Your account details
                  </div>
                  <label className={styles.sign_up__form_field_name}>
                    Sort code 
                  </label> *

                  {errors.sortCodeFirstPart && (
                    <FormFieldError
                      errorText={errors.sortCodeFirstPart.message}
                      errorId={"sortCodeFirst"}
                    />
                  )}
                  {!errors.sortCodeFirstPart && errors.sortCodeSecondPart && (
                    <div className={styles.sortCode_two}>
                      <FormFieldError
                        errorText={errors.sortCodeSecondPart.message}
                        errorId={"sortCodeSecond"}
                      />
                    </div>
                  )}
                  {!errors.sortCodeFirstPart &&
                    !errors.sortCodeSecondPart &&
                    errors.sortCodeThirdPart && (
                      <div className={styles.sortCode_three}>
                        <FormFieldError
                          errorText={errors.sortCodeThirdPart.message}
                          errorId={"sortCodeThird"}
                        />
                      </div>
                    )}
                  <div className={styles.sign_up__form_field_sortcode}>
                    <input
                      aria-describedby="sortCodeFirst"
                      aria-label="sortCode"
                      aria-invalid={errors?.sortCodeFirstPart?.message ? true : false}
                      required
                      defaultValue={isShowError ? sortCodeFirstPart : null}
                      className={styles.sign_up__form_field_sortcode__sortcode}
                      {...register('sortCodeFirstPart')}
                      data-test="sortCode-field"
                      type="text"
                      maxLength={2}
                      id="sortCodeFirstPart"
                      onKeyPress={(e) => allowNumbersOnly(e)} />
                    <input
                      required
                      aria-describedby="sortCodeSecond"
                      aria-label="sortCode"
                      aria-invalid={errors?.sortCodeSecondPart?.message ? true : false}
                      defaultValue={isShowError ? sortCodeSecondPart : null}
                      className={styles.sign_up__form_field_sortcode__sortcode}
                      {...register('sortCodeSecondPart')}
                      data-test="sortCode-field"
                      type="text"
                      maxLength={2}
                      id="sortCodeSecondPart"
                      onKeyPress={(e) => allowNumbersOnly(e)} />
                    <input
                      required
                      aria-describedby="sortCodeThird"
                      aria-label="sortCode"
                      aria-invalid={errors?.sortCodeThirdPart?.message ? true : false}
                      defaultValue={isShowError ? sortCodeThirdPart : null}
                      className={styles.sign_up__form_field_sortcode__sortcode}
                      {...register('sortCodeThirdPart')}
                      data-test="sortCode-field"
                      type="text"
                      maxLength={2}
                      id="sortCodeThirdPart"
                      onKeyPress={(e) => allowNumbersOnly(e)} />
                  </div>

                  <div className={styles.sign_up__form_field_name}>
                    Account number *
                  </div>
                  {errors.accountNumber && (
                    <FormFieldError errorText={errors.accountNumber.message}  errorId={"accountNumber"}/>
                  )}
                  <input
                    aria-describedby="accountNumber"
                    aria-label="Account number"
                    aria-invalid={errors?.accountNumber?.message ? true : false}
                    defaultValue={isShowError ? accountNumber : null}
                    className={styles.sign_up__form_field}
                    {...register('accountNumber')}
                    type="text"
                    data-test="accountNumber-field"
                    maxLength={8}
                    onKeyPress={(e) => allowNumbersOnly(e)}
                    id="accountnumber"></input>
                  <div className={styles.signup_form__note}>
                    {`The information you provide to a third party supplier of
                    benefits will be shared with ${getConstantNameForTheme()} `}
                  </div>
                </div>
              </div>
              <div className={styles.sign_up__btn}>
                <PrimaryButton
                  text="Next"
                  disabled={false}
                  classNames={styles.sign_up__btn__next_btn}
                  data-test="next-button"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};
export default withRouter(SignUpForm);
