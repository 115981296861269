import React, { Fragment} from "react";
import styles from "./hero-banner.module.scss";
import classnames from "classnames/bind";
import { MarkedComponent } from "components/MarkedComponent";
import { scrollToTarget } from "utlis/generalUtils";


let bindCss = classnames.bind(styles);
interface bannerMarkedTextData {
  className?: string;
  content: string;
}
interface IContent {
  buttonText?: string;
  headingText?: string;
  imageUrl?: string;
  showImageInAllDevices?: string;
  bannerNote?: string;
  secondaryBannerNote?: string;
  buttonUrl?: string;
  linkType?: string;
  bannerMarkedText?: bannerMarkedTextData[];
  impMessage?: boolean;
  highlightText?:string;
}
interface IProps {
  metaData: IContent;
  id?: string;
}

const HeroBanner: React.FC<IProps> = ({
  metaData: {
    buttonText,
    headingText,
    imageUrl,
    showImageInAllDevices,
    buttonUrl,
    linkType,
    bannerNote,
    secondaryBannerNote,
    bannerMarkedText,
    highlightText
  },
  id,
}) => {

  const renderHighlightedText = () => {
    return (
      <div className={styles.highlighted_message_section} data-test="imp-msg">
         <MarkedComponent rawString={highlightText} />
      </div>
    );
  }
  return (
    <Fragment>
      <div
        className={styles.component_basepadding}
        id={id}
        data-test="hero_banner"
      >
        <div className={styles.component_innerpadding}>
          <div
            className={bindCss({
              banner: true,
              banner_image: true,
              banner_responsive_image: !showImageInAllDevices,
            })}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          >
            {bannerMarkedText ? renderBannerMarkedText(bannerMarkedText) : ""}
            <div className={styles.banner_content}>
              <div className={styles.banner_content__title}>
                <MarkedComponent rawString={headingText} />
              </div>
              {buttonText && linkType
                ? renderButton(linkType, buttonText, buttonUrl)
                : null}
                <div className={styles.margin_adjust}>
                {highlightText ? renderHighlightedText() : null}
              </div>
            </div>
          </div>
          {bannerNote ? (
            <div className={styles.banner_note_text}>{bannerNote}</div>
          ) : null}
          {
            secondaryBannerNote ? (
              <div className={bindCss({ banner_note_text: true, secondary_banner_note_text: true})}>
                <MarkedComponent rawString={secondaryBannerNote}/>
              </div>
            ) : null
          }
        </div>
      </div>
    </Fragment>
  );
};
const renderButton = (linkType, buttonText, buttonUrl) => {
  return <div>{typeMapping[linkType](buttonUrl, buttonText)}</div>;
};

const renderExternalLink = (buttonUrl, buttonText) => {
  return (
    <a
      className={bindCss({
        banner_content_button__margin: true,
        banner_content_button: true,
      })}
      href={buttonUrl}
    >
      {buttonText}
    </a>
  );
};
const renderInternalLink = (buttonUrl, buttonText) => {
  return (
    <a
      className={bindCss({
        banner_content_button__margin: true,
        banner_content_button: true,
      })}
      href={buttonUrl}
    >
      {buttonText}
    </a>
  );
};
const jumpToSection = (buttonUrl, buttonText) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      tabIndex={0}
      className={bindCss({
        banner_content_button__margin: true,
        banner_content_button: true,
      })}
      onClick={() => scrollToBanner(buttonUrl)}
      data-test="scroll_to_banner"
    >
      {buttonText}
    </a>
  );
};

const scrollToBanner = (buttonUrl) => {
  console.log(buttonUrl);
  let targetElement = document.getElementById(buttonUrl);
  scrollToTarget(targetElement);
};

const renderBannerMarkedText = (bannerMarkedText) => {
  return bannerMarkedText.map(({ className, content }, index) => {
    return (
      <MarkedComponent
        className={styles[className]}
        rawString={content}
        key={index}
      />
    );
  });
};

const typeMapping = {
  external: renderExternalLink,
  internal: renderInternalLink,
  sectionJump: jumpToSection,
};

export default HeroBanner;
