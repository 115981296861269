

 import * as React from "react";
 import styles from "./primaryButton.module.scss";
 
 export interface Props {
   text?: string;
   handleClick?: () => void;
   disabled: boolean;
   classNames?: string;
   ariaLabel?: string;
   iconImage?: string;
 }
 
 const PrimaryButton: React.FC<Props> = (props) => {
   let { text, handleClick, disabled, classNames, iconImage } = props;
 
   return handleClick ? (
     <button
       className={`${styles.primaryButton} ${classNames}`}
       onClick={handleClick}
       disabled={disabled}
       data-test="button"
       aria-label={props.ariaLabel || text}
     >
      { iconImage ? <><img height={16} width={16} src={iconImage}/> &nbsp;</>: ''} {text}
     </button>
   ) : (
     <button
       type="submit"
       className={`${styles.primaryButton} ${classNames}`}
       disabled={disabled}
       data-test="submit-button"
       aria-label={props.ariaLabel || text}
     >
       {text}
     </button>
   );
 };
 
 export default PrimaryButton;
 