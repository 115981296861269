import { Component } from "react";
import { withRouter } from "react-router-dom";
import HeaderComponent from "./Header";
import { ThemeNameModel } from "../../redux/models/ThemeNameModel";
import { connect } from "react-redux";
import { IContainerProps, IState } from "./interfaces";
import {
  genrateAccountTypeLinks,
  getDateByProvidedFormat,
} from "../../utlis/generalUtils";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { setThemeBrandWise, settingURL } from "themeApplicator";
import setting from "services/RestAPI/axiosConfig";
import { updateQuickAccessContent } from "../Header/quickAccessMenuContent/quickAccessLink";
import { LoginStateModel } from "redux/models/LoginStateModel";
import * as _ from "lodash";
import { PRODUCT_DETAILS } from "config/constants";

const dropdownType = "primary";
const accountDropdownType = "secondary";

class HeaderContainer extends Component<IContainerProps, IState> {
  constructor(props) {
    super(props);
    const { selectedAccount } = props;
    let accountTitle =
      selectedAccount[0] &&
      selectedAccount[0].props &&
      selectedAccount[0].props.linkTitle;

    this.state = {
      isHeaderSticky: false,
      dropdownIsOpen: false,
      dropdownTitle: "Quick access",
      accountDropdownIsOpen: false,
      accountDropdownTitle: accountTitle,
      themeLogo: "",
      quickAccessContent: [],
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    updateQuickAccessContent().then((response) => {
      this.setState({ quickAccessContent: response });
    });
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
  };
  }

  /* check if header needs to be sticky depending on scrolling event*/
  onScroll = () => {
    if (window.pageYOffset > 72) {
      this.setState({
        isHeaderSticky: true,
      });
    } else {
      this.setState({
        isHeaderSticky: false,
      });
    }
  };
  /* open or close the select drop down of page links */
  toggleIsOpen = (dropdownIsOpen) => {
    this.setState({
      dropdownIsOpen: !dropdownIsOpen,
    });
  };

  /* open or close the select bank account drop down */
  accountToggleIsOpen = (accountDropdownIsOpen) => {
    this.setState({
      accountDropdownIsOpen: !accountDropdownIsOpen,
    });
  };

  /* update the option value selected in dropdown of page links */
  updateSelectTitle = (title) => {
    this.setState({
      dropdownTitle: title.link_title,
      dropdownIsOpen: false,
    });
    this.handleSelectBoxClick(title);
  };

  /*Below function is used to handle the click for account Type selection box*/
  handleAccountSelect = (accountType) => {
    const { userInstance } = this.props;
    const { account, cin } = userInstance.props.LoggedInUserDetails.member;
    let selectedAccountType = account.find((accountObj) => {
      return accountObj.accountId === accountType.link_account_id;
    });

    const {
      createdOn,
      membershipId,
      memberNumber,
      memberId,
    } = selectedAccountType;
    const {
      packageName,
      packageId,
      PRefId,
      description,
      productDefinition,
      benefits,
      siteTheme,
    } = selectedAccountType.package;

    let sortedBenefits = PRODUCT_DETAILS[productDefinition.id]
      ? _.sortBy(benefits, "benefitDisplayOrder")
      : "";

    new SelectedAccountModel({
      id: "1",
      type: packageName,
      creationDate: createdOn,
      packageId: packageId,
      PRefId: PRefId,
      packageDescription: description,
      productDefinition: productDefinition,
      benefits: sortedBenefits,
      membershipId: membershipId,
      linkTitle: accountType.link_title,
      memberNumber: memberNumber,
      cin: cin,
      memberId: memberId,
    }).$update();

    new ThemeNameModel({
      id: "1",
      name: siteTheme ? siteTheme : process.env.REACT_APP_THEME_NAME,
    }).$update("1");
    this.handleSelectBoxClick(accountType);
    //Call reapplying theme functions.

    setting.get(settingURL).then((resp) => {
      setThemeBrandWise(siteTheme, resp.data);
    });

    //below function is called to update the quick access links data depending on the theme selected in account type drop down
    updateQuickAccessContent().then((response) =>
      this.setState({ quickAccessContent: response })
    );
  };

  /* update the option value selected in bank account dropdown */
  accountUpdateSelectTitle = (title) => {
    this.setState({
      accountDropdownTitle: title.link_title,
      accountDropdownIsOpen: false,
    });
    this.handleAccountSelect(title);
  };

  /* reroute to the page selected from option selected in dropdown of page links */
  handleSelectBoxClick = (link) => {
    const { history } = this.props;
    if (link.linkType === "external" || link.linkType === "downloadable") {
      window.open(link.url_path, "_blank");
      return;
    }
    history.push(link.url_path);
  };

  clickedAway = () => {
    this.setState({
      dropdownIsOpen: false,
    });
  };
  accountDropdownClickedAway = () => {
    this.setState({
      accountDropdownIsOpen: false,
    });
  };

  renderAccountTypes = () => {
    const { loggedUser } = this.props;
    return [
      {
        //title: "",
        links: [].concat.apply([], genrateAccountTypeLinks(loggedUser)),
      },
    ];
  };

  render() {
    const {
      accountDropdownIsOpen,
      accountDropdownTitle,
      dropdownIsOpen,
      dropdownTitle,
      isHeaderSticky,
      quickAccessContent,
    } = this.state;
    let accountTypes = {
      options: this.renderAccountTypes(),
      toggleIsOpen: this.accountToggleIsOpen,
      updateSelectTitle: this.accountUpdateSelectTitle,
      dropdownIsOpen: accountDropdownIsOpen,
      dropdownTitle: accountDropdownTitle,
      dropdownType: accountDropdownType,
      updatableDropdownTitle: true,
      clickAway: this.accountDropdownClickedAway,
    };
    let quickLinks = {
      options: quickAccessContent,
      toggleIsOpen: this.toggleIsOpen,
      updateSelectTitle: this.updateSelectTitle,
      dropdownIsOpen: dropdownIsOpen,
      dropdownTitle: dropdownTitle,
      dropdownType: dropdownType,
      updatableDropdownTitle: true,
      clickAway: this.clickedAway,
    };

    const { firstName, lastName } = this.props.userInstance
      ? this.props.userInstance.props.LoggedInUserDetails.member
      : "";

    const { lastLogin } = this.props.loginData
      ? this.props.loginData.props.LoginData
      : "";

    let IContent = {
      userName: firstName + " " + lastName,
      lastLogin: getDateByProvidedFormat(lastLogin, "dd-mm-yyyy", "/"),
      isHeaderSticky: isHeaderSticky,
      accountTypes: accountTypes,
      quickLinks: quickLinks,
      showStickyHeader: this.props.showStickyHeader,
    };

    return <HeaderComponent IContent={IContent} />;
  }
}
const mapStateToProps = (state) => {
  const themeName = ThemeNameModel.list(state);
  const loggedUser = UserDetailModel.list();
  const userInstance = UserDetailModel.getInsatnce("LoggedUser");
  const loginData = LoginStateModel.getInsatnce("LoginDetails");
  const selectedAccount = SelectedAccountModel.list();
  return { themeName, loggedUser, userInstance, loginData, selectedAccount };
};

export default connect(mapStateToProps)(withRouter(HeaderContainer));
