import React, { Fragment, useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { connect } from "react-redux";
import { UserDetailModel } from "redux/models/UserDetailModel";
import styles from "./contact-us-success.module.scss";
import { BreadCrumbs } from "components/BreadCrumbs";

export interface ContactUsContainerProps {
  userDetails: UserDetailModel[];
  history: History;
}

const ContactUsSuccess: React.FC<ContactUsContainerProps> = ({
  userDetails,
}) => {
  const [isUserLoggedIn, setUserLoginStatus] = useState(false);

  useEffect(() => {
    let loginStatus = userDetails.length ? true : false;
    console.log(isUserLoggedIn);
    setUserLoginStatus(loginStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />

      <div className={styles.page}>
        <div className={styles.content_wrapper}>
          <BreadCrumbs />
          <h2 className={styles.heading}>Success</h2>
          <p>Your email has been sent to the customer service team.</p>
          <p>A member of our team will be in touch within 2 working days.</p>
          <p className={styles.link}>
            <Link to="/contact-us">
              Click here if you wish to send another email.
            </Link>
          </p>
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};

const mapStateToProps = () => {
  let userDetails = UserDetailModel.list();
  return {
    userDetails,
  };
};

export default withRouter(connect(mapStateToProps)(ContactUsSuccess));
