import React, { Fragment, useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Icon } from "components/Icon";
import { Button } from "components/Button";
import { BenefitTile } from "./BenefitTile/index";
import classNames from "classnames";
import styles from "./login.module.scss";
import {
  getLoginPageBenefits,
  getBrandName,
} from "services/RestAPI/exploreCalls";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { IState } from "./LoginInterface";
import { ErrorBox } from "./ErrorBox";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import TextContent from "../../config/Labels/label.json";
import * as errorMessages from "../../config/errorMessages";
import { FormFieldError } from "components/FormFieldError";
import {
  UNAUTHORIZED,
  BACKEND_API_ERROR,
  ACOUNT_LOCKED,
  NOT_FOUND,
  CIN_NOT_FOUND,
  LWB_ERROR,
  LWB_TOKEN_VERIFICATION_ERROR,
} from "config/errorCodeConstants";
import {
  BRAND_ULSTER_NI,
  BRAND_ULSTER_RI,
  emailFieldRegex,
  BRAND_NATWEST,
  BRAND_RBS
} from "config/constants";
import { MarkedComponent } from "components/MarkedComponent";
import ReCAPTCHA from "react-google-recaptcha";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import { CaptchaModel } from "redux/models/CaptchaModel";
import { addMatomoEvent, useYupValidationResolver } from "utlis/generalUtils";
import { KJUR } from "jsrsasign";
import { LoaderStateModel } from "redux/models/LoaderModel";
import { PrimaryButton } from "components/PrimaryButton";
import { RIO_NOT_FOUND } from "../../config/errorMessages";
interface ILoginProps {
  isSubmitting: boolean;
  handleLoginClick: any;
  errorSet: any;
  showCaptcha: boolean;
  viewMessageSection?: boolean;
  resetCaptcha: boolean;
}

const ScrollToTop = () => {
  window.scrollTo({
    top: 520,
    left: 0,
    behavior: "smooth",
  });
};
const SignupSchema = yup.object({
  email: yup
    .string()
    .email(`${errorMessages.EMAIL_PATTERN_ERROR}`)
    .matches(emailFieldRegex, errorMessages.EMAIL_PATTERN_ERROR)
    .required(`${errorMessages.EMAIL_REQUIRED_ERROR}`),
  password: yup.string().required("Password is mandatory"),
});

export const LoginForm: React.FC<ILoginProps> = (
  props: ILoginProps,
  State: IState
) => {
  const {
    isSubmitting,
    handleLoginClick,
    errorSet,
    viewMessageSection,
  } = props;
  const resolver = useYupValidationResolver(SignupSchema);
  let { showCaptcha, resetCaptcha } = props;
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver
  });
  const [pageContent, setPageContent] = useState(State);
  const [isCaptchaValid, setCaptchaValidity] = useState(false);
  const [captchaError, setCaptchaError] = useState<any | null>(null);
  const [lwb, setlwb] = useState(true);
  const { captchaSiteKey } = CaptchaModel.getInsatnce("1")
    ? CaptchaModel.getInsatnce("1").props
    : "";

  getSettingConfig().then((data) => {
    setCaptchaValidity(data.SET_CAPTCHA_VALID);
  });

  const openLWBpage = async () => {
    new LoaderStateModel({
      id: "1",
      isLoading: true,
    }).$save();
    const signature = `-----BEGIN RSA PRIVATE KEY-----
    MIIEpQIBAAKCAQEA8Pw8xRHMOgseabWKTBpUMBm9Z+J/UV4ibLh9vfr2kAtDtSXw
    TxP7b93Rf/97gYYgW6gOU88DTI7XXHjI1BNo+8Y3z/tPW1+zkA5b+3xoBMs4QVKU
    yTm2EEe4JzBuFBlIn160r86UN4hW2NW7S1FbyqLULg0FMbqs//M53GMAiqDtxppg
    Kgpeo27e0UhNUEmUHNB3WkyNKa1Gom3MBy0z8oGyOat24llWh3zMeCxwoKqcK5kQ
    sFVdzA8mcGgyePGelqiH3nGVQXR+H/NqxR+Ez09tpnu/OESf2IUdt6ZcrSMFeHd9
    7dTJBdrOrskyZ6LNFqtXgBn0rIrMCHouIaPnrwIDAQABAoIBAQDZAcuXixdljW2t
    s5T+Qxdc2VD+o7ATDbniPaC926j8vagTED7+dvgNHKrzoJUPZkDtSMDeJ6TI5Dh7
    RSlUEMaRM0Y/PAeSOPPx3XxnUvg4mOfnqv3g1t9kebETWUFHSB1lplr/lf9SoqvS
    R2WJxZHrQAXiEkeDKJ9xPQ31sBFkwxb0/budz/kV11KkntVBg1z6qfaMmWgDG01V
    u3b6ppVVyhYRunVY2yZAR/u9iEQUfInCkfRX6gDREISlQ54bSHNHNTXJpjnZNIbK
    OKkc4ckYnJkCUEth/b/IcwY+F1DIBbI80bJmsD6EID395F9k0ozm/RIo701n4VZe
    HvkbCHhBAoGBAPyb1tZLP3+jTdwr0PkT8qVonEtNcB/O/ixZIDGsm0uXDTRZSwer
    ZSHQkT7IgDTtFsRO3NCep/F4/tFZ0+LVtHocuZqcMd4GIUkDh8zUvFRmzA5M/BzA
    f10FCXj2n6OWlFGpj8FTj24X0z8Ht7muOp/LXnoBzHeg96PAUPyHowkjAoGBAPQ4
    c3FR/THGfITDcVE/8im9yUiDMlSXgMXirzw21kWDrX24mvtCZ1FdJwz8UCkdc7di
    dmYhKXJQjzboHJKwA/5owOPBWLiWbQ+RgkNgUzM1cPA8sJwbfee0jQkBKr/A/N9z
    97zpzCbSjPK8eCr8TgnbM+i+4Ksz+VQ3sFQWTP4FAoGBAKvBCgIKh9IxJu9cm2m/
    tmvhBOPucx4tj4R31PvxcGWk/sVpMSq1zr1/IkKd8Q4LZj9zn4/5UBdPPXL6sKa6
    x0t/3HLTYNSlNlx+mgcbXjHE81mF3qVt/SsyEY69oS2JZK9hquy38/3XFTr19GW7
    oQClNkeekXeFattcF7ScRK4hAoGBALLt6SbNe0RMuE5NI2j0AOi0NBT8ipOPrLlP
    LDRhuPuVTjceWe5c6HWkWkgZVGHLQkzJxxVsloE6pLMFvGl8rHBpL2mu7uHO1Hjj
    kxzqJgsOzIA29u5o9gYdMne2cyuqqZrNzGSMpxaKjNoLW7+iu0pdX9rWfqTwn2wk
    oPv6ms5BAoGAAt4orawfCOWER8E7PDKLsg/OlcXZGIU/vHFGLlZOjHXy7r0SBmMw
    j5cu+KFswfUZSVW8M8ETEY68k9sFCWCW23fzw9al+P5P0NBgU2sAYVbm4nhwLaun
    4UGQJ7gyxU4JVPiLM0ekFQTiKL3Nu98orBhtfys7IRrjz9lyXNyGYrY=
    -----END RSA PRIVATE KEY-----`;
    addMatomoEvent('nm/generic/pressedloginwithbank', 'nm/generic/pressedloginwithbank');
    await getSettingConfig().then((data) => {
      let brandname = getBrandName();
      let brandURL = "";
      if (brandname === 'rbs') {
        brandURL = data.LWB_SETTINGS.RBS_URL;
        data.LWB_SETTINGS.DATA.state = 'rbs';
      } else if (brandname === 'natwest') {
        brandURL = data.LWB_SETTINGS.NATWEST_URL;
        data.LWB_SETTINGS.DATA.state = 'natwest';
      } else {
        brandURL = data.LWB_SETTINGS.ULSTER_URL;
        data.LWB_SETTINGS.DATA.state = 'ulster';
      }
      data.LWB_SETTINGS.DATA.exp = Math.round(600 + Date.now() / 1000);
      var sJWT = KJUR.jws.JWS.sign("PS256", JSON.stringify(data.LWB_SETTINGS.HEADER), JSON.stringify(data.LWB_SETTINGS.DATA), signature);
      window.open(brandURL + sJWT, '_self');
    });
  }

  const recaptchaRef = React.createRef<ReCAPTCHA>();
  let currentBrandName = getBrandName();

  const getPageContent = async () => {
    const pageContent = await getLoginPageBenefits();
    setPageContent(pageContent);
  };

  const resetErrors = () => {
    setCaptchaError(null);
    setCaptchaValidity(false);
  };

  const resetMessage = () => {
    errorSet.length = 0;
  };

  const addEventLWB = (trackingURL) =>{
    addMatomoEvent(trackingURL, trackingURL);
  }
  useEffect(() => {
    let controller = new AbortController();
    getPageContent();
    return () => controller?.abort();
  }, [currentBrandName]);

  useEffect(() => {
    let controller = new AbortController();
    if (showCaptcha) {
      let cp = recaptchaRef.current;
      cp.reset();
      resetErrors();
    }
    return () => controller?.abort();
  }, [resetCaptcha]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderErrorBox = (errorToPrint?: any) => {
    if (errorToPrint) {
      const { errorMsg } = errorToPrint;
      return (
        <ErrorBox
          errors={errorMsg}
          errorHeader={errorMessages.LOGIN_ERROR_BOX_HEADER}
        />
      );
    }
    if (errorSet && errorSet.length) {
      let lwbError = false;
      let lwbMemberNotFound = false;
      let errorMsg = errorSet.map((errorItem) => {
        switch (errorItem) {
          case UNAUTHORIZED:
            return errorMessages.GENERAL_ERROR_MESSAGE; // Incorrect password entered
          case NOT_FOUND:
            return errorMessages.GENERAL_ERROR_MESSAGE; // Incorrect username entere
          case RIO_NOT_FOUND:
            return errorMessages.RIO_NOT_FOUND; // Incorrect username entere
          case ACOUNT_LOCKED:
            return errorMessages.USER_LOCKED; // Account Locked
          case BACKEND_API_ERROR:
            return errorMessages.DEFAULT_ERROR_MESSAGE;
          case CIN_NOT_FOUND:
            lwbMemberNotFound = true;
            return errorMessages.LWB_MEMBER_NOT_FOUND;
          case LWB_ERROR:
            lwbError = true;
            return errorMessages.LWB_ERROR;
          case LWB_TOKEN_VERIFICATION_ERROR:
            return errorMessages.LWB_TOKEN_VERIFICATION_ERROR;
          default:
            return errorMessages.DEFAULT_ERROR_MESSAGE;
        }
      });

      //If same error is occurred multiple time the to get unique value
      let unique = (item, index, array) => array.indexOf(item) === index;
      errorMsg = errorMsg.filter(unique);
      let lwberrorHeader = 'Your bank could not sign you in';
      let lwbMemberNotFoundHeader = 'We could not find your member details';
      let errorHeader = lwbError ? lwberrorHeader : lwbMemberNotFound ? lwbMemberNotFoundHeader : errorMessages.ERROR_BOX_HEADER;
      return errorSet.length ? (
        <ErrorBox
          errors={errorMsg}
          errorHeader={errorHeader}
        />
      ) : null;
    } else {
      return;
    }
  };

  const onCaptchaChange = (value) => {
    resetErrors();
    window["grecaptcha"].getResponse() !== ""
      ? setCaptchaValidity(true)
      : setCaptchaValidity(false);
    showCaptcha = !showCaptcha;
    setCaptchaError(null);
  };

  const isCaptchaPresentAndValidated = (value) => {
    if (showCaptcha) {
      if (window["grecaptcha"].getResponse() !== "" || isCaptchaValid) {
        handleLoginClick(value);
      } else {
        const error = ["Captcha information missing. Please try again."];
        setCaptchaError({ errorMsg: error });
      }
    } else {
      handleLoginClick(value);
    }
  };
  const renderUpdatesSection = () => {
    return (
      <div className={styles.message_section}>
        {(currentBrandName === BRAND_ULSTER_RI) ? <div> <MarkedComponent
            rawString={generateAccountSpecificString(TextContent.login.loginTextBlock.ImportantMessage)}
          /> </div> : ""
        }
        {(currentBrandName !== BRAND_ULSTER_RI) ?  <div className={styles.message_section_text}>
          {TextContent.login.loginTextBlock.ImportantMessage_line_two}
        </div> :"" }
      </div>
    );
  };

  const getAccountNames = (accountSpecificData) => {
    const {
      para1_rbs_natwest,
      para1_ulster_ni,
      para1_ulster_ri,
    } = accountSpecificData;
    let brand = getBrandName();
    if (brand === BRAND_ULSTER_NI) return para1_ulster_ni;
    else if (brand === BRAND_ULSTER_RI) return para1_ulster_ri;
    else return para1_rbs_natwest;
  };

  const generateAccountSpecificString = (data) => {
    const {
      para1_start2,
      para1_end,
    } = data;
     return `${para1_start2} ${getAccountNames(data)} ${para1_end}`;
  }
  const getLoginTextBoxContent = () => {
    const {
      title,
      para1_start,
      para1_end,
      para2,
    } = TextContent.login.loginTextBlock;
    let clickHereURL = '';
    if(currentBrandName === BRAND_ULSTER_NI) {
      clickHereURL = "https://www.ulsterbank.co.uk/content/ulster_com/ni/personal/current-accounts/manage-your-current-account/travel-faqs.html";
    } else if(currentBrandName === BRAND_NATWEST) {
      clickHereURL = "https://www.natwest.com/content/natwest_com/en_uk/personal/current-accounts/existing-customers/managing/travel-faqs.html";
    } else if(BRAND_RBS) {
      clickHereURL = "https://www.rbs.co.uk/content/rbs_co_uk/en_uk/personal/current-accounts/existing-customers/managing/travel-faqs.html"
    }
    
    return (
      <div className={classNames(styles.loginPage__col, styles.extendCol)}>
        <div className={ currentBrandName === BRAND_ULSTER_RI ? classNames(styles.loginPage__text_block_content, styles.marginright) : styles.loginPage__text_block_content }>
          <p className={styles.loginPage__text_block_title}>{title}</p>
          {viewMessageSection ? renderUpdatesSection() : null}
          {currentBrandName !== BRAND_ULSTER_RI ? <p>{`${para1_start} ${getAccountNames(TextContent.login.loginTextBlock)} ${para1_end}`}</p> : "" }

          <div className={styles.loginPage_createAccount}>
          {currentBrandName !== BRAND_ULSTER_RI ?  <div className={styles.list_para}>
              <ul className={styles.loginPage_ul_list}>
                {para2.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div> : "" }
            {currentBrandName === BRAND_ULSTER_RI ? <div> <span>Please visit our website <a href="https://www.ulsterbank.ie/" target="_blank" className={styles.link}>www.ulsterbank.ie</a> for full details and further assistance.</span> 
            <p>The benefits associated with your ufirst/ ufirstgold/ufirstPrivate account have now ceased. If you have a query in relation to your benefits, please contact Membership Services on <a href="tel:1800925067" className={styles.link}>1800 925 067</a> for ufirst account holders, <a href="tel:1800946486" className={styles.link}>1800 946 486</a> for ufirstgold account holders or <a href="tel:1800825125" className={styles.link}>1800 825 125</a> for ufirstPrivate account holders.</p> </div> : ''}
          </div>
          
        </div>
      </div>
    );
  };

  const { data } = pageContent;
  const benefitLength = data && (data.benefits || []).reduce((count, benefit) => count + benefit.benefitsType.length, 0);
  const brand = getBrandName() == 'rbs' ? 'Royal Bank' : getBrandName() == 'natwest' ? 'NatWest' : 'Ulster Bank';
  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />

      <div className={styles.loginPage__wrapper}>
        {/*================== ROW 1 ==================*/}
        <div
          className={classNames(
            styles.loginPage__row,
            styles.loginPage_BannerParent
          )}
        >
          <div className={styles.loginPage__col}>
            <div className={styles.loginPage__Banner}>
              <p className={styles.loginPage__banner_text}>
                Membership Services
              </p>
            </div>
            
          </div>
        </div>
        {/*================= ROW 2 ===================*/}
        <div
          className={classNames(
            styles.loginPage__row,
            styles.membership_login_parent
          )}
        >
          {/*=============== COLOUMN 1 ===============*/}
          {getLoginTextBoxContent()}

          {/*===================== COLOUMN 2 ================*/}
          {lwb && currentBrandName !== BRAND_ULSTER_RI ?
            <div className={classNames(styles.loginPage__col)}>
              <div className={styles.login_form_lwb} data-test="login_form_lwb">
                <div
                  className={styles.login_form__form}
                >
                  {renderErrorBox(captchaError)}
                  {errorSet.length ? "" : <div className={styles.login_form__header}>
                    <div className={styles.heading}>Sign into Membership Services</div>
                  </div>}
                  <div className={styles.heading__heading_note}>
                    You can now use your mobile or online banking credentials to activate and sign into your Membership Services benefits.
                  </div>
                  <div className={styles.btn}>
                    <PrimaryButton
                      text={"Sign in through " + brand}
                      handleClick={() => openLWBpage()}
                      disabled={false}
                      classNames={styles.btn__login_btn_white}
                      data-test="sign-in-button"
                    />
                  </div>
                  <div className={styles.line}></div>
                  <div className={styles.heading__heading_note}>
                    Alternatively, if you are already an active benefits customer, you can still use your Membership Services registered email and password to sign in.
                  </div>
                  <div className={styles.btn}>
                    <Button
                      text="Sign in with Membership Services"
                      disabled={false}
                      classNames={styles.btn__login_btn}
                      handleClick={() => { setlwb(false); resetMessage(); addEventLWB('nm/generic/pressedloginwithMS');}}
                      data-test="sign-in-button"
                    />
                  </div>
                </div>
              </div>
            </div>
            :
            currentBrandName !== BRAND_ULSTER_RI ? <div className={classNames(styles.loginPage__col)}>
             <div className={styles.login_form} data-test="login-form">
                <form
                  className={styles.login_form__form}
                  onSubmit={handleSubmit(isCaptchaPresentAndValidated)}
                  noValidate
                >
                  { currentBrandName !== BRAND_ULSTER_RI ? <div className={styles.backBtn} onClick={() => { setlwb(true); resetMessage();}}>
                    <Icon iconName="icon-arrow-thin-left" />
                    &nbsp; &nbsp;<span >Back </span>
                  </div> : <div className={styles.login_form__header}>
                    <div className={styles.heading}>Sign in</div>
                    <div className={styles.heading__heading_note}>
                      {"Note: This is different to your online banking login"}
                    </div>
                  </div>}
                  {renderErrorBox(captchaError)}
                  <div className={styles.login_form__paragraph} id="Email">Email</div>
                  {errors.email && (
                    <FormFieldError errorText={errors.email.message} />
                  )}
                  <div className={styles.input_icons}>
                    <input
                      className={classNames(styles.form_control)}
                      type="email"
                      data-test="login-email-field"
                      {...register('email')}
                      aria-labelledby="Email"
                    />
                    <div className={styles.pull_right}>
                      <i>
                        <Icon iconName="icon-mail-box" />
                      </i>
                    </div>
                  </div>
                  <div className={styles.login_email__link}>
                    <Link to="/forgot-email" className={styles.login_form__link}>
                      Forgotten your benefits email?
                    </Link>
                  </div>
                  <div className={styles.login_form__paragraph} id="Password">Password</div>
                  {errors.password && (
                    <FormFieldError errorText={errors.password.message} />
                  )}
                  <div className={styles.input_icons}>
                    <input
                      className={classNames(styles.form_control)}
                      type="password"
                      defaultValue=""
                      data-test="login-password-field"
                      {...register('password')}
                      autoComplete="off"
                      aria-labelledby="Password"
                    />
                    <div className={styles.pull_right}>
                      <i>
                        <Icon iconName="icon-lock-key" />
                      </i>
                    </div>
                  </div>
                  <div className={styles.login_forgot__link}>
                    <Link
                      to="/forgot-password"
                      className={styles.login_form__link}
                    >
                      Forgotten your benefits password?
                    </Link>
                  </div>
                  {showCaptcha ? (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={captchaSiteKey}
                      onChange={onCaptchaChange}
                    />
                  ) : null}
                  <div className={styles.btn}>
                    <PrimaryButton
                      text="Sign in"
                      disabled={isSubmitting}
                      classNames={styles.btn__login_btn}
                      data-test="sign-in-button"
                    />
                  </div>
                  {currentBrandName !== BRAND_ULSTER_RI ? <div className={styles.btn}>
                    <Link to="/user-signup" className={styles.login_form__link}>
                      Need to register your Membership Services?
                    </Link>
                  </div> : ''}
                </form>

              </div> 
            </div> : ''
          }
        </div>

        {/*=============== ROW 1 - 2nd Section ================ */}
        { currentBrandName !== BRAND_ULSTER_RI ? pageContent && pageContent.data ? (
          <div
            className={classNames(
              styles.loginPage__row,
              styles.loginPage_discover_container_padding
            )}
            data-test="discover-section"
          >
            <div className={styles.loginPage_discoverParent}>
              <div
                className={classNames(
                  styles.loginPage__row,
                  styles.discover_padding
                )}
              >
                <div className={classNames(styles.loginPage_discoverText)}>
                  <h2>{data.title}</h2>
                </div>
                <div
                  className={classNames(
                    styles.loginPage__col,
                    styles.sub_heading
                  )}
                >
                  <h4>{data.accountTypes.title}</h4>
                  <ul>
                    {(data.accountTypes.accounts || []).map((data, index) => {
                      return (
                        <li
                          key={index}
                          className={classNames(styles[data.className])}
                        >
                          <div
                            className={classNames(styles.loginPage_stripe)}
                          ></div>
                          <span
                            className={classNames(
                              styles.loginPage_one,
                              styles.stripeText
                            )}
                          >
                            {" "}
                            {data.text}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div
                className={classNames(
                  styles.loginPage__row,
                  styles.info_section
                )}
                style={{ gridTemplateColumns: `repeat(${data.benefits.length}, 1fr)` }}
              >
                {(data.benefits || []).map((benefit, index, benefitList) => {
                  return (
                    <div
                      key={index}
                      className={classNames(
                        styles.newLoginPage__col,
                        styles.padding0,
                        { [styles.border_right]: index !== benefitList.length - 1 }
                      )}
                      data-test="benefits-wrapper"
                    >
                      <h4 className={styles.loginPage_h4}>{benefit.title}</h4>
                      <div className={styles.newLoginPage__row} style={{ gridTemplateColumns: `repeat(${benefitLength <= 6 ? benefit.benefitsType.length : 6 / benefitList.length}, 1fr)` }}>
                        {(benefit.benefitsType || []).map((type, index) => {
                          return (
                            <BenefitTile
                              key={index}
                              tileIcon={type.iconName}
                              tileContent={type.text}
                              accounts={type.accounts}
                              className={styles.paddingTile}
                              accountTypes={data.accountTypes.accounts.length}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* /*================= ROW 4 ====================  */}

              {currentBrandName === BRAND_ULSTER_RI ? 
              <div className={styles.loginPage__bottom_section}>
                <div className={classNames(styles.loginPage__row)}>
                  <Link
                    to="/user-signup"
                    className={styles.loginPage__bottom_signup_button}
                  >
                    {data.buttons.signUp}
                  </Link>
                </div>
                <div
                  className={classNames(styles.loginPage__row, styles.margin10)}
                >
                  <Link
                    to="#"
                    className={styles.loginPage__scrollUp_link}
                    onClick={ScrollToTop}
                  >
                    {data.buttons.signIn}
                  </Link>
                  <span className={styles.loginPage__scrollUp_icon}>
                    <Icon iconName={data.buttons.signInIconName} />
                  </span>
                </div>
              </div>
                : <div className={styles.loginPage__bottom_section}></div>}
            </div>
          </div>
        ) : null : '' }
      </div>
      <FooterComponent />
    </Fragment>
  );
};
export default withRouter(LoginForm);
