import React, { useEffect } from "react";
import BaseRoute from "./routes";
import { CookieSettingModel } from "redux/models/CookieSettings";
import { connect } from "react-redux";
import { deleteCookies } from "utlis/generalUtils";
import { withRouter } from "react-router-dom";

interface IProps {
  cookieSetting: CookieSettingModel[];
}

const AppIndex: React.FC<IProps> = ({ cookieSetting }) => {
  useEffect(() => {
    const cookieSettingValue = cookieSetting && cookieSetting[0];
    const cookieOtpIn =
      cookieSettingValue && cookieSettingValue.props.cookieOptIn;
    if (!cookieOtpIn) deleteCookies();
  });

  return <BaseRoute />;
};

const mapStateToProps = (state) => {
  return {
    cookieSetting: CookieSettingModel.list(state),
  };
};

export default withRouter(connect(mapStateToProps)(AppIndex));
