import React from "react";
import styles from "./changeEmail.module.scss";
import content from "config/Labels/label.json";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { ErrorBox } from "../../../Login/ErrorBox";
import classNames from "classnames";
import { FormFieldError } from "../../../../components/FormFieldError";
import * as errorMessages from "../../../../config/errorMessages";
import {
  EMAIL_REQUIRED_VALIDATION,
  EMAIL_PATTERN_ERROR,
  EMAIL_MISMATCH_ERROR,
  PASSWORD_REQUIRED_ERROR,
  CURRENT_EMAIL_ERROR,
  ERROR_BOX_HEADER
} from "config/errorMessages";
import { emailFieldRegex } from "config/constants";
import { PrimaryButton } from "components/PrimaryButton";
import { useYupValidationResolver } from "utlis/generalUtils";
import { ACOUNT_LOCKED, BACKEND_API_ERROR, NOT_FOUND, UNAUTHORIZED, EMAIL_ALREADY_EXIST } from "config/errorCodeConstants";
interface IChangeEmailProps {
  isSubmitting?: boolean;
  handleNextClick: any;
  errorSet?: any;
  error?: any;
}
const changeEmailSchema = yup.object({
  oldEmail: yup
    .string()
    .email(`${EMAIL_PATTERN_ERROR}`)
    .matches(emailFieldRegex, EMAIL_PATTERN_ERROR)
    .required(`${CURRENT_EMAIL_ERROR}`),
  newEmail: yup
    .string()
    .email(`${EMAIL_PATTERN_ERROR}`)
    .matches(emailFieldRegex, EMAIL_PATTERN_ERROR)
    .required(`${EMAIL_REQUIRED_VALIDATION}`),
  confirmEmail: yup
    .string()
    .test("emailCheck", `${EMAIL_MISMATCH_ERROR}`, function (value) {
      let newEmail: any = this.resolve(yup.ref("newEmail"));
      let confirmEmail: any = this.resolve(yup.ref("confirmEmail"));
      return newEmail.toLowerCase() === confirmEmail.toLowerCase();
    })
    .matches(emailFieldRegex, EMAIL_PATTERN_ERROR)
    .required(`${EMAIL_REQUIRED_VALIDATION}`),
    password: yup
    .string()
    .required(`${PASSWORD_REQUIRED_ERROR}`),
});

export const ChangeEmailForm: React.FC<IChangeEmailProps> = (
  props: IChangeEmailProps
) => {
  let { isSubmitting, handleNextClick, error } = props;
  const resolver = useYupValidationResolver(changeEmailSchema);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver
  });
  const {
    changeEmail: {
    changeEmailHeader,
    changeEmailMainSubtitle,
    changeEmailSubtitle,
    enterPassword,
    enterOldEmail,
    enterNewEmail,
    confirmNewEmail,
    changeEmailParaOne,
    PleaseNote,
    changeEmailParaTwo,
    }
  } = content;

  const renderErrorBox = (errors?: any) => {
    if (errors && errors.length) {
      let errorMsg = errors.map((errorItem) => {
        switch (errorItem) {
          case UNAUTHORIZED:
            return errorMessages.GENERAL_ERROR_MESSAGE; // Incorrect password entered
          case NOT_FOUND:
            return errorMessages.GENERAL_ERROR_MESSAGE; // Incorrect username entere 
          case ACOUNT_LOCKED:
            return errorMessages.USER_LOCKED; // Account Locked
          case EMAIL_ALREADY_EXIST:
            return errorMessages.EMAIL_ALREADY_EXIST; // Account Locked
          case BACKEND_API_ERROR:
            return errorMessages.DEFAULT_ERROR_MESSAGE;
          default:
            return errorMessages.DEFAULT_ERROR_MESSAGE;
        }
      });

      //If same error is occurred multiple time the to get unique value
      let unique = (item, index, array) => array.indexOf(item) === index;
      errorMsg = errorMsg.filter(unique);
      return errors.length ? (
        <ErrorBox
          errors={errorMsg}
          errorHeader={ERROR_BOX_HEADER}
        />
      ) : null;
    } else {
      return;
    }
  };
  const formErrorMsg = () => {
    error = [];
    let formErrors;
    if (Object.keys(errors).length) {
      let uniqueErrors: any;
      let errorsArray = Object.values(errors).map((item: any) => {
        return item.message;
      });
      uniqueErrors = Array.from(new Set(errorsArray));
      formErrors = (
        <ErrorBox
          className={styles.error_box_background}
          errors={uniqueErrors}
          errorHeader={ERROR_BOX_HEADER}
        />
      );
    }
    return formErrors;
  };
  return (
    <div className={styles.main_Container} data-test="change_email">
      <div className={styles.change_email_main_Container}>
        <div className={styles.changeEmail_title}>{changeEmailHeader}</div>
        <div className={styles.change_email_form_Container}>
          {error ? renderErrorBox(error) : null}
          {errors ? formErrorMsg() : null}
          <form onSubmit={handleSubmit(handleNextClick)} noValidate>
            <div className={styles.changeEmail__row_content}>
              <div className={styles.changeEmail__left_column_content}>
                <div className={styles.changeEmail__content_box}>
                  <div className={styles.changeEmail__main_subtitle_text}>
                    {changeEmailMainSubtitle}
                  </div>
                  <div className={styles.changeEmail_subtitle_text}>
                    {changeEmailSubtitle}
                  </div>
                  <div className={styles.changeEmail_form}>
                    {/* Old Email */}
                    <div className={styles.changeEmail_form__content}>
                      {enterOldEmail}
                    </div>
                    {errors.oldEmail && (
                      <FormFieldError errorText={errors.oldEmail.message} />
                    )}
                    <input
                      {...register('oldEmail')}
                      type="email"
                      data-test="confirm-email-field"
                      className={styles.changeEmail_form__field}></input>

                    {/* New Email */}
                    <div className={styles.changeEmail_form__content}>
                      {enterNewEmail}
                    </div>
                    {errors.newEmail && (
                      <FormFieldError errorText={errors.newEmail.message} />
                    )}
                    <input
                      {...register('newEmail')}
                      type="email"
                      data-test="email-field"
                      data-testid="email-field-id"
                      className={styles.changeEmail_form__field}></input>
                    {/* Confirm email */}
                    <div className={styles.changeEmail_form__content}>
                      {confirmNewEmail}
                    </div>
                    {errors.confirmEmail && (
                      <FormFieldError errorText={errors.confirmEmail.message} />
                    )}
                    <input
                      {...register('confirmEmail')}
                      type="email"
                      data-test="confirm-email-field"
                      className={styles.changeEmail_form__field}></input>
                       {/* Password */}
                    <div className={styles.changeEmail_form__content}>
                      {enterPassword}
                    </div>
                    {errors.password && (
                      <FormFieldError errorText={errors.password.message} />
                    )}
                    <input
                      {...register('password')}
                      type="password"
                      data-test="confirm-email-field"
                      className={styles.changeEmail_form__field}></input>
                  </div>
                </div>
              </div>
              <div className={styles.changeEmail__right_column_content}>
                <div className={styles.changeEmail__content_box}>
                  <div className={styles.changeEmail__right_column_text}>
                    {changeEmailParaOne}
                  </div>

                  <div className={styles.changeEmail__right_column_text}>
                    <b>{PleaseNote} </b>
                    {changeEmailParaTwo}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classNames(
                styles.button_container,
                styles.next_button
              )}
              data-testid="NextButton"
            >
              <PrimaryButton
                text="Next"
                disabled={isSubmitting ? isSubmitting : false}
                data-test="next-button"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangeEmailForm);
