import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import styles from "./eventBookingSuccess.module.scss";
import labels from "config/Labels/label.json";

const EventBookingSuccess: React.FC<{}> = () => {
  const {
    eventBookingForm:{
    successHeading,
    successMessageLineOne,
    successMessageLineTwo,
    successRedirectionLink,
    }
  } = labels;
  return (
    <Fragment>
      <div className={styles.page}>
        <div className={styles.content_wrapper}>
          <h2 className={styles.heading}>{successHeading}</h2>
          <p>{successMessageLineOne}</p>
          <p>{successMessageLineTwo}</p>
          <p className={styles.link}>
            <Link to="/explore/ticket-booking-service/event-booking">
              {successRedirectionLink}
            </Link>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default withRouter(EventBookingSuccess);
