import * as React from "react";
import styles from "./Accordion.module.scss";
import { Icon } from "components/Icon";
import classnames from "classnames/bind";
import { addGAEvent, addMatomoEvent, getTrackingUrl } from "utlis/generalUtils";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";

let bindCss = classnames.bind(styles);

/**
 * @author Anjali Agambare
 * @param {*} props
 * @param {String} content-value can be string
 * @param {String} title-value can be string
 */

export interface IAccordion {
  content: string;
  title: string;
  subTitle?: string;
  active?: boolean;
  className?: string;
  isOpen?: boolean;
  iconName?: string;
}

export const Accordion: React.FC<IAccordion> = (props) => {
  const { content, title, subTitle, className, isOpen, iconName } = props;
  const [active, inactive] = React.useState(isOpen ? isOpen : false);

  const toggle = (e) => {

    if(window.location.pathname.includes("covidfaq")){

      let subTtl = subTitle ? subTitle.toLowerCase().split(' ').join('-') : '';
      let ttl = title.toLowerCase().split(' ').join('-');
      if(!active){
        let pathName = window.location.pathname.split("/");
        let newUrl = '';
        for(let i = 0;i < pathName.length; i++){
          if(pathName[i].includes("covidfaq")){
            newUrl += '/'+pathName[i];
            break;
          }
          if(pathName[i]!=='')
            newUrl += '/'+pathName[i];
        }
        if(!newUrl.includes(subTtl) && subTtl!==''){

          newUrl = newUrl + "/" + subTtl;
        }
        else if(!pathName.includes(ttl)){
          newUrl = newUrl + "/" + ttl;
        }
        window.history.replaceState(null, '', newUrl+window.location.search);
      }
      else{
        let path = window.location.pathname;
        let pathName = window.location.pathname.split("/");
          let newUrl = '';
          if(path.includes(ttl))
          {
            for(let i = 0;i < pathName.length-1; i++){
              if(pathName[i]!=='')
                newUrl += '/'+pathName[i];
            }
          }
          else if(path.includes(subTtl) && subTtl!==''){
            for(let i = 0;i < pathName.length-1; i++){
              if(pathName[i]!=='')
                newUrl += '/'+pathName[i];
            }
          }
          else{
            for(let i = 0;i < pathName.length; i++){
              if(pathName[i]!=='')
                newUrl += '/'+pathName[i];
            }
          }
          window.history.replaceState(null, '', newUrl+window.location.search);
      }
      let trackingUrl = getTrackingUrl(SelectedAccountModel.getInsatnce("1"), window.location);
      trackingUrl = trackingUrl.toLowerCase().split(' ').join('-');
      addMatomoEvent(trackingUrl, trackingUrl);
      addGAEvent(trackingUrl);

    }
    return inactive(!active);
  };
  return (
    <div data-test="accordionComponent">
      <div className={`${styles.main_container} ${className}`}>
        <div
          className={bindCss({
            wrapper: true,
            active_accordion: active,
            inactive_accordion: !active,
          })}
          onClick={toggle}
          data-test-id="toggleButton"
        >
          <div
            className={bindCss({
              accordion_title__container: true,
              active_accordion: active,
              inactive_accordion: !active,
            })}
          >
            <div className={styles.heading_icon} data-test="headingIcon">
              <Icon iconName={iconName ? iconName : ""} />
            </div>
          <div>{title} <span className={!active? styles.subTitleClose:styles.subTitleOpen}>{subTitle}</span></div>
          </div>
          <div className={styles.icon} data-test="arrowIcon" aria-haspopup="true" aria-expanded={active}  onClick={toggle}
          onKeyPress={(e) => { if(e.key === 'Enter')toggle(e);}}
         >
            <Icon
              tabIndex={0}
              iconName={
                active ? "icon-chevron-normal-up" : "icon-chevron-normal-down"
              }
              title={title}
            />
          </div>
        </div>

        <div
          className={bindCss({
            accordion_content: true,
            accordion_content__expanded: active,
            accordion_content_collapsed: !active,
            expanded_content: active,
          })}
        >
          {content}
        </div>
      </div>
    </div>
  );
};

export default Accordion;
