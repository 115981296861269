import { useState, useEffect } from "react";
import { withRouter, match } from "react-router-dom";
import { History, Location } from "history";
import ChangePasswordForm from "./ChangePassword";
import { connect } from "react-redux";
import { CHANGE_PASSWORD_AFTER_LOGIN } from "services/GraphQL/gqlQueries";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { LoginEmailModel } from "redux/models/LoginEmailModel";

interface IProps {
  location: Location;
  history: History;
  match: match;
  userDetails: UserDetailModel;
  tenantDetails: BrandSpecificDataModel;
  selectedAccount: SelectedAccountModel;
  emailDetails: LoginEmailModel;
}

const ChangePasswordContainer = (props: IProps) => {
  const [backendErrors, setError] = useState([]);

  useEffect(() => {
    populateBreadCrumbs();
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Change Password",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  const changePasswordData = async (values) => {
    const {
      userDetails: {
        props: {
          LoggedInUserDetails: {
            member: { id, firstName, lastName },
          },
        },
      },
      tenantDetails: {
        props: { tenantId },
      },
      selectedAccount: {
        props: { membershipId },
      },
      emailDetails: {
        props: { loginEmail },
      },
      history,
    } = props;
    return await graphQlCall(
      "mutation",
      CHANGE_PASSWORD_AFTER_LOGIN,
      {
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
        memberId: props.userDetails && id,
        firstName: props.userDetails && firstName,
        lastName: props.userDetails && lastName,
        membershipId: membershipId,
        emailId: props.emailDetails && (loginEmail ||'').toLowerCase(),
      },
      {
        tenantId: tenantId,
      }
    )
      .then((data) => {
        const response = data.data["changePassword"];
        if (response.code === 200) {
          history.push("/account/password-success-page");
        } else {
        }
      })
      .catch((error) => {
        setError(onGqlError(error));
      });
  };

  const handleLoginClick = (values) => {
    changePasswordData(values);
  };

  return (
    <ChangePasswordForm
      handleLoginClick={handleLoginClick}
      errorSet={backendErrors}
    />
  );
};

const mapStateToProps = (state) => {
  let userDetails = UserDetailModel.getInsatnce("LoggedUser");
  let tenantDetails = BrandSpecificDataModel.getInsatnce("1");
  let selectedAccount = SelectedAccountModel.getInsatnce("1");
  let emailDetails = LoginEmailModel.getInsatnce("1");
  return {
    userDetails,
    tenantDetails,
    selectedAccount,
    emailDetails,
  };
};

export default connect(mapStateToProps)(withRouter(ChangePasswordContainer));
