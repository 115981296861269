export const Typography = {
  properties: {
    "--fontSize48": "48px",
    "--fontSize36": "36px",
    "--fontSize32": "32px",
    "--fontSize28": "28px",
    "--fontSize26": "26px",
    "--fontSize22": "22px",
    "--fontSize18": "18px",
    "--fontSize16": "16px",
    "--fontSize14": "14px",
    "--fontSize12": "12px",
    "--fontSize10": "10px"
  }
};
