import { BaseModel } from "./BaseModel";

interface ILoaderStateModel {
  id: string;
  isLoading: boolean;
}
/**
 * To store the user data coming from the GitHub API.
 * @export
 * @class LoaderStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class LoaderStateModel extends BaseModel<ILoaderStateModel> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ILoaderStateModel) {
    super(props);
  }

  static resource = "ILoaderState";
}
