import { IThunkAction, IAction } from "../interfaces";
import { useCallback } from "react";
import { store } from "../redux/store";
import { History } from "history";
import ReactGA from "react-ga";
import settings from "../services/RestAPI/axiosConfig";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import {
  NATWEST_BLACK,
  NATWEST_PLATINUM,
  NATWEST_SILVER,
  RBS_BLACK,
  RBS_SILVER,
  RBS_PLATINUM,
  ULSTER_RI_UFIRST,
  ULSTER_RI_GOLD,
  ULSTER_RI_PRIVATE,
  ULSTER_NI_GOLD,
  ULSTER_NI_PRIVATE,
  BRAND_ULSTER_RI,
  BRAND_ULSTER_NI,
  RBS_ENQUIRY_LINK,
  NATWEST_ENQUIRY_LINK,
  ULSTER_NI_ENQUIRY_LINK,
  ULSTER_RI_ENQUIRY_LINK,
  BRAND_NATWEST,
} from "config/constants";
import {
  FRIENDLY_PATH_NAME,
  FRIENDLY_ACCOUNT_NAME,
  AFTER_LOGIN_FRIENDLY_PATH_NAME
} from "config/FriendlyNamesGA";
import { getBrandName, logout, getAccountType } from "services/RestAPI/exploreCalls";
import { v4 as uuidv4 } from "uuid";
import label from "config/Labels/label.json";
let { ulster, natwest, rbs } = label.ThemeConstants;

export function keys(object: Object, callback: Function) {
  for (let key in object) {
    if (object.hasOwnProperty(key)) {
      callback(key);
    }
  }
}

export function dispatch<T extends IAction>(action: T | IThunkAction) {
  if ((action as IAction).type) {
    return store.dispatch(action as IAction);
  }
  return store.dispatch<{ type: string }>(action as IThunkAction);
}

export const randomNumberGenerator = () => {
  return Math.ceil(Math.random() * 100);
};

export const sequenceAdder = (baseArray) => {
  for (let i = 0; i < baseArray.length; i++) {
    let obj;
    obj = baseArray[i];
    obj["sequenceNumber"] = i + 1;
    let content = obj.metaData && obj.metaData.content;
    if (content && Array.isArray(content)) {
      sequenceAdder(content);
    }
  }
  return baseArray;
};

export const getDateByProvidedFormat = (loginDate, format, del) => {
  let userDate = new Date(loginDate);
  let dateFormat = {
    dd:
      userDate.getUTCDate() < 10
        ? `0${userDate.getUTCDate()}`
        : userDate.getUTCDate(),
    mm:
      userDate.getUTCMonth() + 1 < 10
        ? `0${userDate.getUTCMonth() + 1}`
        : userDate.getUTCMonth() + 1,
    yyyy: userDate.getUTCFullYear(),
  };
  let providedFormat = format.split("-");
  return (
    dateFormat[providedFormat[0]] +
    del +
    dateFormat[providedFormat[1]] +
    del +
    dateFormat[providedFormat[2]]
  );
};
/**
 * Function to call graphQL queries
 * @param headersToPass {object} pass the headers in the query
 */

export const deleteCookies = async () => {
  const resp = await getSettingConfig();
  let cookieName = resp.COOKIES_TO_FLAG;
  if (!Array.isArray(cookieName)) return;
  let expiryTimeStamp = "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  (cookieName || []).forEach((cName) => {
    document.cookie = cName + expiryTimeStamp;
  });
};

/**
 * @summary The below function is just to check if the Login model is present, if yes the user is treated as valid
 */
export const AuthenticationCheck = () => {
  const tokenInfo = store.getState().models.has("ILoginStateLoginDetails");
  return tokenInfo && getRefreshTokenExpiryTime();
};

/**
 * @summary: Below function just check the account type passed in and returns actual theme name using constants
 used in headerContainer */
export const resolveAccountTheme = (accountType) => {
  switch (accountType) {
    case "Natwest Black":
      return NATWEST_BLACK;

    case "Natwest Silver":
      return NATWEST_SILVER;

    case "Natwest Platinum":
      return NATWEST_PLATINUM;

    case "RBS Black":
      return RBS_BLACK;

    case "RBS Silver":
      return RBS_SILVER;

    case "RBS Platinum":
      return RBS_PLATINUM;

    case "Ulster RI ufirst":
      return ULSTER_RI_UFIRST;

    case "Ulster RI Gold":
      return ULSTER_RI_GOLD;

    case "Ulster RI Private":
      return ULSTER_RI_PRIVATE;

    case "Ulster NI Gold":
      return ULSTER_NI_GOLD;

    case "Ulster NI Private":
      return ULSTER_NI_PRIVATE;
    default:
      return;
  }
};

/**
 * @summary: Below function is used to build an array i.e passed to accounts
             dropdown in header type from GQL Repsonse
 */
export const genrateAccountTypeLinks = (dataToFormat: any) => {
  let sortedAccount =
    dataToFormat[0] &&
    dataToFormat[0].props &&
    dataToFormat[0].props.LoggedInUserDetails &&
    dataToFormat[0].props.LoggedInUserDetails.member &&
    dataToFormat[0].props.LoggedInUserDetails.member.account.slice().sort((a, b) => {
      let accountA = a.accountId.slice(a.accountId.length - 4);
      let accountB = b.accountId.slice(b.accountId.length - 4);
      return accountA - accountB;
    });

  return (sortedAccount || []).map((acc_name) => {
    const { accountId } = acc_name;
    let slicedAccountId = accountId.slice(accountId.length - 4);
    return {
      link_title: `${slicedAccountId} - ${acc_name.package.description}`,
      linkType: "internal",
      url_path: `/home`,
      link_account_id: accountId,
    };
  });
};

/* Below function resolves the privacy url for pre login screen*/
export const privacyUrlBeforeLogin = () => {
  if (
    getBrandName() === BRAND_ULSTER_RI ||
    getBrandName() === BRAND_ULSTER_NI
  ) {
    return "/privacy-ulster";
  }
  return "/privacy";
};

export const getURLParams = (url) => {
  let concatParams: any = [];
  let queryparams = url.split("?")[1];
  let params = queryparams.split("&");
  params.map((item) => {
    let paramsValue = item.split("=");
    return concatParams.push({ [paramsValue[0]]: paramsValue[1] });
  });
  return concatParams;
};

export const addMatomoEvent = (customUrl, customtitle) => {
  var _paq = window["_paq"] || [];
  _paq.push(["setCustomUrl", customUrl]);
  _paq.push(["setDocumentTitle", customtitle]);
  _paq.push(["trackPageView"]);
};

export const addGAEvent = (customUrl) => {
  settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const trackingKeys = response.data.GA_TRACKING_KEY;
        let brand = process.env.REACT_APP_THEME_NAME;
        let gaTrackKey = trackingKeys[`${brand}`];
        ReactGA.initialize(gaTrackKey);
        ReactGA.set({ page: customUrl });
        ReactGA.pageview(customUrl);
    })
    .catch((error) => console.log(error));
}



export const getEnquiryLinkForManageAccount = (brand) => {
  switch (brand) {
    case "rbs":
      return RBS_ENQUIRY_LINK;
    case "natwest":
      return NATWEST_ENQUIRY_LINK;
    case "ulster_ni":
      return ULSTER_NI_ENQUIRY_LINK;
    case "ulster_ri":
      return ULSTER_RI_ENQUIRY_LINK;
    default:
      return "#";
  }
};

export const getBenefitPath = (title) => {
  let splitName = title ? title.split(" ") : "";
  let newPath = "/explore/";
  (splitName || []).map((name) => {
    var lastNameInArray = splitName[splitName.length - 1];
    if (name !== lastNameInArray) {
      newPath = newPath + name.toLowerCase() + "-";
    } else {
      newPath = newPath + name.toLowerCase();
    }
    return newPath;
  });
  return newPath;
};

export const getCorrelationId = () => {
  let correlationId = uuidv4();
  return correlationId;
};

export const allowNumbersOnly = (e) => {
  let regex = new RegExp("^[0-9]+$");
  let str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  if (regex.test(str)) {
    return true;
  }
  e.preventDefault();
  return false;
};

export const scrollToTarget = (targetElement) => {
  if (targetElement) {
    let top = !window.pageYOffset
      ? targetElement.offsetTop - 200
      : targetElement.offsetTop - 102;
    window.scrollTo({
      top: top,
      behavior: "smooth",
    });
  }
};

export const getConstantNameForTheme = () => {
  let loadedBrand = getBrandName();
  if (loadedBrand.search("ulster") !== -1) {
    return ulster;
  } else if (loadedBrand === BRAND_NATWEST) {
    return natwest;
  } else {
    return rbs;
  }
};

export const getRefreshTokenExpiryTime = () => {
  let date = new Date();
  //converted miliseconds to minutes and added buffer time to check token is about to expire after 4 minutes
  let currentTime = Math.floor(date.getTime() / 60000) + 4;

  let token = localStorage.getItem("refresh_token");
  if (token) {
    let { exp } = token && JSON.parse(atob(token.split(".")[1])); // exprition time from (jwt) token
    let tokenExpTime = Math.floor(exp / 60);
    if (tokenExpTime > currentTime) {
      return tokenExpTime - currentTime;
    } else {
      return null;
    }
  } else return null;
};

export const logoutIfTokenExpired = async (history: History) => {
  if (getRefreshTokenExpiryTime()) {
    history.push("/home");
  } else {
    logout();
  }
};

export const getDocumentTitle = (theme) => {
  let displayTitle;
  switch (theme) {
    case "rbs-silver":
      displayTitle = "RBS Membership Services";
      break;
    case "natwest-silver":
      displayTitle = "NatWest Membership Services";
      break;
    case "ulster_ni-gold":
      displayTitle = "Ulster Bank Membership Services";
      break;
    case "ulster_ri-gold":
      displayTitle = "Ulster Bank Membership Services";
      break;
    default:
      displayTitle = "H2ng-Hub";
      break;
  }
  return displayTitle;
};

const getFriendlyPathName = () => {
  let obj = FRIENDLY_PATH_NAME.find((obj) => {
    return obj.pathName === window.location.pathname;
  });
  return obj && obj.friendlyName;
};

const getFriendlyAccountName = () => {
  let accountType = getAccountType();
  let obj = FRIENDLY_ACCOUNT_NAME.find((obj) => {
    return obj.accountType === accountType;
  });
  return obj && obj.friendlyName;
};

const getAfterLoginFriendlyPathName = () => {
  let obj = AFTER_LOGIN_FRIENDLY_PATH_NAME.find((obj) => {
    return obj.pathName === window.location.pathname;
  });
  return obj && obj.friendlyName;
};
const getBenefitNameUsingBenfitId = (selectedAccount, location) => {
  let selectedAccountBenefits =
  (selectedAccount &&
    selectedAccount.props &&
    selectedAccount.props.benefits) ||
  "";
  let benefitName;
  let queryparams = location.search?location.search.split("?")[1]:"";
  if(queryparams!=="" && queryparams.includes("bId")){
    let bId = Number(queryparams.split("bId=")[1]);
    (selectedAccountBenefits || []).map((benefits) => {
      if(bId === benefits.benefitId){
        return benefitName = benefits.benefitName;
      }
    });
    return benefitName;
  }
  else {
    if(location.pathname.includes("partner-jump")){
      queryparams = (location.pathname.split("/").length >= 2) ? location.pathname.split("/")[2] : "";
      let bId = Number(queryparams);
      (selectedAccountBenefits || []).map((benefits) => {
        if(bId === benefits.benefitId){
          return benefitName = benefits.benefitName;
        }
      });
      return benefitName;
    }
    else {
      return null;
    }
  }
}

export const getAdobeTrackingUrl = (selectedAccount,trackingUrl) => {
  if (selectedAccount && selectedAccount.props) {
    let type = selectedAccount.props.type?selectedAccount.props.type:null;
    var packageId = trackingUrl.substring(trackingUrl.lastIndexOf('/')+1);
    return trackingUrl.replace(packageId,"") + type;
  }
  else{
    return trackingUrl.replace(packageId,"") + `/${getBrandName()}`;
  }
}

export const getTrackingUrl = (selectedAccount, location) => {
  let friendlyPathName = getFriendlyPathName();
  let friendlyAccountName = getFriendlyAccountName();
  let afterLoginFriendlyPathName = getAfterLoginFriendlyPathName();
  let benefitName = getBenefitNameUsingBenfitId(selectedAccount, location);
  if (selectedAccount && selectedAccount.props) {
    let { packageId } = selectedAccount.props;
    if(benefitName!==null && benefitName!==undefined && benefitName!==""){
      if(window.location.pathname.includes("partner-jump")){
        let urlPaths = window.location.pathname.split("/");
        let pathName = urlPaths[urlPaths.length-1].replace(/-/g, ' ');
        if(pathName.includes("faq")){
          let appendUrl = pathName.split("?")[0];
          return `m/${friendlyAccountName}/Explore/${benefitName}/${appendUrl}/${packageId}`;
        }
        else if(pathName.includes("Use Benefit")){
          return `m/${friendlyAccountName}/${benefitName}/${pathName}/${packageId}`;
        }
        else {
          let section = (location.pathname.split("/").length >= 3)?location.pathname.split("/") : "";
          if(section.includes("Cinema")){
            return `m/${friendlyAccountName}/Explore/${benefitName}/Cinema/${pathName}/${packageId}`;
          }
          else if(section.includes("MovieRental")){
            return `m/${friendlyAccountName}/Explore/${benefitName}/Movie Rental/${pathName}/${packageId}`;
          }
          else{
            return `m/${friendlyAccountName}/Explore/${benefitName}/${pathName}/${packageId}`;
          }
        }
      }
      else{
        return `m/${friendlyAccountName}/explore/${benefitName}/${packageId}`;
      }
    }
    else if(afterLoginFriendlyPathName){
      return `m/${friendlyAccountName}${afterLoginFriendlyPathName}/${packageId}`;
    }
    else{
      if(window.location.pathname === '' || window.location.pathname === '/'){
        return `m/${friendlyAccountName}/${packageId}`
      }
      return `m/${friendlyAccountName}${window.location.pathname}/${packageId}`;
    }
  } else {
    if (friendlyPathName) {
      return `nm/Generic/${friendlyPathName}`;
    }
  }
  return "";
};


export const useYupValidationResolver = validationSchema =>
  useCallback(
    async data => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        });

        return {
          values,
          errors: {}
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        };
      }
    },
    [validationSchema]
  );
