import { Fragment } from "react";
import { Button } from "components/Button";
import { withRouter } from "react-router-dom";
import styles from "./signUpSuccess.module.scss";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import label from "../../../../src/config/Labels/label.json";
import { BreadCrumbs } from "components/BreadCrumbs";

const SignUpSuccessPage = (props) => {
  let { confirmEmail } = props.history.location.state;

  let { history } = props;
  let { register, regEmail, emailAddress } = label.signUpSuccess;

  const handleRedirection = () => {
    history.push("/home");
  };

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.SignUpSuccessPage__success_message_block}>
        <BreadCrumbs />
        <p className={styles.main_heading}>{register}</p>
        <p>
          <>
            {regEmail}
            {confirmEmail}
            {emailAddress}
          </>
        </p>

        <Button
          text="Benefit Overview"
          handleClick={handleRedirection}
          disabled={false}
        />
      </div>
      <FooterComponent />
    </Fragment>
  );
};

export default withRouter(SignUpSuccessPage);
