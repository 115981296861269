import { BaseModel } from "./BaseModel";

interface urls {
  accessibility: urlModel;
  privacyAndCookiesNotice: urlModel;
  legalInformation: urlModel;
}
interface urlModel {
  url: string;
  label: string;
}

interface IBrandSpecificUrlsModelProps {
  id: string;
  brandSpecificUrls: urls;
}
/**
 * To store the user data coming from the GitHub API.
 * @export
 * @class ThemeName
 * @extends {BaseModel<IDummyModelProps>}
 */
export class BrandSpecificUrlsModel extends BaseModel<
  IBrandSpecificUrlsModelProps
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IBrandSpecificUrlsModelProps) {
    super(props);
  }

  static resource = "BrandSpecificUrls";
}
