import React, { Fragment, useState, useEffect } from "react";
import styles from "./image-banner.module.scss";
import Carousel from "components/AliceCarousel";
import { renderMarkedText } from "components/AbstractedMethods";
import axios from "axios";
import { getSettingConfig } from "services/RestAPI/settingConfig";

interface linkData {
  text: string;
  href: string;
}

interface images {
  imageUrl: string;
  imageAltText: string;
  sequenceNumber: number,
  imageTitle: string;
}

interface componentType {
  type: string;
  className?: string;
  content: string | linkData;
}

interface content {
  imageUrl?: string | images[];
  className?: string;
  heading?: string;
  content?: componentType[];
  imagesFromS3?: boolean;
  typeOfEcode?:string;
}

interface IProps {
  metaData: content;
  id?: string;
}

const ImageBanner: React.FC<IProps> = ({
  metaData: { imageUrl, heading, content, className, imagesFromS3,typeOfEcode },
  id,
}) => {
  const [imageData, setImageData] = useState([]);
  let heading_margin = className;

  useEffect(() => {
    if(imagesFromS3)
      fetchImageDataFromS3(typeOfEcode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderButtonLink = (linkData, componentKey) => {
    return (
      <a className={styles.linkButton} href={linkData.href} key={componentKey}>
        {linkData.text}
      </a>
    );
  };

  const fetchImageDataFromS3 = async (typeOfEcode) => {
    await getSettingConfig().then(async (data) => {
      let headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers' : 'Content-Type',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET'
      }
      let resp = await axios.get(`${data.IMAGE_DATA_FETCHING_FROM_S3_URL}?t=${new Date().getTime()}`, {headers});
      let imageData = [];
      if (typeOfEcode ==="cinema"){
        imageData = resp.data.hubTiles.cinemaTiles.map(data => Object.assign({}, { imageUrl: data.image, imageAltText: data.imageAltText, sequenceNumber:data.imageSequenceNumber, imageTitle: data.imageTitle }))
      } else if (typeOfEcode ==="film"){
        imageData = resp.data.hubTiles.filmTiles.map(data => Object.assign({}, { imageUrl: data.image, imageAltText: data.imageAltText, sequenceNumber:data.imageSequenceNumber }))
      }
      setImageData(imageData);
    });
  }
  const renderImage = () => {
    if(imagesFromS3) {
      return (
        <div className={styles.booking_banner_image_carousel}>
          <Carousel content={imageData} />
        </div>
      );
    } else if (Array.isArray(imageUrl)) {
      return (
        <div className={styles.booking_banner_image_carousel}>
          <Carousel content={imageUrl} />
        </div>
      );
    } else {
      return (
        <div
          className={styles.booking_banner_image}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      );
    }
  };

  const renderContent = () => {
    return (content || []).map((component, componentKey) => {
      return typeMappings[component.type](
        component.content,
        componentKey,
        styles[component.className || ""]
      );
    });
  };

  const typeMappings = {
    marked: renderMarkedText,
    linkButton: renderButtonLink,
  };

  return (
    <Fragment>
      <div id={id} className={styles.component_basepadding}>
        <div className={styles.component_innerpadding}>
          <section className={styles.booking_banner}>
            {renderImage()}
            <div className={styles.booking_desc}>
              {heading ? (
                <h2 className={styles[heading_margin || ""]}>{heading}</h2>
              ) : null}
              {renderContent()}
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default ImageBanner;
