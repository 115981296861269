export const BRAND_RBS = "rbs";
export const RBS_BLACK = "rbs-black";
export const RBS_SILVER = "rbs-silver";
export const RBS_PLATINUM = "rbs-platinum";

export const BRAND_NATWEST = "natwest";
export const NATWEST_BLACK = "natwest-black";
export const NATWEST_SILVER = "natwest-silver";
export const NATWEST_PLATINUM = "natwest-platinum";

export const BRAND_ULSTER_NI = "ulster_ni";
export const ULSTER_NI_PRIVATE = "ulster_ni-private";
export const ULSTER_NI_GOLD = "ulster_ni-gold";

export const BRAND_ULSTER_RI = "ulster_ri";
export const ULSTER_RI_UFIRST = "ulster_ri-ufirst";
export const ULSTER_RI_PRIVATE = "ulster_ri-private";
export const ULSTER_RI_GOLD = "ulster_ri-gold";

export const RBS_ENQUIRY_LINK = "https://locator-rbs.co.uk/";
export const NATWEST_ENQUIRY_LINK = "https://locator.natwest.com/index.html/";
export const ULSTER_NI_ENQUIRY_LINK = "https://ulsterbank.co.uk/search-results/locator.html";
export const ULSTER_RI_ENQUIRY_LINK = "https://locator.ulsterbank.ie/";

export const SpecialCharRegex = /`|~|!|@|#|\$|%|\^|&|\*|\(|\)|\+|=|\[|\{|\]|\}|\||\\|'|<|,|\.|>|\?|\/|""|"|;|-|_|:|\s/;
export const SpaceCharRegex = /\\r|\n|\s{1,}/;
export const cinemaRegex = new RegExp(`(cinema)`);
export const ticketRegex = new RegExp(`(ticket)`);
export const emailFieldRegex = /^(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+([_]|)@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z]{2,6}$/i;

export const DEFAULT_CURRENCY = "\u00A3";

export const benefitIdForCookie = ["1003", "1004", "1007", "1015"];

export const PRODUCT_DETAILS = {
  10001: {
    brandName: "rbs",
    breadCrumbName: "Silver Account Benefits",
    pRefId: "659",
  },
  10002: {
    brandName: "rbs",
    breadCrumbName: "Black Account Benefits",
    pRefId: "660",
  },
  10003: {
    brandName: "rbs",
    breadCrumbName: "Platinum Account Benefits",
    pRefId: "821",
  },
  10004: {
    brandName: "ulster_ni",
    breadCrumbName: "ufirstgold",
    pRefId: "483",
  },
  10005: {
    brandName: "ulster_ni",
    breadCrumbName: "ufirst Private",
    pRefId: "484",
  },
  10006: {
    brandName: "ulster_ri",
    breadCrumbName: "ufirst",
    pRefId: "485",
  },
  10007: {
    brandName: "ulster_ri",
    breadCrumbName: "ufirstgold",
    pRefId: "486",
  },
  10008: {
    brandName: "ulster_ri",
    breadCrumbName: "ufirst Private",
    pRefId: "487",
  },
  10009: {
    brandName: "natwest",
    breadCrumbName: "Silver Account Benefits",
    pRefId: "661",
  },
  10010: {
    brandName: "natwest",
    breadCrumbName: "Black Account Benefits",
    pRefId: "662",
  },
  10011: {
    brandName: "natwest",
    breadCrumbName: "Platinum Account Benefits",
    pRefId: "812",
  },
};
