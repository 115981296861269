import settings from "services/RestAPI/axiosConfig";
import { store, persistor } from "../../redux/store";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { LoginStateModel } from "redux/models/LoginStateModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { setThemeBrandWise, settingURL } from "themeApplicator";
import { PRODUCT_DETAILS } from "config/constants";
import { LOGOUT_CALL } from "services/GraphQL/gqlQueries";
import { graphQlCall } from "services/GraphQL/gqlHelper";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { removeSessionEvents } from "components/IdleSession/IdleSession";
import { TokenTimeout } from "redux/models/TokenTimeout";
import { LoginEmailModel } from "redux/models/LoginEmailModel";

const publicPath = process.env.PUBLIC_URL;

export const getFolderPath = () => {
  let selectedAccount = SelectedAccountModel.getInsatnce("1")
    ? SelectedAccountModel.getInsatnce("1").props
    : null;

  const { id, description } =
    selectedAccount && selectedAccount.productDefinition;
  const accountType = getAccountType();
  const brandName = getBrandName();
  let productBrand = PRODUCT_DETAILS[id] && PRODUCT_DETAILS[id].brandName;

  if (productBrand === brandName && description === accountType) {
    return `${brandName}/${accountType}`;
  }
  return "";
};

export const getExplorePageContent = (pageUrl: string) => {
  let folderPath = getFolderPath();
  let path = publicPath + `/explore-page-content/${folderPath}/${pageUrl}.json`;
  return settings.get(path).then((response) => {
    return response.data;
  });
};

export const getBenefits = () => {
  let folderPath = getFolderPath();
  let path = publicPath + `/explore-page-content/${folderPath}/benefits.json`;
  return settings.get(path).then((response) => {
    return response.data && response.data;
  });
};

export const getLoginPageBenefits = () => {
  let path =
    publicPath + `/login-page-benefits/${getBrandName()}/benefits.json`;
  return settings.get(path).then((response) => {
    return response.data && response.data;
  });
};

export const getThemeName = () => {
  let themeArray = ThemeNameModel.list(store.getState());
  let themeName =
    themeArray &&
    themeArray.length &&
    themeArray[0] &&
    themeArray[0].props.name.split("-");
  return themeName;
};

export const getBrandName = () => {
  let themeName = getThemeName();
  return themeName && themeName[0] ? themeName[0] : "";
};

export const getAccountType = () => {
  let themeName = getThemeName();
  return themeName && themeName[1] ? themeName[1] : "";
};

/*This function just clears the local Storage, and user Entries from Redux; purge is redux-persist's method. */
export const logout = async () => {
  let refreshToken = localStorage.getItem("refresh_token");
  let accessToken = localStorage.getItem("access_token");

  if (refreshToken) {
    const { tenantId } = BrandSpecificDataModel.getInsatnce("1")
      ? BrandSpecificDataModel.getInsatnce("1").props
      : "";

    graphQlCall(
      "mutation",
      LOGOUT_CALL,
      { refreshToken: refreshToken },
      {
        accessToken: accessToken ? accessToken : "",
        refreshToken: refreshToken ? refreshToken : "",
        tenantId: tenantId,
      }
    ).catch((errors) => {
      return errors;
    });

    new UserDetailModel({
      id: "LoggedUser",
      LoggedInUserDetails: "",
    }).$delete("LoggedUser");

    new LoginStateModel({
      id: "LoginDetails",
      LoginData: "",
    }).$delete("LoginDetails");
    persistor.purge();

    new SelectedAccountModel({
      id: "",
      creationDate: "",
      packageId: "",
      PRefId: "",
      type: "",
      benefits: "",
      membershipId: "",
      linkTitle: "",
      memberNumber: "",
      productDefinition: "",
      packageDescription: "",
      cin: "",
      memberId: "",
    }).$delete("1");

    new LoginEmailModel({
      id: "1",
      loginEmail: "",
    }).$delete("1");

    new TokenTimeout({
      id: "1",
      timeOut: 0,
    }).$delete("1");

    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.clear();
    removeSessionEvents();
    persistor.purge();
  }
  settings.get(settingURL).then((response) => {
    setThemeBrandWise(process.env.REACT_APP_THEME_NAME, response.data);
  });
};

export const getBenefitIconObj = (theme) => {
  let path = publicPath + "/explore-page-content/benefitIconMapping.json";
  return settings.get(path).then((response) => {
    return response.data[theme];
  });
};
