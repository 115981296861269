/**
 * @author Swapnali Ingle
 * @param {cardBodyContent} props
 * @param {string} cardBodyContent-value can be string
 */
import * as React from "react";
import styles from "./card.module.scss";

export interface Props {
  cardBodyContent: any;
  classNames?: string;
  cardBodyHeight?: string;
}
const CardBody: React.FC<Props> = (props) => {
  let { cardBodyContent, classNames, cardBodyHeight } = props;
  return (
    <div
      className={`${styles.body} ${classNames}`}
      style={{
        minHeight: cardBodyHeight,
      }}
    >
      {cardBodyContent}
    </div>
  );
};
export default CardBody;
