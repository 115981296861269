import React, { Fragment, useEffect, useState } from "react";
import styles from "./section.module.scss";
import { Icon } from "components/Icon";
import TableComponent from "../Table/TableComponent";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import { renderMarkedText } from "components/AbstractedMethods";
import { Modal } from "components/Modal";
import { ClickAwayListener } from "components/ClickAwayListener";
import { SigninImpMessage } from "components/SigninImpMessage";

let bindCss = classnames.bind(styles);
interface componentType {
  type: string;
  content: textSection | buttonData | ITable | imageSection[];
}
interface buttonData {
  text: string;
  href: string;
  className?: string;
  tootipText?: string;
  buttonIcon: string;
  linkType?: string;
}

interface textSection {
  heading: string;
  content: string;
  className?: string;
  styleNames?: string[];
}

interface imageSection {
  imageUrl: string;
}

interface ITable {
  heading: string;
  columnHeading: string;
  rows: any[];
}
interface content {
  content: componentType[];
  styleNames?: string[];
  noBasePadding?: boolean;
}

interface IProps {
  metaData: content;
  id?: string;
  makeClaimbId?: number;
}
const Section: React.FC<IProps> = ({ metaData, id, makeClaimbId }) => {
  const renderTextSection = ({ heading, content, className }, componentKey) => {
    return (
      <Fragment key={componentKey}>
        {heading ? <h2 className={styles[className]}>{heading}</h2> : null}
        {renderMarkedText(content, componentKey + 1, styles[className])}
      </Fragment>
    );
  };
  const renderTextSubSection = ({ heading,subHeading, content, className }, componentKey) => {
    return (
      <Fragment key={componentKey}>
        {heading ? <h2 className={styles[className]}>{heading}</h2> : null}
        {subHeading ? <h3 className={styles[className]}>{subHeading}</h3> : null}
        {renderMarkedText(content, componentKey + 1, styles[className])}
      </Fragment>
    );
  };
  const [isVisible, setIsVisible] = useState(false);
  const togglePopUp = (option) => {
    if (option === true) {
      setIsVisible(false);
      document.body.style.overflow = "hidden";
    } 
    else {
      document.body.style.overflow = "auto";
      setIsVisible(true);
    }
  };

  const toggleModal = (option) => {
    if(option === "close"){
      document.body.style.overflow = "auto";
      setIsVisible(false);
    }
  };


  useEffect(() => {
    let atag = document.getElementsByTagName('a');
    let i = 0;
    for(;i<atag.length;i++){
      if(atag[i].innerText === "endorsement") {
        atag[i].addEventListener('click', (e) => {e.preventDefault();togglePopUp(isVisible);});
      }
      if(atag[i].innerText === "FAQs") {
        atag[i].addEventListener('click', (e) => {e.preventDefault();togglePopUp(isVisible);});
      };
    }
  });

  const getClasses = () => {
    let { styleNames } = metaData;
    let classes = "";
    if (styleNames) {
      styleNames.forEach((style) => {
        classes += ` ${styles[style]} `;
      });
      return classes;
    }
    return "";
  };

  const renderContent = () => {
    try {
      return (metaData.content || []).map((component, componentKey) => {
        return typeMappings[component.type](component.content, componentKey);
      });
    } catch (e) {
      return;
    }
  };

  const renderButton = (
    { text, href, className, buttonIcon, linkType, tooltipText },
    componentKey
  ) => {
    let buttonContent = (
      <div
        title={tooltipText ? tooltipText : ""}
        className={bindCss({ button: true, [className]: true })}
      >
        {text}
        {buttonIcon ? <Icon iconName={buttonIcon} /> : null}
      </div>
    );

    return (
      <Fragment key={componentKey}>
        {linkType === "internal" ? (
          makeClaimbId ? (
            <Link to={`${href}?bId=${makeClaimbId}`}>{buttonContent}</Link>
          ) : (
            <Link to={href}>{buttonContent}</Link>
          )
        ) : (
          <a href={href}>{buttonContent}</a>
        )}
      </Fragment>
    );
  };

  const renderImages = (content, componentKey) => {
    return (
      <div
        className={styles.image_container}
        key={componentKey}
        data-test="section_image_data"
      >
        {(content || []).map((image, innerComponentKey) => {
          return (
            <div
              className={styles.image}
              key={componentKey + innerComponentKey}
            >
              {renderMarkedText(image.imageUrl, innerComponentKey)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTable = (tableDescription, componentKey) => {
    return (
      <div data-test="section_table_data" key={componentKey}>
        <TableComponent metadata={tableDescription} />
      </div>
    );
  };

  const typeMappings = {
    marked: renderTextSection,
    markedSub: renderTextSubSection,
    linkButton: renderButton,
    images: renderImages,
    table: renderTable,
  };

  return (
    <Fragment>
      <div
        className={bindCss({
          component_basepadding: true,
          no_base_padding: metaData.noBasePadding,
        })}
        id={id}
        data-test="section_component"
      >
        <div
          className={bindCss({
            component_innerpadding: true,
            [getClasses()]: true,
          })}
        >
          <Modal
            title="Coronavirus (Covid-19) and any other pandemics"
            isVisible={isVisible}
            body={
              <ClickAwayListener
                clickAway={() => {
                  setIsVisible(false);
                  document.body.style.overflow = "auto";
                }}
              >
                <SigninImpMessage toggleModal={toggleModal} />{" "}
              </ClickAwayListener>
            }
            className={styles.modal_styles}
          />
          {renderContent()}
        </div>
      </div>
    </Fragment>
  );
};

export default Section;
