import { Fragment, Component } from "react";
import { Route, Switch, withRouter, match, Redirect } from "react-router-dom";
import Account from "./Account";
import Explore from "./Explore";
import Home from "./Home";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import styles from "./wrapper.module.scss";
import { IdleSessionModel } from "redux/models/IdleSessionModel";
import { connect } from "react-redux";
import { History, Location } from "history";
import { Modal } from "components/Modal";
import {
  setSessionEvents,
  removeSessionEvents,
} from "components/IdleSession/IdleSession";
import { Icon } from "components/Icon";
import { IdleSessionTimeout } from "redux/models/IdleSessionTimeout";
import { Privacy } from "./privacy/privacy_rbs_natwest";
import { PrivacyUlsterRI } from "./privacy/privacy_ulster_ri";
import { logout, getBrandName } from "services/RestAPI/exploreCalls";
import { persistor } from "redux/store";
import {
  AuthenticationCheck,
  privacyUrlBeforeLogin,
  getRefreshTokenExpiryTime,
} from "utlis/generalUtils";
import {
  BRAND_RBS,
  BRAND_NATWEST,
  BRAND_ULSTER_RI,
  BRAND_ULSTER_NI,
} from "config/constants";
import { TokenTimeout } from "redux/models/TokenTimeout";
import { EventBookingSuccess } from "./EventBookingSuccess";
import { PrimaryButton } from "components/PrimaryButton";

interface IProps {
  location: Location;
  history: History;
  match: match;
  idleSession: any;
  idleSessionTimeout: any;
}

const routes = [
  {
    path: "/account",
    exact: false,
    component: Account,
  },
  {
    path: "/explore/:pageUrl",
    exact: true,
    component: Explore,
  },
  {
    path: "/explore/:pageUrl/:nestedPageUrl",
    exact: true,
    component: Explore,
  },
  {
    path: "/event-booking-success",
    exact: false,
    component: EventBookingSuccess,
  },

  {
    path: "/home",
    exact: false,
    component: Home,
  },
  {
    path: `/privacy`,
    exact: true,
    component: Privacy,
  },
  {
    path: `/privacy-ulster`,
    exact: true,
    component: PrivacyUlsterRI,
  },
];
class HeaderFooterWrapper extends Component<IProps> {
  loadComponent = () => {
    let brand = getBrandName();
    if (brand === BRAND_RBS || brand === BRAND_NATWEST) {
      return Privacy;
    } else if (brand === BRAND_ULSTER_RI || brand === BRAND_ULSTER_NI) {
      return PrivacyUlsterRI;
    } else {
      return;
    }
  };
  renderRoutes = () => {
    return routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      );
    });
  };

  handleClickEvent = (props: string) => {
    switch (props) {
      case "continue":
        let tokenExpiryTime = getRefreshTokenExpiryTime();
        if (tokenExpiryTime) {
          new TokenTimeout({
            id: "1",
            timeOut: tokenExpiryTime,
          }).$save();
          setSessionEvents();
        }
        new IdleSessionModel({
          id: "1",
          sessionStatus: "active",
        }).$update("1");
        break;
      case "logout":
        removeSessionEvents();
        new IdleSessionModel({
          id: "1",
          sessionStatus: "loggedIn",
        }).$delete("1");
        this.props.history.push("/login");
        persistor.flush().then(() => {
          logout();
        });
        break;
    }
  };

  preLogoutModal = (showModal, time) => {
    return (
      <Modal
        title={
          <div className={styles.modal_title}>
            <span className={styles.modal_title__icon_color}>
              <Icon iconName="icon-exclamation-circle" />
            </span>
            <span className={styles.modal_title__logout_text}>
              You are about to be logged out
            </span>
          </div>
        }
        body={
          <p className={styles.modal_title__logout_body_text}>
            {`We haven’t seen you in the last ${
              time - 1
            } minutes, so for your security we will log you out of your internet session in the next few seconds unless you wish to continue using your Membership Services?`}
          </p>
        }
        footer={
          <Fragment>
            <PrimaryButton
              text={"Log Out Now"}
              disabled={false}
              handleClick={() => this.handleClickEvent("logout")}
              classNames={styles.secondary_button}
            />
            <PrimaryButton
              text={"Continue with Membership Services"}
              disabled={false}
              classNames={styles.primary_button}
              handleClick={() => this.handleClickEvent("continue")}
            />
          </Fragment>
        }
        isVisible={showModal}
        className={styles.modalCustomClass}
        contentClassName={styles.content}
      />
    );
  };

  loggedOutModal = (showModal, time) => {
    return (
      <Modal
        title={
          <div className={styles.modal_title}>
            <span className={styles.modal_title__icon_color}>
              <Icon iconName="icon-exclamation-circle" />
            </span>
            <span className={styles.modal_title__logout_text}>
              You have been logged out
            </span>
          </div>
        }
        body={
          <p className={styles.modal_title__logout_body_text}>
            {`Because we haven't seen you in the last ${time} minutes, for security we have logged you out of your internet session.`}
          </p>
        }
        footer={
          <PrimaryButton
            text={"Log in to Membership Services"}
            disabled={false}
            classNames={styles.primary_button}
            handleClick={() => this.handleClickEvent("logout")}
          />
        }
        isVisible={showModal}
        className={styles.modalCustomClass}
        contentClassName={styles.content}
      />
    );
  };

  tokenExpiredModal = (showModal) => {
    return (
      <Modal
        title={
          <div className={styles.modal_title}>
            <span className={styles.modal_title__icon_color}>
              <Icon iconName="icon-exclamation-circle" />
            </span>
            <span className={styles.modal_title__logout_text}>
              You have been logged out
            </span>
          </div>
        }
        body={
          <p className={styles.modal_title__logout_body_text}>
            {`We have logged you out of your internet session.`}
          </p>
        }
        footer={
          <PrimaryButton
            text={"Log in to Membership Services"}
            disabled={false}
            classNames={styles.primary_button}
            handleClick={() => this.handleClickEvent("logout")}
          />
        }
        isVisible={showModal}
        className={styles.modalCustomClass}
        contentClassName={styles.content}
      />
    );
  };

  showIdleSessionPopup = () => {
    const { idleSession, idleSessionTimeout } = this.props;
    let sessionStatus =
      idleSession &&
      idleSession.length > 0 &&
      idleSession[0].props.sessionStatus;
    let isShowModal =
      sessionStatus === "inactive" ||
      sessionStatus === "loggedOut" ||
      sessionStatus === "tokenExpired"
        ? true
        : false;
    let time =
      idleSessionTimeout &&
      idleSessionTimeout.length > 0 &&
      idleSessionTimeout[0].props.time;
    let popup;
    switch (sessionStatus) {
      case "inactive":
        popup = this.preLogoutModal(isShowModal, time);
        break;
      case "loggedOut":
        popup = this.loggedOutModal(isShowModal, time);
        removeSessionEvents();
        break;
      case "tokenExpired":
        popup = this.tokenExpiredModal(isShowModal);
        removeSessionEvents();
        break;
    }
    return popup;
  };

  render() {
    return (
      <Fragment>
        {this.showIdleSessionPopup()}
        <HeaderContainer showStickyHeader={true} />
        <div className={styles.content_wrapper}>
          <Switch>
            <Route
              path={privacyUrlBeforeLogin()}
              component={this.loadComponent()}
              exact
            />
            {AuthenticationCheck() ? (
              this.renderRoutes()
            ) : (
              <Redirect to="/login" />
            )}
            <Redirect to={`/login`} />
          </Switch>
        </div>
        <FooterComponent />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    idleSession: IdleSessionModel.list(),
    idleSessionTimeout: IdleSessionTimeout.list(),
  };
};
export default connect(mapStateToProps)(withRouter(HeaderFooterWrapper));
