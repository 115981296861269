import React, {  useEffect } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import styles from "./privacy.module.scss";
import { MarkedComponent } from "components/MarkedComponent";
import { getBrandName } from "services/RestAPI/exploreCalls";
import { CookieSettingModel } from "redux/models/CookieSettings";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import {Helmet} from "react-helmet";

const PRIVACY_POLICY_RBS = "privacyPolicyRbs"; // json file name for i18n next.
const PRIVACY_POLICY_NATWEST = "privacyPolicyNatwest"; // json file name for i18n next.
interface IProps {
  themeName: [];
  cookieSetting: CookieSettingModel[];
}
const Privacy: React.FC<IProps> = (props) => {
 
  useEffect(() => {
    populateBreadCrumbs();
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Privacy Policy",
      },
    ];
    new BreadCrumbModel({
      id: "1",
      breadCrumbData: breadCrumbData,
    }).$save();
  };

  const selectNamespace = () => {
    let loadedBrand = getBrandName();
    return loadedBrand === "rbs" ? PRIVACY_POLICY_RBS : PRIVACY_POLICY_NATWEST;
  };

  const { t } = useTranslation(selectNamespace());

  const getTranslations = (key) => {
    return t(`${key}`, { returnObjects: true });
  };



  return (
    <>
      <Helmet>
        <script>
          {`
            //SHOULD BE USED ONLY ON COOKIE POLICY. TRIGGER FUNCTION BELOW TO REMOVE DUPLICATE CATEGORIES
            function clearDup() {
              var sec = document.getElementById("ot-sdk-cookie-policy");
              var tally = [];
              for (var i = sec.length - 1; i >= 0; i--) {
                if (tally[sec[i].firstChild.innerText] === undefined){
                  tally[sec[i].firstChild.innerText] = 1;
                } else {
                  //console.log(i,sec[i].firstChild.innerText);
                  sec[i].remove();
                  //return true; 
                }
              } 
              //return false;
            }
          `}
        </script>
      </Helmet>
      <div className={styles.privacyPage}>
      <div className={styles.privacyPage__wrapper}>
        <div className={styles.content_wrapper}>
          <div className={styles.privacyPage__row}>
            <div className={styles.privacyPage__title}>
              {getTranslations(`pageHeading.sectionTitle`)}
            </div>
            <div className={styles.privacy__para}>
              {getTranslations(`pageHeading.content`).map((item, idx) => {
                return (
                  <p key={`section_${idx}`}>
                    <MarkedComponent rawString={item} />
                  </p>
                );
              })}
            </div>
          </div>
          {getTranslations("TextContent").map((item, idx) => {
            return (
              <div className={styles.privacyPage__row} key={`section_${idx}`}>
                <div className={styles.privacy__sub_heading}>
                  {item.sectionTitle}
                </div>
                <div className={styles.privacy__para} key={`para_${idx}`}>
                  <MarkedComponent
                    rawString={getTranslations(`${item.content}`)}
                  />
                </div>
              </div>
            );
          })}

          {/* ============== Accept Changes BLock =========== */}
          <div className={styles.privacyPage__row}>
            <div className={styles.privacyPage__manage_setting}>
              <button id="ot-sdk-btn" className="ot-sdk-show-settings" ref={(el) => {if (el) { el.style.setProperty('width', '174px', 'important'); el.style.setProperty('color', '#fff', 'important');}}} style={{borderRadius:'6px', padding:'0.8em 1em', height: '40px',fontSize: '0.9em'}}></button>
            </div>
          </div>
          <div className={styles.footNote}>
                If you do not change your preference here, your current preference, (as indicated) will continue to apply
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    themeName: ThemeNameModel.list(),
    cookieSetting: CookieSettingModel.list(),
  };
};

export default withTranslation("")(connect(mapStateToProps)(Privacy));
