import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  HttpLink,
  from,
} from "@apollo/client";
import { LoaderStateModel } from "redux/models/LoaderModel";
import { BACKEND_API_ERROR, NETWORK_TIMEOUT } from "config/errorCodeConstants";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import { getCorrelationId } from "utlis/generalUtils";

export function isEmpty(obj: Object) {
  if (Object.keys(obj).length <= 0 || !obj) {
    return true;
  }
  return false;
}

export const configApolloClient = async (headersToPass, authorizedAPI) => {
  let apolloClient;

  await getSettingConfig().then((data) => {
    const correlationIdAlias = "x-correlation-id";
    const tenantIdAlias = "tenant-id";
    const accessTokenAlias = "access-token";
    const refreshTokenAlias = "refresh-token";
    const httpLink = new HttpLink({ uri: data.REACT_APP_SERVER_URL });

    if (!isEmpty(headersToPass)) {
      const middlewareAuthLink = new ApolloLink((operation, forward) => {
        const correlationId = getCorrelationId();
        const tenantId = headersToPass!.tenantId;

        //access and refresh tokens passed in query
        const accessToken = headersToPass!.accessToken
          ? headersToPass!.accessToken
          : "";
        const refreshToken = headersToPass!.refreshToken
          ? headersToPass!.refreshToken
          : "";

        //access and refresh tokens from local storage
        const access_token = localStorage.getItem("access_token");
        const refresh_token = localStorage.getItem("refresh_token");

        let accessTokenToPass = access_token ? access_token : accessToken;
        let refreshTokenToPass = refresh_token ? refresh_token : refreshToken;
        let requestHeaders;

        authorizedAPI === "true"
          ? (requestHeaders = {
              [accessTokenAlias]: accessTokenToPass,
              [refreshTokenAlias]: refreshTokenToPass,
              [correlationIdAlias]: correlationId,
              [tenantIdAlias]: tenantId,
            })
          : (requestHeaders = {
              [correlationIdAlias]: correlationId,
              [tenantIdAlias]: tenantId,
            });

        operation.setContext({
          headers: requestHeaders,
        });
        return forward(operation);
      });

      const afterwareLink = new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
          const context = operation.getContext();
          const {
            response: { headers },
          } = context;

          if (headers) {
            const access_token = headers.get("access-token");
            const refresh_token = headers.get("refresh-token");
            if (access_token) {
              localStorage.setItem("access_token", access_token);
            }
            if (refresh_token) {
              localStorage.setItem("refresh_token", refresh_token);
            }
          }
          return response;
        });
      });

      apolloClient = new ApolloClient({
        link: from([middlewareAuthLink, afterwareLink, httpLink]),
        cache: new InMemoryCache(),
      });
    } else {
      apolloClient = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
      });
    }
  });
  return apolloClient;
};
/**
 * @summary The below function is just to make graphql Query and mutation
 * @param Type: graphql operation (query or mutation)
 * @param Query:actual gql Query consisting response field
 * @param variable: the variable is object i.e passed to query
 * @param header: Used to pass headers like access_token and refresh_token
 */
export const graphQlCall = async (
  type: string,
  query: any,
  variable?: {},
  headers?: {},
  authorized?: string
) => {
  let client;
  let authorizedAPI = authorized ? authorized : "true";

  headers
    ? await configApolloClient(headers, authorizedAPI).then((data) => {
        client = data;
      })
    : await configApolloClient({}, authorizedAPI).then((data) => {
        client = data;
      });

  switch (type) {
    case "query":
      new LoaderStateModel({
        id: "1",
        isLoading: true,
      }).$save();

      let queryPromise = client.query({
        query: query,
        variables: { ...variable },
      });
      queryPromise.then(() => {
        new LoaderStateModel({
          id: "1",
          isLoading: false,
        }).$update();
      });
      return queryPromise;
    case "mutation":
      new LoaderStateModel({
        id: "1",
        isLoading: true,
      }).$save();

      let mutationPromise = client.mutate({
        mutation: query,
        variables: { ...variable },
      });
      mutationPromise.then(() => {
        new LoaderStateModel({
          id: "1",
          isLoading: false,
        }).$update();
      });
      return mutationPromise;
    default:
      break;
  }
};
/*Below function processes the error occured  during Graphql calls */
export const onGqlError = (error, customError?) => {
  new LoaderStateModel({
    id: "1",
    isLoading: false,
  }).$update();
  let errorCount;
  if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    errorCount = error.graphQLErrors.map((item) => {
      return item.extensions ? item.extensions.error_code : [BACKEND_API_ERROR]; //To indicate Unprocessable entity (ref: https://www.bennadel.com/blog/2434-http-status-codes-for-invalid-data-400-vs-422.htm)
    });
  } else {
    customError
      ? (errorCount = [customError])
      : (errorCount = [NETWORK_TIMEOUT]); //Its Used for Network Timeout(ref: https://en.wikipedia.org/wiki/List_of_HTTP_status_codes);
  }
  return errorCount;
};
