import { BaseModel } from "./BaseModel";

interface ILoginStateModelProps {
  id: string;
  LoginData: any;
}
/**
 * To store the user data coming from the GitHub API.
 * @export
 * @class LoginStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class LoginStateModel extends BaseModel<ILoginStateModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ILoginStateModelProps) {
    super(props);
  }

  static resource = "ILoginState";
}
