import { BaseModel } from "./BaseModel";

interface IBrandSpecificDataModelProps {
  id: string;
  tenantId: string;
  brandId: string;
  brandName: string;
}
/**
 * To store the TenantId, BrandId, BrandName
 * @export
 * @class BrandSpecificDataModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class BrandSpecificDataModel extends BaseModel<
  IBrandSpecificDataModelProps
> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IBrandSpecificDataModelProps) {
    super(props);
  }

  static resource = "BrandSpecificData";
}
