import React from "react";
import styles from "./errorBox.module.scss";
import { Icon } from "components/Icon";
import classnames from "classnames";
interface ErrorProps {
  errorHeader: string;
  errors?: string[];
  className?: any;
  error?: string;
}

const ErrorBox: React.FC<ErrorProps> = (props) => {
  const { errors, errorHeader, className, error } = props;
  return (
    <div className={styles.errorBox_container}>
      <div className={styles.errorBox_header}>
        <div>
          <Icon iconName="icon-info-circle" />
        </div>

        <div>{errorHeader}</div>
      </div>
      <div className={classnames(styles.errorBox_body, className)}>
        {errors && errors.length ? (
          <ul>
            {errors.map((errorItem, i) => (
              <li key={i}>{errorItem}</li>
            ))}
          </ul>
        ) : error ? (
          <ul>
            <li>{error}</li>
          </ul>
        ) : null}
      </div>
    </div>
  );
};

export default ErrorBox;
