import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "scroll-behavior-polyfill";
import { createRoot } from 'react-dom/client';
import { Suspense, Fragment } from "react";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import settings from "./services/RestAPI/axiosConfig";
import queryString from "query-string";
import { IdleSessionTimeout } from "redux/models/IdleSessionTimeout";
import { setThemeBrandWise } from "themeApplicator";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import { CaptchaModel } from "redux/models/CaptchaModel";
import { graphQlCall } from "services/GraphQL/gqlHelper";
import { GET_CAPTCHA_KEY } from "services/GraphQL/gqlQueries";
import {
  AuthenticationCheck,
  getRefreshTokenExpiryTime,
  getDocumentTitle,
} from "utlis/generalUtils";
import { getBrandName } from "services/RestAPI/exploreCalls";
import { LoaderStateModel } from "redux/models/LoaderModel";
import { setSessionEvents } from "components/IdleSession/IdleSession";
import { TokenTimeout } from "redux/models/TokenTimeout";
import { ErrorBoundary } from "components/ErrorBoundary";
import EUCookiesAnalytics from "components/EUCookiesAnalytics";

const setIdleSessionTimeout = (data) => {
  let tokenExpiryTime = getRefreshTokenExpiryTime();

  if (AuthenticationCheck()) {
    if (tokenExpiryTime) {
      new TokenTimeout({
        id: "1",
        timeOut: tokenExpiryTime,
      }).$update();
      setSessionEvents();
    }
  }
  new IdleSessionTimeout({
    id: "1",
    time: data.TIMEOUT,
    interval: data.INTERVAL,
  }).$save();
};

const setCaptchaSiteKey = (tenantId) => {
  graphQlCall(
    `query`,
    GET_CAPTCHA_KEY,
    {},
    {
      tenantId: tenantId[getBrandName().toUpperCase()],
    },
    "false"
  )
    .then((resp) => {
      let keyAlias = `captcha-siteKey`;
      let captchaSiteKey = resp.data.loadContext.configurations[keyAlias];
      new CaptchaModel({
        id: "1",
        captchaSiteKey: captchaSiteKey,
      }).$save();
    })
    .catch((errors) => {
      let errorKey = "random-nonempty-string"; //the key for captcha component cannot be empty so assigning random string in case context api failed.
      new LoaderStateModel({ id: "1", isLoading: false }).$update("1");
      new CaptchaModel({
        id: "1",
        captchaSiteKey: errorKey,
      }).$save();
      return errors;
    });
};


const settingURL = process.env.PUBLIC_URL + "/setting.json";

let displayTitle = getDocumentTitle(process.env.REACT_APP_THEME_NAME);
document.title = displayTitle;

settings.get(settingURL).then((response) => {
  const { name: reduxTheme } = ThemeNameModel.getInsatnce("1")
    ? ThemeNameModel.getInsatnce("1").props
    : "";
  let themeName = queryString.parse(window.location.search);
  const {
    BRAND_SPECIFIC_TENANT_ID,
    IDLE_SESSION,
  } = response.data;
  if (reduxTheme) {
    setThemeBrandWise(reduxTheme, response.data);
  } else if (themeName.brand) {
    setThemeBrandWise(themeName.brand, response.data);
  } else {
    setThemeBrandWise(process.env.REACT_APP_THEME_NAME, response.data);
  }
  setIdleSessionTimeout(IDLE_SESSION);
  setCaptchaSiteKey(BRAND_SPECIFIC_TENANT_ID);
});

const container = document.getElementById('root')!;
const root = createRoot(container); 
root.render(
  <Fragment>
    <ErrorBoundary>
      <EUCookiesAnalytics />
    </ErrorBoundary>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<div>Loading</div>}>
        <App />
      </Suspense>
    </I18nextProvider>
  </Fragment>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
