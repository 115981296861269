import { Fragment, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { persistor } from "./redux/store";
import AppIndex from "./routes";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { PersistGate } from "redux-persist/integration/react";
import { CookieSettingModel } from "redux/models/CookieSettings";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import AdobeAnalytics from "components/AdobeAnalytics";
import { ErrorBoundary } from "components/ErrorBoundary";
import MatomoAnalytics from "components/MatomoAnalytics";
import GoogleAnalytics from "components/GoogleAnalytics";

const App: React.FC = () => {
  const cache = new InMemoryCache();
  const [gqlClient, setGqlClient] = useState(
    new ApolloClient({
      uri: "",
      cache,
    })
  );

  const initializeGqlClient = async () => {
    let settingConfig = await getSettingConfig();
    setGqlClient(
      new ApolloClient({
        uri: settingConfig.REACT_APP_SERVER_URL,
        cache,
      })
    );
  };

  useEffect(() => {
    initializeGqlClient();
    new CookieSettingModel({
      id: "1",
      cookieOptIn: true,
    }).$save();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ApolloProvider client={gqlClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Fragment>
              <ErrorBoundary>
                <GoogleAnalytics />
                <MatomoAnalytics />
                <AdobeAnalytics />
              </ErrorBoundary>
              <AppIndex data-test="app_component" />
            </Fragment>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ApolloProvider>
  );
};

export default App;
