import React, { useEffect } from "react";
import { History } from "history";
import styles from "./changePasswordSuccess.module.scss";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { PrimaryButton } from "components/PrimaryButton";

interface IProps {
  history: History;
}

const ChangePasswordSuccessPage: React.FC<IProps> = (props) => {
  const onButtonClick = () => {
    return props.history.push("/home");
  };

  useEffect(() => {
    populateBreadCrumbs();
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Change Password",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  return (
    <div className={styles.main_container}>
      <p className={styles.changePassword_title}>Change your Password</p>
      <p className={styles.content_text}>Your password has now been changed.</p>
      <div className={styles.button_container}>
        <PrimaryButton text="Home" handleClick={onButtonClick} disabled={false} />
      </div>
    </div>
  );
};

export default ChangePasswordSuccessPage;
