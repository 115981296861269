import { BaseModel } from "./BaseModel";

interface IAccountModelProps {
  id: string;
  AccountData: any;
}
/**
 * To store the user data coming from the GraphQL.
 * @export
 * @class AccountStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class AccountStateModel extends BaseModel<IAccountModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IAccountModelProps) {
    super(props);
  }

  static resource = "IAccountState";
}
