import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import EventBooking from "./EventBooking";
import { connect } from "react-redux";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { EVENT_BOOKING_QUERY } from "services/GraphQL/gqlQueries";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { LoginEmailModel } from "redux/models/LoginEmailModel";

interface IProps {
  userDetails: UserDetailModel[];
  history: History;
  formData: any;
  tenantDetails: BrandSpecificDataModel;
  selectedAccount: SelectedAccountModel;
  emailDetails: LoginEmailModel;
}

const EventBookingContainer: React.FC<IProps> = (props: IProps) => {
  const [backendErrors, setError] = useState([]);
  const {
    history,
    formData,
    userDetails,
    tenantDetails,
    selectedAccount: {
      props: { membershipId, memberNumber, packageId },
    },
    emailDetails,
  } = props;

  const { tenantId } = tenantDetails && tenantDetails.props;
  const { loginEmail } = emailDetails && emailDetails.props;

  const getDefaultValues = (key) => {
    let userInfo =
      userDetails[0] && userDetails[0].props.LoggedInUserDetails.member;
    return userInfo[key] && userInfo[key];
  };

  const getRequiredDateFormat = (date) => {
    let splitDate = date ? date.split("/") : "";
    let formattedDate =
      splitDate.length === 3
        ? splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0]
        : null;
    return formattedDate;
  };

  const submitForm = async (values) => {
    const { id } =
      userDetails[0] && userDetails[0].props.LoggedInUserDetails.member;

    const {
      FirstName,
      LastName,
      HouseNumberName,
      PostCode,
      HomeTelephoneNumber,
      DaytimeTelephoneNumber,
      MobilePhoneNumber,
      NameOfEvent,
      DateOfEventFirst,
      DateOfEventSecond,
      TimeOfEventChoiceHours1,
      TimeOfEventChoiceMinutes1,
      TimeOfEventChoiceHours2,
      TimeOfEventChoiceMinutes2,
      NameOfVenue,
      CityOfVenue,
      NumberOfTickets,
      SeatingChoiceFirst,
      SeatingChoiceSecond,
      Concessions,
      SpecialRequirements,
    } = values;
    let formData = {
      firstName: FirstName,
      lastName: LastName,
      houseName: HouseNumberName,
      postCode: PostCode,
      telephoneNumber: HomeTelephoneNumber,
      dayTimeTelephoneNumber: DaytimeTelephoneNumber,
      mobileNumber: MobilePhoneNumber,
      eventName: NameOfEvent,
      dateOfEventChoice1: getRequiredDateFormat(DateOfEventFirst),
      dateOfEventChoice2: getRequiredDateFormat(DateOfEventSecond),
      timeOfEventChoiceHours1: TimeOfEventChoiceHours1,
      timeOfEventChoiceMinutes1: TimeOfEventChoiceMinutes1,
      timeOfEventChoiceHours2: TimeOfEventChoiceHours2,
      timeOfEventChoiceMinutes2: TimeOfEventChoiceMinutes2,
      venueName: NameOfVenue,
      cityOfVenue: CityOfVenue,
      ticketCount: NumberOfTickets,
      seatingChoice1: SeatingChoiceFirst,
      seatingChoice2: SeatingChoiceSecond,
      concessions: Concessions,
      specialRequirement: SpecialRequirements,
      memberId: id,
      membershipId: props.selectedAccount && membershipId,
      emailId: loginEmail.toLowerCase(),
      memberNumber: props.selectedAccount && memberNumber,
      packageId: props.selectedAccount && packageId,
    };

    return await graphQlCall(
      "mutation",
      EVENT_BOOKING_QUERY,
      {
        ...formData,
      },
      {
        tenantId: tenantId,
      }
    )
      .then((data) => {
        const response = data.data["eventBooking"];
        if (response.returnDetails.code === "200") {
          history.push("/event-booking-success");
        } else {
          history.push("/event-booking");
        }
      })
      .catch((error) => {
        setError(onGqlError(error));
      });
  };

  return (
    <EventBooking
      getDefaultValues={getDefaultValues}
      formData={formData}
      submitForm={submitForm}
      errorSet={backendErrors}
    ></EventBooking>
  );
};

const mapStateToProps = () => {
  let userDetails = UserDetailModel.list();
  let tenantDetails = BrandSpecificDataModel.getInsatnce("1");
  let selectedAccount = SelectedAccountModel.getInsatnce("1");
  let emailDetails = LoginEmailModel.getInsatnce("1");
  return {
    userDetails,
    tenantDetails,
    selectedAccount,
    emailDetails,
  };
};

export default connect(mapStateToProps)(withRouter(EventBookingContainer));
