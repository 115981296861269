import React, { Fragment } from "react";
import styles from "./additional-info.module.scss";
import { Icon } from "components/Icon";
import { renderMarkedText } from "components/AbstractedMethods";
import { addMatomoEvent } from "utlis/generalUtils";

interface fileData {
  title: string;
  fileType: string;
  fileSize: string;
  fileUrl: string;
}

interface IItem {
  type: string;
  content: (fileData | string)[] | any;
  className?: string;
}

interface content {
  heading?: string;
  type: string;
  className: string;
  content?: IItem[];
}

interface IProps {
  metaData: content;
  id?: string;
}
const AdditionalInfo: React.FC<IProps> = ({
  metaData: { heading, content },
  id,
}) => {
  const renderTextSection = (content, componentKey, className) => {
    return (
      <div className={styles.additional_info_subcontent} key={componentKey}>
        {renderMarkedText(content, componentKey + 1, styles[className])}
      </div>
    );
  };
  const renderFile = (content, componentKey) => {
    return (
      <div className={styles.file_wrapper}>
        {(content || []).map((fileObj, index) => {
          return (
            <Fragment key={index}>
              <div className={styles.file_container} data-test="file-container">
                <div className={styles.file_icon}>
                  <Icon iconName="icon-doc" />
                </div>
                <a href={fileObj.fileUrl} className={styles.file_info} download onClick={() => addMatomoEvent(fileObj.matomoURL,fileObj.matomoURL)}> 
                {/*  add onclick matomo event in above a tag */}
                  <p>{fileObj.title} </p>
                  {fileObj?.fileSize ? <p>({`${fileObj.fileType},${fileObj?.fileSize}`})</p> : fileObj?.fileType ? <p>({`${fileObj.fileType}`})</p> : ''
                  }
                </a>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  };
  const renderContent = () => {
    return (content || []).map((component, componentKey) => {
      return (
        <Fragment key={componentKey}>
          {typeMappings[component.type](
            component.content,
            componentKey,
            component.className
          )}
        </Fragment>
      );
    });
  };
  const typeMappings = {
    marked: renderTextSection,
    file: renderFile,
  };
  return (
    <Fragment>
      <div
        className={styles.component_basepadding}
        id={id}
        data-test="additional-info"
      >
        <div className={styles.component_innerpadding}>
          <div className={styles.addingTop}>
            <h2>{heading}</h2>
          </div>
          {renderContent()}
        </div>
      </div>
    </Fragment>
  );
};

export default AdditionalInfo;
