import { withRouter } from "react-router-dom";
import styles from "./confirmEmail.module.scss";
import content from "config/Labels/label.json";
import classNames from "classnames";
import { connect } from "react-redux";
import { CHANGE_EMAIL } from "services/GraphQL/gqlQueries";
import { graphQlCall } from "services/GraphQL/gqlHelper";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { LoginEmailModel } from "redux/models/LoginEmailModel";
import { PrimaryButton } from "components/PrimaryButton";

const ConfirmEmailPage = (props) => {
  const { newEmailValue } = props.history.location.state;
  const {
    confirmNewEmail: {
    confirmEmailHeader,
    confirmEmailAddress,
    confirmEmailNote,
    }
  } = content;

  const changeEmailData = async () => {
    const {
      userDetails: {
        props: {
          LoggedInUserDetails: {
            member: { id, firstName, lastName },
          },
        },
      },
      tenantDetails: {
        props: { tenantId },
      },
      selectedAccount: {
        props: { membershipId },
      },
      history,
    } = props;

    return await graphQlCall(
      "mutation",
      CHANGE_EMAIL,
      {
        email: newEmailValue.toLowerCase(),
        memberId: props.userDetails && id,
        membershipId: props.selectedAccount && membershipId,
        firstName: props.userDetails && firstName,
        lastName: props.userDetails && lastName,
      },
      {
        tenantId: tenantId,
      }
    )
      .then((data) => {
        const response = data.data.changeEmail;
        if (response.code === 200) {
          new LoginEmailModel({
            id: "1",
            loginEmail: newEmailValue,
          }).$update();
          history.push("/account/email-success-page", { newEmailValue });
        } else {
          return;
        }
      })
      .catch((error) => {
        //let backendError = onGqlError(error);
        history.push("/account/change-email", error);
      });
  };
  const handleConfirmClick = () => {
    changeEmailData();
  };
  return (
    <div className={styles.main_Container}>
      <div className={styles.confirm_email_container}>
        <div className={styles.confirm_email_heading}>{confirmEmailHeader}</div>

        <div className={styles.confirm_email_content}>
          {confirmEmailAddress}
        </div>
        <div className={styles.confirm_email_value}>
          <strong>{newEmailValue}</strong>
        </div>
        <div className={styles.confirm_email_content}>{confirmEmailNote}</div>
        <div
          className={classNames(styles.button_container, styles.confirm_button)}
        >
          <PrimaryButton
            text="Confirm"
            handleClick={handleConfirmClick}
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  let userDetails = UserDetailModel.getInsatnce("LoggedUser");
  let tenantDetails = BrandSpecificDataModel.getInsatnce("1");
  let selectedAccount = SelectedAccountModel.getInsatnce("1");
  return {
    userDetails,
    tenantDetails,
    selectedAccount,
  };
};

export default withRouter(connect(mapStateToProps)(ConfirmEmailPage));
