import { Fragment } from "react";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import styles from "./forgotPasswordSuccess.module.scss";
import { ForgotPasswordModel } from "redux/models/ForgotPasswordModel";
import label from "../../../src/config/Labels/label.json";

const EmailConfirmation = (props) => {
  let fetchEmail = ForgotPasswordModel.getInsatnce("ResetUserPassword");
  let { email } = fetchEmail ? fetchEmail.props.ForgotPasswordData : "";
  let {
    forgotDetails,
    forgotDetailsContent,
    forgotDetailsSubContent,
  } = label.forgotPassword;
  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container}>
        <div className={styles.forgot_password__heading}>{forgotDetails}</div>
        <div className={styles.subhaeding}>
          {forgotDetailsContent} {email}
          {forgotDetailsSubContent}
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};
export default EmailConfirmation;
