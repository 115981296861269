import React, { Fragment, useState } from "react";
import styles from "./hero-banner-with-video.module.scss";
import { ClickAwayListener } from "components/ClickAwayListener";
import { renderMarkedText } from "components/AbstractedMethods";

interface IProps {
  metaData: content;
  id?: string;
}

interface content {
  headingText?: string;
  textInfo?: string;
  bannerImageUrl?: string;
  bannerImageHref?: string;
  appStoreButtons?: appButtons[];
}

interface appButtons {
  imageUrl: string;
  href: string;
}

const HeroBannerWithVideo: React.FC<IProps> = ({
  metaData: {
    headingText,
    textInfo,
    bannerImageHref,
    bannerImageUrl,
    appStoreButtons,
  },
  id,
}) => {
  const [showVideoPlayer, toggelVideoPlayer] = useState(false);

  const renderButtons = () => {
    return (
     <div className={styles.video_wrapper}>
      <div className={styles.video_container}>
        <iframe
          title="iFrame"
          id="player"
          frameBorder="0"
          // eslint-disable-next-line no-template-curly-in-string
          src={`https://player.vimeo.com/video/${bannerImageHref}`}
          allow="autoplay;" 
        ></iframe>
        <div
          className={styles.video_cross_icon}
          onClick={() => toggelVideoPlayer(false)}
        >
        </div>
      </div>
     </div>
  );
  };

  return (
    <Fragment>
      <div
        className={styles.component_basepadding}
        id={id}
        data-test="hero_banner_with_video"
      >
        <div className={styles.component_innerpadding}>
          <div className={styles.banner}>
            <ClickAwayListener
              clickAway={() => toggelVideoPlayer(false)}
              data-test="hero_banner_video_click"
            >
            <div className={styles.banner_image__container}>
            </div>
            </ClickAwayListener>
            <div className={styles.banner_content}>
              <div className={styles.banner_content__title}>
                <h1>{renderMarkedText(headingText)}</h1>
              </div>
              <div className={styles.banner_content__description}>
                {renderMarkedText(textInfo)}
              </div>
              
                {renderButtons()}
              
            </div>
          </div>
          {showVideoPlayer ? (
            <div className={styles.video_wrapper}>
              <div className={styles.video_container}>
                <iframe
                  title="iFrame"
                  id="player"
                  frameBorder="0"
                  // eslint-disable-next-line no-template-curly-in-string
                  src={`https://player.vimeo.com/video/${bannerImageHref}`}
                  allow="autoplay;" 
                ></iframe>
                <div
                  className={styles.video_cross_icon}
                  onClick={() => toggelVideoPlayer(false)}
                >
                  <img src="/asset/natwest/black/mark.png" alt="cross_icon" />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default HeroBannerWithVideo;
