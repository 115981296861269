import React, { Fragment, useEffect, useState } from "react";
import styles from "./explore.module.scss";
import { ImageBanner } from "components/ImageBanner";
import { IntuitiveMessages } from "components/IntuitiveMessages";
import { HeroBanner } from "components/HeroBanner";
import { Section } from "components/Section";
import { MultiSection } from "components/MutliSection";
import { AdditionalInfo } from "components/AdditionalInfo";
import { AccordionComponent } from "components/AccordionComponent";
import { withRouter, match } from "react-router-dom";
import { getExplorePageContent } from "services/RestAPI/exploreCalls";
import { ErrorBoundary } from "components/ErrorBoundary";
import { History, Location } from "history";
import { EventBookingContainer } from "../EventBookingForm";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import {
  scrollToTarget,
  getURLParams,
  getBenefitPath,
} from "utlis/generalUtils";
import { cinemaRegex, ticketRegex } from "config/constants";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { Modal } from "components/Modal";
import { PrimaryButton } from "components/PrimaryButton";
import { HeroBannerWithVideo } from "components/HeroBannerWithVideo";

interface IProps {
  match: match;
  history: History;
  location: Location;
}

interface content {
  type: string;
  metaData: any[] | any;
}

const Explore: React.FC<IProps> = ({
  match: {
    params: { pageUrl, nestedPageUrl },
  },
  history,
  location,
}) => {
  const [pageContent, setPageContent] = useState([]);
  const [makeClaimbId, setMakeClaimbId] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [benefitId, setBenefitId] = useState(0);
  const anchorTagRef = document.getElementsByTagName("a");
  let hostName = window.location.hostname;
  let blackSlashWithFwdSlashRegex = /\//gi;
  hostName.replace(blackSlashWithFwdSlashRegex, "\\/");
  let hostNameCheckRegex = new RegExp(hostName);

  let partnerJump = "partner-jump";
  let sectionJump = "sectionJump";
  let partnerJumpRegex = new RegExp(`(/${partnerJump})`);
  let sectionJumpRegex = new RegExp(`(/${sectionJump})`);
  let mobilePageRegex = new RegExp(`(/explore/mobile-phone-insurance)`);
  let internalLinkRegex = new RegExp(`^internal-`);
 
  useEffect(() => {
      getPageContent(pageUrl, nestedPageUrl);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    addTargetToLink();
    return () => {
      //OnComponentUnmount
      detachClickListener();
    };
  });

  const handleSectionJump = (anchorTagRef) => {
    let sectionJumpUrl = anchorTagRef.getAttribute("href") || "";
    let splitUrl = sectionJumpUrl ? sectionJumpUrl.split("/") : "";
    let targetElementId = splitUrl && splitUrl[2] ? splitUrl[2] : "";
    let targetElement = document.getElementById(targetElementId);

    anchorTagRef.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      scrollToTarget(targetElement);
    });
  }; 

  const setbIdToPartnerJump = (anchorTagRef) => {
    let cinemaRegex = new RegExp(`(/Cinema)`);
    let MovieRentalRegex = new RegExp(`(/MovieRental)`);
    let cashbackRegex = new RegExp(`(/cashback)`);
    let params;
    if (location && location.search) {
      params = getURLParams(location && location.search);
    }

    let BenefitIdToUpdate = params && params[0] && params[0].bId;
    if (BenefitIdToUpdate  && !anchorTagRef.href.includes(BenefitIdToUpdate)) {
      if (cinemaRegex.test(anchorTagRef.href) || MovieRentalRegex.test(anchorTagRef.href)) {
        let href = anchorTagRef.getAttribute("href");
        let urlPaths = href.split("/");
        urlPaths[1] = `${urlPaths[1]}/${BenefitIdToUpdate}`;
        if(urlPaths[2].includes('?')){
          let newUrlPaths = urlPaths[2].split("?");
          newUrlPaths[0]= `${newUrlPaths[0]}/${anchorTagRef.innerText.replace(/\s/g,'-')}`;
          urlPaths[2]=newUrlPaths[0]+`?${newUrlPaths[1]}`;
        }
        else urlPaths[2] = `${urlPaths[2]}/${anchorTagRef.innerText.replace(/\s/g,'-')}`;
        let newUrl = "";
        for (let i = 1; i < urlPaths.length; i++){
          newUrl += `/${urlPaths[i]}`; 
        }
        anchorTagRef.setAttribute(
          "href",
          newUrl
        );
      } else if (cashbackRegex.test(anchorTagRef.href)) {
        anchorTagRef.setAttribute(
          "href",
          `/partner-jump/${BenefitIdToUpdate}/cashback`
        );
      } else {
        let buttonText = anchorTagRef.innerText.replace(/\s/g,'-');
        if(params[0].bId == 1014 || params[0].bId == 1001){
          anchorTagRef.addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            handlepopup(params[0].bId);
          });
        }
        else {
        anchorTagRef.setAttribute("href", `/partner-jump/${BenefitIdToUpdate}/${buttonText}`);
        }
      }
    }
  };
  const handlepopup = (bId) => {
    setBenefitId(bId);
    setIsVisible(true);
    
  }
  const getbIdForMakeClaimPage = () => {
    const selectedAccount = SelectedAccountModel.list();
    let selectedAccountBenefits =
      selectedAccount &&
      selectedAccount[0] &&
      selectedAccount[0].props &&
      selectedAccount[0].props.benefits;
    let benefitObj = (selectedAccountBenefits || []).find((obj) => {
      return mobilePageRegex.test(getBenefitPath(obj.benefitName));
    });
    let makeClaimbId = benefitObj && benefitObj.benefitId;
    setMakeClaimbId(makeClaimbId);
  };

  const addTargetToPartnerJump = (anchorTagRef) => {
    let pathName = window.location.pathname;
    if (cinemaRegex.test(pathName) || ticketRegex.test(pathName)) {
      anchorTagRef.removeAttribute("target");
    } else {
      anchorTagRef.setAttribute("target", "_blank");
    }
  };

  const addTargetToLink = () => {
    for (let i = 0; i < anchorTagRef.length; i++) {
      let targetHref = anchorTagRef[i].href;

      if (sectionJumpRegex.test(targetHref)) {
        handleSectionJump(anchorTagRef[i]);
      } else if (!hostNameCheckRegex.test(targetHref)) {
        anchorTagRef[i].setAttribute("target", "_blank");
      } else if (partnerJumpRegex.test(targetHref)) {
        addTargetToPartnerJump(anchorTagRef[i]);
        setbIdToPartnerJump(anchorTagRef[i]);
      } else if (mobilePageRegex.test(targetHref)) {
        getbIdForMakeClaimPage();
      } else {
        let anchorTagRefTitle = anchorTagRef[i].getAttribute("title") || "";
        let isInternalLink = internalLinkRegex.test(anchorTagRefTitle);
        if (isInternalLink) {
          anchorTagRef[i].setAttribute("title", getTitle(anchorTagRefTitle));
          anchorTagRef[i].addEventListener("click", (e) =>
            handleInternalRedirect(e, anchorTagRef[i])
          );
        }
      }
    }
  };

  const getTitle = (rawTitle) => {
    return rawTitle.split("-")[1] || "";
  };

  const handleInternalRedirect = (e, anchorTagRef) => {
    e.preventDefault();
    e.stopPropagation();
    let internalPageLink = anchorTagRef.getAttribute("href") || "";
    history.push(internalPageLink);
  };
  const toggleModal = (option) => {
    if (option === "open") {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const detachClickListener = () => {
    for (let i = 0; i < anchorTagRef.length; i++) {
      anchorTagRef[i].removeEventListener("click", (e) =>
        handleInternalRedirect(e, anchorTagRef[i]), true
      );
    }
  };
  const handleModalClick = () =>{
    let buttonUrl = "/partner-jump/" + benefitId + "/Use-Benefit";
   window.open(buttonUrl);
  }
  const handleInsuranceClick= (benefitId) =>{
    let buttonUrl;
    if(benefitId == 1001 ){
      buttonUrl = "/partner-jump/" + 1028 + "/Use-Benefit";
    }
    else{
    buttonUrl = "/partner-jump/" + 1029 + "/Use-Benefit";
    }
   window.open(buttonUrl);
  }
  const getPageContent = async (mainPage, nestedPage) => {
    let resp = await getExplorePageContent(
      nestedPage ? `${mainPage}/${nestedPage}` : mainPage
    );

    if (!resp.breadCrumb) {
      console.warn("BreadCrumb data not defined in page JSON!");
    }
    new BreadCrumbModel({
      id: `1`,
      breadCrumbData: resp.breadCrumb,
    }).$save();

    setPageContent(resp.data);
  };

  return (
    <Fragment>
      <div className={styles.page}>
        {(pageContent || []).map((ele: content, index) => {
          let componentId = `section${index}`;
          return (
            <ErrorBoundary key={index}>
              {allTypesMapping[ele.type](
                ele.metaData,
                componentId,
                makeClaimbId
              )}
            </ErrorBoundary>
          );
        })}
        <Modal
        title={
          <Fragment>
            <div className={styles.importantText}>Important Information</div>
            <div className={styles.imageHeading}><span className={styles.text}>Travel Insurance</span></div>
            <div className={styles.modalHeader} style={{
              backgroundImage: `url("/asset/header-bg.png")`,
            }}>

            </div>
            <PrimaryButton
              text={" Close"}
              disabled={false}
              iconImage={"/asset/icons-close.png"}
              classNames={styles.primary_button_close}
              handleClick={() => toggleModal("close")} />
          </Fragment>
        }
        body={
          <>
            <p className={styles.heading}>
              On 1 February 2024 we changed the provider of our travel insurance from U K Insurance Limited to AWP P&C S.A. administered by AWP Assistance UK Ltd (trading as Allianz Assistance)
            </p>
            <p className={styles.info}> <img className={styles.img} src="/asset/info-icon.png" /> Please carefully read the options listed below to direct you to the correct provider for your circumstances and make your enquiry as smooth as possible.
            </p>
            <div className={styles.modalCards}>
              <div className={styles.modalCardContainer}>
                <div className={styles.modalcardHeader} style={{
                  backgroundImage: 'url("/asset/rectangle.png")'
                }}>
                </div>
                <div className={styles.modalCardBody}>
                  <div className={styles.title}>
                    New upgrades or claims for incidents that happened on or after 1 February 2024
                  </div>
                  <div className={styles.text}>
                    Allianz Assistance will support you in these scenarios.
                  </div>

                  <button type="button" className={styles.cardButton} onClick={() => handleInsuranceClick(benefitId)}>
                    Allianz Assistance
                  </button>

                </div>
              </div>
              <div className={styles.modalCardContainer}>
                <div className={styles.modalcardHeader} style={{
                  backgroundImage: 'url("/asset/modal2.png")'
                }}>
                </div>
                <div className={styles.modalCardBody}>
                  <div className={styles.title}>
                    New or existing claims for any incidents that happened prior to 1 February 2024
                  </div>
                  <div className={styles.text}>
                    U K Insurance Limited (UKI) will support you with these claims. They will also support with existing UKI upgrades and related documents.
                  </div>

                  <button type="button" className={styles.cardButton} onClick={() => handleModalClick()}>
                    U K Insurance Limited
                  </button>

                </div>
              </div>
            </div>
          </>

        }
        isVisible={isVisible}
        className={styles.modalInsurance}
      />
      </div>
    </Fragment>
  );
};

const renderCustomWidthWrapper = (componentData) => {
  return (
    <div
      style={{ display: "flex" }}
      className={styles.custom_width_wrapper_container}
    >
      {componentData.content.map((ele, index) => {
        return allTypesMapping[ele.type](ele.metaData, index);
      })}
    </div>
  );
};

const renderHeroBanner = (heroBannerContent, index) => {
  let { width, padding } = heroBannerContent;
  return (
    <div
      style={{
        width: width,
        padding: padding,
      }}
    >
      <HeroBanner metaData={heroBannerContent} id={index} />
    </div>
  );
};

const renderSingleSection = (sectionContent, index, makeClaimbId) => {
  let { width, padding } = sectionContent;

  return (
    <div
      style={{ width: width, padding: padding }}
      key={"single-section" + index}
    >
      <Section
        metaData={sectionContent}
        id={index}
        makeClaimbId={makeClaimbId}
      />
    </div>
  );
};

const renderMultiSections = (multiSectionContent, index) => {
  let { width, padding } = multiSectionContent;
  return (
    <div style={{ width: width, padding: padding }} >
      <MultiSection metaData={multiSectionContent} id={index} />
    </div>
  );
};

const renderImageBanner = (imageBannerContent, index) => {
  let { width, padding } = imageBannerContent;

  return (
    <div style={{ width: width, padding: padding }}>
      <ImageBanner metaData={imageBannerContent} id={index} />
    </div>
  );
};

const renderIntuitiveMessages = (msgContent, index) => {
  return (
    <IntuitiveMessages
      metaData={msgContent}
      id={index}
      key={"intuitive-messages" + index}
    />
  );
};

const renderAdditionalInfo = (addtionalInfoContent, index) => {
  let { width, padding } = addtionalInfoContent;
  return (
    <div style={{ width: width, padding: padding }} >
      <AdditionalInfo metaData={addtionalInfoContent} id={index} />
    </div>
  );
};

const renderAccordionComponent = (accordionContent, index) => {
  let { width, padding } = accordionContent;
  return (
    <div style={{ width: width, padding: padding }} >
      <AccordionComponent metaData={accordionContent} id={index}/>
    </div>
  );
};

const renderEventBookingForm = (formData) => {
  return <EventBookingContainer formData={formData}></EventBookingContainer>;
};
const renderBannerVideo = (videoData, index) =>{
  return(
    <HeroBannerWithVideo metaData={videoData} id={index}></HeroBannerWithVideo>
  )
}

const allTypesMapping = {
  HeroBanner: renderHeroBanner,
  Section: renderSingleSection,
  MultiSection: renderMultiSections,
  ImageBanner: renderImageBanner,
  AdditionalInfo: renderAdditionalInfo,
  AccordionComponent: renderAccordionComponent,
  CustomWidthWrapper: renderCustomWidthWrapper,
  IntuitiveMessages: renderIntuitiveMessages,
  EventBookingForm: renderEventBookingForm,
  HeroBannerWithVideo: renderBannerVideo,
};

export default withRouter(Explore);
