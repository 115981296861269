import { BaseModel } from "./BaseModel";

interface IBreadcrumb {
  path: string;
  name: string;
}

export interface IBreadCrumbProps {
  id: string;
  breadCrumbData: IBreadcrumb[];
}
/**
 * To store the Breadcrumb Data.
 * @export
 * @class BreadCrumb
 * @extends {BaseModel<IBreadCrumbProps>}
 */
export class BreadCrumbModel extends BaseModel<IBreadCrumbProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IBreadCrumbProps) {
    super(props);
  }

  static resource = "BreadCrumb";
}
