/**
 * @author Swapnali Ingle
 * @param {imageUrl,cardHeading} props
 * @param {string} cardHeading-value can be string
 * @param {string} iconName-value can be string
 */
import * as React from "react";
import styles from "./card.module.scss";
import { Icon } from "components/Icon";

export interface Props {
  cardHeading: string;
  iconName: string;
  classNames?: string;
  cardHeaderHeight?: string;
}

const CardHeader: React.FC<Props> = (props) => {
  let { iconName, cardHeading, classNames, cardHeaderHeight } = props;
  return (
    <div
      className={`${styles.header} ${classNames}`}
      style={{
        minHeight: cardHeaderHeight,
      }}
    >
      <div className={styles.header__name}>{cardHeading}</div>
      <div className={styles.header__icon}>
        <Icon iconName={iconName} />
      </div>
    </div>
  );
};
export default CardHeader;
