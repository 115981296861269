import React, { useEffect, Fragment } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import LoginContainer from "./Login/LoginContainer";
import HeaderFooterWrapper from "./HeaderFooterWrapper";
import { History } from "history";
import { FormWizard } from "./SignUpForm";
import ForgotPasswordContainer from "./ForgotPassword/ForgotPassword/ForgotPasswordContainer";
import ContactUsContainer from "./ContactUs/ContactUsContainer";
import { ContactUsSuccess } from "./ContactUsSuccess";
import ForgotEmailApp from "./ForgotEmail/ForgotEmailContainer";
import ForgotEmailSuccessPage from "./ForgotEmailSuccess/ForgotEmailSuccesPage";
import ForgotPasswordSecondPageContainer from "./ForgotPassword/ForgotPasswordSecondPage/ForgotPasswordSecondPageContainer";
import EmailConfirmation from "./ForgotPassword/EmailConfirmation";
import ForgotPasswordSuccess from "./ForgotPassword/ForgotPasswordSuccess";
import { SignOut } from "./SignOut";
import Loader from "components/Loader/loader";
import PartnerJumpPage from "./PartnerJump/partnerJump";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { addMatomoEvent, getTrackingUrl, getURLParams, AuthenticationCheck } from "utlis/generalUtils";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import { LoaderStateModel } from "redux/models/LoaderModel";
import LwbContainer from "./LWB/LwbContainer";

interface IProps {
  history: History;
}
const BaseRoute: React.FC<IProps> = ({ history }) => {
  let params; 
  if (history.location && history.location.search) {
    params = getURLParams(history.location && history.location.search);
  }
  let BenefitIdToUpdate = params && params[0] && params[0].bId;
  if(AuthenticationCheck() && BenefitIdToUpdate === `${1006}`) {
    new LoaderStateModel({
      id: "1",
      isLoading: true,
    }).$save();
    (async() => {
      let settingConfig = await getSettingConfig();
      let brand = process.env.REACT_APP_THEME_NAME;
      const brandName = brand ? brand.split("-")[0].toUpperCase() : settingConfig.REACT_APP_SITE;
      let serviceUnavailableLink = settingConfig.SERVICE_UNAVAILABLE_URL[brandName];
      
      window.open(serviceUnavailableLink, '_self');
    })();
  }
  //This useEffect is equivalent to component did mount
  useEffect(() => {

    history.listen((location) => {
      onRouteChanged();
    });
    onRouteChanged();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRouteChanged = () => {
    
      let selectedAccount = SelectedAccountModel.getInsatnce("1");
      let trackingUrl = getTrackingUrl(selectedAccount, history.location);
      trackingUrl = trackingUrl.toLowerCase().split(' ').join('-');
      addMatomoEvent(trackingUrl, trackingUrl);
    
  };

  const routes = [
    {
      path: "/login",
      exact: false,
      component: LoginContainer,
    },
    {
      path: "/partner-jump/:bId/:vendor",
      exact: false,
      component: PartnerJumpPage,
    },
    {
      path: "/partner-jump/:bId",
      exact: false,
      component: PartnerJumpPage,
    },
    {
      path: "/partner-jump",
      exact: false,
      component: PartnerJumpPage,
    },
    {
      path: "/user-signup",
      exact: false,
      component: FormWizard,
    },
    {
      path: "/forgot-password",
      exact: false,
      component: ForgotPasswordContainer,
    },
    {
      path: "/confirm-password",
      exact: false,
      component: ForgotPasswordSecondPageContainer,
    },
    {
      path: "/email-confirmation",
      exact: false,
      component: EmailConfirmation,
    },
    {
      path: "/forgot-password-success",
      exact: false,
      component: ForgotPasswordSuccess,
    },
    {
      path: "/forgot-email",
      exact: false,
      component: ForgotEmailApp,
    },
    {
      path: "/forgotemail-successpage",
      exact: false,
      component: ForgotEmailSuccessPage,
    },
    {
      path: "/contact-us",
      exact: false,
      component: ContactUsContainer,
    },
    {
      path: "/contact-us-success",
      exact: false,
      component: ContactUsSuccess,
    },
    {
      path: "/sign-out",
      exact: false,
      component: SignOut,
    },
    {
      path: "/loginredirect",
      exact: false,
      component: LwbContainer,
    },
    {
      path: "/lwb",
      exact: false,
      component: LoginContainer,
    },
    {
      path: "/",
      exact: false,
      component: HeaderFooterWrapper,
    },
  ];

  const renderRoutes = () => {
    return routes.map((route, index) => {
      return (
        <Route
          key={index}
          path={route.path}
          component={route.component}
          exact={route.exact}
        />
      );
    });
  };
  return (
    <Fragment>
      <Loader />
      <Switch>
        {renderRoutes()}
        <Redirect to="/login" />
      </Switch>
    </Fragment>
  );
};
export default withRouter(BaseRoute);
