import { useState, Fragment, useEffect } from "react";
import ForgotPassword from "./ForgotPassword";
import { withRouter } from "react-router-dom";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import { FORGOT_PASSWORD_TOKEN_GEN } from "services/GraphQL/gqlQueries";
import { ForgotPasswordModel } from "redux/models/ForgotPasswordModel";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { connect } from "react-redux";
import { CaptchaModel } from "redux/models/CaptchaModel";

const ForgotPasswordContainer = (props) => {
  const [backendErrors, setCustomError] = useState([]);

  const {
    tenantDetails: {
      props: { tenantId, brandId, brandName },
    },
    captchaDetails: {
      props: { captchaSiteKey },
    },
  } = props;

  useEffect(() => {
    populateBreadCrumbs();
  }, []);

  const populateBreadCrumbs = () => {
    let breadCrumbData = [
      {
        path: "",
        name: "Forgot Password",
      },
    ];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  const queryForgotPasswordData = async (values) => {
    let {
      email,
      firstName,
      lastName,
      birthDay,
      birthMonth,
      birthYear,
      sortCodeFirstPart,
      sortCodeSecondPart,
      sortCodeThirdPart,
      accountNumber,
    } = values;

    email = email.toLowerCase();

    let birthDate = birthYear + "-" + birthMonth + "-" + birthDay;
    let sortCode = sortCodeFirstPart + sortCodeSecondPart + sortCodeThirdPart;

    return await graphQlCall(
      "mutation",
      FORGOT_PASSWORD_TOKEN_GEN,
      {
        email: email.toLowerCase(),
        firstName: firstName,
        lastName: lastName,
        birthDate: birthDate,
        accountId: accountNumber,
        accountSortCode: sortCode,
        brandId: brandId,
        brandName: brandName,
      },
      {
        tenantId: tenantId,
      },
      "false"
    )
      .then((data) => {
        new ForgotPasswordModel({
          id: "ResetUserPassword",
          ForgotPasswordData: values,
        }).$save();
        const response = data.data["forgotPassword"];

        if (response) {
          return response.returnDetails.code === "200"
            ? props.history.push("/email-confirmation")
            : "";
        } else {
          props.history.push("/forgot-password");
        }
      })
      .catch((error) => {
        return setCustomError(onGqlError(error));
      });
  };
  const handleSubmit = (values) => {
    queryForgotPasswordData(values);
  };

  return (
    <Fragment>
      <ForgotPassword
        errorSet={backendErrors}
        onHandleSubmit={handleSubmit}
        captchaSiteKey={captchaSiteKey}
      />
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    tenantDetails: BrandSpecificDataModel.getInsatnce("1"),
    captchaDetails: CaptchaModel.getInsatnce("1"),
  };
};

export default connect(mapStateToProps)(withRouter(ForgotPasswordContainer));
