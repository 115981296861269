export const AFTER_LOGIN_FRIENDLY_PATH_NAME = [
  {
    pathName: "/home/covidfaq",
    friendlyName: "/Home/CovidFAQs"
  },
  {
    pathName: "/home/covidfaq/click-here",
    friendlyName: "/Home/CovidFAQs/Click-Here"
  },
  {
    pathName: "/home/covidfaq/(trips-booked-before-5th-january-2021)",
    friendlyName: "/Home/CovidFAQs/TravelBefore5Jan21"
  },
  {
    pathName: "/home/covidfaq/(trips-booked-on-or-after-5th-january-2021)",
    friendlyName: "/Home/CovidFAQs/TravelAfter5Jan21"
  },
  {
    pathName: "/home/covidfaq/ticket-booking",
    friendlyName: "/Home/CovidFAQs/Ticket Booking"
  },
  {
    pathName: "/home/covidfaq/travel-service",
    friendlyName: "/Home/CovidFAQs/Travel Service"
  },
  {
    pathName: "/home/covidfaq/membership-services",
    friendlyName: "/Home/CovidFAQs/Membership Services"
  },
  {
    pathName: "/account/manage-account",
    friendlyName: "/Account/Manage Your Account"
  },
  {
    pathName: "/account/recent-benefit-usage",
    friendlyName: "/Account/Recent Benefit Usage"
  },
  {
    pathName: "/account/change-email",
    friendlyName: "/Account/Change Email"
  },
  {
    pathName: "/account/confirm-email",
    friendlyName: "/Account/Confirm Email"
  },
  {
    pathName: "/account/password-success-page",
    friendlyName: "/Account/Password Success Page"
  },
  {
    pathName: "/account/email-success-page",
    friendlyName: "/Account/Email Success Page"
  },
  {
    pathName: "/account/change-password",
    friendlyName: "/Account/Change Password"
  },
  {
    pathName: "/explore/ticket-booking-service/event-booking",
    friendlyName: "/Explore/Ticket Booking Service/Event Booking"
  },
  {
    pathName: "/contact-us",
    friendlyName: "/Contact Us",
  },
  {
    pathName: "/contact-us-success",
    friendlyName: "/Contact Us Success",
  },
];

export const FRIENDLY_PATH_NAME = [
  {
    pathName: "/login",
    friendlyName: "Login",
  },
  {
    pathName: "/login/covidfaq",
    friendlyName: "CovidFAQs",
  },
  {
    pathName: "/login/covidfaq/click-here",
    friendlyName: "CovidFAQs/Click-Here",
  },
  {
    pathName: "/login/covidfaq/(trips-booked-before-5th-january-2021)",
    friendlyName: "CovidFAQs/TravelBefore5Jan21",
  },
  {
    pathName: "/login/covidfaq/(trips-booked-on-or-after-5th-january-2021)",
    friendlyName: "CovidFAQs/TravelAfter5Jan21",
  },
  {
    pathName: "/login/covidfaq/ticket-booking",
    friendlyName: "CovidFAQs/Ticket Booking",
  },
  {
    pathName: "/login/covidfaq/travel-service",
    friendlyName: "CovidFAQs/Travel Service",
  },
  {
    pathName: "/login/covidfaq/membership-services",
    friendlyName: "CovidFAQs/Membership Services",
  },
  {
    pathName: "/home",
    friendlyName: "Login",
  },
  {
    pathName: "/forgot-password",
    friendlyName: "Forgot Password Step 1",
  },
  {
    pathName: "/confirm-password",
    friendlyName: "Forgot Password Step 2",
  },
  {
    pathName: "/forgot-password-success",
    friendlyName: "Forgot Password Success",
  },
  {
    pathName: "/forgot-email",
    friendlyName: "Forgot Email Step 1",
  },
  {
    pathName: "/email-confirmation",
    friendlyName: "Forgot Email step 2",
  },
  {
    pathName: "/forgotemail-successpage",
    friendlyName: "Forgot Email Success",
  },
  {
    pathName: "/contact-us",
    friendlyName: "Contact Us",
  },
  {
    pathName: "/contact-us-success",
    friendlyName: "Contact Us Success",
  },
  {
    pathName: "/user-signup",
    friendlyName: "Registration Step 1",
  },
  {
    pathName: "/user-signup/registration",
    friendlyName: "Registration Step 2",
  },
  {
    pathName: "/user-signup/registration/success",
    friendlyName: "Registration Success",
  },
  {
  pathName: "/user-signup/activate-member",
  friendlyName: "activationpostLWB",
  },
];

export const FRIENDLY_ACCOUNT_NAME = [
  {
    accountType: "silver",
    friendlyName: "Silver",
  },
  {
    accountType: "platinum",
    friendlyName: "Platinum",
  },
  {
    accountType: "black",
    friendlyName: "Black",
  },
  {
    accountType: "gold",
    friendlyName: "Gold",
  },
  {
    accountType: "private",
    friendlyName: "Private",
  },
  {
    accountType: "ufirst",
    friendlyName: "UFirst",
  },
];
