import { BaseModel } from "./BaseModel";

export interface IUserDetailModelProps {
  id: string;
  LoggedInUserDetails: any;
}
/**
 * To store the user data coming from the GitHub API.
 * @export
 * @class UserModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class UserDetailModel extends BaseModel<IUserDetailModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: IUserDetailModelProps) {
    super(props);
  }

  static resource = "UserDetail";
}
