import { Fragment } from "react";
import styles from "./forgotEmailSuccess.module.scss";
import { Link } from "react-router-dom";
import labels from "config/Labels/label.json";
import { History, Location } from "history";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { PrimaryButton } from "components/PrimaryButton";

interface IProps {
  location: Location;
  history: History;
}

const { forgotEmailSuccess } = labels;

const ForgotEmailSuccessPage = (props: IProps) => {
  let { history } = props;
  let forgottenEmail = props.location.state;

  const onSubmit = () => {
    history.push(`/login`);
  };
  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container}>
        <div className={styles.redirect_link}>
          <Link to="/login">{forgotEmailSuccess.homeText}</Link>
        </div>
        <div className={styles.forgot_email__heading}>
          {forgotEmailSuccess.signInDetailText}
        </div>
        <div>
          {forgotEmailSuccess.emailAdressText}
          {forgottenEmail}
        </div>
        <div className={styles.sign_in__btn}>
          <PrimaryButton
            text="Sign In"
            disabled={false}
            classNames={styles.forgot_email__btn__next_btn}
            data-test="next-button"
            handleClick={onSubmit}
          />
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};

export default ForgotEmailSuccessPage;
