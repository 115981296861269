import React, {useEffect } from "react";
import { withRouter, match } from "react-router-dom";
import { History, Location } from "history";
import { getSettingConfig } from "services/RestAPI/settingConfig";
import { LoaderStateModel } from "redux/models/LoaderModel";
interface IProps {
  location: Location;
  history: History;
  match: match;
}

const LwbContainer: React.FC<IProps> = ({ history, location }) => {

  useEffect(() => {
    lwbRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const lwbRedirect = () => {
    new LoaderStateModel({
      id: "1",
      isLoading: true,
    }).$save();
    let token = location.hash?.split('#id_token=')[1];
    let error = location.hash?.split('&error=')[1];
    let state = location.hash?.split('#state=')[1]?.split('&')[0];
    if (error === 'access_denied') {
      checkBrandURL(state);
    }
    else if (token !== undefined) {
      checkBrandURL(location.hash?.split('&state=')[1])
    } else {
      return;
    }
  }

  const checkBrandURL = (brand) => {
    // getSettingConfig().then((data) => {
    // //  window.open('http://localhost:3000/lwb' + location.hash, "_self");
    // });
    if (brand === 'natwest') {
      getSettingConfig().then((data) => {
        window.open(data.SITE_URLS.NATWEST + location.hash, "_self");
      });
    } else if (brand ==='rbs') {
      getSettingConfig().then((data) => {
        window.open(data.SITE_URLS.RBS + location.hash, "_self");
      });
    } else if (brand === 'ulster') {
      getSettingConfig().then((data) => {
        window.open(data.SITE_URLS.ULSTER_NI + location.hash, "_self");
      });
    }
  }
  
  return (
   <></>
  );
};
export default withRouter(LwbContainer);
