import React, { Fragment, useEffect, useState } from "react";
import ContactUsComponent from "./ContactUsComponent";
import { HeaderContainer } from "core/Header";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getBrandName, getAccountType } from "services/RestAPI/exploreCalls";
import axios from "services/RestAPI/axiosConfig";
import { FooterComponent } from "core/Footer";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import {
  CONTACT_US_AFTER_LOGIN,
  CONTACT_US_BEFORE_LOGIN,
} from "services/GraphQL/gqlQueries";
import { History } from "history";
import { BreadCrumbModel } from "redux/models/BreadCrumbModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { CaptchaModel } from "redux/models/CaptchaModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { LoaderStateModel } from "redux/models/LoaderModel";
import { GENERAL_ERROR_MESSAGE } from "config/errorMessages";
import { encode } from "html-entities";
import { LoginEmailModel } from "redux/models/LoginEmailModel";
export interface ContactUsContainerProps {
  userDetails: any;
  loggedUser: any;
  history: History;
  tenantDetails: BrandSpecificDataModel;
  captchaDetails: CaptchaModel;
  emailDetails: LoginEmailModel;
}

const ContactUsContainer: React.FC<ContactUsContainerProps> = ({
  userDetails,
  history,
  tenantDetails,
  captchaDetails,
  loggedUser,
  emailDetails,
}) => {
  const publicPath = process.env.PUBLIC_URL;
  const initialPageContent = {
    HeadingSection: { metaData: { content: [] } },
    InfoSection: { metaData: { content: [] } },
    formSection: { metaData: { content: [] } },
  };
  const [isUserLoggedIn, setUserLoginStatus] = useState(false);
  const [pageContent, setPageContent] = useState(initialPageContent);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [backendError, setBackendError] = useState();
  const [userData, setUserData] = useState({
    memberId: "",
    membershipId: "",
    firstName: "",
    lastName: "",
    email: "",
  });
  const { captchaSiteKey } = captchaDetails.props;

  //this useEffect is equivalent to ComponentDidMount
  useEffect(() => {
    if (
      typeof userDetails !== "undefined" &&
      typeof emailDetails !== "undefined"
    ) {
      let { membershipId } = userDetails && userDetails.props;

      let { firstName, lastName, id } =
        loggedUser &&
        loggedUser.props &&
        loggedUser.props.LoggedInUserDetails.member;

      let { loginEmail } = emailDetails.props;

      setUserData({
        memberId: id,
        membershipId: membershipId,
        firstName: firstName,
        lastName: lastName,
        email: loginEmail,
      });
      setUserLoginStatus(true);
    }

    fetchPageContent();
    populateBreadcrumbs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateBreadcrumbs = () => {
    let breadCrumbData = [{ path: "", name: "Contact Us" }];
    new BreadCrumbModel({ id: "1", breadCrumbData: breadCrumbData }).$save();
  };

  const fetchPageContent = async () => {
    let path = publicPath + `/contact-us/${getBrandName()}/contact-us.json`;
    axios.get(path).then((response) => {
      let pageData = response.data && response.data.data;
      setPageContent(pageData);
    });
  };

  const submitForm = (values) => {
    const { tenantId, brandId } = tenantDetails && tenantDetails.props;

    const { email, name, message, subject } = values;

    let CONTACT_US_QUERY = isUserLoggedIn
      ? CONTACT_US_AFTER_LOGIN
      : CONTACT_US_BEFORE_LOGIN;

    let variables = isUserLoggedIn
      ? {
          memberID: userData.memberId,
          membershipId: userData.membershipId,
          query: encode(message),
          subject: encode(subject),
          brandId: brandId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          emailId: email,
        }
      : {
          query: encode(message),
          subject: encode(subject),
          brandId: brandId,
          fullName: encode(name),
          emailId: email,
        };

    let authorizedQuery = isUserLoggedIn ? "true" : "false";

    graphQlCall(
      "mutation",
      CONTACT_US_QUERY,
      variables,
      {
        tenantId: tenantId,
      },
      authorizedQuery
    )
      .then((resp) => {
        if (
          resp.data[
            `${
              isUserLoggedIn
                ? "contactUsLoggedInUser"
                : "contactUsAnonymousUser"
            }`
          ].returnDetails.code === "200"
        ) {
          history.push("/contact-us-success");
        } else {
          setFormErrorMessage(GENERAL_ERROR_MESSAGE);
        }
      })
      .catch((error) => {
        new LoaderStateModel({
          id: "1",
          isLoading: false,
        }).$update("1");
        setBackendError(onGqlError(error));
      });
  };

  const getDefaultValues = (element) => {
    let { firstName, lastName, email } = userData;
    let elementName = element.content && element.content.name;
    if (elementName === "name") {
      return `${firstName} ${lastName}`;
    }
    return `${email}`;
  };

  const getInjectableValues = (data) => {
    let { content, defaultInjectableValue } = data;
    let accountType = isUserLoggedIn
      ? getAccountType()
      : defaultInjectableValue.accountType;
    let injectedValue = content.replace(
      // eslint-disable-next-line no-template-curly-in-string
      "${accountType}",
      `${accountType.charAt(0).toUpperCase()}${accountType.slice(1)}`
    );
    return injectedValue;
  };

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={isUserLoggedIn} />
      <ContactUsComponent
        isUserLoggedIn={isUserLoggedIn}
        pageContent={pageContent}
        submitForm={submitForm}
        formErrorMessage={formErrorMessage}
        getDefaultValues={getDefaultValues}
        getInjectableValues={getInjectableValues}
        captchaSiteKey={captchaSiteKey}
        backendErrors={backendError}
      />
      <FooterComponent />
    </Fragment>
  );
};

const mapStateToProps = () => {
  let userDetails = SelectedAccountModel.getInsatnce("1");
  let loggedUser = UserDetailModel.getInsatnce("LoggedUser");
  let tenantDetails = BrandSpecificDataModel.getInsatnce("1");
  let captchaDetails = CaptchaModel.getInsatnce("1");
  let emailDetails = LoginEmailModel.getInsatnce("1");
  return {
    userDetails,
    loggedUser,
    tenantDetails,
    captchaDetails,
    emailDetails,
  };
};

export default withRouter(connect(mapStateToProps)(ContactUsContainer));
