import { Theme } from "../interfaces";
import { Typography } from "./typography";
import { ulster_ni_gold_vars } from "./themeVariables/ulster_ni";
import { ulster_ri_gold_theme_vars } from "./themeVariables/ulster_ri";

export const ulsterNIGoldTheme: Theme = {
  name: "ulster_ni-gold",
  properties: {
    ...ulster_ni_gold_vars,
    ...Typography.properties
  }
};

export const ulsterRIGoldTheme: Theme = {
  name: "ulster_ri-gold",
  properties: {
    ...ulster_ri_gold_theme_vars,
    ...Typography.properties
  }
};
