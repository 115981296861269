/**
 * @author Swapnali Ingle
 * @param { buttonText,text, iconName,onButtonClick} props
 * @param {string} buttonText-value can be string
 * @param {string} text-value can be string
 *  @param {string} iconName-value can be string
 * @param {function} onButtonClick-value can be function
 */
import * as React from "react";
import styles from "./card.module.scss";
import  { PrimaryButton } from "components/PrimaryButton";
import { Icon } from "components/Icon";
import { Link } from "react-router-dom";
export interface Props {
  buttonText?: string;
  text?: string;
  iconName: string;
  onButtonClick: () => void;
  classNames?: string;
  cardFooterHeight?: string;
  exploreLink?: string;
  cardHeading?: string;
}
const CardFooter: React.FC<Props> = (props) => {
  let {
    buttonText,
    text,
    iconName,
    onButtonClick,
    classNames,
    cardFooterHeight,
    exploreLink,
    cardHeading
  } = props;
  return (
    <div
      className={`${styles.footer} ${classNames}`}
      style={{
        minHeight: cardFooterHeight,
      }}
    >
      {buttonText ? (
        <div className={styles.footer_button}>
          <PrimaryButton
            text={buttonText}
            handleClick={onButtonClick}
            disabled={false}
            ariaLabel={`Use ${cardHeading}`}
          />
        </div>
      ) : null}
      
      {/* Based on the benefit type code will decide whether to show the Explore button or not  */}
      {
        text ? (
          <Link to={exploreLink} className={styles.footer_explore} aria-label={`explore ${cardHeading}`}>
            <div className={styles.footer_explore_wrapper}>
              <div className={styles.footer_explore_text}>{text}</div>
              <div className={styles.footer_explore__icon}>
                <Icon iconName={iconName} />
              </div>
            </div>
          </Link>
        ) : null
      }
    </div>
  );
};
export default CardFooter;
