import React from "react";
import styles from "./selectDropdown.module.scss";
import classnames from "classnames/bind";
import { Icon } from "components/Icon";
import FocusLock from 'react-focus-lock';

let bindCss = classnames.bind(styles);
/**
 * @param {
 * options,
 * toggleIsOpen,
 * dropdownIsOpen,
 * updateSelectTitle,
 * dropdownTitle,
 * dropdownType}
 * @param {Array} options-Array of Object.
 * @param {Function} toggleIsOpen-useState function.
 * @param {boolean} dropdownIsOpen-useState state, value can be boolean.
 * @param {Function}  updateSelectTitle-UseState function.
 * @param {string} dropdownTitle-useState state,value can be string.
 * @param {string} dropdownType-Is accept value string only primary/secondary
 * to changed background color of dropdown.
 */

export interface IItem {
  title?: string;
  links: ILink[];
}
export interface ILink {
  link_title: string;
  linkType: string;
  url_path: string;
  link_account_id?: any;
}
export interface IProps {
  options: IItem[] | undefined;
  toggleIsOpen: Function;
  dropdownIsOpen: boolean | false;
  updateSelectTitle: Function;
  dropdownTitle: string;
  dropdownType: string;
  updatableDropdownTitle?: boolean;
  classNames?: string;
}

const SelectDropdown: React.FC<IProps> = (props) => {
  const staticTitle = `Quick access`;
  const {
    options,
    toggleIsOpen,
    dropdownIsOpen,
    updateSelectTitle,
    dropdownTitle,
    dropdownType,
    updatableDropdownTitle,
    classNames,
  } = props;

  const renderQuickAccessLinks = (item) => {
    return (
      <ul>
        {item.links.map((innerItem, index) => {
         if(innerItem.benefitId != 1028 && (innerItem.benefitId != 1029)){
            return (
              <li
                key={`link_${index}`}
                onClick={(e) => updateSelectTitle(innerItem, e)}
                onKeyPress={(e) => { if (e.key === 'Enter') return updateSelectTitle(innerItem, e)}}
                tabIndex={dropdownIsOpen ? 0 : -1}
                aria-label={innerItem.link_title}
              >
                {innerItem.linkType === "downloadable" ? (
                  <Icon iconName="icon-doc" />
                ) : (
                  ""
                )}
                {innerItem.link_title}
              </li>
            );
          }
          else{ return}
        })}
      </ul>
    );
  };

  return (
    <div
      className={bindCss({
        select_dropdown: true,
      })}
      data-test="select-dropdown"
    >
      <div className={`${styles.select_dropdown__wrapper}`}>
        <div
          tabIndex={0}
          className={`${bindCss({
            select_dropdown__header: true,
            select_dropdown__border_radius: dropdownIsOpen,
            select_dropdown__title_bgcolor: dropdownType === "secondary",
            select_dropdown__title_bgcolor_onchange:
              dropdownIsOpen && dropdownType === "secondary",
            //Change background color of dropdown
          })} ${classNames}`}
          /**
           * this function updates the state
           * and toggle dropdown.
           */
           role="button" aria-haspopup="true" aria-expanded={dropdownIsOpen}
          onClick={() => toggleIsOpen(dropdownIsOpen)}
          onKeyPress={(e) => { if (e.key === 'Enter') return toggleIsOpen(dropdownIsOpen)}}
        >
          <div className={`${styles.select_dropdown__header_title}`}>
            {updatableDropdownTitle ? dropdownTitle : staticTitle}
          </div>
          <div className={styles.select_dropdown__icon}>
            {dropdownIsOpen ? (
              <Icon iconName={"icon-chevron-normal-up"} />
            ) : (
              <Icon iconName={"icon-chevron-normal-down"} />
            )}
          </div>
        </div>
        {dropdownIsOpen ? (
          <FocusLock disabled={!dropdownIsOpen} returnFocus={dropdownIsOpen}>
          <div
            className={`${bindCss({
              select_dropdown__options_container: true,
              select_dropdown__options_bgcolor: dropdownType === "secondary",
            })} `}
            data-test="dropdown-options"
            onKeyDown={(e) =>{ if (e.key === 'Escape'|| e.key === "Esc")props.toggleIsOpen(dropdownIsOpen)}} 
          >
            {
              //Array of Dropdown data receiving as props from parent
              Array.isArray(options) &&
                (options || []).map((item, i) => {
                  return (
                    <div
                      className={styles.select_dropdown__options}
                      key={i}
                      /**
                       * this function updates the state
                       * and change the options title.
                       */
                    >
                      {item.title ? (
                        <div
                          className={styles.sectionHeading}
                          data-test="item-title"
                          role="heading"
                          aria-level="1"
                          tabIndex={dropdownIsOpen ? 0 : -1}
                        >
                          {item.title}
                        </div>
                      ) : null}
                      {renderQuickAccessLinks(item)}
                    </div>
                  );
                })
            }
          </div>
          </FocusLock>
        ) : null}
      </div>
    </div>
  );
};

export default SelectDropdown;
