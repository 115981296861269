import { setSessionEvents } from "components/IdleSession/IdleSession";
import { IdleSessionModel } from "redux/models/IdleSessionModel";
import { LoginStateModel } from "redux/models/LoginStateModel";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { LOGIN_QUERY, GET_HEADER_DETAILS, LOGIN_WITH_CIN, GET_JWKS } from "services/GraphQL/gqlQueries";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import setting from "services/RestAPI/axiosConfig";
import { graphQlCall } from "services/GraphQL/gqlHelper";
import { settingURL, setThemeBrandWise } from "themeApplicator";
import { BACKEND_API_ERROR, NETWORK_TIMEOUT } from "config/errorCodeConstants";
import { getRefreshTokenExpiryTime } from "./generalUtils";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import * as _ from "lodash";

import { PRODUCT_DETAILS } from "config/constants";
import { TokenTimeout } from "redux/models/TokenTimeout";
import { LoginEmailModel } from "redux/models/LoginEmailModel";

export const queryLoginData = async (email, password) => {
  const { tenantId } = BrandSpecificDataModel.getInsatnce("1")
    ? BrandSpecificDataModel.getInsatnce("1").props
    : "";

  return await graphQlCall(
    "mutation",
    LOGIN_QUERY,
    {
      uname: email.toLowerCase(),
      pass: password,
    },
    {
      tenantId: tenantId,
    },
    "false"
  )
    .then((data) => {
      window.scrollTo(0, 0);
      const response = data.data["login"];
      return graphQlCall(
        "query",
        GET_HEADER_DETAILS,
        {
          id: data.data.login.memberId,
        },
        {
          tenantId: tenantId,
        }
      )
        .then((resp) => {
          let defaultAccountType = resp.data.member.account.find((account) => {
            return account.membershipId === response.membershipId;
          });
          let {
            createdOn,
            membershipId,
            memberNumber,
            accountId,
          } = defaultAccountType;
          let {
            packageName: defaultUser,
            packageId: selectedPackageId,
            description: packageDescription,
            productDefinition: selectedProductDefinition,
            benefits: accountBenefits,
            siteTheme: selectedTheme,
          } = defaultAccountType.package;
          if(selectedTheme.includes("ulster_ri")) {
            return { type: "ulster_ri", value: "", faliureCount: 0 };
          }
          if (response["accessToken"]) {
            new LoginStateModel({
              id: "LoginDetails",
              LoginData: data.data.login,
            }).$save();

            let tokenExpiryTime = getRefreshTokenExpiryTime();
            if (tokenExpiryTime) {
              new TokenTimeout({
                id: "1",
                timeOut: tokenExpiryTime,
              }).$save();
              setSessionEvents();
            }
          }
          new IdleSessionModel({
            id: "1",
            sessionStatus: "active",
          }).$save();

          new UserDetailModel({
            id: "LoggedUser",
            LoggedInUserDetails: resp.data,
          }).$save();

          let account = resp.data.member.account;
          let slicedAccountId = accountId.slice(accountId.length - 4);
          let link_title =
            account.length === 1
              ? packageDescription
              : `${slicedAccountId} - ${packageDescription}`;

          let sortedBenefits = PRODUCT_DETAILS[selectedProductDefinition.id]
            ? _.sortBy(accountBenefits, "benefitDisplayOrder")
            : "";
          new SelectedAccountModel({
            id: "1",
            type: defaultUser,
            creationDate: createdOn,
            packageId: selectedPackageId,
            packageDescription: packageDescription,
            productDefinition: selectedProductDefinition,
            benefits: sortedBenefits,
            membershipId: membershipId,
            linkTitle: link_title,
            memberNumber: memberNumber,
            cin: resp.data.member.cin,
            memberId: defaultAccountType.memberId,
          }).$save();

          new LoginEmailModel({
            id: "1",
            loginEmail: resp.data.member.email,
          }).$save();

          new ThemeNameModel({
            id: "1",
            name: selectedTheme
              ? selectedTheme
              : process.env.REACT_APP_THEME_NAME,
          }).$update("1");

          setting.get(settingURL).then((resp) => {
            setThemeBrandWise(selectedTheme, resp.data);
          });
          return { type: "LoginSuccess", value: "", faliureCount: 0 };
        })
        .catch((err) => {
          return { type: "Invalid", value: err, faliureCount: 0 };
        });
    })
    .catch((error) => {
      let faliureCount;
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
        faliureCount = error.graphQLErrors.map((item) => {
          return item.extensions
            ? item.extensions.failCount
            : [BACKEND_API_ERROR]; //To indicate Unprocessable entity (ref: https://www.bennadel.com/blog/2434-http-status-codes-for-invalid-data-400-vs-422.htm)
        });
      } else {
        faliureCount = [NETWORK_TIMEOUT];
        return { type: "Error", value: error, faliureCount }; //Its Used for Network Timeout(ref: https://en.wikipedia.org/wiki/List_of_HTTP_status_codes);
      }
      return { type: "Error", value: error, faliureCount };
    });
};

export const queryLoginwithCINData = async (cin) => {
  const { tenantId } = BrandSpecificDataModel.getInsatnce("1")
    ? BrandSpecificDataModel.getInsatnce("1").props
    : "";

  return await graphQlCall(
    "mutation",
    LOGIN_WITH_CIN,
    {
      cin: cin,
    },
    {
      tenantId: tenantId,
    },
    "false"
  )
    .then((data) => {
      window.scrollTo(0, 0);
      const response = data.data["loginWithCIN"];
      if(response.statusCode === 'AUTH206') {
        let {
          memberId,
          membershipId,
          packageId,
          firstName,
          lastName,
        } = response;
        return { type: "LWBNotWebEnabled", value: {
          memberId,
          membershipId,
          packageId,
          firstName,
          lastName,
        }, faliureCount: 0 };
      }
      return graphQlCall(
        "query",
        GET_HEADER_DETAILS,
        {
          id: data.data.loginWithCIN.memberId,
        },
        {
          tenantId: tenantId,
        }
      )
        .then((resp) => {
          if (response["accessToken"]) {
            new LoginStateModel({
              id: "LoginDetails",
              LoginData: data.data.loginWithCIN,
            }).$save();

            let tokenExpiryTime = getRefreshTokenExpiryTime();
            if (tokenExpiryTime) {
              new TokenTimeout({
                id: "1",
                timeOut: tokenExpiryTime,
              }).$save();
              setSessionEvents();
            }
          }
          new IdleSessionModel({
            id: "1",
            sessionStatus: "active",
          }).$save();

          new UserDetailModel({
            id: "LoggedUser",
            LoggedInUserDetails: resp.data,
          }).$save();

          let account = resp.data.member.account;
          let defaultAccountType = resp.data.member.account.find((account) => {
            return account.membershipId === response.membershipId;
          });
          let {
            createdOn,
            membershipId,
            memberNumber,
            accountId,
          } = defaultAccountType;
          let {
            packageName: defaultUser,
            packageId: selectedPackageId,
            PRefId: PRefId,
            description: packageDescription,
            productDefinition: selectedProductDefinition,
            benefits: accountBenefits,
            siteTheme: selectedTheme,
          } = defaultAccountType.package;
          let slicedAccountId = accountId.slice(accountId.length - 4);
          let link_title =
            account.length === 1
              ? packageDescription
              : `${slicedAccountId} - ${packageDescription}`;

          let sortedBenefits = PRODUCT_DETAILS[selectedProductDefinition.id]
            ? _.sortBy(accountBenefits, "benefitDisplayOrder")
            : "";
          new SelectedAccountModel({
            id: "1",
            type: defaultUser,
            creationDate: createdOn,
            packageId: selectedPackageId,
            PRefId: PRefId,
            packageDescription: packageDescription,
            productDefinition: selectedProductDefinition,
            benefits: sortedBenefits,
            membershipId: membershipId,
            linkTitle: link_title,
            memberNumber: memberNumber,
            cin: resp.data.member.cin,
            memberId: defaultAccountType.memberId,
          }).$save();

          new LoginEmailModel({
            id: "1",
            loginEmail: resp.data.member.email,
          }).$save();

          new ThemeNameModel({
            id: "1",
            name: selectedTheme
              ? selectedTheme
              : process.env.REACT_APP_THEME_NAME,
          }).$update("1");

          setting.get(settingURL).then((resp) => {
            setThemeBrandWise(selectedTheme, resp.data);
          });
          return { type: "LoginSuccess", value: "", faliureCount: 0 };
        })
        .catch((err) => {
          return { type: "Invalid", value: err, faliureCount: 0 };
        });
    })
    .catch((error) => {
      let faliureCount;
      if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
        faliureCount = error.graphQLErrors.map((item) => {
          return item.extensions
            ? item.extensions.failCount
            : [BACKEND_API_ERROR]; //To indicate Unprocessable entity (ref: https://www.bennadel.com/blog/2434-http-status-codes-for-invalid-data-400-vs-422.htm)
        });
      } else {
        faliureCount = [NETWORK_TIMEOUT];
        return { type: "Error", value: error, faliureCount }; //Its Used for Network Timeout(ref: https://en.wikipedia.org/wiki/List_of_HTTP_status_codes);
      }
      return { type: "Error", value: error, faliureCount };
    });
};


export const queryJWKS = async () => {
  const { tenantId } = BrandSpecificDataModel.getInsatnce("1")
    ? BrandSpecificDataModel.getInsatnce("1").props
    : "";
  return await graphQlCall(
    "query",
    GET_JWKS,
    {},
    {
      "x-correlation-id": "4c3d065c-6b3f-4582-bd57-bde47aadcde3",
      "tenantId": tenantId,
      "Content-Type": "application/json"
    });
}
