import { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import styles from "./captcha.module.scss";

interface ICaptchaProps {
  onSubmit: Function;
  siteKey: string;
}

export default class Captcha extends Component<ICaptchaProps> {
  constructor(props: ICaptchaProps) {
    super(props);
    this.state = {};
  }

  onChange = (value) => {
    this.props.onSubmit(value);
  };

  render() {
    return (
      <ReCAPTCHA
        sitekey={this.props.siteKey}
        onChange={this.onChange}
        className={styles.captcha_wrapper}
      />
    );
  }
}
