import { BaseModel } from "./BaseModel";

interface ISignUpModelProps {
  id: string;
  SignUpData: any;
}
/**
 * To store the user data coming from the GraphQL.
 * @export
 * @class SignUpStateModel
 * @extends {BaseModel<IDummyModelProps>}
 */
export class SignUpStateModel extends BaseModel<ISignUpModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ISignUpModelProps) {
    super(props);
  }

  static resource = "ISignUpState";
}
