import { BaseModel } from "./BaseModel";

interface ICookieSettingModelProps {
  id: string;
  cookieOptIn: boolean;
}

export class CookieSettingModel extends BaseModel<ICookieSettingModelProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(props: ICookieSettingModelProps) {
    super(props);
  }

  static resource = "CookieSetting";
}
