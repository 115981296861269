import React, { Fragment } from "react";
import classNames from "classnames";
import styles from "./form-field-error.module.scss";

export interface IFormFieldErrorProps {
  errorText?: any;
  className?: string;
  errorId?: string;
}

const FormFieldError: React.FC<IFormFieldErrorProps> = ({
  errorText,
  className,
  errorId
}) => {
  return (
    <Fragment>
      <div
        className={classNames(
          styles.fieldError,
          styles.fieldErrorDesign,
          className
        )}
        id={errorId}
      >
        {errorText}
      </div>
    </Fragment>
  );
};

export default FormFieldError;
