import { useState, useEffect } from "react";
import { graphQlCall, onGqlError } from "services/GraphQL/gqlHelper";
import {
  PARTNER_JUMP_CALL,
  ACCOUNT_DETAILS,
} from "services/GraphQL/gqlQueries";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Match } from "react-router-dom";
import { History, Location } from "history";
import { UserDetailModel } from "redux/models/UserDetailModel";
import { ErrorBox } from "../Login/ErrorBox/index";
import { PARTNER_PAGE_NOT_FOUND_MESSAGE } from "config/errorMessages";
import { ErrorBoundary } from "components/ErrorBoundary";
import { Loader } from "components/Loader";
import { AccountStateModel } from "redux/models/AccountModel";
import { LoaderStateModel } from "redux/models/LoaderModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { AuthenticationCheck, getURLParams } from "utlis/generalUtils";
import { benefitIdForCookie } from "config/constants";

interface IProps {
  userDetails: UserDetailModel;
  match: Match;
  history: History;
  location: Location;
  tenantDetails: BrandSpecificDataModel;
  selectedAccount: SelectedAccountModel;
}


const PartnerJumpPage = (props: IProps) => {
  const [backendErrors, setError] = useState([]);
  const {
    match: {
      params: { bId, vendor },
    },
    location,
  } = props;

  let deepLinkVendor;
  let deepLinkUrl;
  let faqSection = "False";
  if (location && location.search) {
    let urlParams = getURLParams(location.search);
    (urlParams || []).map((vendorDetails, index) => {
      faqSection = vendorDetails.faqSection ? vendorDetails.faqSection : "False";
      deepLinkVendor = vendorDetails.deepLinkVendor ? vendorDetails.deepLinkVendor : deepLinkVendor;
      deepLinkUrl = vendorDetails.deepLinkUrl ? vendorDetails.deepLinkUrl : deepLinkUrl;
    })
  }

  const deepLinkVendorTypes = [
    "RBSTravelInsurance",
    "NatWestTravelInsurance",
    "UlsterTravelInsurance",
  ];

  const makePartnerPostCall = (partners) => {
    let form = document.createElement("form");
    document.body.appendChild(form);
    form.method = "post";
    form.action = partners.url;
    for (let name in partners.parameters) {
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = name;
      input.value = partners.parameters[name];
      form.appendChild(input);
    }
    form.submit();
  };

  const makePartnerGetCall = (partners) => {
    let url = partners.url;
    let tokenInfo = partners.parameters;
    let tokenNames: any = Object.keys(tokenInfo);
    let tokens: any;
    tokens = tokenNames
      .map((key, index) => {
        return index === 0
          ? `?${key}=${encodeURIComponent(tokenInfo[key])}`
          : `&${key}=${encodeURIComponent(tokenInfo[key])}`;
      })
      .join("");

    let newURL = `${url}${tokens}`;
    window.location.assign(newURL);
  };

  const partnerJumpFunction = async () => {
    new LoaderStateModel({
      id: "1",
      isLoading: true,
    }).$save();
    const {
      tenantDetails: {
        props: { tenantId },
      },
      selectedAccount: {
        props: { membershipId, memberId },
      },
    } = props;

    let benefitIdToPass = bId ? bId : null;
    let vendorTypeToPass =
      vendor === "cashback"
        ? vendor
        : deepLinkVendorTypes.includes(deepLinkVendor)
          ? deepLinkVendor
          : "";
    let section = vendor === "Cinema" || vendor === "MovieRental" ? vendor : "";
    return await graphQlCall(
      "query",
      ACCOUNT_DETAILS,
      {
        memberId: props.selectedAccount && memberId,
      },
      {
        tenantId: tenantId,
      }
    )
      .then((data) => {
        let account = data.data.account.find((account) => {
          return account.membershipId === membershipId;
        });
        let oneTrustCookies = window['OnetrustActiveGroups'] ? window['OnetrustActiveGroups'].split(',').filter(function (el) { return el !== "" }).join(',') : null;
        let attributes;
        if (benefitIdForCookie.includes(benefitIdToPass))
          attributes = section ? { section: section, faqSection: faqSection, OneTrustCookiesGroup: oneTrustCookies } :
            deepLinkUrl ? { deepLinkUrl: deepLinkUrl } :
              { OneTrustCookiesGroup: oneTrustCookies };
        else attributes = section ? { section: section, faqSection: faqSection } : deepLinkUrl ? { deepLinkUrl: deepLinkUrl } : null;
        if(benefitIdToPass == 1028 || benefitIdToPass == 1029) {
          attributes = { "mode": "web"}
        }
        let accountDetails = account.accountDetails;
        let packageDetails = account.package;
        let memberId = account.memberId;

        new AccountStateModel({
          id: "1",
          AccountData: account,
        }).$save();
        return graphQlCall(
          "query",
          PARTNER_JUMP_CALL,
          {
            memberId: memberId,
            packageId: packageDetails.packageId || "",
            packageRefId: packageDetails && packageDetails.PRefId,
            accountNumber: accountDetails && accountDetails.accountId,
            sortCode: accountDetails && accountDetails.accountSortCode,
            benefitId: benefitIdToPass,
            membershipId: membershipId,
            vendorType: vendorTypeToPass,
            attributes: attributes,
          },
          {
            tenantId: tenantId,
          }
        )
          .then((res) => {
            new LoaderStateModel({
              id: "1",
              isLoading: true,
            }).$save();
            const {partners} = res.data;
            if(benefitIdToPass == 1028 || benefitIdToPass == 1029) {
               if(partners.httpStatusCode == 301) {
                window.location.href = partners.url;
               }  
            }
            else {
              let httpMethod = partners.httpMethod.toLowerCase();
              httpMethod === "post"
                ? makePartnerPostCall(partners)
                : makePartnerGetCall(partners);
            }
            
          })
          .catch((error) => {
            setError(onGqlError(error));
          });
      })
      .catch((error) => {
        setError(onGqlError(error));
      });
  };

  const renderErrorBox = (backendErrors) => {
    if (backendErrors && backendErrors.length) {
      return (
        <ErrorBox
          errors={backendErrors}
          errorHeader={PARTNER_PAGE_NOT_FOUND_MESSAGE}
        />
      );
    } else {
      return;
    }
  };

  useEffect(() => {
    AuthenticationCheck()
      ? partnerJumpFunction()
      : props.history.push("/login");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ErrorBoundary>
      <Loader />
      {renderErrorBox(backendErrors)}
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => {
  let userDetails = UserDetailModel.getInsatnce("LoggedUser");
  let tenantDetails = BrandSpecificDataModel.getInsatnce("1");
  let selectedAccount = SelectedAccountModel.getInsatnce("1");
  return {
    userDetails,
    tenantDetails,
    selectedAccount,
  };
};

export default connect(mapStateToProps)(withRouter(PartnerJumpPage));
