import React from "react";
import {Helmet} from "react-helmet";
import settings from "../../services/RestAPI/axiosConfig";

export default class EUCookiesAnalytics extends React.Component<{}, {}> {

    state = {
      dataDomainScriptText: '',
    }
    renderEuCookiesScript = () => {
      let brand = process.env.REACT_APP_THEME_NAME;
      settings
      .get(process.env.PUBLIC_URL + "/setting.json")
      .then((response) => {
        const brandName = brand ? brand.split("-")[0].toUpperCase() : response.data.REACT_APP_SITE;
        let dataDomainScriptText = response.data.DATA_DOMAIN_SCRIPT[brandName];        
        if(brandName === "RBS"){
          let domainUrlHostName = window.location.hostname;
          dataDomainScriptText = dataDomainScriptText[domainUrlHostName]? dataDomainScriptText[domainUrlHostName] : dataDomainScriptText["membershipbenefits.rbs.co.uk"];
        }
        if(brandName === "ULSTER_NI"){
          let domainUrlHostName = window.location.hostname;
          if(domainUrlHostName === "ufirstaccount.com"){
            domainUrlHostName = "www.ufirstaccount.com";
          }
          dataDomainScriptText = dataDomainScriptText[domainUrlHostName]? dataDomainScriptText[domainUrlHostName] : dataDomainScriptText["www.ufirstaccount.co.uk"];          
        }
        
        this.setState({dataDomainScriptText});
      })
      .catch((error) => console.log(error));
  };

  componentDidMount(){
    this.renderEuCookiesScript();
  }

  render() {
    return (
      <>
        {
          this.state.dataDomainScriptText && <Helmet>
            <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" type="text/javascript" charSet="UTF-8"  data-domain-script={this.state.dataDomainScriptText}/>
            <script type="text/javascript">
              {`
                function OptanonWrapper() { }
                function getCookie(name) {
                  var value = "; " + document.cookie;
                  var parts = value.split("; " + name + "=");
                  if (parts.length == 2){ 
                    return true; 
                  }
                }

                function reloadOTBanner() {
                  var otConsentSdk = document.getElementById("onetrust-consent-sdk");
                  if (otConsentSdk){ 
                    otConsentSdk.remove(); 
                  }
                  if (window.OneTrust != null) {
                    OneTrust.Init();

                    setTimeout(function() {
                      OneTrust.LoadBanner();
                      var toggleDisplay = document.getElementsByClassName("ot-sdk-show-settings");

                      for (var i = 0; i < toggleDisplay.length; i++) {
                        toggleDisplay[i].onclick = function(event) {
                          event.stopImmediatePropagation();
                          window.OneTrust.ToggleInfoDisplay();
                        };
                      }
                    }, 1000);
                  }
                }
              `}
            </script>
          </Helmet>
        }
      </>
    );
  }
}