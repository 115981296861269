/*========================== Theme Variables for Natwest Silver =====================*/
const natwest_silver_button_color_scheme = {
  "--button-background": "#ffffff",
  "--buttonBorder": "#5e10b1",
  "--buttonShadow": "#666",
  "--buttonBorderTop": "#166375",
  "--buttonTextColor": "#5e10b1",
  "--primaryButtonBackground": "#1E7A8A",
  "--primaryButtonBackgroundHover": "#009eaa",
  "--secondaryButtonBackground": "#fff",
  "--secondaryButtonBorder": "#000",
  "--secondaryButtonColor": "#000",
  "--genericButtonBackground": "#5e10b1",
  "--genericButtonTextColor": "#ffffff",
  "--genericButtonBackgroundHover": "#3c1053",
   "--button-hover-background": "#ffffff",
  "--button-hover-text-color": "#3c1053",
  "--button-font-family": "RNHouseSans",
  "--block-button-font-family": "RNHouseSans",
  "--button-border-background": "#1b768b",
};

const natwest_silver_page_element_scheme = {
  //logo variables
  "--header-logo": 'url("/asset/natwest/logo/nw-secondary-header.png")',
  "--header-lg-height": "50px",
  "--header-md-height": "34px",
  "--header-sm-height": "28px",
  "--header-lg-width": "221px",
  "--header-md-width": "185px",
  "--header-sm-width": "103px",
  "--logo-url": "url('../assets/images/natwest-logo-silver.png')",
  "--loader-url": "url('../assets/images/NW-Premier-travel.gif')",
  "--primary": "#5a287d",
  "--heading": "#333333",
  "--secondary": "#fff",
  "--tertiary": "#42145f",
  "--footerBackground": "#5a287d",
  "--footer-text-color": "#5e10b1",
  "--boxShadow": "#e0d9d1",
  "--base-padding": "32px",
  "--inner-padding": "0px",
  "--section-base-background": "#ffffff",
   "--section-inner-background": "#ffffff",
  "--page-background": "#FFFFFF",
  "--root-background": "#f2f2f8",
  "--sticky-header-background": "#ffffff",
  "--RNHouseSansLight": "RNHouseSansLight",
  "--knileBlack-font-family": "KnileBlack",
  "--font-family": "RNHouseSans",
  "--quick-access-font-family": "RNHouseSans",
  "--membership-title-font-family": "RNHouseSansBold",
  "--account-dropdown-font-family": "RNHouseSans",
  "--quick-access-font-weight": "400",
  "--footer-link-font-size": "18px",
  "--footer-link-icon-font-size": "16px",
  "--notice-header-base-padding": "25px 52px 25px 32px",
  "--lilac-color":"#f2eaf9",
  "--base-margin": "32px",
  "--body-grey" : "#646068",
  "--text-box-border-color": "#646068",
  "--no-padding" : "0px",
  "--opacity": "0.85",
  "--dropdown-hover": "#5e10b1"
};

const natwest_silver_card_color_scheme = {
  "--card-background": "#F2F2F8",
 "--card-text": "#646068",
 "--card-heading": "#333333",
 "--card-icon": "#5a287d",
  "--card-header--separator": "#cccfd0",
  "--home-margin-fix": "0px 16px",
  "--card-footer-text-font-size": "18px",
  "--card-footer-color": "#5e10b1",
};

const natwest_silver_image_banner_color_scheme = {
  "--title-first-child":  "#5a287d",
  "--title-second-child": "#5a287d",
  "--image-banner-button-background": "#FFFFFF",
  "--image-banner-button-hover-background": "#30909e",
  "--image-banner-button-border": "#1b768b",
  "--image-banner-button-color": "#ffffff",
};

const natwest_silver_misc_elements_color_scheme = {
  "--link-color": "#5e10b1",
  "--link-hover-color": "#5e10b1",
  "--link-active-color": "#cccfd0",
  "--link-active-color-dark": "#3c1053",
  "--info-text-color": "#646068",
  "--counterBackground": "#42145f",
  "--calendarActive": "#42145f",
  "--calendarRange": "#ccbed4",
  "--loadMoreLink": "#42145F",
  "--discountTextBackground": "#f7f7f5",
  "--subTitle": "#42145f",
  "--optionalText": "#42145f",
  "--importantNoteBg": "#dfd9d1",
  "--tabActiveBgColor": "#42145f",
  "--tabInactiveBgColor": "#ccbed4",
  "--noteBackground": "#dfd9d1",
  "--linkUnderline": "underline",
  "--cookies-header-background": "#ecedfc",
  "--error-color": "#CF223F",
  "--logout-text": "#333333",
  "--make-claim-icon-color": "#5a287d",
};
const natwest_silver_accordion_color_scheme = {
  "--accordion-primary": "#5e10b1",
  "--accordion-secondary": "#FFF",
};
const natwest_silver_forms_color_scheme = {
  "--formInputBgColor": "#EEEDE9",
  "--formContainerBgColor": "#fff",
  "--formFieldWrapper": "#fff",
};

const natwest_silver_change_password_color_scheme = {
  "--primary": "#5a287d",
  "--container-background-color": "#ffffff",
  "--change-password-content-background": "#ffffff",
  "--change-password-input-textbox-background": "#EEEDE9",
  "--change-password-error-color": "#DB1A31",
  "--change-password-button-background": "#1E7A8A",
  "--error-message-color": "#fff",
  "--save-changes-button-border-bottom": "#1b768b",
  "--save-changes-button-border-top": "#43c9d2",
  "--textbox-outline-color": "#ffbf47",
  "--change-page-container-padding": "0px 32px 32px 32px",
  "--change-page-container-inner-padding": "32px",
};
export const natwest_silver_theme_vars = {
  ...natwest_silver_button_color_scheme,
  ...natwest_silver_page_element_scheme,
  ...natwest_silver_card_color_scheme,
  ...natwest_silver_image_banner_color_scheme,
  ...natwest_silver_misc_elements_color_scheme,
  ...natwest_silver_accordion_color_scheme,
  ...natwest_silver_forms_color_scheme,
  ...natwest_silver_change_password_color_scheme,
};
