import { getBrandName, getAccountType } from "services/RestAPI/exploreCalls";
import { SelectedAccountModel } from "redux/models/SelectedAccountModel";
import { getBenefitPath } from "utlis/generalUtils";

const QuickAccessContent = {
  "rbs-black": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },
    {
      title: "Key documents",
      links: [
        {
          link_title: "Black Account Benefit Terms",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/royal_bank_of_scotland/personal/current-accounts/documents/black-account-benefit-terms.pdf",
        },
        {
          link_title: "Black Account Insurance Product Information Document",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/currentaccounts/downloads/Reward/Black-Account-Insurance-Policy-Summaries-including-Keyfacts-about-our-insurance-services-.pdf",
        },
        {
          link_title: "Black Account Travel Insurance Policy",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/royal_bank_of_scotland/personal/current-accounts/documents/BlackXAccountXTravelXInsuranceXPolicy.pdf",
        },
        {
          link_title: "What You Need to Know",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/Insurance/downloads/keyfacts_doc.pdf",
        },
      ],
    },
  ],
  "rbs-silver": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },
    {
      title: "Key documents",
      links: [
        {
          link_title: "Silver Account Benefit Terms",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/currentaccounts/downloads/Reward/Silver-Benefit-Terms.pdf",
        },
        {
          link_title: "Silver Account Insurance Product Information Document",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/natwest_com/tools/mta2018tcs/RBS-Reward-Silver-Product-Info.pdf",
        },
        {
          link_title: "Silver Account Travel Insurance Policy",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/currentaccounts/downloads/Reward/Silver-Travel-Insurance-Policy.pdf",
        },
        {
          link_title: "What You Need to Know",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/Insurance/downloads/keyfacts_doc.pdf",
        },
      ],
    },
  ],
  "rbs-platinum": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "Platinum Account Benefit Terms",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/currentaccounts/downloads/Reward/Platinum-Benefit-Terms.pdf",
        },
        {
          link_title: "Platinum Account Insurance Product Information Document",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/currentaccounts/downloads/Reward/Platinum-Insurance-Policy-Summaries-including-Keyfacts-about-our-insurance-services-.pdf",
        },
        {
          link_title:
            "Platinum Account Worldwide Family Travel Insurance Policy",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/currentaccounts/downloads/Reward/Platinum-Travel-Insurance-Policy.pdf",
        },
        {
          link_title: "What You Need to Know",
          linkType: "downloadable",
          url_path:
            "https://personal.rbs.co.uk/content/dam/rbs_co_uk/Insurance/downloads/keyfacts_doc.pdf",
        },
      ],
    },
  ],
  "natwest-black": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "Black Account Benefit Terms",
          linkType: "downloadable",
          url_path:"https://personal.natwest.com/content/dam/natwest/premier/documents/current%20accounts/Black-Account-Benefit-Terms.pdf",
        },
        {
          link_title: "Black Account Travel Insurance Policy",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest/premier/documents/current%20accounts/NW-RewardBlack-TravelInsurance.pdf",
        },
        {
          link_title: "Black Account Insurance Product Information Document",
          linkType: "downloadable",
          url_path: "https://personal.natwest.com/content/dam/natwest/premier/documents/current%20accounts/Black-Insurance-Policy-Summaries-including-Keyfacts-about-our-insurance-services-.pdf",
        },
        {
          link_title: "What You Need to Know",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/insurance/downloads/keyfacts_doc.pdf",
        },
      ],
    },
  ],
  "natwest-silver": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "Silver Account Benefit Terms",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/currentaccounts/downloads/Reward/Silver-Benefit-Terms.pdf",
        },
        {
          link_title: "Silver Account Insurance Product Information Document",
          linkType: "downloadable",
          url_path: "https://personal.natwest.com/content/dam/natwest_com/currentaccounts/downloads/Reward/Silver-Insurance-Policy-Summaries-including-Keyfacts-about-our-insurance-services-.pdf",
        },
        {
          link_title: "Silver Account Travel Insurance Policy",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/currentaccounts/downloads/Reward/Silver-Travel-Insurance-Policy.pdf",
        },
        {
          link_title: "What You Need to Know",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/insurance/downloads/keyfacts_doc.pdf",
        },
      ],
    },
  ],
  "natwest-platinum": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "Platinum Account Benefit Terms",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/currentaccounts/downloads/Reward/Platinum-Benefit-Terms.pdf",
        },
        {
          link_title: "Platinum Account Insurance Product Information Document",
          linkType: "downloadable",
          url_path:"https://personal.natwest.com/content/dam/natwest_com/currentaccounts/downloads/Reward/Platinum-Insurance-Policy-Summaries-including-Keyfacts-about-our-insurance-services-.pdf",
        },
        {
          link_title:
            "Platinum Account Worldwide Family Travel Insurance Policy",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/currentaccounts/downloads/Reward/Platinum-Travel-Insurance-Policy.pdf",
        },
        {
          link_title: "What You Need to Know",
          linkType: "downloadable",
          url_path:
            "https://personal.natwest.com/content/dam/natwest_com/insurance/downloads/keyfacts_doc.pdf",
        },
      ],
    },
  ],
  "ulster_ri-gold": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "uFirst Gold user guide",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.ie/content/dam/Ulster/documents/RI%20Region/ri-personal-ulst-brochures/ufirst-gold-user-guide-ULST1664RI.pdf",
        },
        {
          link_title: "A guide to personal Account fees and Interest",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.ie/content/dam/Ulster/documents/RI%20Region/ri-personal-ulst-brochures/a-guide-to-personal-accounts-fees-and-interest-ULST1376RI.pdf",
        },
      ],
    },
  ],
  "ulster_ri-private": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },
    {
      title: "Key documents",
      links: [
        {
          link_title: "uFirst Private user guide",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.ie/content/dam/Ulster/documents/RI%20Region/ri-personal-ulst-brochures/private-banking-user-guide-ULST773RI.pdf",
        },
        {
          link_title: "A guide to personal Account fees and Interest",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.ie/content/dam/Ulster/documents/RI%20Region/ri-personal-ulst-brochures/a-guide-to-personal-accounts-fees-and-interest-ULST1376RI.pdf",
        },
      ],
    },
  ],
  "ulster_ri-ufirst": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "uFirst user guide",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.ie/content/dam/Ulster/documents/RI%20Region/ri-personal-ulst-brochures/ufirst-user-guide-ULST881RI.pdf",
        },
        {
          link_title: "A guide to personal Account fees and Interest",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.ie/content/dam/Ulster/documents/RI%20Region/ri-personal-ulst-brochures/a-guide-to-personal-accounts-fees-and-interest-ULST1376RI.pdf",
        },
      ],
    },
  ],
  "ulster_ni-gold": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "uFirst Gold user guide",
          linkType: "downloadable",
          url_path: "https://digital.ulsterbank.co.uk/content/dam/Ulster/documents/ni-region/ni-personal-ulst-brochures/ufirst-gold-user-guide-ULST1554NI.pdf",
        },
        {
          link_title: "A guide to personal Account fees and Interest",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.co.uk/content/dam/Ulster/documents/ni-region/ni-personal-ulst-brochures/a-guide-to-personal-accounts-fees-and-interest-ULST1551NI.pdf",
        },
        {
          link_title:
            "Ufirst Gold Account Insurance Product Information document",
          linkType: "downloadable",
          url_path: "https://digital.ulsterbank.co.uk/content/dam/Ulster/documents/ni-region/ni-personal-ulst-brochures/ubn-ufirst-gold-account-insurance-product-information-documents.pdf",
        },
      ],
    },
  ],
  "ulster_ni-private": [
    {
      title: "Manage your account",
      links: [
        {
          link_title: "Account summary",
          linkType: "internal",
          url_path: "/account/manage-account",
        },
        {
          link_title: "Benefit usage history",
          linkType: "internal",
          url_path: "/account/recent-benefit-usage",
        },
        {
          link_title: "Change email",
          linkType: "internal",
          url_path: "/account/change-email",
        },
        {
          link_title: "Change password",
          linkType: "internal",
          url_path: "/account/change-password",
        },
      ],
    },

    {
      title: "Key documents",
      links: [
        {
          link_title: "Private Banking user guide",
          linkType: "downloadable",
          url_path: "https://digital.ulsterbank.co.uk/content/dam/Ulster/documents/ni-region/ni-personal-ulst-brochures/private-banking-user-guide-ULST691NI.pdf",
        },
        {
          link_title: "A guide to personal Account fees and Interest",
          linkType: "downloadable",
          url_path:
            "https://digital.ulsterbank.co.uk/content/dam/Ulster/documents/ni-region/ni-personal-ulst-brochures/a-guide-to-personal-accounts-fees-and-interest-ULST1551NI.pdf",
        },
        {
          link_title:
            "Ufirst Private Account Insurance Product Information document",
          linkType: "downloadable",
          url_path: "https://digital.ulsterbank.co.uk/content/dam/Ulster/documents/ni-region/ni-personal-ulst-brochures/ubn-ufirst-private-account-insurance-product-information-documents.pdf",
        },
      ],
    },
  ],
};

export const updateQuickAccessContent = async () => {
  let themeName = `${getBrandName()}-${getAccountType()}`;
  const selectedAccount = SelectedAccountModel.list();

  let benefitsData =
    selectedAccount &&
    selectedAccount[0] &&
    selectedAccount[0].props &&
    selectedAccount[0].props.benefits;

  let yourBenefitObj = {
    title: "Your benefits",
    links: [
      {
        link_title: "View all benefits",
        linkType: "internal",
        url_path: "/home",
      },
    ],
  };

  (benefitsData || []).map((benefitObj, i) => {
    const { benefitName, benefitId, benefitType } = benefitObj;
    let explorePath = getBenefitPath(benefitName);
    let explorePathWithId = `${explorePath}?bId=${benefitId}`;

    let linksObj = {
      link_title: benefitName,
      linkType: "benefit",
      url_path: explorePathWithId,
      benefitId: benefitId,
    };
    // if the benefit type is disabled then link will not be added in the Quick Access
    let removeQuickAccessLink = (benefitType && ["DISABLED", "REDIRECTION_DISABLE_EXPLORE", "PARTNER_JUMP_DISABLE_EXPLORE"].includes(benefitType)) || false;
    // TODO: 3202 - Needs to remove the condition on the travel money issue resolve 
    removeQuickAccessLink = benefitId === 1006 ? true : removeQuickAccessLink;

    if(!removeQuickAccessLink) {
      yourBenefitObj.links.push(linksObj);
    }
    return yourBenefitObj;
  });

  if (themeName) {
    if (benefitsData && benefitsData.length !== 0) {
      if (
        QuickAccessContent[themeName] &&
        QuickAccessContent[themeName][1] &&
        QuickAccessContent[themeName][1].title === "Your benefits"
      ) {
        QuickAccessContent[themeName][1] = yourBenefitObj;
      } else {
        themeName &&
          QuickAccessContent[themeName] &&
          QuickAccessContent[themeName].splice(1, 0, yourBenefitObj);
      }
    } else {
      QuickAccessContent[themeName] &&
        QuickAccessContent[themeName].length > 2 &&
        QuickAccessContent[themeName].splice(1, 1);
    }
    return QuickAccessContent[themeName];
  }
};
