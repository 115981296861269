/**
 * @author Swapnali Ingle
 * @param {text,handleClick,disabled} props
 * @param {string} text-value can be string
 * @param {Function} handleClick-value can be Function
 * @param {boolean} disabled-value can be boolean
 */

import * as React from "react";
import styles from "./button.module.scss";

export interface Props {
  text?: string;
  handleClick?: () => void;
  disabled: boolean;
  classNames?: string;
  ariaLabel?: string;
}

const Button: React.FC<Props> = (props) => {
  let { text, handleClick, disabled, classNames } = props;

  return handleClick ? (
    <button
      className={`${styles.button} ${classNames}`}
      onClick={handleClick}
      disabled={disabled}
      data-test="button"
      aria-label={props.ariaLabel || text}
    >
      {text}
    </button>
  ) : (
    <button
      type="submit"
      className={`${styles.button} ${classNames}`}
      disabled={disabled}
      data-test="submit-button"
      aria-label={props.ariaLabel || text}
    >
      {text}
    </button>
  );
};

export default Button;
