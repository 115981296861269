import React from "react";
import "./icon.component.scss";

interface IProps {
  iconName: string;
  tabIndex ?: number;
  title?: string;
}
const Icon: React.FC<IProps> = (props) => {
  return (
  <span className={props.iconName} tabIndex={props.tabIndex} aria-label={props.title}
  aria-expanded={props.iconName === "icon-chevron-normal-up" ? true : false} />
  );
};

export default Icon;
