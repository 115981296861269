import { Fragment } from "react";
import styles from "./forgotPasswordSecondPage.module.scss";
import { Link,withRouter } from "react-router-dom";
import * as yup from "yup";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { useForm } from "react-hook-form";
import * as errorMessage from "../../../config/errorMessages";
import { ErrorBox } from "../../Login/ErrorBox";
import { FormFieldError } from "components/FormFieldError";
import { FormErrorMessage } from "components/FormErrorMessage";
import { SpaceCharRegex } from "config/constants";
import { Icon } from "components/Icon";
import { PrimaryButton } from "components/PrimaryButton";
import { useYupValidationResolver } from "utlis/generalUtils";
interface IProps {
  formErrorMessage?: string;
  errorSet: any;
  onHandleSubmit: any;
}

export const ForgotPasswordSecondPage = (props: IProps) => {
  const validationSchema = yup.object({
    password: yup
      .string()
      .min(8, errorMessage.PASSWORD_VALIDATION_MIN_CHARS)
      .max(80, errorMessage.PASSWORD_VALIDATION_MAX_CHARS)
      .matches(/[a-z]/, errorMessage.PASSWORD_VALIDATION_LOWERCASE_CHAR)
      .matches(/[A-Z]/, errorMessage.PASSWORD_VALIDATION_UPPERCASE_CHAR)
      .matches(/[0-9]/, errorMessage.PASSWORD_VALIDATION_NUMBER)
      .matches(
        /^((?!password).)*$/i,
        errorMessage.PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM
      )
      .test(
        "password",
        errorMessage.PASSWORD_VALIDATION_SPACE_CHARACTER,
        (value) => {
          if (SpaceCharRegex.test(value!)) {
            return false;
          } else return true;
        }
      )
      .test(
        "password",
        errorMessage.PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM,
        (value) => {
          let newValue = value!.toLowerCase();
          for (let i = 0; i < value!.length; i++) {
            if (
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) - 1 &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) - 2 &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 3) - 3
            ) {
              return false;
            } else if (
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 3)
            ) {
              return false;
            }
          }
          return true;
        }
      )
      .required(errorMessage.PASSWORD_REQUIRED_ERROR),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), ""], errorMessage.PASSWORD_MATCH)
      .required(errorMessage.PASSWORD_VALIDATION_REQUIRED),
  });
  const resolver = useYupValidationResolver(validationSchema);
  const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver,
  });
  let { onHandleSubmit, errorSet, formErrorMessage } = props;

  const renderErrorBox = () => {
    if (errorSet[0] === 'HUB018') {
      return errorSet.length ? (
        <div className={styles.errorBox_container}>
        <div className={styles.errorBox_header}>
          <div>
            <Icon iconName="icon-info-circle" />
          </div>
  
          <div>{errorMessage.ERROR_BOX_HEADER}</div>
        </div>
        <div className={styles.errorBox_body}>
            <ul>
              <li>For security purposes, the link to reset your password has now expired. Please submit a new request through the <Link to="/forgot-password">reset your password</Link> page.</li>
            </ul>
        </div>
      </div>
      ) : null;
    } else {
      if (errorSet && errorSet.length) {
        let errorMsg = errorSet.map(() => {
              return errorMessage.DEFAULT_ERROR_MESSAGE;
        });
        return errorSet.length ? (
          <ErrorBox
            errors={errorMsg}
            errorHeader={errorMessage.ERROR_BOX_HEADER}
          />
        ) : null;
      } else {
        return;
      }
    }
  };
  const formErrorMsg = () => {
    let formErrors;
    if (Object.keys(errors).length) {
      errorSet.length = 0;
      formErrors = (
        <FormErrorMessage errors={{ ...errors }} errorMsg={formErrorMessage} />
      );
    }
    return formErrors;
  };
  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.main_container}>
        <div className={styles.forgot_password_response__heading}>
          Change and reset your password
        </div>
        <div className={styles.forgot_password_response__sub_heading}>
          All fields are mandatory.
        </div>
        {formErrorMsg()}
        {renderErrorBox()}
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          noValidate
          className={styles.forgot_pasword__form_wrapper}
        >
          <div className={styles.forgot_password_response__form_fields}>
            <div
              className={
                styles.forgot_password_response__left_column_form_fields
              }
            >
              <div className={styles.forgot_password_response__form_field_name}>
                Enter new password *
              </div>
              {errors.password && (
                <FormFieldError errorText={errors.password.message} errorId={"password"}/>
              )}
              <input
                className={styles.forgot_password_response__form_field}
                aria-labelledby="password"
                {...register('password')}
                type="password"
                autoComplete="off" />
              <div className={styles.forgot_password_response__form_field_name}>
                Confirm new password *
              </div>
              {errors.confirmPassword && (
                <FormFieldError errorText={errors.confirmPassword.message} errorId={"confirmPassword"}/>
              )}
              <input
                className={styles.forgot_password_response__form_field}
                aria-labelledby="confirmPassword"
                {...register('confirmPassword')}
                type="password"
                autoComplete="off" />
            </div>
            <div
              className={
                styles.forgot_password_response__middle_column_form_fields
              }
            ></div>
            <div
              className={
                styles.forgot_password_response__right_column_form_fields
              }
            >
              <div className={styles.forgot_password_response__information}>
                <label>Your password must include:</label>
              </div>
              <ul>
                <li>At least 8 characters</li>
                <li>At least one capital letter</li>
                <li>At least one small letter</li>
                <li>At least one number</li>
              </ul>
              <div className={styles.forgot_password_response__information}>
                <label> Your password cannot include more than 3 consecutive letters or
                numbers, or the word 'password'.</label>
              </div>
            </div>
          </div>
          <div className={styles.forgot_password_response__btn}>
            <PrimaryButton
              text="Next"
              disabled={false}
              classNames={styles.forgot_password_response__btn__next_btn}
              data-test="next-button"
            />
          </div>
        </form>
      </div>
      <FooterComponent />
    </Fragment>
  );
};

export default withRouter(ForgotPasswordSecondPage);
