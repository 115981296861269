import { natwestBlackTheme, rbsBlackTheme } from "./black.theme";
import { natwestPlatinumTheme, rbsPlatinumTheme } from "./platinum.theme";
import { rbsSilver, natwestSilverTheme } from "./silver.theme";
import { ulsterNIPrivateTheme } from "./private.theme";
import { ulsterNIGoldTheme } from "./gold.theme";
import { ulsterRIPrivateTheme } from "./private.theme";
import { ulsterRIGoldTheme } from "./gold.theme";
import { ulsterRIuFirstTheme } from "./uFirst.theme";

const Themes = {
  natwestBlackTheme,
  natwestSilverTheme,
  rbsBlackTheme,
  natwestPlatinumTheme,
  rbsPlatinumTheme,
  rbsSilver,
  ulsterNIPrivateTheme,
  ulsterNIGoldTheme,
  ulsterRIPrivateTheme,
  ulsterRIGoldTheme,
  ulsterRIuFirstTheme
};

export default Themes;
