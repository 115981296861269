import gql from "graphql-tag";

export const GET_CAPTCHA_KEY = gql`
  query {
    loadContext {
      configurations
    }
  }
`;

export const LOGIN_QUERY = gql`
  mutation($uname: String, $pass: String) {
    login(input: { userName: $uname, password: $pass }) {
      accessToken
      expiresIn
      memberId
      membershipId
      refreshExpiresIn
      refreshToken
      lastLogin
    }
  }
`;

export const LOGIN_WITH_CIN = gql`
  mutation($cin: String) {
    loginWithCIN(input: { cin: $cin}) {
      accessToken
      expiresIn
      memberId
      membershipId
      refreshExpiresIn
      refreshToken
      lastLogin
      statusCode 
      message 
      firstName 
      lastName 
      email
      packageId
    }
  }
`;

export const GET_HEADER_DETAILS = gql`
  query memberById($id: String!) {
    member(id: $id) {
      id
      firstName
      lastName
      email
      cin
      account {
        createdOn
        membershipId
        memberId
        accountId
        memberNumber
        package {
          packageId
          PRefId
          packageName
          description
          siteTheme
          productDefinition {
            id
            description
            productDefinitionRefId
          }
          benefits {
            benefitId
            benefitName
            shortDescription
            benefitType
            vendorId
            benefitDisplayOrder
            redirectionUrl
          }
        }
      }
    }
  }
`;

export const GET_MEMBER_DETAILS = gql`
  query memberById($id: String!) {
    member(id: $id) {
      id
      cin
      firstName
      lastName
      addressLine1
      addressLine2
      addressLine3
      city
      postalCode
      email
      account {
        createdOn
        package {
          packageId
          PRefId
          packageName
        }
      }
    }
  }
`;

export const GET_ACCOUNT_TYPE = gql`
  query($sid: Long) {
    package(solicitationId: $sid) {
      PRefId
      packageId
      packageName
      benefits {
        benefitName
      }
    }
  }
`;

export const CONTACT_US_AFTER_LOGIN = gql`
  mutation contactUsLoggedInUser(
    $query: String
    $subject: String
    $brandId: Long
    $firstName: String
    $lastName: String
    $emailId: String
    $memberID: String
    $membershipId: String
  ) {
    contactUsLoggedInUser(
      input: {
        query: $query
        subject: $subject
        brandId: $brandId
        memberDetailMessage: {
          firstName: $firstName
          lastName: $lastName
          emailId: $emailId
          memberID: $memberID
          membershipId: $membershipId
        }
      }
    ) {
      returnDetails {
        code
        messageSource
        messageDescription
        errorDetails
      }
    }
  }
`;

export const CONTACT_US_BEFORE_LOGIN = gql`
  mutation contactUsAnonymousUser(
    $query: String
    $subject: String
    $brandId: Long
    $fullName: String
    $emailId: String
  ) {
    contactUsAnonymousUser(
      input: {
        query: $query
        subject: $subject
        brandId: $brandId
        fullName: $fullName
        emailId: $emailId
      }
    ) {
      returnDetails {
        code
        messageSource
        messageDescription
        errorDetails
      }
    }
  }
`;

export const CHANGE_PASSWORD_AFTER_LOGIN = gql`
  mutation(
    $oldPassword: String
    $newPassword: String
    $memberId: String
    $firstName: String
    $lastName: String
    $membershipId: String
    $emailId: String
  ) {
    changePassword(
      input: {
        oldPassword: $oldPassword
        newPassword: $newPassword
        memberId: $memberId
        firstName: $firstName
        lastName: $lastName
        membershipId: $membershipId
        emailId: $emailId
      }
    ) {
      code
      message
    }
  }
`;

export const SIGNUP_QUERY_AFTER_VALIDATION = gql`
  mutation(
    $email: String
    $password: String
    $memberId: String
    $membershipId: String
    $packageId: String
    $firstName: String
    $lastName: String
  ) {
    signupAndLogin(
      input: {
        email: $email
        password: $password
        memberID: $memberId
        membershipId: $membershipId
        packageId: $packageId
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      accessToken
      expiresIn
      memberId
      membershipId
      refreshExpiresIn
      refreshToken
      lastLogin
    }
  }
`;

export const SIGNUP_QUERY_VALIDATE_USER = gql`
  query(
    $firstName: String
    $lastName: String
    $birthDate: LocalDate
    $accountId: String
    $accountSortCode: String
    $brandName: String
  ) {
    validateSignup(
      input: {
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $birthDate
        brandName: $brandName
        signupAccountDetails: {
          accountId: $accountId
          accountSortCode: $accountSortCode
        }
      }
    ) {
      statusCode
      message
      memberId
      membershipId
      packageId
      firstName
      lastName
    }
  }
`;
export const CHANGE_EMAIL = gql`
  mutation(
    $email: String
    $memberId: String
    $membershipId: String
    $firstName: String
    $lastName: String
  ) {
    changeEmail(
      input: {
        email: $email
        memberId: $memberId
        membershipId: $membershipId
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      code
      message
    }
  }
`;

export const FORGOT_EMAIL = gql`
  mutation(
    $lastName: String
    $firstName: String
    $birthDate: LocalDate
    $accountId: String
    $accountSortCode: String
    $brandName: String
  ) {
    forgotUsername(
      input: {
        lastName: $lastName
        firstName: $firstName
        dateOfBirth: $birthDate
        brandName: $brandName
        forgotAccountDetails: {
          accountId: $accountId
          accountSortCode: $accountSortCode
        }
      }
    ) {
      userName
      returnDetails {
        code
        messageSource
        messageDescription
      }
    }
  }
`;

export const EVENT_BOOKING_QUERY = gql`
  mutation eventBooking(
    $firstName: String
    $lastName: String
    $houseName: String
    $postCode: String
    $telephoneNumber: String
    $dayTimeTelephoneNumber: String
    $mobileNumber: String
    $eventName: String
    $dateOfEventChoice1: LocalDate
    $dateOfEventChoice2: LocalDate
    $timeOfEventChoiceHours1: String
    $timeOfEventChoiceMinutes1: String
    $timeOfEventChoiceHours2: String
    $timeOfEventChoiceMinutes2: String
    $venueName: String
    $cityOfVenue: String
    $ticketCount: String
    $seatingChoice1: String
    $seatingChoice2: String
    $concessions: String
    $specialRequirement: String
    $memberId: String
    $membershipId: String
    $emailId: String
    $memberNumber: String
    $packageId: Long
  ) {
    eventBooking(
      input: {
        eventMemberInfo: {
          lastName: $lastName
          firstName: $firstName
          houseName: $houseName
          postCode: $postCode
          telephoneNumber: $telephoneNumber
          dayTimeTelephoneNumber: $dayTimeTelephoneNumber
          mobileNumber: $mobileNumber
        }
        eventInfo: {
          eventName: $eventName
          dateOfEventChoice1: $dateOfEventChoice1
          dateOfEventChoice2: $dateOfEventChoice2
          timeOfEventChoiceHours1: $timeOfEventChoiceHours1
          timeOfEventChoiceMinutes1: $timeOfEventChoiceMinutes1
          timeOfEventChoiceHours2: $timeOfEventChoiceHours2
          timeOfEventChoiceMinutes2: $timeOfEventChoiceMinutes2
        }
        eventVenueInfo: { venueName: $venueName, cityOfVenue: $cityOfVenue }
        eventTicketInfo: {
          ticketCount: $ticketCount
          seatingChoice1: $seatingChoice1
          seatingChoice2: $seatingChoice2
        }
        eventMetaInfo: {
          concessions: $concessions
          specialRequirement: $specialRequirement
        }
        memberDetail: {
          memberID: $memberId
          membershipId: $membershipId
          lastName: $lastName
          firstName: $firstName
          emailId: $emailId
        }
        memberNumber: $memberNumber
        packageId: $packageId
      }
    ) {
      returnDetails {
        code
        errorDetails
        messageDescription
        messageSource
      }
    }
  }
`;
export const FORGOT_PASSWORD_TOKEN_GEN = gql`
  mutation(
    $email: String
    $firstName: String
    $lastName: String
    $birthDate: LocalDate
    $accountSortCode: String
    $accountId: String
    $brandId: Long
    $brandName: String
  ) {
    forgotPassword(
      input: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        dateOfBirth: $birthDate
        brandId: $brandId
        brandName: $brandName
        forgotAccountDetails: {
          accountSortCode: $accountSortCode
          accountId: $accountId
        }
      }
    ) {
      returnDetails {
        code
        messageDescription
        messageSource
        errorDetails
      }
    }
  }
`;

export const FORGOT_PASSWORD_RESET = gql`
  mutation($password: String, $forgotPasswordToken: String) {
    resetPassword(
      input: { password: $password, forgotPasswordToken: $forgotPasswordToken }
    ) {
      returnDetails {
        code
        errorDetails
        messageDescription
        messageSource
      }
    }
  }
`;

export const PARTNER_JUMP_CALL = gql`
  query(
    $memberId: String
    $packageId: String
    $accountNumber: String
    $sortCode: String
    $packageRefId: String
    $benefitId: String
    $membershipId: String
    $vendorType: String
    $attributes: Map_String_StringScalar
  ) {
    partners(
      input: {
        memberId: $memberId
        packageId: $packageId
        accountNumber: $accountNumber
        sortCode: $sortCode
        packageRefId: $packageRefId
        benefitId: $benefitId
        membershipId: $membershipId
        vendorType: $vendorType
        attributes: $attributes
      }
    ) {
      httpMethod
      url
      parameters
      httpStatusCode
    }
  }
`;

export const GET_USAGE_HISTORY = gql`
  query($memberNumber: String) {
    benefitHistory(memberNumber: $memberNumber) {
      usageId
      usageType
      usageDate
      bookingType
      eventCategory
      featureName
      ticketCount
      orderAmount
      discountAmount
      settlementAmount
      eventName
      airportDesc
      travelLocation
      passengerCount
      claimAmount
    }
  }
`;

export const CASH_BACK_REQUEST = gql`
  mutation($bookingRef: String, $memberId: String) {
    requestCashbackSummary(
      input: { bookingRef: $bookingRef, memberId: $memberId }
    ) {
      returnDetails {
        code
        errorDetails
        messageDescription
        messageSource
      }
    }
  }
`;

export const ACCOUNT_DETAILS = gql`
  query($memberId: String) {
    account(memberId: $memberId) {
      accountDetails {
        accountId
        accountSortCode
      }
      package {
        PRefId
        packageId
      }
      memberNumber
      membershipId
      memberId
    }
  }
`;

export const LOGOUT_CALL = gql`
  mutation($refreshToken: String!) {
    logout(refreshToken: $refreshToken)
  }
`;

export const GET_JWKS = gql`
  query{
    lwbGetJWKS{
      keys{
        kty
        kid
        use
        n
        e
      }
      __typename
   }
  }
`;
