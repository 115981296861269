import React from "react";
import { Route, Switch } from "react-router-dom";
import SignUpSecondPageContainer from "./SignUpSecondPage/SignUpSecondPageContainer";
import SignUpSuccessPage from "./SignUpSuccessPage/SignUpSuccess";
import SignUpFormContainer from "./SignUpFirstPage/SignUpFormContainer";
import ActivateMemberContainer from "routes/ActivateMember/ActivateMemberContainer";

const WizardForm: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/user-signup" component={SignUpFormContainer} />
      <Route
        exact
        path="/user-signup/registration"
        component={SignUpSecondPageContainer}
      />
      <Route
        exact
        path="/user-signup/registration/success"
        component={SignUpSuccessPage}
      />
      <Route
      exact
      path="/user-signup/activate-member"
      component={ActivateMemberContainer}
      />
    </Switch>
  );
};

export default WizardForm;
