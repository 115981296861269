import React, { Fragment } from "react";
import styles from "./form-error-message.module.scss";
import { Icon } from "components/Icon";
import classNames from "classnames";

interface FormErrorMessageProps {
  errors?: any;
  errorMsg?: string;
  className?: string;
}

const FormErrorMessage: React.FC<FormErrorMessageProps> = ({
  errors,
  errorMsg,
  className,
}) => {
  let errorKeys = Object.keys(errors);
  return (
    <Fragment>
      <div className={styles.error_container}>
        <div className={styles.error_msg_box}>
          <div className={styles.error_msg_icon}>
            <Icon iconName="icon-exclamation-circle" />
          </div>
          <div className={styles.error_msg_text}>
            {!(errorMsg || "").trim()
              ? `It looks like something has gone wrong. Please check the error below
            before proceeding.`
              : errorMsg}
          </div>
        </div>
        <div className={classNames(styles.error_messages, `${className}`)}>
          <ul>
            {(errorKeys || []).map((field, index) => {
              return <li key={"Error-msg" + index}>{errors[field].message}</li>;
            })}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default FormErrorMessage;
