import { Theme } from "../interfaces";
import { rbs_black_theme_vars } from "./themeVariables/rbs";
import { natwest_black_theme_vars } from "./themeVariables/natwest";
import { Typography } from "./typography";

export const natwestBlackTheme: Theme = {
  name: "natwest-black",
  properties: {
    ...natwest_black_theme_vars,
    ...Typography.properties
  }
};
export const rbsBlackTheme: Theme = {
  name: "rbs-black",
  properties: {
    ...rbs_black_theme_vars,
    ...Typography.properties
  }
};
