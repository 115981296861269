import { BrandSpecificUrlsModel } from "redux/models/brandSpecificUrlModel";
import Themes from "./themeConfig/themeSettings";
import { BrandWiseThemeColor } from "./themeConfig/brandWiseColor.theme";
import { ThemeNameModel } from "redux/models/ThemeNameModel";
import { BrandSpecificDataModel } from "redux/models/BrandSpecificDataModel";
import cssVars from "css-vars-ponyfill";

export const settingURL = process.env.PUBLIC_URL + "/setting.json";

export const setThemeBrandWise = (brand, settingResponse) => {
  const {
    BRAND_SPECIFIC_URL,
    BRAND_SPECIFIC_TENANT_ID,
    BRAND_ID,
    BRAND_NAME,
  } = settingResponse;
  let siteTheme = brand ? brand : process.env.REACT_APP_THEME_NAME;

  for (const theme in Themes) {
    const brandName = Themes[theme];
    if (brandName.name === siteTheme) {
      let rootDiv = document.getElementsByTagName("body")[0];
      for (const property in brandName.properties) {
        rootDiv &&
          rootDiv.style.setProperty(property, brandName.properties[property]);
      }
      cssVars({
        variables: brandName.properties,
      });
    }
    new ThemeNameModel({ id: "1", name: siteTheme }).$save();
  }

  setBrandWiseThemeColor(siteTheme);
  setUrlsBrandWise(BRAND_SPECIFIC_URL, siteTheme);
  setDataBrandWise(siteTheme, BRAND_SPECIFIC_TENANT_ID, BRAND_ID, BRAND_NAME);
};

const setBrandWiseThemeColor = (brand) => {
  for (const theme in BrandWiseThemeColor) {
    const brandName = BrandWiseThemeColor[theme];
    if (brandName.name === brand.split("-")[0]) {
      let rootDiv = document.getElementsByTagName("body")[0];
      for (const property in brandName.properties) {
        rootDiv &&
          rootDiv.style.setProperty(property, brandName.properties[property]);
      }
      cssVars({
        variables: brandName.properties,
      });
    }
  }
};

const setUrlsBrandWise = (data, brandName) => {
  let brand = brandName.split("-");
  let themeName;
  if (brand && brand.length) {
    themeName = brand[0].toUpperCase();
    new BrandSpecificUrlsModel({
      id: "1",
      brandSpecificUrls: data[themeName],
    }).$save();
  }
};

const setDataBrandWise = (siteTheme, tenantId, brandId, brandName) => {
  let brand = siteTheme.split("-");
  let themeName;
  if (brand && brand.length) {
    themeName = brand[0].toUpperCase();
    new BrandSpecificDataModel({
      id: "1",
      tenantId: tenantId[themeName],
      brandId: brandId[themeName],
      brandName: brandName[themeName],
    }).$save();
  }
};
