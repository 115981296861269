import { Theme } from "../interfaces";
import { natwest_silver_theme_vars } from "./themeVariables/natwest";
import { rbs_silver_theme_vars } from "./themeVariables/rbs";
import { Typography } from "./typography";

export const rbsSilver: Theme = {
  name: "rbs-silver",
  properties: {
    ...rbs_silver_theme_vars,
    ...Typography.properties
  }
};

export const natwestSilverTheme: Theme = {
  name: "natwest-silver",
  properties: {
    ...natwest_silver_theme_vars,
    ...Typography.properties
  }
};
