import axios from "axios";
import { LoaderStateModel } from "redux/models/LoaderModel";

let axiosInstance = axios.create({
  baseURL: window.location.origin,
  headers: {
    "Content-Type": "application/json"
    // 'Authorization': ACCESS_TOKEN if any
  }
});

axiosInstance.interceptors.request.use(
  //To Add Interceptor
  req => {
    new LoaderStateModel({
      id: "1",
      isLoading: true
    }).$save();
    return req;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  res => {
    new LoaderStateModel({
      id: "1",
      isLoading: false
    }).$save();

    return res;
  },
  error => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
