import { Fragment } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import styles from "./signUpSecondPage.module.scss";
import { isEmpty } from "lodash";
import { HeaderContainer } from "core/Header";
import { FooterComponent } from "core/Footer";
import { FormFieldError } from "components/FormFieldError";
import { withRouter } from "react-router-dom";
import * as errorMessage from "config/errorMessages";
import { ErrorBox } from "../../Login/ErrorBox";
import { emailFieldRegex, SpaceCharRegex } from "config/constants";
import { PrimaryButton } from "components/PrimaryButton";
import { useYupValidationResolver } from "utlis/generalUtils";
interface IValues {
  formErrorMessage?: string;
  onHandleSubmit: any;
}
const staticData = {
  pageTitle: `Benefit Registrations`,
  textBlock1: {
    one: `All fields are mandatory`,
    two: `Your sign in details`,
    three: `Please provide your preferred sign in details to view your benefits.`,
    four: `Please note: These details are not linked to your Online Banking log in details.`,
  },
  textBlock2: {
    noteFirst: `Your password must include:`,
    noteSecond: `Your password cannot include more than 3 consecutive letters or numbers, or the word 'password'.`,
    checkList: [
      `At least one number`,
      `At least one capital letter`,
      `At least one small letter`,
      `At least 8 characters`,
    ],
  },
};

export const SignUpSecondPage = (props: IValues) => {
  let { onHandleSubmit } = props;

  const {
    EMAIL_PATTERN_ERROR,
    EMAIL_REQUIRED_ERROR,
    CONFIRM_EMAIL_MATCH,
    CONFIRM_EMAIL,
    PASSWORD_VALIDATION_MIN_CHARS,
    PASSWORD_VALIDATION_MAX_CHARS,
    PASSWORD_VALIDATION_LOWERCASE_CHAR,
    PASSWORD_VALIDATION_UPPERCASE_CHAR,
    CONFIRM_PASSWORD,
    PASSWORD_VALIDATION_NUMBER,
    PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM,
    PASSWORD_MATCH_CONFIRM,
    PASSWORD_VALIDATION_SPACE_CHARACTER,
    ERROR_BOX_HEADER,
    PASSWORD_REQUIRED_ERROR,
  } = errorMessage;

  const SignupSchema = yup.object({
    newEmail: yup
      .string()
      .email(EMAIL_PATTERN_ERROR)
      .matches(emailFieldRegex, errorMessage.EMAIL_PATTERN_ERROR)
      .required(EMAIL_REQUIRED_ERROR),
    confirmEmail: yup
      .string()
      .test("emailCheck", `${CONFIRM_EMAIL_MATCH}`, function (value) {
        let newEmail:any = this.resolve(yup.ref("newEmail"));
        let confirmEmail:any = this.resolve(yup.ref("confirmEmail"));
        return newEmail.toLowerCase() === confirmEmail.toLowerCase();
      })
      .matches(emailFieldRegex, errorMessage.EMAIL_PATTERN_ERROR)
      .required(`${CONFIRM_EMAIL}`),
    newPassword: yup
      .string()
      .min(8, PASSWORD_VALIDATION_MIN_CHARS)
      .max(80, PASSWORD_VALIDATION_MAX_CHARS)
      .matches(/[a-z]/, PASSWORD_VALIDATION_LOWERCASE_CHAR)
      .matches(/[A-Z]/, PASSWORD_VALIDATION_UPPERCASE_CHAR)
      .matches(/[0-9]/, PASSWORD_VALIDATION_NUMBER)
      .matches(/^((?!password).)*$/i, PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM)
      .test("newPassword", PASSWORD_VALIDATION_SPACE_CHARACTER, (value) => {
        if (SpaceCharRegex.test(value!)) {
          return false;
        } else return true;
      })
      .test(
        "newPassword",
        PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM,
        (value) => {
          let newValue = value!.toLowerCase();
          for (let i = 0; i < value!.length; i++) {
            if (
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) - 1 &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) - 2 &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 3) - 3
            ) {
              return false;
            } else if (
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) &&
              newValue.charCodeAt(i) === newValue.charCodeAt(i + 3)
            ) {
              return false;
            }
          }
          return true;
        }
      )
      .required(PASSWORD_REQUIRED_ERROR),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword")], PASSWORD_MATCH_CONFIRM)
      .required(CONFIRM_PASSWORD),
  });

  const resolver = useYupValidationResolver(SignupSchema);
  const { register, handleSubmit, formState: {errors}, getValues, clearErrors } = useForm({
    resolver,
  });

  const resetErrors = (fieldOne, fieldtwo, fieldToReset) => {
    let formValues = getValues();
    return formValues[fieldOne] === formValues[fieldtwo]
      ? clearErrors(fieldToReset)
      : null;
  };

  const formErrorMsg = () => {
    let formErrors;
    if (Object.keys(errors).length) {
      let uniqueErrors: any;
      let errorsArray = Object.values(errors).map((item: any) => {
        return item.message;
      });
      uniqueErrors = Array.from(new Set(errorsArray));
      formErrors = (
        <ErrorBox
          className={styles.error_box_background}
          errors={uniqueErrors}
          errorHeader={ERROR_BOX_HEADER}
        />
      );
    }
    return formErrors;
  };

  return (
    <Fragment>
      <HeaderContainer showStickyHeader={false} />
      <div className={styles.signUpSecondPage__container_wrapper}>
        <div className={styles.signUpSecondPage__container}>
          <div className={styles.signUpSecondPage__row}>
            <p className={styles.page_title}>{staticData.pageTitle}</p>
          </div>
          {formErrorMsg()}
          <div className={styles.signUpSecondPage__row}>
            <div className={styles.text_block}>
              {staticData.textBlock1.one}
              <div className={styles.sub_heading}>
                {staticData.textBlock1.two}
              </div>
              {staticData.textBlock1.three}
              <br />
              {staticData.textBlock1.four}
            </div>
          </div>

          <div className={styles.signUpSecondPage__row}>
            <div className={styles.secondPage__signUp_form}>
              <form onSubmit={handleSubmit(onHandleSubmit)} noValidate>
                <div className={styles.changePassword__row_content}>
                  <div className={styles.changePassword__column_content}>
                    <div className={styles.changePassword__content_box}>
                      <p className={styles.content_text}>Email *</p>
                      {errors.newEmail && (
                        <FormFieldError errorText={errors.newEmail.message} errorId={"email"} />
                      )}
                      <input
                        aria-labelledby="email"
                        {...register('newEmail')}
                        type="email"
                        data-test="newEmail-field"
                        className={styles.form_field}
                        onChange={(e) =>
                          resetErrors(
                            "newEmail",
                            "confirmEmail",
                            "confirmEmail"
                          )
                        } />
                      <p className={styles.content_text}>Confirm Email *</p>
                      {!errors.newEmail || errors.confirmEmail
                        ? errors.confirmEmail && (
                            <FormFieldError
                              errorText={errors.confirmEmail.message}
                              errorId={"confirmEmail"} 
                            />
                          )
                        : null}
                      <input
                        {...register('confirmEmail')}
                        aria-labelledby="confirmEmail"
                        type="email"
                        className={styles.form_field}
                        required />
                      <p className={styles.content_text}>Password *</p>
                      {errors.newPassword && (
                        <FormFieldError
                          errorText={errors.newPassword.message}
                          errorId={'newPassword'}
                        />
                      )}
                      <input
                        aria-labelledby="newPassword"
                        {...register('newPassword')}
                        type="password"
                        data-test="newPassword-field"
                        className={styles.form_field}
                        autoComplete="off"
                        onChange={(e) =>
                          resetErrors(
                            "newPassword",
                            "confirmPassword",
                            "newPassword"
                          )
                        } />
                      <p className={styles.content_text}>Confirm Password *</p>
                      {errors.newPassword || errors.confirmPassword
                        ? errors.confirmPassword && (
                            <FormFieldError
                              errorText={errors.confirmPassword.message}
                              errorId={'confirmPassword'}
                            />
                          )
                        : null}
                      <input
                        aria-labelledby="confirmPassword"
                        {...register('confirmPassword')}
                        type="password"
                        className={styles.form_field}
                        autoComplete="off" />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    (styles.changePassword__row_content,
                    styles.button_container,
                    styles.btn)
                  }
                >
                  <div className={styles.signup__next_btn_wrapper}>
                    <PrimaryButton
                      text="Register and sign in"
                      disabled={!isEmpty(errors) ? true : false}
                      classNames={styles.signup__next_btn}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className={styles.signUpSecondPage__row}>
            <label>{staticData.textBlock2.noteFirst}</label>
            <div className={styles.signUpSecondPage__row}>
              <ul>
                {staticData.textBlock2.checkList.map((item, key) => (
                  <li key={key}>{item}</li>
                ))}
              </ul>
            </div>
            <label>{staticData.textBlock2.noteSecond}</label>
          </div>
        </div>
      </div>
      <FooterComponent />
    </Fragment>
  );
};

export default withRouter(SignUpSecondPage);
