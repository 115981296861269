/*========================== Theme Variables for ULSTER RI uFirst =====================*/
const ulster_ufirst_ri_button_color_scheme = {
  "--button-background": "#1E7A8A",
  "--primaryButtonBackground": "#00A6B5",
  "--primaryButtonBackgroundHover": "#009eaa",
  "--secondaryButtonBackground": "#fff",
  "--buttonBorder": "#00A6B5",
  "--buttonShadow": "#666",
  "--buttonBorderTop": "#1E7A8A",
  "--buttonTextColor": "#fff",
  "--secondaryButtonBorder": "#1e7a8a",
  "--secondaryButtonColor": "#000",
  "--button-hover-background": "#156676",
  "--button-font-family": "Flama",
  "--block-button-font-family": "Flama",
  "--genericButtonBackground": "#1e7a8a",
  "--genericButtonTextColor": "#ffffff",
  "--genericButtonBackgroundHover": "#156676",
  "--button-hover-text-color":"#ffffff"
};

const ulster_ufirst_ri_page_element_scheme = {
  //logo variables
  "--header-logo": 'url("/asset/ulster_ni/logo/logo-regular_mdpi.png")',
  "--header-lg-height": "44px",
  "--header-md-height": "34px",
  "--header-sm-height": "28px",
  "--header-lg-width": "223px",
  "--header-md-width": "152px",
  "--header-sm-width": "128px",
  "--logo-url": "url('../assets/images/ulster-logo-black.png')",
  "--loader-url": "url('/assets/images/')",
  "--primary": "#23356B",
  "--secondary": "#fff",
  "--heading": "#23356B",
  "--tertiary": "#067CC4",
  "--boxShadow": "#e0d9d1",
  "--footerBackground": "#0a2f64",
  "--footer-text-color": "#ffffff",
  "--sticky-header-background": "#EEEDE9",
  "--font-family": "Flama",
  "--font-family-ulster": "Flama",
  "--font-family-bold": "FlamaBold",
  "--base-padding": "32px",
  "--inner-padding": "32px",
  "--inner-padding-top": "0px",
  "--section-base-background": "#F7F7F7",
   "--section-inner-background": "#ffff",
  "--page-background": "#F7F7F7",
  "--root-background": "#f0eeea",
  "--quick-access-font-family": "Flama",
  "--membership-title-font-family": "FlamaBold",
  "--account-dropdown-font-family": "Flama",
  "--quick-access-font-weight": "400",
  "--footer-link-font-size": "18px",
  "--footer-link-icon-font-size": "16px",
  "--notice-header-base-padding": "25px 52px 25px 32px",
  "--lilac-color":"#EEEDE9",
  "--borderbox": "border-box",
  "--body-grey": "#23356B",
  "--text-box-border-color": "none",
  "--accordian-heading": "#23356B",
  "--additional-info-icon": "#00A6B5",
  "--input-shadow": "0 3px #a09489 inset,0 1px #fff",
  "--inputbox-shadow": "0 1px #a09489 inset,0 1px #fff",
  "--input-radius": "4px",
  "--input-border":"none",
  "--input-background":"rgba(70,70,70,.15)",
};

const ulster_ufirst_ri_card_color_scheme = {
  "--card-background": "#eeede9",
 "--card-text": "#0A2F64",
  "--card-header--separator": "#F7F7F7",
  "--home-margin-fix": "0px 16px",
  "--card-footer-text-font-size": "18px",
  "--card-footer-color": "#00A6B5",
  "--card-heading": "#0A2F64",
  "--card-icon": "#0a2f64",
};

const ulster_ufirst_ri_image_banner_color_scheme = {
  "--title-first-child":  "#00A6B5",
  "--title-second-child": "#00A6B5",
  "--hero-banner-note-background": "#B7BE16",
  "--hero-secondary-banner-note-background": "#ff1800",
  "--hero-banner-note-text-color": "#23356B",
  "--hero-secondary-banner-note-text-color": "#ffffff",
  "--banner-content-left-position": "0px",
  "--banner-title-radius": "56px",
  "--banner-title-width": "100%",
  "--banner-title-min-width": "225px",
  "--title-first-child-padding": "16px 48px 0px 48px",
  "--title-second-child-padding": "0px 48px 16px 48px",
  "--banner-button-top-margin": "32px",
  "--banner-button-left-margin": "32px",
  "--banner-title-text": "16px 48px",
  "--banner-title-single-child-padding": "16px 60px 16px 48px",
  "--image-banner-button-border": "#1b768b",
  "--ulster-covid-msg": "0px",
  "--image-banner-button-background": "#1E7A8A",
  "--image-banner-button-hover-background": "#30909e",
  "--image-banner-button-color": "#ffffff",
};
const responsive_ulster_ufirst_ri_image_banner_color_scheme = {
  "--title-first-child-padding_responsive": "12px 48px 0px 36px",
  "--title-second-child-padding_responsive": "0px 60px 16px 36px",
};

const ulster_ufirst_ri_accordion_elements_color_scheme = {
  "--accordion-primary": "#0A2F64",
  "--accordion-secondary": "#FFF",
};

const ulster_ufirst_ri_stripes_elements_color_scheme = {
  "--stripe-color-uFirst": "#009185",
  "--stripe-color-gold": "#D7572B",
  "--stripe-color-private": "#23356B",
};

const ulster_ufirst_ri_misc_elements_color_scheme = {
  "--link-color": "#00A6B5",
  "--link-color-ri": "#23356B",
  "--link-hover-color": "#1e7a8a",
  "--link-active-color": "#030e1e",
  "--info-text-color": "#00A6B5",
  "--counterBackground": "#067CC4",
  "--calendarActive": "#067CC4",
  "--calendarRange": "#C3DEF1",
  "--loadMoreLink": "#2da3c3",
  "--discountTextBackground": "#ebedfc",
  "--subTitle": "gray",
  "--optionalText": "gray",
  "--importantNoteBg": "#dfd9d1",
  "--tabActiveBgColor": "#0a2f64",
  "--tabInactiveBgColor": "#9fb4ce",
  "--noteBackground": "#dfd9d1",
  "--linkUnderline": "underline",
  "--cookies-header-background": "#ecedfc",
  "--error-color": "#DB1A31",
  "--warning-text-background": "#d9edf7",
  "--table-background-odd-row": "#e7e5df",
  "--table-text-color": "#23356b",
  "--logout-text": "#3d3d3d",
  "--logout-body-text": "#23356B",
  "--dropdown-dark": "#23356B",
  "--dropdown-light": "#fff",
};
const ulster_ri_ufirst_forms_color_scheme = {
  "--formInputBgColor": "#f0eeea",
  "--formContainerBgColor": "#f7f7f7",
  "--formFieldWrapper": "#fff",
};

const ulster_ri_ufirst_change_password_color_scheme = {
  "--primary": "#23356B",
  "--container-background-color": "#f7f7f7",
  "--change-password-content-background": "#ffffff",
  "--change-password-input-textbox-background": "#F0EEEA",
  "--change-password-error-color": "#DB1A31",
  "--change-password-button-background": "#00A6B5",
  "--error-message-color": "#fff",
  "--save-changes-button-border-bottom": "#007881",
  "--save-changes-button-border-top": "#43c9d2",
  "--textbox-outline-color": "#B7BE16",
  "--change-page-container-padding": "0px 32px 32px 32px",
  "--change-page-container-inner-padding": "32px",
};

const ulster_ri_ufirst_event_booking_form_color_scheme = {
  "--form-heading-font-family": "Flama",
  "--field-font-family": "Flama",
  "--form-text-color": "#23356B",
  "--select-box-border-color": "#0A2F64",
  "--select-box-border-color-onfocus": "#3c5bb8",
  "--select-box-shadow-primary-onfocus": "#222222",
  "--select-box-shadow-secondary-onfocus": "#486ddb",
  "--input-field-box-shadow-primary": "#A09489",
  "--input-field-box-shadow-secondary": "#ffffff",
  "--input-field-box-shadow-primary-onfocus": "#222222",
  "--input-field-box-shadow-secondary-onfocus": "#486ddb",
  "--form-input-textbox-background": "#eeeeee",
  "--error-message-color-event-booking": "#ffffff",
  "--group-error-message-color-event-booking": "#DB1A31",
  "--group-error-box-max-width": "488px",
  "--textbox-outline-color": "#B7BE16",
  "--error-message-box-width": "432px",
  "--form-container-padding": "0px 32px 32px 32px",
  "--field-container-padding": "32px 32px 0px 32px",
  "--field-container-background": "#FFFFFF",
  "--event-booking-success-page-background": "#f0eeea",
  "--form-heading-margin": "0px 0px 24px 0px",
};

export const ulster_ufirst_theme_vars = {
  ...ulster_ufirst_ri_button_color_scheme,
  ...ulster_ufirst_ri_page_element_scheme,
  ...ulster_ufirst_ri_card_color_scheme,
  ...ulster_ufirst_ri_image_banner_color_scheme,
  ...responsive_ulster_ufirst_ri_image_banner_color_scheme,
  ...ulster_ufirst_ri_accordion_elements_color_scheme,
  ...ulster_ufirst_ri_stripes_elements_color_scheme,
  ...ulster_ufirst_ri_misc_elements_color_scheme,
  ...ulster_ri_ufirst_forms_color_scheme,
  ...ulster_ri_ufirst_change_password_color_scheme,
  ...ulster_ri_ufirst_event_booking_form_color_scheme,
};
