import { useYupValidationResolver } from "utlis/generalUtils";
import styles from "./ChangePassword.module.scss";
import * as yup from "yup";
import { Icon } from "components/Icon";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorBox } from "../../../Login/ErrorBox";
import classnames from "classnames/bind";
import { FormFieldError } from "components/FormFieldError/index";

import {
  CHANGE_PASSWORD_ERROR_MAPPING,
  ERROR_BOX_HEADER,
  DEFAULT_ERROR_MESSAGE,
  OLDPASSWORD_REQUIRED_MESSAGE,
  PASSWORD_VALIDATION_MIN_CHARS,
  PASSWORD_VALIDATION_MAX_CHARS,
  PASSWORD_VALIDATION_LOWERCASE_CHAR,
  PASSWORD_VALIDATION_UPPERCASE_CHAR,
  PASSWORD_VALIDATION_REQUIRED,
  PASSWORD_VALIDATION_NUMBER,
  PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM,
  PASSWORD_MATCH_CONFIRM,
  PASSWORD_VALIDATION_SPACE_CHARACTER,
} from "config/errorMessages";
import labels from "config/Labels/label.json";
import { SpaceCharRegex } from "config/constants";
import { PrimaryButton } from "components/PrimaryButton";
interface IChangePasswordProps {
  isSubmitting: boolean;
  handleLoginClick: any;
  errorSet: any;
}
const { changePassword } = labels;
const data = [
  changePassword.changePasswordMaxMinCharRule,
  changePassword.changePasswordCapitalLetterRule,
  changePassword.changePasswordSmallLetterRule,
  changePassword.changePasswordNumberRule,
];

const ChangePasswordSchema = yup.object({
  oldPassword: yup.string().required(OLDPASSWORD_REQUIRED_MESSAGE),
  newPassword: yup
    .string()
    .min(8, PASSWORD_VALIDATION_MIN_CHARS)
    .max(80, PASSWORD_VALIDATION_MAX_CHARS)
    .matches(/[a-z]/, PASSWORD_VALIDATION_LOWERCASE_CHAR)
    .matches(/[A-Z]/, PASSWORD_VALIDATION_UPPERCASE_CHAR)
    .matches(/[0-9]/, PASSWORD_VALIDATION_NUMBER)
    .matches(/^((?!password).)*$/i, PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM)
    .test("newPassword", PASSWORD_VALIDATION_SPACE_CHARACTER, (value) => {
      if (SpaceCharRegex.test(value!)) {
        return false;
      } else return true;
    })
    .test("newPassword", PASSWORD_VALIDATION_CONSECUTIVE_CHARS_NUM, (value) => {
      let newValue = value!.toLowerCase();
      for (let i = 0; i < value!.length; i++) {
        if (
          newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) - 1 &&
          newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) - 2 &&
          newValue.charCodeAt(i) === newValue.charCodeAt(i + 3) - 3
        ) {
          return false;
        } else if (
          newValue.charCodeAt(i) === newValue.charCodeAt(i + 1) &&
          newValue.charCodeAt(i) === newValue.charCodeAt(i + 2) &&
          newValue.charCodeAt(i) === newValue.charCodeAt(i + 3)
        ) {
          return false;
        }
      }
      return true;
    })
    .required(`${PASSWORD_VALIDATION_REQUIRED}`),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), ""], PASSWORD_MATCH_CONFIRM)
    .required(`${PASSWORD_VALIDATION_REQUIRED}`),
});
export const ChangePasswordPage: React.FC<IChangePasswordProps> = (
  props: IChangePasswordProps
) => {
  
  const resolver = useYupValidationResolver(ChangePasswordSchema);
  const { isSubmitting, errorSet, handleLoginClick } = props;
  const { register, handleSubmit, formState: {errors} } = useForm({
    resolver
  });

  const renderErrorBox = (errors?: any) => {
    let errorsArray: any;
    let uniqueErrors: any;
    errorsArray = Object.values(errors).map((item: any) => {
      return item.message;
    });
    uniqueErrors = Array.from(new Set(errorsArray));
    if (errorSet && errorSet.length) {
      let errorMsg = errorSet.map((errorItem) => {
          return CHANGE_PASSWORD_ERROR_MAPPING[errorItem] || DEFAULT_ERROR_MESSAGE;
      });
      return <ErrorBox errors={errorMsg} errorHeader={ERROR_BOX_HEADER} />;
    } else {
      if (errorsArray && errorsArray.length) {
        return (
          <ErrorBox errors={uniqueErrors} errorHeader={ERROR_BOX_HEADER} />
        );
      } else {
        return;
      }
    }
  };

  return (
    <div className={styles.main_Container} data-test="change-pwd-component">
      <div className={styles.main_sub_Container}>
        <div className={styles.changePassword__row_content}>
          <p className={styles.changePassword_title}>
            {changePassword.changePasswordHeader}
          </p>
        </div>
        <div className={styles.main_content_container}>
          {errors ? renderErrorBox(errors) : null}

          <form
            className=""
            onSubmit={handleSubmit(handleLoginClick)}
            noValidate
          >
            <div className={styles.changePassword__row_content}>
              <div className={styles.changePassword__column_content}>
                <div className={styles.changePassword__content_box}>
                  <p className={styles.changePassword_subtitle_text}>
                    {changePassword.changePasswordSubtitle}
                  </p>

                  <p className={styles.content_text}>
                    {changePassword.currentPasswordText}
                  </p>
                  {errors.oldPassword && (
                    <FormFieldError errorText={errors.oldPassword.message} />
                  )}
                  <input
                    {...register('oldPassword')}
                    type="password"
                    data-test="oldPassword-field"
                    autoComplete="off"
                    className={
                      styles.input_textbox +
                      (errors.oldPassword ? " is-invalid" : "")
                    } />
                  <p className={styles.content_text}>
                    {changePassword.newPasswordText}
                  </p>
                  {errors.newPassword && (
                    <FormFieldError errorText={errors.newPassword.message} />
                  )}
                  <input
                    {...register('newPassword')}
                    type="password"
                    data-test="newPassword-field"
                    autoComplete="off"
                    className={
                      styles.input_textbox +
                      (errors.newPassword ? " is-invalid" : "")
                    } />
                  <p className={styles.content_text}>
                    {changePassword.confirmPasswordText}
                  </p>
                  {errors.confirmPassword && (
                    <FormFieldError
                      errorText={errors.confirmPassword.message}
                    />
                  )}
                  <input
                    {...register('confirmPassword')}
                    type="password"
                    data-test="confirmPassword-field"
                    autoComplete="off"
                    className={
                      styles.input_textbox +
                      (errors.confirmPassword ? " is-invalid" : "")
                    } />
                </div>
              </div>

              <div className={styles.changePassword__column_content}>
                <div className={styles.changePassword__content_box}>
                  <div className={styles.changePassword__rules_box}>
                    <p className={styles.changePassword_rules_text}>
                      {changePassword.passwordRuleTitle}
                    </p>
                    <div className={styles.rules}>
                      {data.map((text, i) => {
                        return (
                          <div
                            className={styles.rule_item}
                            key={`bulletList ${i}`}
                          >
                            <div className={styles.icon_dot}>
                              <Icon iconName="icon-dot" />{" "}
                            </div>
                            <span className={styles.rules_text}> {text} </span>
                          </div>
                        );
                      })}
                    </div>
                    <p className={styles.changePassword_rules_text}>
                      {changePassword.passwordRuleBottomText}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={classnames(
                styles.button_sub_container,
                styles.button_container
              )}
              data-test="save-button"
            >
              <PrimaryButton text="Save Changes" disabled={isSubmitting} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChangePasswordPage);
