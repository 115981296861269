import styles from "./successPage.module.scss";
import content from "config/Labels/label.json";
import classnames from "classnames/bind";
import { PrimaryButton } from "components/PrimaryButton";

export const ChaneEmailSuccessPage = (props) => {
  const { newEmailValue } = props.history.location.state;
  const { history } = props;
  const { confirmEmailHeader, SuccessMsg } = content.confirmNewEmail;

  const handleHomeClick = () => {
    history.push("/home");
  };
  return (
    <div className={styles.main_Container}>
      <div className={styles.confirm_email_container}>
        <div className={styles.confirm_email_heading}>{confirmEmailHeader}</div>
        <div>
          <div className={styles.confirm_email_content}>{SuccessMsg}</div>
          <div className={styles.confirm_email_value}>
            <strong>{newEmailValue}</strong>
          </div>
          <div
            className={classnames(
              styles.button_container,
              styles.confirm_button
            )}
          >
            <PrimaryButton
              text="Home"
              handleClick={handleHomeClick}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ChaneEmailSuccessPage;
