import React, { Fragment } from "react";
import { withRouter, match } from "react-router-dom";
import { History, Location } from "history";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import styles from "./eventBooking.module.scss";
import { FormFieldError } from "components/FormFieldError";
import { FormErrorMessage } from "components/FormErrorMessage";
import {
  EVENT_BOOKING_FORM_ERRORS,
  DEFAULT_ERROR_MESSAGE,
} from "config/errorMessages";
import labels from "config/Labels/label.json";
import classnames from "classnames/bind";
import { PrimaryButton } from "components/PrimaryButton";
import { useYupValidationResolver } from "utlis/generalUtils";

interface IProps {
  location: Location;
  history: History;
  match: match;
  formData: any;
  getDefaultValues: Function;
  submitForm: (values) => void;
  errorSet: any;
}
const {
  HOUSE_NUMBER_NAME_REQUIRED_ERROR,
  POST_CODE_REQUIRED_ERROR,
  HOME_TELEPHONE_NUMBER_VALIDATION_ERROR,
  NAME_OF_EVENT_REQUIRED_ERROR,
  DATE_OF_EVENT_REQUIRED_ERROR,
  DATE_OF_EVENT2_REQUIRED_ERROR,
  DATE_OF_EVENT_VALIDATION_ERROR,
  NAME_OF_VENUE_REQUIRED_ERROR,
  CITY_OF_VENUE_REQUIRED_ERROR,
  NUMBER_OF_TICKETS_REQUIRED_ERROR,
  SEATING_CHOICE_REQUIRED_ERROR,
  DATE_OF_EVENT_CHOICE_ERROR,
  NUMBER_OF_TICKETS_VALIDATION_ERROR,
} = EVENT_BOOKING_FORM_ERRORS;
const dateRegex = /^(0[1-9]|[12][0-9]|3[01])(\/)(0[1-9]|1[012])(\/)(19|20)\d\d/;

function checkDateDifference(value: string) {
  let splitDate = value ? value.split("/") : "";
  let concatedDate =
    splitDate.length === 3
      ? splitDate[1] + "/" + splitDate[0] + "/" + splitDate[2]
      : "";
  const enteredDate = new Date(concatedDate);
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  if (enteredDate.getTime() < currentDate.getTime()) return false;
  else return value ? dateRegex.test(value) : true;
}

const EventBookingSchema = yup.object({
  HouseNumberName: yup.string().required(`${HOUSE_NUMBER_NAME_REQUIRED_ERROR}`),
  PostCode: yup.string().required(`${POST_CODE_REQUIRED_ERROR}`).max(10),
  HomeTelephoneNumber: yup
    .string()
    .required(`${HOME_TELEPHONE_NUMBER_VALIDATION_ERROR}`)
    .max(16, `${HOME_TELEPHONE_NUMBER_VALIDATION_ERROR}`)
    .min(8, `${HOME_TELEPHONE_NUMBER_VALIDATION_ERROR}`)
    .test("len", `${HOME_TELEPHONE_NUMBER_VALIDATION_ERROR}`, (value) => {
      const zero = value ? value[0] : "";
      if (zero !== "0") return false;
      return /^\d+$/.test(value!);
    }),

  NameOfEvent: yup.string().required(`${NAME_OF_EVENT_REQUIRED_ERROR}`),
  DateOfEventFirst: yup
    .string()
    .test("Date Checking", `${DATE_OF_EVENT_VALIDATION_ERROR}`, (value) => {
      return checkDateDifference(value!);
    })
    .required(`${DATE_OF_EVENT_REQUIRED_ERROR}`),

  DateOfEventSecond: yup
    .string()
    .test("Date Checking", `${DATE_OF_EVENT_CHOICE_ERROR}`, (value) => {
      return checkDateDifference(value!);
    })
    .required(`${DATE_OF_EVENT2_REQUIRED_ERROR}`),
  TimeOfEventFirst: yup.string(),
  NameOfVenue: yup.string().required(`${NAME_OF_VENUE_REQUIRED_ERROR}`),
  CityOfVenue: yup.string().required(`${CITY_OF_VENUE_REQUIRED_ERROR}`),
  NumberOfTickets: yup
    .string()
    .test("integer", `${NUMBER_OF_TICKETS_VALIDATION_ERROR}`, (value) => {
      const number = value ? value[0] : 0;
      if (!(number >= 0 || number <= 9)) return false;
      else if (value === "0" || value === "00") return false;
      return /^\d+$/.test(value!);
    })
    .required(`${NUMBER_OF_TICKETS_REQUIRED_ERROR}`),
  SeatingChoiceFirst: yup.string().required(`${SEATING_CHOICE_REQUIRED_ERROR}`),
});

const EventBooking: React.FC<IProps> = (props: IProps) => {
  const { getDefaultValues, submitForm, errorSet } = props;
  const { eventBookingHeadingText } = labels.eventBookingForm;
  const resolver = useYupValidationResolver(EventBookingSchema); 
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver
  });

  const renderFormFieldError = (errorName) => {
    return (
      errorName && (
        <FormFieldError
          errorText={errorName.message}
          className={styles.form_field_error}
        />
      )
    );
  };
  const renderInputBox = (field, name) => {
    const { type, id, maxLength, autoPopulatedFieldName, readOnly } = field;
    let errorName = errors[`${name}`];
    return (
      <Fragment>
        {renderFormFieldError(errorName)}
        <input
          {...register(name)}
          type={type}
          className={
            autoPopulatedFieldName
              ? classnames(styles.input_textbox, styles.input_textbox_uppercase)
              : styles.input_textbox
          }
          id={id}
          maxLength={maxLength}
          value={getDefaultValues(autoPopulatedFieldName)}
          readOnly={readOnly} />
      </Fragment>
    );
  };
  const renderSelectBox = (fields, name1) => {
    let errorName = errors[`${name1}`];
    let optionsArray: any[] = [];
    return (
      <Fragment>
        {renderFormFieldError(errorName)}
        <div className={styles.select_box_container}>
          {(fields || []).map((field, i) => {
            const { options, name, id } = field;
            const { range } = options;

            if (range && Array.isArray(range) && range.length === 3) {
              optionsArray = [];
              for (let i = range[0]; i <= range[1]; i++) {
                optionsArray.push(i < 10 ? "0" + i.toString() : i.toString());
              }
            }

            return (
              <Fragment key={i}>
                <select {...register(name)} id={id}>
                  {Array.isArray(options) &&
                    options.map((option, index) => {
                      return (
                        <option key={index} value={option.value}>
                          {option.name}
                        </option>
                      );
                    })}

                  {Array.isArray(options) ||
                    optionsArray.map((option, index) => {
                      return (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      );
                    })}
                </select>
              </Fragment>
            );
          })}
        </div>
      </Fragment>
    );
  };
  const renderButton = (field) => {
    const { buttonText } = field;
    return (
      <div className={styles.button_container}>
        <PrimaryButton
          text={buttonText}
          disabled={false}
          classNames={styles.submit_button}
        />
      </div>
    );
  };
  const renderTypes = (fieldType, fields, name) => {
    switch (fieldType) {
      case "input":
        return renderInputBox(fields, name);
      case "select":
        return renderSelectBox(fields, name);
      case "button":
        return renderButton(fields);
      default:
        return null;
    }
  };

  const renderFormErrorMessage = (errors?: any) => {
    if (Object.keys(errors).length) {
      return (
        <FormErrorMessage
          errors={{ ...errors }}
          errorMsg=""
          className={styles.form_group_error}
        />
      );
    }
    let APIErrors = {};

    if (errorSet && errorSet.length) {
      //below lines will get only single value if its repetative
      let unique = (item, index, array) => array.indexOf(item) === index;
      let uniqueError = errorSet.filter(unique);
      APIErrors[uniqueError] = { message: DEFAULT_ERROR_MESSAGE };
      // errorSet.map((errorItem, i) =>
      //   errorItem
      //     ? (APIErrors[i] = { message: errorItem })
      //     : (APIErrors["technical_error"] = {
      //         message: TECHNICAL_ERROR_MESSAGE
      //       })
      // );
    }
    return errorSet.length ? (
      <FormErrorMessage
        errors={{ ...APIErrors }}
        errorMsg=""
        className={styles.form_group_error}
      />
    ) : null;
  };

  return (
    <div className={styles.form_container}>
      <form onSubmit={handleSubmit(submitForm)}>
        <h2>{eventBookingHeadingText}</h2>
        {errors ? renderFormErrorMessage(errors) : null}
        <div className={styles.field_container}>
          {props.formData.map((formMember, index) => {
            const { fieldType, fieldInfo, fields, name } = formMember;
            return (
              <div className={styles.form_fields} key={index}>
                <div>{formMember.fieldLabel}</div>
                <div className={styles.form_field_types}>
                  {renderTypes(fieldType, fields, name)}
                  <div>{fieldInfo}</div>
                </div>
              </div>
            );
          })}
        </div>
      </form>
    </div>
  );
};
export default withRouter(EventBooking);
