import { MarkedComponent } from "components/MarkedComponent";

export const renderMarkedText = (
  rawString: string = "",
  componentKey: number = 1, //default componentKey set to 1.
  styleObject: string = "" //default empty incase className doesn't exists.
) => {
  return (
    <MarkedComponent
      className={styleObject}
      rawString={rawString}
      key={componentKey}
    />
  );
};
