import React, { Fragment, useEffect, useRef } from "react";
import { ReactI18NextChild } from "react-i18next";

/*
-----Demo----
<ClickAwayListener clickAway={callback function if clickedAway }>
your target component...
</ClickAwayListener>
*/

interface IProps {
  children: ReactI18NextChild | Iterable<ReactI18NextChild>;
  clickAway: () => void;
}

const ClickAwayListener: React.FC<IProps> = (props) => {
  const node = useRef<HTMLInputElement>(null);
  const handleClick = (e) => {
    if (node.current && node.current.contains(e.target)) {
      return;
    } else {
      props.clickAway();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick, { capture: true });
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <div ref={node}>{props.children}</div>
    </Fragment>
  );
};

export default ClickAwayListener;
