import React, { Fragment } from "react";
import styles from "./noticeSection.module.scss";
import { Icon } from "components/Icon";
import TableComponent from "../Table/TableComponent";
import { Link } from "react-router-dom";
import classnames from "classnames/bind";
import { renderMarkedText } from "components/AbstractedMethods";

let bindCss = classnames.bind(styles);
interface componentType {
  type: string;
  content: textSection | buttonData | ITable | imageSection[];
}
interface buttonData {
  text: string;
  href: string;
  className?: string;
  tootipText?: string;
  buttonIcon: string;
  linkType?: string;
}

interface textSection {
  heading: string;
  content: string;
  className?: string;
  styleNames?: string[];
}

interface imageSection {
  imageUrl: string;
}

interface ITable {
  heading: string;
  columnHeading: string;
  rows: any[];
}
interface IContent {
  content?: componentType[];
  styleNames?: string[];
  noBasePadding?: boolean;
  noticeHeader?: noticeHeader;
}
interface noticeHeader {
  headerIcon?: string;
  headerBackgroundColor?: string;
  heading?: string;
  headerStyleNames?: string[];
}
interface IProps {
  metaData: IContent;
  id?: string;
  makeClaimbId?: number;
}
const NoticeSection: React.FC<IProps> = ({ metaData, id, makeClaimbId }) => {
  const renderNotice = ({content, className}, componentKey) => {
    return (
      <Fragment key={componentKey}>
        {renderMarkedText(content, componentKey + 1, styles[className])}
      </Fragment>
    );
  };
  const getClasses = (styleNames) => {
    let classes = "";
    if (styleNames) {
      styleNames.forEach((style) => {
        classes += ` ${styles[style]} `;
      });
      return classes;
    }
    return "";
  };

  const renderContent = () => {
    try {
      return (metaData.content || []).map((component, componentKey) => {
        return typeMappings[component.type](component.content, componentKey);
      });
    } catch (e) {
      return;
    }
  };

  const renderButton = (
    { text, href, className, buttonIcon, linkType, tooltipText },
    componentKey
  ) => {
    let buttonContent = (
      <div
        title={tooltipText ? tooltipText : ""}
        className={bindCss({ button: true, [className]: true })}
      >
        {text}
        {buttonIcon ? <Icon iconName={buttonIcon} /> : null}
      </div>
    );

    return (
      <Fragment key={componentKey}>
        {linkType === "internal" ? (
          makeClaimbId ? (
            <Link to={`${href}?bId=${makeClaimbId}`}>{buttonContent}</Link>
          ) : (
            <Link to={href}>{buttonContent}</Link>
          )
        ) : (
          <a href={href}>{buttonContent}</a>
        )}
      </Fragment>
    );
  };

  const renderImages = (content, componentKey) => {
    return (
      <div
        className={styles.image_container}
        key={componentKey}
        data-test="notice_section_image_data"
      >
        {(content || []).map((image, innerComponentKey) => {
          return (
            <div
              className={styles.image}
              key={componentKey + innerComponentKey}
            >
              {renderMarkedText(image.imageUrl, innerComponentKey)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTable = (tableDescription, componentKey) => {
    return (
      <div data-test="notice_section_table_data" key={componentKey}>
        <TableComponent metadata={tableDescription} />
      </div>
    );
  };

  const typeMappings = {
    linkButton: renderButton,
    images: renderImages,
    table: renderTable,
    notice: renderNotice,
  };

  return (
    <Fragment>
      <div
        className={bindCss({
          component_basepadding: true,
          no_base_padding: metaData.noBasePadding,
        })}
        id={id}
        data-test="notice_section_component"
      >
        {
          metaData.noticeHeader ? (
            <div
            className={bindCss({
              notice_header: true,
              [getClasses(metaData.noticeHeader.headerStyleNames)]: true,
            })}
            style={{backgroundColor: metaData.noticeHeader.headerBackgroundColor}}
            >
              <h2>{metaData.noticeHeader.heading}</h2>
              <div>
                {renderMarkedText(metaData.noticeHeader.headerIcon)}
              </div>
            </div>
          ): null
        }
        <div
          className={bindCss({
            component_innerpadding: true,
            [getClasses(metaData.styleNames)]: true,
          })}
        >
          {renderContent()}
        </div>
      </div>
    </Fragment>
  );
};

export default NoticeSection;
