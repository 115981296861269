import { Fragment, PureComponent } from "react";
import { IComponentProps } from "./interfaces";
import { withRouter } from "react-router-dom";
import styles from "./header.module.scss";
import content from "../../config/Labels/label.json";
import classnames from "classnames/bind";
import { Icon } from "components/Icon";
import { ClickAwayListener } from "components/ClickAwayListener";
import { SelectDropdown } from "components/SelectDropdown";
import { BreadCrumbs } from "components/BreadCrumbs";
import { logout } from "services/RestAPI/exploreCalls";
import _ from "lodash";
import { AuthenticationCheck } from "utlis/generalUtils";
let bindCss = classnames.bind(styles);

/**
 * @author Manjiri Dani
 * @param {
 * userName,
 * lastLogin,
 * isHeaderSticky,
 * accountTypes,
 * quickLinks,
 }
 * @param {string} userName - name of the user logged in,value can be string.
 * @param {string} lastLogin - last login time of the user,value can be string.
 * @param {boolean} isHeaderSticky - state for sticky header.
 * @param {ISelectBoxProp} - props for bank account type select box.
 * @param {ISelectBoxProp} - props for quick links select box.
 */

class HeaderComponent extends PureComponent<IComponentProps> {
  onSignOut = (props) => {
    const { history } = props;
    logout();
    history.push(`/login`);
  };

  componentDidMount() {
    window.addEventListener("storage", (e) => {
      if (e.key === "persist:models" && e.oldValue && !e.newValue) {
        this.onSignOut(this.props);
      }
    });
    const fixedfocus:any = {
      init: function(fixedElem) {
        fixedfocus.fixedElem = fixedElem;
        document.body.addEventListener('focusin', fixedfocus.adjust);
      },
      
      adjust: function(e) {
        const fixedBottomPos = fixedfocus.fixedElem ? fixedfocus.fixedElem.getBoundingClientRect().bottom : null;
        const rect = e.target.getBoundingClientRect();
        if (rect.top < fixedBottomPos) {
          window.scrollBy(0, rect.top - fixedBottomPos - 250);
        }
      }
    };  
    document.addEventListener('DOMContentLoaded', function() {
      fixedfocus.init(document.querySelector('#header')); 
    }, { capture: true });
  }

  renderBreadCrum = () => {
    return (
      <div className={styles.sticky_header__pagename_parent}>
        <div className={styles.sticky_header__pagename}>
          <BreadCrumbs />
        </div>
      </div>
    );
  };

  redirectToHome = (props) => {
    let pathname = props.history.location.pathname;
    if (pathname !== "/home" || pathname !== "/login") {
      props.history.push("/home");
    }
  };
  render() {
    const {
      header:{
      welcome,
      signOut,
      lastLoginText,
      membershipServices,
      }
    } = content;
    const {
      userName,
      lastLogin,
      isHeaderSticky,
      accountTypes,
      quickLinks,
      showStickyHeader,
    } = this.props.IContent;

    const {
      options: accountOptions,
      toggleIsOpen: accountToggleIsOpen,
      dropdownIsOpen: accountDropdownIsOpen,
      updateSelectTitle: accountUpdateSelectTitle,
      dropdownTitle: accountDropdownTitle,
      dropdownType: accountDropdownType,
      updatableDropdownTitle: accountUpdatableDropdownTitle,
      clickAway: accountClickAway,
    } = accountTypes;

    const {
      options,
      toggleIsOpen,
      updateSelectTitle,
      dropdownIsOpen,
      dropdownTitle,
      dropdownType,
      clickAway,
    } = quickLinks;

    const viewPortWidth = 767;
    const accountLinks = _.find(accountTypes.options, (o) => o);
    const { links } = accountLinks;
    const account = accountTypes.dropdownTitle;
    return (
      <Fragment>
        <div className={styles.header}>
          <div className={styles.scrollable_header}>
            <div className={styles.scrollable_header__body}>
              <div className={styles.scrollable_header__logo}>
                <div
                  className={styles.scrollable_header__logo_img}
                  onClick={() => this.redirectToHome(this.props)}
                />
              </div>

              <div
                className={styles.scrollable_header__info_parent}
                style={
                  showStickyHeader ? { display: "block" } : { display: "none" }
                }
              >
                {AuthenticationCheck() ? (
                  <>
                    <div className={styles.scrollable_header__info}>
                      <div
                        className={styles.scrollable_header__username_parent}
                      >
                        {welcome}{" "}
                        <span className={styles.scrollable_header__username}>
                          {userName}
                        </span>
                      </div>
                      <span className={styles.scrollable_header_divider}>
                        |
                      </span>
                      <div
                        className={styles.scrollable_header__signout}
                        data-test="sign_out"
                        onClick={() => this.onSignOut(this.props)}
                      >
                        <span tabIndex={0}> {signOut}</span>
                        <div className={styles.signOut_iconRight}>
                          <Icon iconName="icon-chevron-normal-right" />
                        </div>
                      </div>
                    </div>

                    <div className={styles.scrollable_header__info}>
                      <div className={styles.scrollable_header__logintime}>
                        {lastLoginText}: {lastLogin}
                      </div>
                      {links.length > 1 ? (
                        <div
                          className={styles.scrollable_header__dropdown_account}
                        >
                          <ClickAwayListener
                            clickAway={() => accountClickAway()}
                          >
                            {/* Account Dropdown */}
                            <SelectDropdown
                              options={accountOptions}
                              toggleIsOpen={accountToggleIsOpen}
                              dropdownIsOpen={accountDropdownIsOpen}
                              updateSelectTitle={accountUpdateSelectTitle}
                              dropdownTitle={accountDropdownTitle}
                              dropdownType={accountDropdownType}
                              updatableDropdownTitle={
                                accountUpdatableDropdownTitle
                              }
                              classNames={
                                styles.scrollable_header__select_dropdown_account
                              }
                            />
                          </ClickAwayListener>
                        </div>
                      ) : (
                        <div className={styles.scrollable_header__account}>
                          {account}
                        </div>
                      )}
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
          {showStickyHeader ? (
            <div
              id="header"
              className={bindCss({
                sticky_header: true,
                sticky_header__sticky_feature: isHeaderSticky,
              })}
            >
              <div className={styles.sticky_header__body}>
                <div className={styles.sticky_header__info_membershipServices}>
                  {AuthenticationCheck() ? (
                    <div
                      className={
                        styles.sticky_header__info_membershipServices_account
                      }
                    >
                      <div
                        className={styles.sticky_header__membership_icon}
                        onClick={() => this.redirectToHome(this.props)}
                        title="back to Home"
                      >
                        <Icon iconName="icon-home" />
                      </div>
                      <div>{membershipServices}</div>
                    </div>
                  ) : null}
                  {window.screen.width > viewPortWidth
                    ? this.renderBreadCrum()
                    : null}
                </div>

                {AuthenticationCheck() ? (
                  <div className={styles.sticky_header__info_customSelect}>
                    <ClickAwayListener clickAway={() => clickAway()}>
                      {/* Quick Access Dropdown */}
                      <SelectDropdown
                        options={options}
                        toggleIsOpen={toggleIsOpen}
                        dropdownIsOpen={dropdownIsOpen}
                        updateSelectTitle={updateSelectTitle}
                        dropdownTitle={dropdownTitle}
                        dropdownType={dropdownType}
                        classNames={
                          styles.sticky_header__info_customSelect_component
                        }
                      />
                    </ClickAwayListener>
                  </div>
                ) : null}
                {window.screen.width < viewPortWidth
                  ? this.renderBreadCrum()
                  : null}
              </div>
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }
}

export default withRouter(HeaderComponent);
